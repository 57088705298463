<!-- <h1 *ngIf="tab === 'account'">{{ "profile.account-settings" | translate }}</h1>
<h1 *ngIf="tab === 'personal'">{{ "profile.your-profile" | translate }}</h1>
<h1 *ngIf="tab === 'musical'">{{ "profile.musical-profile" | translate }}</h1>
<h1 *ngIf="tab === 'membership' && subrole === SubRole.TEACHER_SELF">
	{{ "profile.your-membership" | translate }}
</h1>
<h1 *ngIf="tab === 'membership' && subrole === SubRole.STUDENT_MATCHED">
	{{ "profile.your-membership" | translate }}
</h1>
<h1 *ngIf="tab === 'notifications'">
	{{ "notifications-settings.title" | translate }}
</h1> -->

<div class="flex m-auto flex-col profile-container">
	<ul class="tab-menu rounded-t-border-radius">
		<li>
			<a [routerLink]="['/platform/profile/account']" [routerLinkActive]="['is-active']" [routerLinkActiveOptions]="{ exact: true }">{{
				"profile.account-tab" | translate
				}}</a>
		</li>
		<li *accessControl="Resources.PROFILE_PERSONAL">
			<a [routerLink]="['/platform/profile/personal']" [routerLinkActive]="['is-active']">{{ "profile.profile-tab" | translate }}</a>
		</li>
		<li>
			<a [routerLink]="['/platform/profile/music']" [routerLinkActive]="['is-active']">
				<span class="max-md:hidden">{{ "profile.musical-tab" | translate }}</span>
				<span class="md:hidden">{{ "profile.musical-tab-mobile" | translate }}</span>
			</a>
		</li>

		<li *accessControl="Resources.PROFILE_SUBSCRIPTION">
			<a [routerLink]="['/platform/profile/membership']" [routerLinkActive]="['is-active']">
				<span class="max-md:hidden" [innerHTML]="'profile.membership-tab' | translate"></span>
				<span class="md:hidden" [innerHTML]="'profile.membership-tab-mobile' | translate"></span>
			</a>
		</li>
		<li *accessControl="Resources.PROFILE_MEMBERSHIP">
			<a [routerLink]="['/platform/profile/membership']" [routerLinkActive]="['is-active']">
				<span class="max-md:hidden" [innerHTML]="'profile.membership-tab' | translate"></span>
				<span class="md:hidden" [innerHTML]="'profile.membership-tab-mobile' | translate"></span>
			</a>
		</li>
		<li *accessControl="Resources.PROFILE_NOTIFICATIONS">
			<a [routerLink]="['/platform/profile/notifications']" [routerLinkActive]="['is-active']" [routerLinkActiveOptions]="{ exact: true }">{{ "profile.notifications-tab" | translate }}</a>
		</li>
	</ul>

	<div class="dropdown-menu">
		<mat-select [value]="tab" class="s-mat-select app-select" panelClass="s-mat-checkbox app-select-panel s-mat-select-panel border">
			<mat-option [routerLink]="['/platform/profile']" value="account">
				{{ "profile.account-tab" | translate }}
			</mat-option>
			<mat-option *accessControl="Resources.PROFILE_PERSONAL" [routerLink]="['/platform/profile/personal']" value="personal">
				{{ "profile.profile-tab" | translate }}
			</mat-option>
			<mat-option *ngIf="subrole === SubRole.TEACHER_SELF || subrole === SubRole.STUDENT_MATCHED || subrole === SubRole.ORGANISATION_TEACHER" [routerLink]="['/platform/profile/music']" value="music">
				{{ "profile.musical-tab" | translate }}
			</mat-option>
			<mat-option *accessControl="Resources.PROFILE_SUBSCRIPTION" [routerLink]="['/platform/profile/membership']" value="membership">
				{{ "profile.membership-tab" | translate }}
			</mat-option>
			<mat-option *accessControl="Resources.PROFILE_MEMBERSHIP" [routerLink]="['/platform/profile/membership']" value="membership">
				{{ "profile.membership-tab" | translate }}
			</mat-option>
			<mat-option *accessControl="Resources.PROFILE_NOTIFICATIONS" [routerLink]="['/platform/profile/notifications']" value="notifications">
				{{ "profile.notifications-tab" | translate }}
			</mat-option>
		</mat-select>
	</div>
	<form *ngIf="tab !== 'notifications'" class="profile-table" [formGroup]="form" autocomplete="off">
		<!-- for stopping autofill -->
		<input autocomplete="false" name="hidden" type="text" style="display: none" />
		<input autocomplete="false" name="hidden2" type="password" style="display: none" />
		<div *ngIf="tab === 'account'" formGroupName="account">
			<!-- <h1>{{ "profile.account-settings" | translate }}</h1> -->
			<div class="form-row">
				<div class="form-label">
					{{ "profile.first-name-label" | translate }}
				</div>
				<div class="form-input">
					<input formControlName="firstName" type="text" />
					<div class="error-message" *ngIf="hasRequiredError('account.firstName')">
						{{ "forms.required" | translate }}
					</div>
				</div>
			</div>
			<div class="form-row">
				<div class="form-label">
					{{ "profile.last-name-label" | translate }}
				</div>
				<div class="form-input">
					<input formControlName="lastName" type="text" />
					<div class="error-message" *ngIf="hasRequiredError('account.lastName')">
						{{ "forms.required" | translate }}
					</div>
				</div>
			</div>
			<div formGroupName="emailGroup">
				<div class="form-row">
					<div class="form-label">
						{{ "profile.email-label" | translate }}
					</div>
					<div class="form-input">
						<input formControlName="email" type="text" />
						<div class="error-message" *ngIf="hasRequiredError('account.emailGroup.email')">
							{{ "forms.required" | translate }}
						</div>
						<div class="error-message" *ngIf="hasPatternError('account.emailGroup.email')">
							{{ "forms.invalid-format" | translate }}
						</div>
						<div class="error-message" *ngIf="accountGroup.get('emailGroup.email').hasError('emailExists')">
							{{ "sign-up.email-exists" | translate }}
						</div>

						<div *ngIf="displayPassEmail" class="mt-2">
							<div>
								{{ "profile.password-label" | translate }}
							</div>
							<div class="password-field-outer">
								<input autocomplete="new-password" [type]="isShowPassEmail ? 'text' : 'password'" formControlName="password" [placeholder]="'profile.password-placeholder' | translate" />
								<div class="toggle-password-visibility" [ngClass]="[isShowPassEmail ? 'show' : 'hide']" (click)="toggleShowPassEmail()"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div formGroupName="passwordGroup">
				<div class="form-row">
					<div class="form-label">
						{{ "profile.login-password-label" | translate }}
					</div>
					<div class="form-input">
						<div class="password-field-outer mb-2">
							<input autocomplete="off" [type]="isShowPass || !passwordHasBeenTouched ? 'text' : 'password'" formControlName="password" [placeholder]="'profile.old-password-placeholder' | translate" (focus)="handlePasswordFocus()" />
							<div class="toggle-password-visibility" [ngClass]="[isShowPass ? 'show' : 'hide']" (click)="toggleShowPass()"></div>
						</div>
						<div class="w-full">
							<div class="field-new-password">
								<div class="password-field-outer">
									<input autocomplete="off" [type]="isShowNewPass || !passwordHasBeenTouched ? 'text' : 'password'" formControlName="newPassword" [placeholder]="'profile.new-password-placeholder' | translate" />
									<div class="toggle-password-visibility" [ngClass]="[isShowNewPass ? 'show' : 'hide']" (click)="toggleShowNewPass()"></div>
								</div>
								<div class="error-message" *ngIf="
										form.get('account.passwordGroup.newPassword').dirty &&
										form.get('account.passwordGroup.newPassword').invalid
									">
									{{ "profile.new-password-error" | translate }}
								</div>
								<div class="mt-2">
									<app-password-requirements class="password-requirements" [hasEnoughPassLength]="hasEnoughPassLength" [hasNumberOrSpecial]="hasNumberOrSpecial" [isNotEmpty]="isNotEmpty">
									</app-password-requirements>
								</div>
							</div>

							<div class="password-field-outer">
								<input autocomplete="off" [type]="isShowConfPass || !passwordHasBeenTouched ? 'text' : 'password'" formControlName="confirmPassword" [placeholder]="'profile.confirm-password-placeholder' | translate" />
								<div class="toggle-password-visibility" [ngClass]="[isShowConfPass ? 'show' : 'hide']" (click)="toggleShowConfPass()"></div>
							</div>
							<div class="error-message" *ngIf="
									form.get('account.passwordGroup.newPassword').touched &&
									form.get('account.passwordGroup').errors?.passwordsMismatch
								">
								{{ "profile.passwords-mismatch-error" | translate }}
							</div>
						</div>
						<div class="error-message" *ngIf="form.get('account.passwordGroup.password').hasError('is')">
							{{ "lobby-popup-desktop.password-incorrect" | translate }}
						</div>
					</div>
				</div>
			</div>
			<div *showForSubroles="['teacherMatched']" class="form-row">
				<div class="form-label">
					{{ "teacher-onboarding.phone.title" | translate }}
				</div>
				<div class="form-input">
					<app-intl-tel-input formControlName="phoneNumber"></app-intl-tel-input>
					<div class="error-message" *ngIf="hasRequiredError('account.phoneNumber')">
						{{ "forms.required" | translate }}
					</div>
				</div>
			</div>
			<div class="form-row">
				<div class="form-label">
					{{ "profile.language-label" | translate }}
				</div>
				<div class="form-input">
					<mat-select formControlName="language" class="s-mat-select app-select" panelClass="s-mat-checkbox app-select-panel s-mat-select-panel border">
						<mat-option *ngFor="let language of languages" [value]="language._id">
							{{ language.name }}
						</mat-option>
					</mat-select>
				</div>
			</div>
			<div class="form-row">
				<div class="form-label">
					{{ "profile.timezone-label" | translate }}
				</div>
				<div class="form-input">
					<mat-select formControlName="timezone" class="s-mat-select app-select" panelClass="s-mat-checkbox app-select-panel s-mat-select-panel border">
						<mat-option *ngFor="let timezone of timezones" [value]="timezone">
							{{ timezone }}
						</mat-option>
					</mat-select>
				</div>
			</div>
			<!-- <div *showForSubroles="['teacherMatched']" class="form-row">
				<div class="form-label">
					{{ "profile.beta-testing-label" | translate }}
				</div>
				<div class="form-input">
					<mat-select
						formControlName="betaTesting"
						class="s-mat-select app-select border"
						panelClass="s-mat-checkbox app-select-panel s-mat-select-panel border"
					>
						<mat-option
							*ngFor="let option of betaTestingOptions"
							[value]="option.value"
						>
							{{ option.title | translate }}
						</mat-option>
					</mat-select>
				</div>
			</div> -->
		</div>

		<div *ngIf="tab === 'personal'" formGroupName="personal">
			<!-- <h1>{{ "profile.your-profile" | translate }}</h1> -->
			<div class="photo-row">
				<div class="form-label">
					{{ "profile.photo-label" | translate }}
				</div>
				<div class="form-input pt-[6px]">
					<app-photo-upload formControlName="photo"></app-photo-upload>
					<div *ngIf="photo.hasError('is')" class="error-message">
						{{ "forms.photo-error" | translate }}
					</div>
				</div>
			</div>
			<!-- <div class="form-row">
				<div class="form-label">
					{{ "profile.age-label" | translate }}
				</div>
				<div class="form-input">
					<input formControlName="dob" class="dob" type="date" [min]="minDate" [max]="maxDate" />
					<div *ngIf="hasRequiredError('personal.dob')" class="error-message">
						{{ "teacher-onboarding.required-error" | translate }}
					</div>
				</div>
			</div> -->
			<div class="form-row">
				<div class="form-label">
					{{ "profile.gender-label" | translate }}
				</div>
				<div class="form-input">
					<mat-select formControlName="gender" class="s-mat-select app-select" panelClass="s-mat-checkbox app-select-panel s-mat-select-panel border" [placeholder]="'teacher-onboarding.gender.placeholder' | translate">
						<mat-option *ngFor="let g of gendersOptions" [value]="g.value">
							{{ g.title | translate }}
						</mat-option>
					</mat-select>
					<input *ngIf="isOtherGender" class="mt-2" formControlName="otherGender" [placeholder]="'teacher-onboarding.gender.placeholder2' | translate" type="text" />
					<div *ngIf="hasRequiredError('personal.gender')" class="error-message">
						{{ "teacher-onboarding.required-error" | translate }}
					</div>
				</div>
			</div>
			<div class="form-row">
				<div class="form-label">
					{{
					(subrole === SubRole.STUDENT_MATCHED ? "profile.about-you-label-student" : "profile.about-you-label-teacher")
					| translate
					}}
				</div>
				<div class="form-input">
					<textarea formControlName="teacherIntroduction" class="w-full app-textarea" rows="3" [placeholder]="
							(subrole === SubRole.STUDENT_MATCHED
								? 'profile.description-placeholder-student'
								: 'profile.description-placeholder-teacher'
							) | translate
						"></textarea>
					<div *ngIf="hasRequiredError('personal.teacherIntroduction')" class="error-message">
						{{ "teacher-onboarding.required-error" | translate }}
					</div>
				</div>
			</div>
			<div class="form-row" formGroupName="socials">
				<div class="form-label">
					<div>{{ "profile.socials-label-1" | translate }} /</div>
					<div>
						{{ "profile.socials-label-2" | translate }}
					</div>
				</div>
				<div class="form-input">
					<input type="text" [placeholder]="'teacher-onboarding.social-media.placeholder' | translate" formControlName="link1" />
					<input *ngIf="numberOfLinks > 1" type="text" class="mt-2" formControlName="link2" />
					<input *ngIf="numberOfLinks > 2" type="text" class="mt-2" formControlName="link3" />
					<input *ngIf="numberOfLinks > 3" type="text" class="mt-2" formControlName="link4" />
					<input *ngIf="numberOfLinks > 4" type="text" class="mt-2" formControlName="link5" />
					<button *ngIf="numberOfLinks < 5" class="s-mat-button s-mat-primary add-link-btn mt-[6px]" (click)="addLink()">
						<svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" class="inline-flex h-4 w-4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
							<line x1="12" y1="5" x2="12" y2="19"></line>
							<line x1="5" y1="12" x2="19" y2="12"></line>
						</svg>
						<span>{{ "buttons.add-link" | translate }}</span>
					</button>
					<div *ngIf="hasRequiredError('personal.socials.link1')" class="error-message">
						{{ "teacher-onboarding.required-error" | translate }}
					</div>
				</div>
			</div>
		</div>

		<div *ngIf="tab === 'music'" formGroupName="music">
			@if(subrole === SubRole.STUDENT_MATCHED ||
			subrole === SubRole.TEACHER_SELF ||
			subrole === SubRole.ORGANISATION_TEACHER ||
			subrole === SubRole.STUDENT_EXTERNAL){

			<div class="mb-12" [formGroup]="iControl" *ngFor="let iControl of instrumentsFormArray.controls; let index = index">

				<div class="form-row">
					<div class="form-label">
						{{ "teacher-onboarding.instrument.title" | translate }}
					</div>
					<div class="form-input">
						<mat-select class="s-mat-select app-select" panelClass="s-mat-checkbox app-select-panel s-mat-select-panel border" formControlName="instrument" [placeholder]="'teacher-onboarding.instrument.placeholder' | translate">
							<mat-option *ngFor="let i of instrumentsOptions" [value]="i.value">
								{{ i.title }}
							</mat-option>
						</mat-select>
					</div>
				</div>
				<div class="form-row">
					<div class="form-label">
						{{ "teacher-onboarding.genres.title" | translate }}
					</div>
					<div class="form-input">
						<mat-select class="s-mat-select app-select" panelClass="s-mat-checkbox app-select-panel s-mat-select-panel border" multiple formControlName="genre" [placeholder]="'teacher-onboarding.genres.placeholder' | translate">
							<mat-option *ngFor="let g of genresOptions" [value]="g.value">
								{{ g.title }}
							</mat-option>
						</mat-select>
					</div>
				</div>
				@if (subrole === SubRole.TEACHER_SELF || subrole === SubRole.ORGANISATION_TEACHER) {
				<div class="form-row">
					<div class="form-label">
						{{ "teacher-onboarding.higherEducation.title" | translate }}
					</div>
					<div class="form-input">
						<input matInput formControlName="higherEducation" [placeholder]="'teacher-onboarding.higherEducation.placeholder' | translate" type="text" />
					</div>
				</div>
				<div class="form-row">
					<div class="form-label">
						{{ "teacher-onboarding.degree.title" | translate }}
					</div>
					<div class="form-input">
						<input matInput formControlName="degree" [placeholder]="'teacher-onboarding.degree.placeholder' | translate" type="text" />
					</div>
				</div>
				<div class="form-row">
					<div class="form-label">
						{{ "teacher-onboarding.degreeDescription.title" | translate }}
					</div>
					<div class="form-input">
						<textarea class="w-full app-textarea" rows="4" formControlName="degreeDescription" [placeholder]="'teacher-onboarding.degreeDescription.placeholder' | translate" type="text">
					</textarea>
					</div>
				</div>

				<!-- <div class="bg-cold-offwhite p-4">
							<div class="font-bold mb-px">
								{{ "teacher-onboarding.certification.title" | translate }}
							</div>
							<div class="mb-2">
								{{ "teacher-onboarding.certification.subtitle" | translate }}
							</div>
							<div class="mb-2">
								{{ "teacher-onboarding.certification.formats" | translate }}
							</div>
							<input type="file" class="hidden" (change)="onFileSelected($event, index)" #fileUpload>
							<button class="s-mat-button" mat-flat-button color="accent" (click)="fileUpload.click()">
								{{ "teacher-onboarding.certification.upload" | translate }}
							</button>
				</div> -->
				<div class="form-row" *ngIf="index !== 0">
					<div class="form-label"></div>
					<div class="form-input">
						<button class="add-instrument" (click)="deleteInstrument(index)">
							{{ "buttons.remove-instrument" | translate }}
							<div class="ml-1">
								<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="currentColor">
									<path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z" />
								</svg>
							</div>
						</button>
					</div>
				</div>
				}

			</div>

			@if (subrole === SubRole.TEACHER_SELF || subrole === SubRole.ORGANISATION_TEACHER) {
			<button class="add-instrument" (click)="addInstrument()">
				{{'buttons.add-instrument' | translate}}
				<div class="ml-1">
					<svg stroke="currentColor" fill="none" stroke-width="3" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" class="inline-flex h-4 w-4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
						<line x1="12" y1="5" x2="12" y2="19"></line>
						<line x1="5" y1="12" x2="19" y2="12"></line>
					</svg>
				</div>
			</button>
			}
			}


		</div>

		<div *ngIf="tab === 'membership' && subrole === SubRole.TEACHER_SELF">
			<h1 class="text-lg mb-1">
				{{ "profile.your-membership" | translate }}
			</h1>
			<div class="form-row !mb-0">
				<div class="form-label">
					{{ "profile.subscription" | translate }}
				</div>
				<div class="form-input">
					<div class="flex mb-6">
						<input type="text" [value]="tariffLabel | translate" disabled />
					</div>
					<button *ngIf="!isShowSubscriptionMessage" class="s-mat-button save" mat-flat-button color="primary" (click)="isShowSubscriptionMessage = true">
						{{ "buttons.request-change" | translate }}
					</button>
					<div *ngIf="isShowSubscriptionMessage">
						<div class="text-[16px] payment-message" [innerHTML]="'profile.terminate-subscription' | translate"></div>
						<div class="text-[16px] mt-2 payment-message" [innerHTML]="'profile.payout-change-request' | translate"></div>
					</div>
				</div>
			</div>
		</div>

		<div *ngIf="tab === 'membership' && subrole === SubRole.STUDENT_MATCHED">
			<!-- <h1 class="text-lg mb-1">
				{{ "profile.your-membership" | translate }}
			</h1> -->
			<div class="form-row !mb-0">
				<div class="form-label">
					{{ "profile.billing-label" | translate }}
				</div>
				<div class="form-input">
					<div class="flex mb-6">
						<a class="billing-link flex align-middle text-black pt-[6px] font-bold" (click)="openPortal()">
							<span class="underline">
								{{ "profile.billing-link" | translate }}
							</span>
							<mat-icon class="portal-icon billing-link">open_in_new</mat-icon>
						</a>
					</div>
					<button *ngIf="!isShowPackageMessage" class="s-mat-button save" mat-flat-button color="primary" (click)="openTypeformPopUp()">
						{{ "buttons.manage-membership" | translate }}
					</button>
					<div *ngIf="isShowPackageMessage">
						<div class="text-[16px] payment-message" [innerHTML]="'profile.package-change-request' | translate"></div>
						<div class="text-[16px] mt-2 payment-message" [innerHTML]="'profile.payout-change-request' | translate"></div>
					</div>
				</div>
			</div>
		</div>

		<div class="flex md:justify-end w-full pt-6">
			<div *ngIf="tab !== 'membership'" class="form-input">
				<button class="s-mat-button save" mat-flat-button color="primary" (click)="saveTab(tab)" [disabled]="!isDirty(tab)">
					{{ "buttons.save-changes" | translate }}
				</button>
				<span *ngIf="isSavingError" class="error-message ml-2">
					{{ "forms.error" | translate }}
				</span>
				<span *ngIf="isSavedMessage" class="text-sirius-lilac ml-2">
					{{ "buttons.saved" | translate }}
				</span>
			</div>
		</div>
	</form>
	<app-notifications-settings *ngIf="tab === 'notifications'"> </app-notifications-settings>
</div>
<form class="notice-container" [formGroup]="limitFrom" novalidate>
    <div class="title-desc-wrapper">
        <div class="notice-title">
            {{'availability.day-limit-title' | translate}}
        </div>
        <div class="notice-description">
            {{'availability.day-limit' | translate}}
        </div>
    </div>
    <input numbersOnly #el formControlName="limit" [ngClass]="{'error': limitFrom.controls.limit.invalid}" type="number"
        min="14" autocapitalize="none" autocomplete="off" autocorrect="off" spellcheck="false" tabindex="0"
        aria-autocomplete="list" aria-expanded="false" aria-haspopup="true" role="combobox">
    <span class="ml-1">
        {{'availability.day-limit-input-label' | translate}}

    </span>
    <div class="error-message" *ngIf="limitFrom.controls.limit.invalid && limitFrom.controls.limit.touched">
        <span class="text-xs text-alert">{{'availability.day-limit-input-error' | translate}}</span>
        <!-- {{'availability.error-message' | translate}} -->
    </div>
</form>
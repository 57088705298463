import { PitchDetector as M } from "pitchy";
var y = 69,
  D = ["C", "C\u266F", "D", "D\u266F", "E", "F", "F\u266F", "G", "G\u266F", "A", "A\u266F", "B"],
  S = {
    a4: 440,
    clarityThreshold: .9,
    minVolumeDecibels: -1e3,
    bufferSize: 8192,
    smoothingTimeConstant: .8,
    minFrequency: 27.5,
    maxFrequency: 4186.01,
    updateInterval: 50,
    sampleRate: 44100
  };
function x(e = {}) {
  e = {
    ...S,
    ...e
  };
  let n = new AudioContext({
      sampleRate: e.sampleRate
    }),
    f = new BiquadFilterNode(n, {
      type: "highpass",
      frequency: e.minFrequency
    }),
    l = new BiquadFilterNode(n, {
      type: "lowpass",
      frequency: e.maxFrequency
    }),
    s = new AnalyserNode(n, {
      fftSize: e.bufferSize,
      smoothingTimeConstant: e.smoothingTimeConstant
    });
  l.connect(f).connect(s);
  let u = M.forFloat32Array(s.fftSize);
  u.minVolumeDecibels = e.minVolumeDecibels;
  let d = new Float32Array(u.inputLength),
    a,
    h,
    p = 0;
  async function F() {
    a = await navigator.mediaDevices.getUserMedia({
      audio: !0
    }), h = n.createMediaStreamSource(a), h.connect(l), p = setInterval(T, e.updateInterval);
  }
  function T() {
    let {
      clarityThreshold: t,
      minFrequency: i,
      maxFrequency: r,
      onNote: m
    } = e;
    s.getFloatTimeDomainData(d);
    let [o, c] = u.findPitch(d, n.sampleRate);
    c > t && o > i && o < r && m?.(b(o, c));
  }
  function b(t, i) {
    let r = Math.round(12 * (Math.log(t / e.a4) / Math.log(2))) + y,
      m = e.a4 * Math.pow(2, (r - y) / 12),
      o = Math.floor(1200 * Math.log(t / m) / Math.log(2)),
      c = D[r % 12],
      q = Math.floor(r / 12) - 1;
    return {
      frequency: t,
      name: c,
      value: r,
      cents: o,
      octave: q,
      clarity: i
    };
  }
  async function v() {
    clearInterval(p), a.getTracks().forEach(t => t.stop()), a.removeTrack(a.getAudioTracks()[0]);
  }
  return {
    start: F,
    stop: v,
    context: n,
    analyser: s,
    detector: u,
    config: e,
    getNote: b
  };
}
export { S as TunerDefaults, x as createTuner };

import { animate, style, transition, trigger } from "@angular/animations";
export const emojiFade = trigger("emojiFade", [
  transition(":enter", [
    style({ opacity: 0 }),
    animate("25ms", style({ opacity: 1 })),
  ]),
  transition(":leave", [animate("250ms", style({ opacity: 0 }))]),
]);
export const emojiSlide = trigger("emojiSlide", [
  transition(":enter", [
    style({ transform: "translateY(100%)" }),
    animate("175ms", style({ transform: "translateY(0)" })),
  ])
]);

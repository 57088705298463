<div class="end-call-container">
  <h1>
    {{ 'endCall.thanks' | translate }}
  </h1>
  <h2 *ngIf="isDesktop">
    {{ 'endCall.please' | translate }} <a [href]="contactLink">{{ 'endCall.contactUs' | translate }}</a>{{ 'endCall.forDetails' | translate }}
  </h2>

  <h5 *ngIf="!isDesktop">
    {{ 'endCall.thanks' | translate }}
  </h5>
  <h6 *ngIf="!isDesktop">
    {{ 'endCall.please' | translate }} <a [href]="contactLink">{{ 'endCall.contactUs' | translate }}</a>{{ 'endCall.forDetails' | translate }}
  </h6>
</div>

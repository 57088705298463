<form class="list-box-item" [formGroup]="selectListForm" novalidate>
	<div class="duplicate-list">
		<div class="shadow-dropdown w-50 relative z-10 mt-1 -ml-0 origin-top-right  bg-white"
		>
			<div class="space-y-2 py-2">
				<div class="p-2"><p class="title h6 pb-3 pl-1 text-xs font-medium uppercase">
					{{'availability.duplicate-modal-description' | translate}}</p>
					<ol class="space-y-2">
						<li *ngFor="let data of days; let i = index">
							<label class="label flex w-full items-center justify-between">
							<span class="px-1">{{ data }}</span>
							<input
								[defaultChecked]="data === selectedDay"
								[disabled]="data === selectedDay"
								type="checkbox"
								[value]="data"
								(change)="onCheckboxChange($event)"
								class="checkbox">
						</label>
						</li>
					</ol>
				</div>
				<hr>
				<div class="space-x-2 btn-container">
					<button (click)="close.emit()" class="cancel" type="button">
						{{'availability.duplicate-cancel-btn' | translate}}
					</button>
					<button [disabled]="!selectListForm.valid" (click)="submit()" class="apply" type="button">
						{{'availability.duplicate-apply-btn' | translate}}
					</button>
				</div>
			</div>
		</div>
	</div>
</form>

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthAdminService } from './services/auth-admin.service';
import { Subscription } from 'rxjs';
import { UsersDataService } from './services/users-data.service';
import { PlatformDetectorService } from '../../services/platform-detector/platform-detector.service';
import { PopupService } from '../../services/popup/popup.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
  providers: [UsersDataService]
})
export class AdminComponent implements OnInit, OnDestroy {
  isLogin: boolean = false;
  private isLoginSubscribe: Subscription;
  constructor(private auth: AuthAdminService,
              private route: Router,
              private platformDetectorService: PlatformDetectorService,
              private popupService: PopupService) { }

  ngOnInit(): void {
    if (!this.platformDetectorService.isDesktop()) {
      this.popupService.openWarningPopup('SIRIUS Admin tool can be used only in the desktop web browsers.');
    }
    this.isLoginSubscribe = this.auth.token$
      .subscribe(token => {
        this.isLogin = !!token;
      });
  }
  ngOnDestroy(): void {
    if (this.isLoginSubscribe) {
      this.isLoginSubscribe.unsubscribe();
    }
  }

}

<div *ngIf="subrole === 3">
	<div
		data-vl-container="sharing-stage"
		data-vl-campaign-id="w17nn3SVdhBy9bu8StDOlEj6MmU"
	>
		<form-widget ucid="w17nn3SVdhBy9bu8StDOlEj6MmU"></form-widget>
	</div>
	<div class="mt-8">
		<rewards-widget ucid="w17nn3SVdhBy9bu8StDOlEj6MmU"></rewards-widget>
	</div>
</div>
<div *ngIf="subrole === 6">
	<div
		data-vl-container="sharing-stage"
		data-vl-campaign-id="l7G2rQDbpaDkNLgQFJPOVC5HKF0"
	>
		<form-widget ucid="l7G2rQDbpaDkNLgQFJPOVC5HKF0"></form-widget>
	</div>
	<div class="mt-8">
		<rewards-widget ucid="l7G2rQDbpaDkNLgQFJPOVC5HKF0"></rewards-widget>
	</div>
</div>

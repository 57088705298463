<canvas #canvas *ngIf="blurBg"></canvas>
<!-- <div class="stream-statistics">
   <span class="text-white" *ngIf="techData.videoInbound">Audio Inbound Packets lost:
      {{techData.videoInbound.packetsLost}}
   </span>
</div> -->


<div class="absolute text-xs z-50 p-1 release-warning top-2 left-2 bg-slate-600" *ngIf="lowBandwidth">
    <span class="text-xs text-white">{{ 'call-room.bad-connection' | translate }} </span>
    <!-- <span>{{ 'call-room.bad-connection' | translate }} </span> -->
</div>
<video #video [volume]="volume" [ngClass]="{'video-width-100': isVideoWidth100, 'video-height-100': isVideoHeight100,
   'full-cover': isFullCover, 'mirrored': isMirrored}" [autoplay]="true" style="pointer-events: none" playsinline
    preload="auto" [muted]="muted"></video>
<div class="page">

  <app-account-upgrade-form class="form"></app-account-upgrade-form>
  <app-account-delete-form class="form"></app-account-delete-form>

  <div class="org-list">
    <div class="controls">
      <button class="blue-ui-button new-button"
              (click)="onNewClicked()">Create New</button>
    </div>
    <app-organization *ngFor="let org of organizations" [organization]="org" (orgUpdate)="updateList()">
    </app-organization>
  </div>
  <div class="pagination" *ngIf="maxPages >1">
    <a *ngIf="pageNumber - 1 > 0"
       [routerLink]="['/admin']"
       [queryParams]="{ page : pageNumber - 1 }">
      <
    </a>
    <a *ngFor="let i of pages"
       [class.selected]="pageNumber == i"
       [routerLink]="['/admin']"
       [queryParams]="{ page : i }">
      {{i}}
    </a>
    <a *ngIf="pageNumber < maxPages"
       [routerLink]="['/admin']"
       [queryParams]="{ page : pageNumber + 1 }">
      >
    </a>
  </div>
</div>

import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from './authentication.service';

export interface CheckRegistration {
  status: boolean;
  email?: string;
}

@Injectable()
export class CheckRegistrationResolver  {

  constructor(
    private authenticationService: AuthenticationService,
  ) {

  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<CheckRegistration | null> {
    const registerKey = route.paramMap.get('key');
    if (!registerKey) {
      return null;
    }
    return this.authenticationService.checkRegistration$(registerKey).toPromise()
      .then((responseData: any | HttpErrorResponse) => {
        const response: any = (responseData as any);
        const checkRegistration: CheckRegistration = {
          status: response.status,
          email: response.email
        };
        return checkRegistration;
      })
      .catch((err) =>
        null);
  }
}

import { UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export const confirmPasswordValidator: ValidatorFn = (control: UntypedFormGroup): ValidationErrors | null => {
  let result = null;
  const newPassword = control.get('newPassword');
  const confirmedPassword = control.get('confirmPassword');
  const errorName = 'passwordsMismatch';

  // Reset previous errors
  [newPassword, confirmedPassword].forEach(control => {
    if (control.hasError(errorName)) {
      delete control.errors[errorName];
      control.updateValueAndValidity({ onlySelf: true});
    }
  });

  if (
    newPassword
    && newPassword.dirty
    && confirmedPassword
    && confirmedPassword.dirty
    && newPassword.value !== confirmedPassword.value
  ) {
    result = { [errorName]: true };

    // Highlight invalid fields
    newPassword.setErrors(result);
    confirmedPassword.setErrors(result);
  }

  return result;
};

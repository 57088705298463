<ng-container *ngIf="tasksContent && notesContent">
    <div class="px-6 pb-4">
        <h2 class="mt-3 font-semibold">{{ 'notes-editor.tasks' | translate }}</h2>
        <app-quill [isMiniEditor]="isMiniEditor" [content]="tasksContent" (onChange)="onContentChanged($event,'tasks')" [isStudent]="isStudent"></app-quill>
    </div>
    <hr>
    <div class="px-6 pb-4">
        <h2 class="mt-3 font-semibold">{{ 'notes-editor.general-notes' | translate }}</h2>
        <app-quill [isMiniEditor]="isMiniEditor" [content]="notesContent" (onChange)="onContentChanged($event,'notes')" [isStudent]="isStudent"></app-quill>
    </div>
    <div class="w-full px-6 bottom-toolbar">
        <div *ngIf="isShowSaveMessage"
            class="flex items-center justify-center pr-3 text-xs align-middle text-sirius-lilac">{{'buttons.saved' |
            translate}}</div>
        <button *ngIf="!isStudent" [disabled]="isSaveDisabled" class="!rounded-full  blue-ui-button px-2.5" (click)="onClickSave()">
            <span class="font-light text-[1rem] align-middle save-button material-symbols-outlined">
                save
            </span>
            <span class="align-middle text-[0.8rem]">
                {{'buttons.save' | translate }}
            </span>
        </button>
    </div>
</ng-container>
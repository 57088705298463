import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { CookiesService } from '../services/cookies/cookies.service';
import { BotService } from '../services/bot/bot.service';

@Component({
  selector: 'app-cookies-policy',
  templateUrl: './cookies-policy.component.html',
  styleUrls: [
    './cookies-policy.component.scss',
    './cookies-policy.component.tablet.scss',
    './cookies-policy.component.mobile.scss',
  ],
})
export class CookiesPolicyComponent implements OnInit {
  public showCookiesPolicy = false;
  private cookieName = 'cookies_accepted';
  @Output() accept = new EventEmitter();

  isBot(): boolean {
    return BotService.isBot;
  }
  constructor(
    private cookiesService: CookiesService
  ) { }

  ngOnInit(): void {
    this.showCookiesPolicy = !this.cookiesService.get(this.cookieName);
  }

  acceptCookies(): void {
    this.cookiesService.set(this.cookieName, new Date().toUTCString(), 365 * 24 * 60 * 60 * 1000);
    this.showCookiesPolicy = false;
    this.accept.next(true);
  }
}

import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {CallStorage} from '../components/call-room/service/storage/call-storage.service';
import {TranslateService} from '@ngx-translate/core';
import {PlatformDetectorService} from '../services/platform-detector/platform-detector.service';
import {CONTACT_URL} from '../constants/generic.constants';

@Component({
  selector: 'app-end-call',
  templateUrl: './end-call.component.html',
  styleUrls: ['./end-call.component.scss']
})
export class EndCallComponent implements OnInit {

  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  contactLink: string = CONTACT_URL;

  constructor(public callStorage: CallStorage,
              private translateService: TranslateService,
              protected platformDetectorService: PlatformDetectorService,
              private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.isMobile = this.platformDetectorService.isMobile();
    this.isTablet = this.platformDetectorService.isTablet();
    this.isDesktop = this.platformDetectorService.isDesktop();
    this.cd.detectChanges();
  }

}

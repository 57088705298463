import {
	Component,
	EventEmitter,
	Input,
	Output,
	OnChanges,
	SimpleChanges,
	ViewEncapsulation
} from "@angular/core";
import { SharedModule } from "@shared/shared.module";
import { CommonModule } from "@angular/common";
import { TableColumn, TableAction } from "src/app/types/table.type";
import { TranslateModule } from "@ngx-translate/core";
import { MatTableModule } from "@angular/material/table";
import { MatMenuModule } from "@angular/material/menu";
import { MatIconModule } from "@angular/material/icon";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSortModule } from "@angular/material/sort";

@Component({
	selector: "app-table",
	standalone: true,
	imports: [
		TranslateModule,
		MatTableModule,
		MatMenuModule,
		MatIconModule,
		MatPaginatorModule,
		MatSortModule,
		SharedModule,
		CommonModule
	],
	templateUrl: "./table.component.html",
	styleUrl: "./table.component.scss",
	encapsulation: ViewEncapsulation.None
})
export class TableComponent implements OnChanges {
	@Input() hasMenu: boolean = false;
	@Input() hasPaginator: boolean = false;
	@Input() actions: TableAction[] = [];
	@Input() columns: TableColumn[] = [];
	@Input() dataSource: any[] = [];
	@Input() pageSizeOptions: number[] = [5, 10, 25, 40];
	@Input() pageSize: number = 10;
	@Input() totalItems: number = 0;

	@Output() sort = new EventEmitter<{
		active: string;
		direction: string;
	}>();
	@Output() pageChange = new EventEmitter<{
		pageIndex: number;
		pageSize: number;
	}>();

	displayedColumns: string[] = [];

	ngOnChanges(changes: SimpleChanges): void {
		this.updateDisplayedColumns();
	}

	updateDisplayedColumns() {
		this.displayedColumns = this.columns.map((c) => c.key);
		if (this.hasMenu) {
			this.displayedColumns.push("actions");
		}
	}

	onSortChange(event: any) {
		this.sort.emit(event);
	}

	onPageChange(event: any) {
		this.pageChange.emit({
			pageIndex: event.pageIndex,
			pageSize: event.pageSize
		});
	}

	onActionClick(action: TableAction, row: any) {
		action.callback(row);
	}

	isDate(value: any): boolean {
		if (value instanceof Date) {
			return true;
		} else if (typeof value === "string") {
			const date = new Date(value);
			return !isNaN(date.getTime());
		}
		return false;
	}
}

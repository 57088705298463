import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MatTableModule } from "@angular/material/table";
import { MatPaginatorModule } from "@angular/material/paginator";
import { TranslateModule } from "@ngx-translate/core";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { EmptyListContainerComponent } from "../../../util/empty-list-container/empty-list-container.component";

@Component({
	selector: "app-call-cloud-table",
	standalone: true,
	imports: [
		// Modules
		MatTableModule,
		TranslateModule,
		MatIconModule,
		MatMenuModule,
		CommonModule,
		MatPaginatorModule,
		// Componenets
		EmptyListContainerComponent
	],
	templateUrl: "./call-cloud-table.component.html",
	styleUrl: "./call-cloud-table.component.scss"
})
export class CallCloudTableComponent {
	@Input() filesData;
	@Input() count: number;
	@Input() isTeacher: boolean;
	@Input() displayMaterial: boolean;
	@Output() uploadFileEvent = new EventEmitter<any>();
	@Output() shareEvent = new EventEmitter<any>();
	@Output() renameEvent = new EventEmitter<any>();
	@Output() deleteEvent = new EventEmitter<any>();
	@Output() pageChangeEvent = new EventEmitter<any>();

	public displayedCloudColumns: string[] = ["name", "menu"];

	uploadFile(file) {
		this.uploadFileEvent.emit(file);
	}

	shareFile(value) {
		this.shareEvent.emit(value);
	}

	pageChanged(event) {
		this.pageChangeEvent.emit(event);
	}

	downloadFile(url, fileName) {
		const anchor = document.createElement("a");
		anchor.href = url;
		anchor.target = "_blank";
		anchor.download = fileName || "download";
		document.body.appendChild(anchor);
		anchor.click();
		document.body.removeChild(anchor);
	}
}

<div class="main-info" *ngIf="!isDelete">
  <img *ngIf="organization.logo" alt='logo' [src]="organization.logo">
  <p>{{organization.name}}</p>
  <div>
    <button class="blue-ui-button"
            (click)="onEditorClick()">Edit</button>
    <button class="blue-ui-button"
            (click)="onUsersClick()">Users</button>
    <button class="orange-ui-button"
            (click)="onDelete()" >Delete</button>
  </div>
</div>
<app-organization-edit-form *ngIf="isEditor && !isDelete" [organization]="organization"
                            isEditForm="true"
                            (closing)="onClose()"
                            (submitForm)="onOrganizationEdited($event)">
</app-organization-edit-form>


import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {OrganizationVO} from '../../../data/OrganizationVO';
import {OrganizationDataService} from '../../../services/organization-data.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '../../../../../services/authentication/authentication.service';
import { logger } from 'src/app/lib-core/logger';
import { getSubDomain } from '../../../../../helpers';

@Component({
  selector: 'app-organization-list',
  templateUrl: './organization-list.component.html',
  styleUrls: ['./organization-list.component.scss']
})
export class OrganizationListComponent implements OnInit, OnDestroy {
  pageNumber: number;
  organizations: OrganizationVO[];
  maxPages: number;
  pages: number[] = []; // ToDo: Remove hard code once we get proper back end
  subDomain: string;

  constructor(private dataService: OrganizationDataService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private authenticationService: AuthenticationService) { }
  private queryParamsSubscribe: Subscription;

  ngOnInit() {
    this.queryParamsSubscribe = this.activatedRoute.queryParams
      .subscribe(queryParams => {
        if (!queryParams.page || queryParams.page === '0') {
          this.router.navigate([], {relativeTo: this.activatedRoute, queryParams: {page: 1}});
        } else {
          this.pageNumber = parseInt(queryParams.page, 10) || 1;
          this.subDomain = getSubDomain();
          // this.subDomain = 'tosha'; // Uncomment for local testing
          if (this.subDomain) {
            this.router.navigate(['admin/users-list'],  {queryParams: {page: 1}})
              .then(result => logger.log('navigation result: ', result))
              .catch(error => console.error('navigation error: ', error));
          } else {
            this.dataService.get(this.pageNumber)
              .subscribe(orgData => {
                this.maxPages = orgData.length;
                this.organizations = orgData.items;
                this.definePages();
              }, error => {
                if (error.status === 401 || error.status === 403) {
                  this.authenticationService.logOut$().subscribe(res => {
                    this.authenticationService.isLoggedIn$.next(false);
                    this.router.navigate(['admin/login']);
                  });
                }
                console.error(error);
              });
          }
        }
    });
  }
  ngOnDestroy(): void {
    if (this.queryParamsSubscribe) {
      this.queryParamsSubscribe.unsubscribe();
      this.queryParamsSubscribe = null;
    }
  }
  onNewClicked() {
    this.router.navigate(['admin/create']);
  }
  updateList(): void {
    this.dataService.get(this.pageNumber)
      .subscribe(orgData => {
        this.maxPages = orgData.length;
        this.organizations = orgData.items;
        this.definePages();
      }, error => {
        if (error.status === 401 || error.status === 403) {
          this.authenticationService.logOut$().subscribe(res => {
            this.authenticationService.isLoggedIn$.next(false);
            this.router.navigate(['admin/login']);
          });
        }
        console.error(error);
      });
  }
  private definePages() {
    this.pages = [];
    const startNumber = Math.max(0, this.pageNumber - 5);
    const endNumber = Math.min(startNumber + 10, this.maxPages);
    for (let i = startNumber; i < endNumber; i++) {
      this.pages.push(i + 1);
    }
  }

}

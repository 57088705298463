import { Component } from "@angular/core";
import { ItemNote, NotesService } from "../services/notes/notes.service";

@Component({
	selector: "app-student-notes",
	templateUrl: "./student-notes.component.html",
	styleUrls: ["./student-notes.component.scss"]
})
export class StudentNotesComponent {
	notes: ItemNote[] = [];
	activeEditor: ItemNote["id"] | null = null;
	constructor(private notesService: NotesService) {}

	ngOnInit() {
		this.notesService.getNotes().subscribe(
			(data) => {
				this.notes = data;
			},
			(error) => {
				if (error.status === 404) {
					this.notes = [];
				}
			}
		);
	}

	get isSingleNote() {
		return this.notes?.length === 1;
	}

	get isMultipleNotes() {
		return this.notes?.length > 1;
	}

	get isNoNotes() {
		return this.notes.length === 0;
	}

	openEditor(note: ItemNote): void {
		this.activeEditor = note.id;
	}

	closeEditor(): void {
		this.activeEditor = null;
	}

	isEditorOpen(note: ItemNote): boolean {
		return this.activeEditor === note.id;
	}

	// handleSelectedTeacher(teacher) {
	// 	console.log(teacher);
	// }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { VideoStreamDisplayComponent } from './video-stream-display.component';


@NgModule({
  declarations: [VideoStreamDisplayComponent],
  imports: [
    CommonModule
  ],
  exports: [VideoStreamDisplayComponent]
})
export class VideoStreamDisplayModule { }

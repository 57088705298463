import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {PopupService} from '../../../../../services/popup/popup.service';
import {ButtonType} from '../../../../call-room/data/ButtonType';
import {UserEntryVO} from '../../../data/UserEntryVO';
import {UsersDataService} from '../../../services/users-data.service';
import {AuthenticationService} from '../../../../../services/authentication/authentication.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-user',
  templateUrl: './user-entry.component.html',
  styleUrls: ['./user-entry.component.scss']
})
export class UserEntryComponent {
  @Input() userEntry: UserEntryVO;
  @Output() userUpdate: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('img') image: NgForm;
  isEditor: boolean = false;
  isDelete: boolean = false;
  constructor(private popupService: PopupService,
              private dataService: UsersDataService,
              private authenticationService: AuthenticationService,
              private router: Router) { }
  onDelete() {
    this.popupService.openCustomMessagePopup(
      `Do you really want to delete ${this.userEntry.firstName} ${this.userEntry.lastName} user?`,
      [ {
        type: ButtonType.OK,
        text: 'Leave',
      }, {
        type: ButtonType.CANCEL,
        text: 'Delete',
        callBack: () => {
          this.deleteUser();
        }
      }]);
  }
  onEditorClick() {
    if (this.isEditor) {
      this.onClose();
    } else {
      this.isEditor = true;
    }
  }
  onClose() {
    this.popupService.openCustomMessagePopup(
      `Do you want to cancel editing? Your changes will be deleted`,
      [ {
        type: ButtonType.OK,
        text: 'Stay',
      }, {
        type: ButtonType.CANCEL,
        text: 'Cancel',
        callBack: () => {
          this.isEditor = false;
        }
      }]);
  }
  onUserEdited(editedUser: UserEntryVO) {
    this.popupService.openCustomMessagePopup(
      `Do you really want to update ${this.userEntry.firstName} ${this.userEntry.lastName} user?`,
      [ {
        type: ButtonType.OK,
        text: 'Update',
        callBack: () => {
          this.updateUser(editedUser);
        }
      }, {
        type: ButtonType.CANCEL,
        text: 'Cancel'
      }] , true);
  }
  private deleteUser () {
    this.dataService.delete(this.userEntry.email)
      .subscribe(deleteUser => {
        this.isDelete = true;
        this.userUpdate.emit(true);
      }, error => {
        this.popupService.openSnackBar('Error Can\'t delete user', 3000);
        console.error(error);
        if (error.status === 401 || error.status === 403) {
          this.authenticationService.logOut$().subscribe(res => {
            this.authenticationService.isLoggedIn$.next(false);
            this.router.navigate(['admin/login']);
          });
        }
      });
  }
  private updateUser (user: UserEntryVO) {
    this.dataService.update(user, this.dataService.organizationID)
      .subscribe(newUser => {
        this.userEntry = newUser;
        this.isEditor = false;
        this.userUpdate.emit(true);
      }, error => {
        this.popupService.openSnackBar('Error Can\'t update user', 3000);
        console.error(error);
        if (error.status === 401 || error.status === 403) {
          this.authenticationService.logOut$().subscribe(res => {
            this.authenticationService.isLoggedIn$.next(false);
            this.router.navigate(['admin/login']);
          });
        }
      });
  }

}

<div class="submenu-container">
	@if(showMenu){
	<div class="header">
		<div class="header-icon">
			<mat-icon class="back-arrow" (click)="goBackSubMenu()">arrow_back</mat-icon>
		</div>
		<div class="header-title-container">
			<h2>{{ setOption(selectedTab) | translate }}</h2>
		</div>
	</div>

	<section class="lessons-overview-content">
		<app-lessons-overview-table [userId]="userId" [timeZone]="timeZone" [isMobile]="true" [selectedOption]="selectedTab" />
	</section>
	}@else {
	<div class="header">
		<div class="header-icon">
			<mat-icon class="back-arrow" (click)="goBackStudentsMenu()">arrow_back</mat-icon>
		</div>
		<div class="header-title-container">
			<h2>{{ studentName }}</h2>
		</div>
	</div>

	<div class="menu">
		<ul>
			<li>
				<a (click)="selectOption(1)">{{ "lessons-overview.submenu.hour-overview" | translate }}</a>
			</li>
			<li>
				<a (click)="selectOption(2)">{{ "lessons-overview.submenu.notes" | translate }}</a>
			</li>
			<li>
				<a (click)="selectOption(3)">{{ "lessons-overview.submenu.recordings" | translate }}</a>
			</li>
		</ul>
	</div>

	}
</div>

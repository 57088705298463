import {Component} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AuthAdminService} from '../services/auth-admin.service';
import {AuthenticationService} from '../../../services/authentication/authentication.service';
import { logger } from 'src/app/lib-core/logger';
import {TEXT_NAME_MAX_LENGTH} from '../../../constants/generic.constants';

@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.scss']
})
export class AdminLoginComponent {
  isError: boolean;
  isLoading: boolean;
  loginForm: UntypedFormGroup;
  showPass: boolean = false;
  isAuthError: boolean = false;
  errorMessage: string = null;

  isUserEmailFocused: boolean = false;
  isUserPasswordFocused: boolean = false;

  constructor(private authAdmin: AuthAdminService,
              private router: Router,
              private authenticationService: AuthenticationService) {
    this.loginForm = new UntypedFormGroup({
      userEmail: new UntypedFormControl('', [
        Validators.required, Validators.email, Validators.maxLength(TEXT_NAME_MAX_LENGTH)]),
      userPassword: new UntypedFormControl('', [
        Validators.required])
    });
    this.loginForm.get('userPassword').statusChanges.subscribe((status) => {
      if (status === 'VALID') {
        this.isAuthError = false;
      }
    });
  }

  // ToDo: Delete once backend is fully implemented
  submit() {
    this.isLoading = true;
    this.isError = false;
    this.authAdmin.login(this.loginForm.value)
      .subscribe(isSuccess => {
        this.isLoading = false;
        if (isSuccess) {
          this.router.navigate(['/admin'] );
        } else {
          this.onFail();
        }
      }, error => {
        this.isLoading = false;
        console.error(error);
        this.onFail();
      });
  }

  login(): void {
    if (!this.loginForm.valid) {
      logger.log('login form is invalid ');
    } else {
      logger.log('login form is valid ');
      this.isLoading = true;
      this.isError = false;
      const { userEmail, userPassword } = this.loginForm.value;
      this.authenticationService.email = userEmail;

      this.authenticationService.authorizeAdmin$(userEmail, userPassword, true).subscribe((response) => {
        logger.log('Auth response: ', response);
        this.authenticationService.isLoggedIn$.next(true);
        this.router.navigate(['admin']);
        this.isLoading = false;
      }, (error) => {
        console.error('Auth error: ', error);
        this.isAuthError = true;
        this.isLoading = false;
        if (error.error && error.error.description) {
          this.errorMessage = error.error.description
        }
      });
    }
  }

  setUserEmailFocus(value) {
    this.isUserEmailFocused = value;
  }

  setUserPasswordFocus(value) {
    this.isUserPasswordFocused = value;
  }

  togglePass() {
    this.showPass = !this.showPass;
  }

  private onFail() {
    this.isError = true;
    this.loginForm.setValue({email: '', password: ''});
  }

}

import { ChangeDetectorRef, Component } from "@angular/core";
import { AvailabilityService } from "../services/availability/availability.service";
import { ActivatedRoute } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { Observable } from "rxjs";
import { mergeMap, finalize } from "rxjs/operators";
import { CommonModule } from "@angular/common";
import { MatButtonModule } from "@angular/material/button";
import { LessonsService } from "../services/lessons/lessons.service";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { TranslateService } from "@ngx-translate/core";
import { Booking } from "@data-types/booking.type";
import { isInPast } from "../helpers";
import { transformDateArray, GroupedTimestamp } from "../utils/transform-date-array";
import { HttpErrorResponse } from "@angular/common/http";
import { PopupService } from "../services/popup/popup.service";

const BERLIN_TIMEZONE = "Europe/Berlin";
const WHATSAPP_URL: string =
	"https://api.whatsapp.com/send/?phone=493083798311&text=Ich+m%C3%B6chte+meine+Probestunde+umbuchen.&type=phone_number&app_absent=0";

// to do: seo url!

@Component({
	selector: "app-trial-booking-page",
	standalone: true,
	imports: [
		TranslateModule,
		CommonModule,
		MatButtonModule,
		MatProgressSpinnerModule,
		TranslateModule
	],
	templateUrl: "./trial-booking-page.component.html",
	styleUrl: "./trial-booking-page.component.scss"
})
export class TrialBookingPageComponent {
	teacherId: string;
	pipeDriveDealId: string;
	teacherAvailabilities: GroupedTimestamp[];
	selectedTimeslot: string;
	teacherName: string = "";
	bookedLessonDate: string;
	bookedLesson: boolean = false;
	buttonIsDisabled: boolean = true;
	isLoading = false;
	bookingExists = false;
	existingBookings: Booking[] = [];
	isRescheduling = false;
	showRescheduleConfirmation = false;
	showNoUpcomingLessonsMessage = false;
	isMultipleBookings = false;
	show404 = false;
	selectedDay = 0;

	constructor(
		private availabilityService: AvailabilityService,
		private route: ActivatedRoute,
		private lessonsService: LessonsService,
		private translate: TranslateService,
		private popupService: PopupService
	) {}

	async ngOnInit() {
		this.route.queryParams
			.pipe(
				mergeMap((params) => {
					this.pipeDriveDealId = params.dId;
					this.teacherId = params.tId;
					return this.getTeacherAvailability(params.tId);
				})
			)
			.subscribe((availabilities) => {
				this.teacherAvailabilities = transformDateArray(
					availabilities,
					BERLIN_TIMEZONE
				);
				this.selectDay(this.firstDayWithAvailability());
			});

		const startTimeIso = new Date().toISOString();
		const endTimeIso = new Date(
			Date.now() + 360 * 24 * 60 * 60 * 1000
		).toISOString(); // 360 days from now

		this.lessonsService
			.checkLeadBookings(this.pipeDriveDealId, startTimeIso, endTimeIso)
			.subscribe(
				(response) => {
					const filteredList = response.list.filter((booking) => {
						return this.isValidBooking(booking);
					});

					this.bookingExists = filteredList.length > 0;
					this.existingBookings = filteredList;
					this.isMultipleBookings = filteredList.length > 1;
					if (
						this.hasCompletedBookings(response.list) &&
						!this.bookingExists
					) {
						this.showNoUpcomingLessonsMessage = true;
					}
				},
				(error) => {
					this.handleError(error);
				}
			);

		this.lessonsService.getTeacherDetails(this.teacherId).subscribe({
			next: (response) => (this.teacherName = response.firstName),
			error: (error) => {
				this.handleError(error);
			}
		});
	}

	getTeacherAvailability(teacherId): Observable<any> {
		let startTime = new Date();
		let endTime = new Date();
		endTime.setDate(endTime.getDate() + 6); // 1 week including today
		let startTimeIso = startTime.toISOString();
		let endTimeIso = endTime.toISOString();

		return this.availabilityService.getPublicAvailabilitySlots(
			teacherId,
			startTimeIso,
			endTimeIso
		);
	}

	selectTimeslot(timeslot: string) {
		this.buttonIsDisabled = false;
		this.selectedTimeslot = timeslot;
		this.popupService
			.openTrialScheduleDialog(this.teacherName, this.selectedTimeslot)
			.afterClosed()
			.subscribe((result: boolean) => {
				if (result) {
					this.handleConfirmClick();
				}
			});
	}
	selectTimeslotMobile(timeslot: string) {
		this.buttonIsDisabled = false;
		this.selectedTimeslot = timeslot;
		this.popupService
			.openTrialScheduleDialog(this.teacherName, this.selectedTimeslot)
			.afterClosed()
			.subscribe((result: boolean) => {
				if (result) {
					this.handleConfirmClick();
				}
			});
	}

	bookTrialLesson() {
		this.isLoading = true;
		this.buttonIsDisabled = true;
		const bookingData = {
			teacherId: this.teacherId,
			startTime: this.selectedTimeslot,
			dealId: this.pipeDriveDealId,
			title: "",
			description: "",
			timezone: "Europe/Berlin"
		};
		this.lessonsService
			.addTrialBookingPipedriveEndpoint(bookingData)
			.pipe(finalize(() => (this.isLoading = false)))
			.subscribe({
				next: (response) => {
					this.bookedLessonDate = response.startTime;
					this.existingBookings.push(response);
					this.bookedLesson = true;
					this.bookingExists = true;
				},
				error: console.log
			});
	}

	updateTrialLesson() {
		this.isLoading = true;
		this.buttonIsDisabled = true;

		const start = new Date(this.selectedTimeslot);
		const endTime = new Date(
			start.getTime() + 30 * 60 * 1000
		).toISOString();

		const bookingData = {
			startTime: this.selectedTimeslot,
			endTime: endTime,
			status: "rescheduled"
		};
		this.lessonsService
			.updateTrialBookingPipedriveEndpoint(
				this.existingBookings[0].id,
				bookingData
			)
			.pipe(finalize(() => (this.isLoading = false)))
			.subscribe({
				next: (response) => {
					this.showRescheduleConfirmation = true;
					this.bookedLessonDate = response.startTime;
					this.bookedLesson = true;

					this.bookedLesson = true;
				},
				error: console.log
			});
	}

	openWhatsApp() {
		window.open(WHATSAPP_URL, "_blank");
	}

	switchToRescheduling() {
		if (this.isMultipleBookings) {
			this.openWhatsApp();
		} else {
			this.bookedLesson = false;
			this.bookingExists = false;
			this.buttonIsDisabled = true;
			this.isRescheduling = true;
		}
	}

	isValidBooking(booking: Booking) {
		const isValid =
			booking.status === "accepted" ||
			booking.status === "pending" ||
			booking.status === "rescheduled";
		const inPast = isInPast(booking.startTime, BERLIN_TIMEZONE);
		const correctTeacher = booking.teacher.id === this.teacherId;
		return isValid && !inPast && correctTeacher;
	}

	hasCompletedBookings(bookingList: Booking[]) {
		const res = bookingList.some((booking) => {
			return (
				booking.status === "completed" &&
				booking.teacher.id === this.teacherId
			);
		});

		return res;
	}

	handleConfirmClick() {
		if (this.isRescheduling) {
			this.updateTrialLesson();
		} else {
			this.bookTrialLesson();
		}
	}

	handleError(error: HttpErrorResponse) {
		const isDecodingError = error?.error?.description?.includes(
			"Cannot decode value"
		);
		if (isDecodingError || error.status === 404) {
			this.show404 = true;
		} else {
			console.log(error);
		}
	}

	// mobile picker

	selectDay(day: number) {
		this.selectedDay = day;
	}

	firstDayWithAvailability() {
		return this.teacherAvailabilities.findIndex((day) => day.timestamps?.length > 0);
	}
}

<div class="registration-outer">
  <h1>Log In</h1>
  <form (ngSubmit)="login()" [formGroup]="loginForm">
    <div class="registration-row">
      <input type="email" [placeholder]="'Email Address'"
             formControlName="userEmail"
             (focus)="setUserEmailFocus(true)"
             (blur)="setUserEmailFocus(false)"
             autocomplete="email"
             maxlength="60">
      <div *ngIf="loginForm.controls['userEmail'].value && loginForm.controls['userEmail'].invalid && loginForm.controls['userEmail'].dirty && this.isUserEmailFocused"
           class="registration-error-message">
        Please enter a valid email format like name&#64;domain.com
      </div>
    </div>

    <div class="registration-row">
      <div class="password-field-outer">
        <input [placeholder]="'Password'"
               formControlName="userPassword" [type]="showPass ? 'text' : 'password'"
               (focus)="setUserPasswordFocus(true)"
               (blur)="setUserPasswordFocus(false)"
               autocomplete="current-password">
        <div class="toggle-password-visibility" [ngClass]="{'show': showPass, 'hide': !showPass}" (click)="togglePass()"></div>
      </div>
      <div *ngIf="isAuthError"
           class="registration-error-message">
           {{ errorMessage || 'Incorrect email or password.'}}
      </div>
    </div>
    <div class="registration-row">
      <button type="submit" class="default-ui-button blue-ui-button large" [disabled]="!loginForm.valid">
        Login
      </button>
    </div>
    <div class="login-bottom">
    </div>
  </form>
</div>

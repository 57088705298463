<ng-container *ngIf="isError">
    <h2 class="text-center" class="text-center">
        {{ 'setup.internet-speed-tab.title' | translate }}
    </h2>
    <div>
        {{ 'setup.internet-speed-tab.error-message' | translate }}
    </div>
</ng-container>

<ng-container *ngIf="!isError">

    <ng-container *ngIf="!isTesting">
        <ng-container *ngIf="!latestResult">
            <h2 class="text-center">
                {{ 'setup.internet-speed-tab.title' | translate }}
            </h2>
            <div class="flex px-4 text-center">
                {{ 'setup.internet-speed-tab.subtitle' | translate }}
            </div>
        </ng-container>
        <ng-container *ngIf="latestResult">
            <h2 class="text-center">
                {{ ('setup.internet-speed-tab.connection-title-'+ connectionQuality) | translate }}
            </h2>

            <ng-container [ngSwitch]="connectionQuality">
                <mat-icon *ngSwitchCase="'good'"
                    class="!w-16 !h-16 mb-8 text-[4rem] text-sirius-success-green">check_circle</mat-icon>
                <mat-icon *ngSwitchCase="'moderate'"
                    class="!w-16 !h-16 mb-8 text-[4rem] text-sirius-info-blue">info_circle</mat-icon>
                <mat-icon *ngSwitchCase="'bad'"
                    class="!w-16 !h-16 mb-8 text-[4rem] text-[#EF6054]">error_circle</mat-icon>
            </ng-container>

            <div class="mb-4 text-sm">
                ⬆️ <span class="font-bold">Upload:</span> {{latestSpeed}} Mbps
            </div>

            <div class="px-4 text-sm"
                [innerHTML]="('setup.internet-speed-tab.connection-message-'+ connectionQuality) | translate">
            </div>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="isTesting">
        <h2 class="text-center">
            {{ 'setup.internet-speed-tab.title' | translate }}
        </h2>
        <div class="w-full px-4">
            <div class="w-full mb-4 text-center">
                {{'setup.internet-speed-tab.testing-message' | translate}}
            </div>
            <mat-progress-bar mode="determinate" [value]="testProgressPercentage"></mat-progress-bar>
        </div>
    </ng-container>

</ng-container>

<div class="button-section">
    <button (click)="toggleTest()" class="blue-ui-button"
        [ngClass]="[((latestResult || isError) && !isTesting ? 'network-re-check' : '')]">
        {{(isTesting ? 'setup.internet-speed-tab.cancel-test' : latestResult || isError ?
        'setup.internet-speed-tab.retry-test' :
        'setup.internet-speed-tab.start-test') |
        translate}}
    </button>
    <button *ngIf="latestResult || isError" class="blue-ui-button" (click)="onCompleted()">
        {{'setup.internet-speed-tab.continue' | translate}}
        <span class="ml-2 leading-6 material-symbols-outlined">arrow_forward</span>
    </button>
</div>
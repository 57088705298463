import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { MediaDevicesService } from '../../lib-media-devices/services/media-devices.service';
import { VideoSettingsService } from '../service/video-settings.service';
import { MediaProperiesUtil } from '../../lib-setting/components/setting-popup/setting-popup.interface';
 
@Component({
  selector: 'app-request-permission',
  templateUrl: './request-permission.component.html',
  styleUrls: [
    "./request-permission.component.scss",
  ],
})

export class RequestPermissionComponent implements OnInit, OnDestroy {
  public reRequestPermission = false;
  public blockedDevices = false;
  public noDevices = false;
  @Output() displayPermission = new EventEmitter<boolean>();
  videoDeviceInfo: MediaDeviceInfo | null;
  audioInpDevInfo: MediaDeviceInfo | null;
  stream: MediaStream;

  constructor(
    private mediaDevicesService: MediaDevicesService,
    private videoSettingsService: VideoSettingsService) {
  }

  async ngOnInit() {

    try { 

    let permission = await this.videoSettingsService.checkPermissions().toPromise();
    
    let constraints;
 
    if (permission.camCanBeCalled = true) {
      constraints = {video:true, audio:true}
    }

    else if (permission.camCanBeCalled = false){
      constraints = {video:false, audio:true}
    }

    let stream = await this.mediaDevicesService.getUserMedia(constraints);

    const deviceIds: MediaDeviceInfo[] = await this.mediaDevicesService.enumerateDevices(true); // This is empty when no permissions are set.

    const videoDeviceList: Array<MediaDeviceInfo> = this.mediaDevicesService.getDeviceVideoInput(deviceIds); 
    const audioDeviceList: Array<MediaDeviceInfo> = this.mediaDevicesService.getDeviceAudioInput(deviceIds);

    const mediaProperties = MediaProperiesUtil.readMediaProperiesFromStorage(); // Sets the config for the stream

    if (videoDeviceList.length > 0) {
    this.videoDeviceInfo = this.mediaDevicesService.findMediaDeviceInfo(videoDeviceList, mediaProperties.videoInpDev); // filter for video devices
    this.audioInpDevInfo = this.mediaDevicesService.findMediaDeviceInfo(audioDeviceList, mediaProperties.audioInpDev); // filter for audio devices
    }

    stream.getTracks().forEach((track) => track.stop());
    await this.requestPermission();
    
    } 
    
    catch (err) {
      if (err instanceof DOMException && err.name === 'NotReadableError') {
        await this.requestPermission();
        this.displayPermission.emit(false);
      } 
      if (err instanceof DOMException && err.name === 'NotAllowedError') {
        this.blockedDevices = true;
      } 
      if (err instanceof DOMException && err.name === 'NotFoundError') {
        this.noDevices = true;
      }
      
      else {
        console.error('error:', err);
      }
    }
  }
  
  async requestPermission() {
    const videoId = this.videoDeviceInfo && this.videoDeviceInfo.deviceId ? this.videoDeviceInfo.deviceId : null;
    const audioId = this.audioInpDevInfo && this.audioInpDevInfo.deviceId ? this.audioInpDevInfo.deviceId : null;
    this.reRequestPermission = !await this.videoSettingsService.hasPermission(videoId, audioId); // this calls getUserMedia
    this.videoSettingsService.stopTrack();
    this.videoSettingsService.permission.next(false);
    if (!this.reRequestPermission) {
       this.videoSettingsService.permission.next(true);
       this.displayPermission.emit(false);
    }
  }

  async accessPermission() {
    const videoId = this.videoDeviceInfo && this.videoDeviceInfo.deviceId ? this.videoDeviceInfo.deviceId : null;
    const audioId = this.audioInpDevInfo && this.audioInpDevInfo.deviceId ? this.audioInpDevInfo.deviceId : null;
    this.reRequestPermission = !await this.videoSettingsService.hasPermission(
      videoId,
      audioId
    );
    this.videoSettingsService.permission.next(false);
    if (this.reRequestPermission) {
      this.videoSettingsService.permission.next(true);
      this.displayPermission.emit(false);
    }
  }

  ngOnDestroy(): void {
    this.videoSettingsService.stopTrack();
    this.videoSettingsService.unsubscribe();
  }
}

<div (mouseover)="setIsMouseOver(true)" (mouseout)="setIsMouseOver(false)">
	<div #toolbar *ngIf="!isStudent" [class.isHidden]="istoolbarHidden" [id]="toolbarId" [class.mini]="isMiniEditor">
		<span class="ql-formats">
			<select class="ql-size">
				<option value="small"></option>
				<option selected></option>
				<option value="large"></option>
				<option value="huge"></option>
			</select>
		</span>
		<span class="ql-formats">
			<button class="ql-bold"></button>
			<button class="ql-italic"></button>
			<button class="ql-underline"></button>
			<button class="ql-strike"></button>
		</span>
		<span class="ql-formats">
			<select class="ql-color"></select>
			<select class="ql-background"></select>
		</span>
		<span class="ql-formats">
			<button class="ql-header" value="1"></button>
			<button class="ql-header" value="2"></button>
			<button class="ql-blockquote"></button>
		</span>
		<span class="ql-formats">
			<button class="ql-list" value="ordered"></button>
			<button class="ql-list" value="bullet"></button>
			<button class="ql-indent" value="-1"></button>
			<button class="ql-indent" value="+1"></button>
		</span>
		<span class="ql-formats">
			<button class="ql-align"></button>
		</span>
		<span class="ql-formats">
			<button class="ql-link"></button>
			<button class="ql-image"></button>
			<button class="ql-video"></button>
		</span>
	</div>
	<div #editor [class.read-only]="isStudent" [class.mini]="isMiniEditor"></div>
</div>

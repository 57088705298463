<div class="mobile-container w-full main-container rounded-border-radius">
	<div class="glide bg-white">
		<div class="glide__track" data-glide-el="track">
			<ul class="glide__slides bg-white">
				@for(r of ratings; track r){
				<li class="glide__slide card text-text-2 flex flex-col">
					<div class="row-1">
						<div class="initial">
							{{r.name[0]}}
						</div>
						<div class="ml-[10px] leading-[16px]">
							<div class="font-bold">{{r.name}}</div>
							<div class="text-[#5E5E5E] text-text-3">{{r.reviews}}&nbsp;reviews</div>
						</div>
					</div>
					<div class="row-2 h-[20px]">
						<svg width="69" height="14" viewBox="0 0 69 14" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M6.66563 1.54883L3.26562 12.0488L12.0656 5.64883H1.26562L10.0656 12.0488L6.66563 1.54883Z" fill="#FABB05" stroke="#FABB05" stroke-linejoin="round" />
							<path d="M20.6656 1.54883L17.2656 12.0488L26.0656 5.64883H15.2656L24.0656 12.0488L20.6656 1.54883Z" fill="#FABB05" stroke="#FABB05" stroke-linejoin="round" />
							<path d="M34.6656 1.54883L31.2656 12.0488L40.0656 5.64883H29.2656L38.0656 12.0488L34.6656 1.54883Z" fill="#FABB05" stroke="#FABB05" stroke-linejoin="round" />
							<path d="M48.6656 1.54883L45.2656 12.0488L54.0656 5.64883H43.2656L52.0656 12.0488L48.6656 1.54883Z" fill="#FABB05" stroke="#FABB05" stroke-linejoin="round" />
							<path d="M62.6656 1.54883L59.2656 12.0488L68.0656 5.64883H57.2656L66.0656 12.0488L62.6656 1.54883Z" fill="#FABB05" stroke="#FABB05" stroke-linejoin="round" />
						</svg>
						<div class="text-[#5E5E5E] ml-[10px]">{{r.ratingTime}}</div>
						@if(r.new){
						<div class="new">NEW</div>
						}
					</div>
					{{r.text}}
				</li>
				}
			</ul>
		</div>

		<div data-glide-el="controls" class="controls">
			<button class="align-middle flex items-center text-cold-offwhite-2 hover:text-cold-offwhite" data-glide-dir="<">
				<span class="material-symbols-outlined"> chevron_left </span>
			</button>
			<button class="align-middle flex items-center text-cold-offwhite-2 hover:text-cold-offwhite" data-glide-dir=">">
				<span class="material-symbols-outlined"> chevron_right </span>
			</button>
		</div>
	</div>
</div>

<div class="desktop-container bg-white p-8 text-text-2">
	<div class="grid grid-cols-3 gap-6 grid-rows-2">
		@for(r of ratings; track r){
		<div class="card">
			<div class="row-1">
				<div class="initial">
					{{r.name[0]}}
				</div>
				<div class="ml-[10px] leading-[16px]">
					<div class="font-bold">{{r.name}}</div>
					<div class="text-[#5E5E5E] text-text-3">{{r.reviews}}&nbsp;reviews</div>
				</div>
			</div>
			<div class="row-2 h-[20px]">
				<svg width="69" height="14" viewBox="0 0 69 14" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M6.66563 1.54883L3.26562 12.0488L12.0656 5.64883H1.26562L10.0656 12.0488L6.66563 1.54883Z" fill="#FABB05" stroke="#FABB05" stroke-linejoin="round" />
					<path d="M20.6656 1.54883L17.2656 12.0488L26.0656 5.64883H15.2656L24.0656 12.0488L20.6656 1.54883Z" fill="#FABB05" stroke="#FABB05" stroke-linejoin="round" />
					<path d="M34.6656 1.54883L31.2656 12.0488L40.0656 5.64883H29.2656L38.0656 12.0488L34.6656 1.54883Z" fill="#FABB05" stroke="#FABB05" stroke-linejoin="round" />
					<path d="M48.6656 1.54883L45.2656 12.0488L54.0656 5.64883H43.2656L52.0656 12.0488L48.6656 1.54883Z" fill="#FABB05" stroke="#FABB05" stroke-linejoin="round" />
					<path d="M62.6656 1.54883L59.2656 12.0488L68.0656 5.64883H57.2656L66.0656 12.0488L62.6656 1.54883Z" fill="#FABB05" stroke="#FABB05" stroke-linejoin="round" />
				</svg>
				<div class="text-[#5E5E5E] ml-[10px]">{{r.ratingTime}}</div>
				@if(r.new){
				<div class="new">NEW</div>
				}
			</div>
			{{r.text}}
		</div>
		}
	</div>
</div>
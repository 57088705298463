import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { CallStorage } from '../components/call-room/service/storage/call-storage.service';
import { UserVO } from '../services/data/UserVO';
import { MAIN_VIDEO, SELF_VIDEO } from '../components/call-room/data/local-constants';
import { MainVideoData } from '../components/call-room/data/main-video-data';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { DisplayStatus } from '../components/call-room/service/storage/DisplayStatusVO';
import { RoomStateStorage } from '../components/call-room/service/storage/RoomStateStorage';
import { UserRole } from '../services/role.service';
import { filter, map, startWith, takeUntil, tap } from 'rxjs/operators';
import { VideoService } from './video.service';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
})
export class VideoComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() set videoCaption(value: string) {
    this.videoCaption$.next(value);
    this.cd.detectChanges();
  }

  videoCaption$: BehaviorSubject<string> = new BehaviorSubject<string>('unknown');
  name$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  @Input() forceMute: boolean = false;
  @Input() isFillScale: boolean = false;
  @ViewChild('thisVideo') thisVideo: ElementRef;

  isSelfVideo: boolean = false;
  isSingleInterlocutor: boolean = false;
  isMainVideo: boolean = false;
  isCamera$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isMicro$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  displayClose: boolean = false;
  isDisplay$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private isReflectionMode$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  user: UserVO;

  private destroyed: Subject<void> = new Subject<void>();
  private state$: BehaviorSubject<[Observable<string>, Observable<boolean>, Observable<boolean>, Observable<boolean>, Observable<boolean>] | null>
    = new BehaviorSubject<[Observable<string>, Observable<boolean>, Observable<boolean>, Observable<boolean>, Observable<boolean>] | null>(null);

  get isReflection(): boolean {
    return this.isDisplay$.getValue() || this.isReflectionMode$.getValue();
  }

  constructor(
    public callStorage: CallStorage,
    public roomState: RoomStateStorage,
    private cd: ChangeDetectorRef,
    private videoService: VideoService,
  ) {
  }

  ngOnInit() {
    let stateSubscriptionDestroy$: Subject<void> | null = null;
    this.state$.pipe(
      takeUntil(this.destroyed)
    ).subscribe(([name$, isCamera$, isMicrophone$, isDisplay$, isReflection$]) => {
      if (stateSubscriptionDestroy$) {
        stateSubscriptionDestroy$.next();
        stateSubscriptionDestroy$.complete();
        stateSubscriptionDestroy$ = null;
      }
      stateSubscriptionDestroy$ = new Subject<void>();
      name$.pipe(takeUntil(this.destroyed), takeUntil(stateSubscriptionDestroy$)).subscribe(this.name$);
      isCamera$.pipe(takeUntil(this.destroyed), takeUntil(stateSubscriptionDestroy$)).subscribe(this.isCamera$);
      isMicrophone$.pipe(takeUntil(this.destroyed), takeUntil(stateSubscriptionDestroy$)).subscribe(this.isMicro$);
      isDisplay$.pipe(takeUntil(this.destroyed), takeUntil(stateSubscriptionDestroy$)).subscribe(this.isDisplay$);
      isReflection$.pipe(takeUntil(this.destroyed), takeUntil(stateSubscriptionDestroy$)).subscribe(this.isReflectionMode$);

      this.cd.detectChanges();
    });
    this.videoCaption$.subscribe((videoCaption) => {
      switch (videoCaption) {
        case 'unknown': {
          break;
        }
        case MAIN_VIDEO: {
          this.isMainVideo = true;
          this.initMainVideoSubscribe();
          break;
        }
        case SELF_VIDEO: {
          this.initSelfSubscribe();
          break;
        }
        default: {
          this.displayClose = this.roomState.role === UserRole.AGENT;
          this.user = this.callStorage.usersStorage.getUser(videoCaption);
          if (this.user) {
            this.initUserSubscribe(this.user);
          }
        }
      }
    });
  }

  ngAfterViewInit(): void {
    this.thisVideo.nativeElement.onresize = () => {
      this.videoService.onResize$.next(this.thisVideo.nativeElement);
    };
  }

  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }

  @HostListener('window:unload', ['$event'])
  unloadHandler(event) {
    this.ngOnDestroy();
  }

  applyVideoParameters(): void {
    if (!this.forceMute) {
      this.thisVideo.nativeElement.muted = this.isSelfVideo;
    } else {
      this.thisVideo.nativeElement.muted = this.forceMute;
    }
  }

  private initUserSubscribe(user: UserVO) {
    this.state$.next([
      of(user.name ? user.name.substr(0, 1).toUpperCase() : ''),
      user.isCamera$,
      user.isMicro$,
      user.isDisplay$,
      user.isReflection$.pipe(map(x => !x))
    ]);
    this.cd.detectChanges();
  }

  private initSelfSubscribe() {
    this.state$.next([
      this.callStorage.usersStorage.selfUpdate$.pipe(
        map(x => x.name),
        startWith(this.callStorage.usersStorage.selfUser.name),
        filter(name => !!name),
        map(x => x.substr(0, 1).toUpperCase()),
      ),
      this.roomState.isCamOn$.pipe(startWith(this.roomState.isCamOn)),
      of(false),
      this.roomState.displayMode$.pipe(
        startWith(this.roomState.isScreenShare),
        map(data => data === DisplayStatus.active),
      ),
      this.roomState.isReflectionOn$.pipe(
        startWith(!this.roomState.isReflection),
      )
    ]);
    this.cd.detectChanges();
  }

  private initMainVideoSubscribe() {
    this.callStorage.mainVideo$.pipe(takeUntil(this.destroyed)).subscribe((data: MainVideoData) => {
      this.displayClose = data.currentVideo !== SELF_VIDEO && this.roomState.role === UserRole.AGENT;
      if (data.currentVideo === SELF_VIDEO) {
        this.initSelfSubscribe();
      } else if (data.currentVideo && data.currentVideo.length > 1) {
        const user = this.callStorage.usersStorage.getUser(data.currentVideo);
        if (user) {
          this.initUserSubscribe(user);
        }
      }
      this.isSelfVideo = data.currentVideo === SELF_VIDEO;
      this.applyVideoParameters();
    });
    this.callStorage.usersStorage.onUsersUpdate$.pipe(takeUntil(this.destroyed)).subscribe(() => {
      this.isSingleInterlocutor = this.callStorage.usersStorage.users.filter(user => user.connected).length === 1;
    });
  }
}

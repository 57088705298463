<div class="footer" [ngClass]="{'dark': style === 'dark', 'light': style === 'light'}">
  <div class="languages">
    <button class="default-ui-button" (click)="language.change(GERMAN)">
      <img src="../../../../../../assets/icons/sirius/GermanyFlag.svg">
      DE
    </button>
    <button class="default-ui-button" (click)="language.change(ENGLISH)">
      <img src="../../../../../../assets/icons/sirius/BritainFlag.svg">
      EN
    </button>
  </div>
  <div class="links-section first-link-section">
    <a class="default-ui-button footer-link" href="https://support.sirius.video/" target="_blank">
      {{ 'header.support-center' | translate }}
    </a>
<!--    <button class="default-ui-button" (click)="faqClick()">{{ 'footer.menu.faq' | translate }}</button>-->
    <button class="default-ui-button" (click)="contactUs()">{{ 'footer.menu.contact-us' | translate }}</button>
  </div>
  <div class="links-section last-link-section">
    <button class="default-ui-button" (click)="policyClick()">{{ 'footer.menu.privacy' | translate }}</button>
    <button class="default-ui-button" (click)="termsClick()">{{ 'footer.menu.terms' | translate }}</button>
    <button class="default-ui-button" (click)="imprintClick()">{{ 'footer.menu.imprint' | translate }}</button>
  </div>
</div>

<mat-sidenav-container id="call-sirius-cloud">
	<mat-sidenav #cloudUI [opened]="displayCloud" [hidden]="!displayCloud" position="end" class="cloud-desktop">
		<div id="cloudUI">
			<h2 class="cloud-title">
				{{ "menu.material" | translate }}
			</h2>
			<button class="close-cloud" (click)="toggleCloud()"><mat-icon svgIcon="close"></mat-icon></button>
			@if (!isStudent) {
				<div class="mat-container">
					<input type="file" #fileInput style="display: none" (change)="onFileSelected($event)" multiple />
					<button class="table-button_upload-material" (click)="fileInput.click()">
						<mat-icon fontIcon="upload_file"></mat-icon>
						{{ "material.upload-button" | translate }}
					</button>
				</div>
				<hr />
			}
			<div class="tabs-container">
				<mat-tab-group mat-stretch-tabs="false" mat-align-tabs="center">
					@if (!isStudent) {
						<mat-tab label="All Material"
							><app-call-cloud-table
								[filesData]="dataSource"
								[displayMaterial]="true"
								[isTeacher]="!isStudent"
								[count]="count"
								(pageChangeEvent)="pageChanged($event)"
								(uploadFileEvent)="onFileSelected($event)"
								(shareEvent)="fileSharing($event)"
						/></mat-tab>
						@if (studentName) {
							<mat-tab [label]="'Shared with ' + studentName">
								<app-call-cloud-table
									[filesData]="studentDataSource"
									[displayMaterial]="true"
									[isTeacher]="!isStudent"
									[count]="studentCount"
									(pageChangeEvent)="pageChanged($event)"
									(uploadFileEvent)="onFileSelected($event)"
									(shareEvent)="fileSharing($event)"
								/>
							</mat-tab>
						}
					} @else {
						<mat-tab [label]="'Shared with you'">
							<app-call-cloud-table
								[filesData]="dataSource"
								[displayMaterial]="true"
								[isTeacher]="!isStudent"
								[count]="count"
								(pageChangeEvent)="pageChanged($event)"
							/>
						</mat-tab>
					}
				</mat-tab-group>
			</div>
		</div>
	</mat-sidenav>
</mat-sidenav-container>

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { RoomAuthService } from '../../../services/ws/room-auth.service';
import { PopupService } from '../../../services/popup/popup.service';
import { ErrorHandlerService } from '../../../services/error-handler/error-handler.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../../../services/authentication/authentication.service';
import { first } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';

@Injectable()
export class RoomStateAuth  {
  constructor(
    private roomAuthService: RoomAuthService,
    private router: Router,
    private popupService: PopupService,
    private errorHandlerService: ErrorHandlerService,
    private translateService: TranslateService,
    private authenticationService: AuthenticationService,
    private title: Title) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<boolean | UrlTree> | boolean | UrlTree {
    // todo move to another place
    const roomID = route.params.roomid.split('?')[0];
    const userId = route.queryParams.userId;

    return new Observable<boolean>(observer => {
      this.checkJoinPossibility(roomID, observer, userId);
    });
  }

  private checkJoinPossibility(roomID, observer, userId?) {
    this.roomAuthService.checkJoinPossibility(roomID,userId)
      .pipe(first())
      .subscribe((value) => {

        if (value.error && value.error.description === 'Wrong room password!') {
          // If we didn't delete old password from sessionStorage, delete it now
          sessionStorage.removeItem('roomPassword');
          // And check room existance once again
          this.checkJoinPossibility(roomID, observer);
        } else if (value.error) {
          this.title.setTitle(this.translateService.instant('popUp.roomDoesntExists-seo-title'));
          this.popupService.openWarningPopup(this.translateService.instant('popUp.roomDoesntExists')).then(() => {
            this.authenticationService.checkLoggedIn$().toPromise().then((loggedIn) => {
              if (!!loggedIn) {
                this.router.navigate(['/platform/start']);
              } else {
                this.router.navigate(['']);
              }
            });
          });
          this.errorHandlerService.processServerError(value.error);
        } else {
          // todo add status check
          observer.next(value.status);
          // if (!value.status) {
          //   this.popupService.openDealerPopup(value.redirect);
          // } else {
          //   observer.next(value.status);
          // }
        }
      });
  }

}

import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { MatSidenav, MatSidenavModule } from "@angular/material/sidenav";
import { MatIconModule } from "@angular/material/icon";
import { TranslateModule } from "@ngx-translate/core";
import { MaterialMobileTableComponent } from "../../material/components/material-mobile-table/material-mobile-table.component";
import { getFileTypeByExtension } from "../../helpers/getFileTypeByExtension";
import { PopupService } from "src/app/services/popup/popup.service";
import { from, Observable, of } from "rxjs";
import { catchError, concatMap, switchMap } from "rxjs/operators";
import { FilesCloudService } from "src/app/services/material-cloud/files-cloud.service";
import { AuthenticationService } from "src/app/services/authentication/authentication.service";
import { Profile } from "@data-types/profile.types";
import { MatTableDataSource } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { CallSiriusCloudService } from "./call-sirius-cloud.service";
import { CallCloudTableComponent } from "./components/call-cloud-table/call-cloud-table.component";
import { StudentsService } from "src/app/students/students.service";
import { LessonUtils } from "src/app/utils/lesson-utils";
import { StudentList } from "@data-types/studentList.type";

@Component({
	selector: "app-call-sirius-cloud",
	standalone: true,
	imports: [
		MatSidenavModule,
		TranslateModule,
		MatIconModule,
		MaterialMobileTableComponent,
		MatTabsModule,
		CallCloudTableComponent
	],
	templateUrl: "./call-sirius-cloud.component.html",
	styleUrl: "./call-sirius-cloud.component.scss"
})
export class CallSiriusCloudComponent implements OnInit {
	@Input() displayCloud: boolean;
	@Input() teacherId: string;
	@Input() studentId: string;
	@Input() isStudent = false;
	@Input() profile: Profile;
	@Input() studentName: string;

	dataSource = new MatTableDataSource<any>();
	studentDataSource = new MatTableDataSource<any>();
	studentCount: number;
	dataSourceLength: number;
	public count: number;
	public page: number = 1;
	public pages: number;
	public limit: number = 10;
	studentsList: StudentList;

	@ViewChild("cloudUI") cloudUI: MatSidenav;

	constructor(
		private popupService: PopupService,
		private filesService: FilesCloudService,
		private auth: AuthenticationService,
		private callCloud: CallSiriusCloudService,
		private studentsService: StudentsService,
		private lessonUtils: LessonUtils
	) {}

	async ngOnInit() {
		if (!this.profile) {
			this.profile = await this.auth.profile;
		}
		if (!this.isStudent) {
			this.getAllFiles();
		} else {
			this.getAllStudentFiles().subscribe(
				(files) => {
					const { count, list, page, pages } = files;
					this.page = page;
					this.count = count;
					this.pages = pages;
					this.dataSource = list;
					this.dataSourceLength = list.length;
				},
				(error) => {
					console.error("Error fetching files:", error);
				}
			);
		}
	}

	toggleCloud(): void {
		if (this.displayCloud) {
			this.cloudUI.open();
		} else {
			this.cloudUI.close();
		}
		this.displayCloud = !this.displayCloud;
		this.callCloud.getDisplayCloud$().next(this.displayCloud);
	}

	getAllFiles(): void {
		if (this.profile) {
			this.filesService
				.getMyFiles(this.profile.userId, this.limit, this.page)
				.subscribe(
					(files) => {
						const { count, list, page, pages } = files;
						this.page = page;
						this.count = count;
						this.pages = pages;
						this.dataSource = list;
						this.dataSourceLength = list.length;
					},
					(error) => {
						console.error("Error fetching files:", error);
					},
					() => {
						if (this.studentId && !this.isStudent) {
							this.getAllStudentFiles().subscribe(
								(files) => {
									const { count, list, page, pages } = files;
									this.page = page;
									this.studentCount = count;
									this.pages = pages;
									this.studentDataSource = list;
								},
								(error) => {
									console.error(
										"Error fetching files:",
										error
									);
								}
							);
						}
					}
				);
		}
	}

	getStudentInfo(): Observable<any> {
		return this.studentsService.getStudents(this.teacherId, 1, 50).pipe(
			switchMap((resp) => {
				return this.lessonUtils.getStudentsWithLessonPackages(resp);
			}),
			catchError((error) => {
				console.error("An error ocurred:", error);
				return of(null);
			})
		);
	}

	getAllStudentFiles(): Observable<any> {
		return this.filesService.getSharedFilesByUser(
			this.studentId,
			this.limit,
			this.page
		);
	}

	onFileSelected(event: Event): void {
		const input = event.target as HTMLInputElement;
		if (input?.files) {
			const files = input.files;
			const modifiedFiles: File[] = Array.from(files).map((file) => {
				const extension = file.name.split(".").pop();
				const mimeType = getFileTypeByExtension(extension!);
				return new File([file], file.name, { type: mimeType });
			});
			const dialogRef = this.popupService.openMaterialConfirmUploadDialog(
				modifiedFiles,
				() => this.uploadFiles(files)
			);

			dialogRef.afterClosed().subscribe(() => {
				this.getAllFiles();
			});
		}
	}

	uploadFiles(files: FileList): Observable<any> {
		const fileArray = Array.from(files);

		return from(fileArray).pipe(
			concatMap((file) =>
				this.filesService.addFile(this.profile.userId, file, "material")
			)
		);
	}

	fileSharing(item): void {
		setTimeout(() => {
			const dialogRef = this.popupService.openMaterialFileSharingDialog(
				item,
				this.profile
			);
			dialogRef.afterClosed().subscribe(
				(resp) => {},
				(error) => console.error(error),
				() => {
					this.getAllFiles();
					dialogRef.close();
				}
			);
		}, 50);
	}

	pageChanged(event): void {
		this.page = event.pageIndex + 1;
		!this.isStudent ? this.getAllFiles() : this.getAllStudentFiles();
	}
}

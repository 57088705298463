<div class="form-container">
    <h1>Delete account</h1>
    <form #deleteForm='ngForm'>
        <div>
            <span>Email address</span>
            <input type="email" name="email" ngModel required>
        </div>
        <div class="controls">
            <p [class]="status">{{userMessage}}</p>
            <button class="orange-ui-button" type="submit" (click)="onSubmit()">Delete</button>
        </div>
    </form>
</div>
<mat-form-field appearance="outline" [ngClass]="teachersList?.length > 1 ? null : 'hide_input'">
	<mat-select [formControl]="teacherControl" placeholder="All teachers" (selectionChange)="selectingTeacher($event.value)">
		@if (allTeachers) {
			<mat-option value="all">
				<div class="mat-option__teacher">All</div>
			</mat-option>
		}
		@for (teacher of teachersList; track teacher.id) {
			<mat-option [value]="teacher">
				<div class="mat-option__teacher">
					<img [src]="teacher.photo" [alt]="teacher.name" class="dropdown-avatar" /> {{ teacher.name }}
				</div>
			</mat-option>
		}
	</mat-select>
</mat-form-field>

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { EmojiService } from "./emoji.service";
import { EmojiPickerComponent } from "./components/emoji-picker/emoji-picker.component";
import { EmojiIconComponent } from "./components/emoji-icon/emoji-icon.component";
import { TranslateModule } from "@ngx-translate/core";
import { MatIconModule } from "@angular/material/icon";

@NgModule({
  declarations: [EmojiPickerComponent, EmojiIconComponent],
  imports: [CommonModule, TranslateModule, MatIconModule],
  providers: [EmojiService],
  exports: [EmojiPickerComponent, EmojiIconComponent],
})
export class EmojiModule {}

<img class="sirius-logo" src="/assets/icons/sirius/Sirius.png" alt="Sirius">
<img class="sirius-music-gfx" src="/assets/icons/sirius/redesign/sirius-music.png" alt="Sirius">
<img class="sirius-jupiter-gfx" src="/assets/icons/sirius/redesign/sirius-planet.png" alt="Sirius">
<img class="sirius-mars-gfx" src="/assets/icons/sirius/redesign/sirius-planeten-01.png" alt="Sirius">
<img class="sirius-blueplanet-gfx" src="/assets/icons/sirius/redesign/sirius-planeten-02.png" alt="Sirius">
<img class="sirius-bluebigplanet-gfx" src="/assets/icons/sirius/redesign/sirius-planeten-04.png" alt="Sirius">

<app-language-selector />
<div class="registration-outer box-content">
  <h1>{{ 'sign-up.title' | translate }}</h1>
  <form (ngSubmit)="signUp()" [formGroup]="loginForm">
    <div class="registration-row">
      <label class="registration-label" for="userEmail">{{ 'sign-up.email-label' | translate }}</label>
      <input type="email" id="userEmail" formControlName="userEmail"
        [placeholder]="'sign-up.email-placeholder' | translate" maxlength="60">
      <div *ngIf="loginForm.controls['userEmail'].invalid && loginForm.controls['userEmail'].dirty"
        class="registration-error-message">
        {{ 'sign-up.email-error' | translate }}
      </div>
      <div *ngIf="isMailError" class="registration-error-message">
        {{ 'sign-up.email-exists' | translate }}
      </div>
    </div>

    <div class="registration-privacy-agree" [innerHTML]="'create-profile.agree-info' | translate | safeHtml"></div>

    <div class="registration-row">
      <button type="submit" class="default-ui-button blue-ui-button large" [disabled]="!loginForm.valid">
        {{ 'buttons.sign-up' | translate }}
      </button>
    </div>

    <div *ngIf="!isSchool" class="signup-bottom" [innerHTML]="'sign-up.login' | translate | safeHtml"
      appRouteTransformer></div>
  </form>
</div>
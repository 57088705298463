import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from '../services/authentication/authentication.service';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ProfileService } from '../profile/profile.service';
// SIRIUS-754 Modify Sign up detail page
import { Timezone } from '../models/timezone';
import { GA4Service } from '../services/ga4.service';
import { logger } from 'src/app/lib-core/logger';
import { CODE_MAX_LENGTH, DISALLOW_TRAILING_SPACES, TEXT_NAME_MAX_LENGTH } from '../constants/generic.constants';
import { CheckRegistration } from '../services/authentication/check-registration.resolver';

const PASS_VALIDATION_PATTERN = /(?=.*[\d\W_])/;
declare const fbq: any;

@Component({
  selector: 'app-create-profile',
  templateUrl: './create-profile.component.html',
  styleUrls: ['./create-profile.component.scss']
})
export class CreateProfileComponent implements OnInit, OnDestroy {
  email: string;
  showPass: boolean = false;
  showConfirmPass: boolean = false;
  loginForm: UntypedFormGroup;
  isAuthError: boolean = false;
  isCodeError: boolean = false;
  isAlreadyRegistered: boolean = false;
  // Validators feedback values
  hasEnoughPassLength: boolean;
  hasNumberOrSpecial: boolean;
  isNotEmpty: boolean;
  // SIRIUS-754 Modify Sign up detail page
  timezones: Timezone[]
  disallowTrailingSpaces = DISALLOW_TRAILING_SPACES;
  private registerKey: string;
  private checkRegistration: CheckRegistration;
  public defaultTimezone = 'Europe/Berlin'; // Standardzeitzone

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private profileService: ProfileService,
    private ga4: GA4Service,
  ) {
    this.email = /*this.authenticationService.email ||*/ localStorage.email;
    this.loginForm = new UntypedFormGroup({
      firstName: new UntypedFormControl('', [Validators.required, Validators.maxLength(TEXT_NAME_MAX_LENGTH)]),
      lastName: new UntypedFormControl('', [Validators.required, Validators.maxLength(TEXT_NAME_MAX_LENGTH)]),
      timeZone: new UntypedFormControl(this.defaultTimezone, [Validators.required]),
      userPassword: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern(PASS_VALIDATION_PATTERN)
      ]),
      confirmPassword: new UntypedFormControl('', [Validators.required]),
      // SIRIUS-634 Hide security code in sign up + password reset process
      // securityCode: new FormControl('', [
      //   Validators.required,
      //   Validators.minLength(CODE_MAX_LENGTH),
      //   Validators.maxLength(CODE_MAX_LENGTH)
      // ]),
      isPolicyAndConfirming: new UntypedFormControl(false),
      isMessageSubscription: new UntypedFormControl(false),
    });
    this.checkRegistration = this.route.snapshot.data.checkRegistration;
    if (!!this.checkRegistration) {
      this.isAlreadyRegistered = !this.checkRegistration.status;
      this.email = (!!this.checkRegistration.email ? this.checkRegistration.email : this.email);
    }
  }

  ngOnInit() {
    this.registerKey = this.route.snapshot.paramMap.get('key');
    this.loadTimezones();
    this.loginForm.setValue



    // this.authenticationService.checkRegistration$(this.registerKey).subscribe((response) => {
    //     this.isAlreadyRegistered = !response.status;
    //   },
    //   (error) => {
    //     console.error('registration check error ', error);
    //   });
    // if (!this.registerKey || this.registerKey === '') {
    //   logger.log('key ', this.registerKey);
    // }

    // SIRIUS-754 Modify Sign up detail page
    // this.loadTimezones();
  }

  ngOnDestroy(): void {
    localStorage.email = '';
  }

  register(): void {
    if (this.isDisabled()) {
      return;
    }
    if (!this.loginForm.valid) {
      logger.log('profile form is invalid ');
    } else {
      logger.log('profile form is valid ');
      const userPassword = this.loginForm.value.userPassword;
      // ToDo: Rework those ASAP
      this.authenticationService.firstName = this.loginForm.value.firstName;
      this.authenticationService.lastName = this.loginForm.value.lastName;
      // this.authenticationService.timeZone = 2; // ToDo: Rework this properly
      const data = {
        firstName: this.loginForm.value.firstName,
        lastName: this.loginForm.value.lastName,
        // SIRIUS-754 Modify Sign up detail page
        timezone: this.loginForm.value.timeZone,
        // timezone: 2, // Timezone for Germany.
        password: userPassword,
        // SIRIUS-634 Hide security code in sign up + password reset process
        // key: parseInt(this.loginForm.value.securityCode, 10),
        isSubscription:  this.loginForm.value.isMessageSubscription
      };
      this.authenticationService.register$(this.registerKey, data).subscribe((response) => {
        if (!!this.ga4.hasConsent) {
        this.ga4.profileCreated()
        }
        if (typeof fbq === 'function') {fbq('track', 'CompleteRegistration');}
        if (!!this.email) {
          this.getTokenAuthorizationAndGoStart(this.email, userPassword, false);
        } else {
          this.router.navigate(['login']);
        }
      }, (error) => {
        console.error('Auth error: ', error);
        if (error.status === 404) {
          this.isCodeError = true;
          this.isAuthError = false;
        } else if (error.status === 400) {
          this.isCodeError = false;
          this.isAuthError = true;
        }
      });
    }
  }

  private loadTimezones(): Promise<any> {
    return new Promise<any>((resolve: (value: any) => void, reject: (reason?: any) => void) => {
      this.profileService.getTimezones().subscribe((timezones: Timezone[]) => {
        this.timezones = timezones;
        resolve(null);
      }, (error) => { reject(); });
    });
  }

  onPasswordFocus(): void {
    // To reset it after the user starts entering the password once more
    this.isAuthError = false;
  }

  onCodeFocus(): void {
    // To reset it after the user starts entering the code once more
    this.isCodeError = false;
  }

  togglePass() {
    this.showPass = !this.showPass;
  }

  toggleConfirmPass() {
    this.showConfirmPass = !this.showConfirmPass;
  }

  formUpdate(): void {
    const hasErrors = this.loginForm.controls.userPassword.errors;
    this.isNotEmpty = !hasErrors || hasErrors && !this.loginForm.controls.userPassword.errors.required;
    this.hasEnoughPassLength = !hasErrors || hasErrors && this.loginForm.controls.userPassword.errors
      && !this.loginForm.controls.userPassword.errors.minlength;
    this.hasNumberOrSpecial = !hasErrors || hasErrors && this.loginForm.controls.userPassword.errors
      && !this.loginForm.controls.userPassword.errors.pattern;
  }

  isDisabled(): boolean {
    return (this.isAlreadyRegistered || !this.loginForm.valid || !this.email
      || this.loginForm.value.userPassword !== this.loginForm.value.confirmPassword
      || !this.loginForm.value.isPolicyAndConfirming
      );
  }

  private getTokenAuthorizationAndGoStart(userEmail: string, userPassword: string, rememberUser: boolean): Promise<void> {
    return this.authenticationService.userAuthorization(userEmail, userPassword, !!rememberUser)
    .then(() => {
      this.router.navigate(['platform/start']);
    });
  }
}

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { AuthenticationService } from "src/app/services/authentication/authentication.service";
import { CHAT_ROOM_ID } from "../../constants/connection-constants";
import Talk from "talkjs";
import { Observable } from "rxjs/index";

@Injectable({
	providedIn: "root"
})
export class TalkServiceService {
	private currentUser: Talk.User;
	private session: Talk.Session;

	constructor(
		private auth: AuthenticationService,
		private http: HttpClient
	) {}

	private get authHttpOptions() {
		return {
			withCredentials: true,
			headers: new HttpHeaders({
				"Content-Type": "application/json; charset=utf-8",
				Authorization: this.auth.accessToken
			})
		};
	}

	async intializeUser(userData) {
		await Talk.ready;
		const { accessToken } = await this.getTalkJsToken(
			userData.id
		).toPromise();
		this.currentUser = new Talk.User({
			id: userData.id,
			name: userData.username,
			email: null,
			photoUrl: userData.photoUrl,
			locale: userData.locale || "de-DE"
		});

		this.session = new Talk.Session({
			appId: environment.talkjs_app_id,
			me: this.currentUser,
			token: accessToken
		});
	}

	async createInbox(): Promise<Talk.Inbox> {
		await Talk.ready;

		const inbox = this.session.createInbox();
		return inbox;
	}

	async createChatBox(
		otherUserId: string,
		isTeacher: boolean
	): Promise<Talk.Chatbox> {
		await Talk.ready;

		let roomId: string;
		let conversation: any;

		try {
			const [userId1, userId2] = isTeacher
				? [this.currentUser.id, otherUserId]
				: [otherUserId, this.currentUser.id];

			const response = await this.getChatRoomId(
				userId1,
				userId2
			).toPromise();

			roomId = response.list[0]?.chatRoomId;

			if (!roomId) {
				throw new Error("No chatRoomId found in the response.");
			}
			conversation = this.session.getOrCreateConversation(roomId);
			conversation.setParticipant(this.currentUser);

			const chatBox = this.session.createChatbox();
			chatBox.select(conversation);

			return chatBox;
		} catch (err) {
			console.error("Error creating chatbox:", err);
			throw err;
		}
	}

	async unreadMessages() {
		await Talk.ready;

		return this.session.unreads;
	}

	createTalkRoomId(currentUser: string, otherUser: string) {
		return Talk.oneOnOneId(currentUser, otherUser);
	}

	getChatRoomId(teacherId: string, studentId: string): Observable<any> {
		return this.http.get(
			`${CHAT_ROOM_ID}?limit=10&page=1&teacherId=${teacherId}&studentId=${studentId}`,
			this.authHttpOptions
		);
	}
	getTalkJsToken(userId): Observable<any> {
		return this.http.post(
			`${CHAT_ROOM_ID}/auth`,
			{ userId: userId },
			this.authHttpOptions
		);
	}
}

import { Injectable } from "@angular/core";
import { UserVO } from "../services/data/UserVO";
import { PlayerStateService } from "../services/youtube/player-state.service";

interface RoomVolumes {
	[key: UserVO["id"]]: number;
}

@Injectable({
	providedIn: "root",
})
export class VolumeControlService {
	private roomVolumes: RoomVolumes = {};

	constructor(private playerStateService: PlayerStateService) {
		const savedVolumes =
			JSON.parse(sessionStorage.getItem("roomVolumes")) || {};
		this.roomVolumes = savedVolumes;
	}

	setVolume(user: UserVO, volume: number, isMainPanel = false): void {
		if (user) {
			this.roomVolumes[user.id] = volume;
			if (user.hasYoutube) {
				const playerIndex = isMainPanel ? 0 : 1;
				const player = this.playerStateService.playerEvent[playerIndex];
				this.playerStateService.volume = { volume, id: player.g.id };
			}
		}
	}

	getVolume(user: UserVO): number {
		return this.roomVolumes[user?.id] ?? 0.8;
	}

	saveToSessionStorage(): void {
		sessionStorage.setItem("roomVolumes", JSON.stringify(this.roomVolumes));
	}
}

<div class="piano" [class.big-keyboard]="bigKeyboard">
    <div class=" piano-keys">
        <div class="piano-settings">
            <mat-select (selectionChange)="setOctave($event)" [(value)]="selected" [placeholder]="selected"
                class="selector app-select-mini" panelClass="app-select-panel-mini">
                <mat-option *ngFor="let octave of octavesNumber" [value]="octave.value">
                    {{octave.viewValue}}
                </mat-option>
            </mat-select>
            <label class="mt-1 label-numbers">{{ 'piano.number-of-keys' | translate }}</label>
            <mat-slider class="mb-2" min="-30" max="0" step="0.1" value="0">
                <input matSliderThumb [(ngModel)]="volume" (ngModelChange)="onChange($event)">
            </mat-slider>
            <label class="mr-1">{{ 'piano.volume' | translate }}</label>
        </div>
        <app-piano-octave *ngFor="let i of octaves;" (triggeredNote)="playTriggeredNote
        ($event)" (releasedNote)="releaseNote($event)" [octaveIndex]="i"
            [ngClass]="{'hidden': i < 3 && !bigKeyboard || i > 5 && !bigKeyboard}"></app-piano-octave>
    </div>
</div>
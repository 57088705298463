import { Component, OnInit } from "@angular/core";
import { LessonsService } from "../services/lessons/lessons.service";
import { PopupService } from "../services/popup/popup.service";
import { AuthenticationService } from "../services/authentication/authentication.service";
import { Resources } from "../services/access-control/resources.constants";
import { USER_KEY } from "../constants/localstorage-constants";
import { SubRole } from "../constants/subroles-constants";
// import { HelpScoutService } from "../services/help-scout-service.service";
import { LessonsStatus } from "../constants/lessons-status.constants";
import { map, mergeMap, tap } from "rxjs/operators";
import { AvailabilityService } from "../services/availability/availability.service";

@Component({
	selector: "app-lessons-page",
	templateUrl: "./lessons-page.component.html",
	styleUrls: ["./lessons-page.component.scss"]
})
export class LessonsPageComponent implements OnInit {
	constructor(
		private auth: AuthenticationService,
		private availabilityService: AvailabilityService,
		private lessonsService: LessonsService,
		private popupService: PopupService
		// private helpScoutService: HelpScoutService
	) {}

	public tab: number = 0;
	public subrole: number;
	public isBetaTester: boolean = false;
	public Resources = Resources;
	public teacherName: string;

	private userId: string;
	private firstName: string;
	private timeZone: string;
	private lessonPackage;
	public lessonPackageStatus: boolean;
	public showRequestLesson: boolean = false;
	public selectedTeacher;

	async ngOnInit(): Promise<void> {
		// await this.helpScoutService.showBeaconWithId();
		const profile = await this.auth.profile;
		this.userId = profile.userId;
		this.timeZone = profile.timezone;
		this.subrole = profile.subrole;
		this.firstName = profile.firstName;
		this.isBetaTester = profile.betaTesting;

		if (this.subrole === SubRole.STUDENT_MATCHED) {
			this.fetchLessonPackage(profile?.email).subscribe(
				(lessonPackage) => {
					this.lessonPackage = lessonPackage;
					this.setTeacherName();
					this.checkTeacherAvailabilities();
				}
			);
		}
	}

	private fetchLessonPackage(email: string) {
		return this.lessonsService.getLessonPackage(email);
	}

	public setTab(tab: number): void {
		this.tab = tab;
	}

	public startSession(booking) {
		console.log("startSession");
		
		// move to service??
		if ([2, 3, 6].includes(this.subrole)) {
			sessionStorage.setItem(USER_KEY, this.firstName);
		}

		if (this.subrole === SubRole.TEACHER_MATCHED) {
			const userId = booking.student?.id;
			sessionStorage.setItem("studentId", userId);
		}
		this.lessonsService.startSession(booking, this.subrole);
	}

	public createRegularSession() {
		this.popupService.openCalendarEventDialog(this.userId, this.timeZone);
	}
	public inviteExternalStudent() {
		this.popupService.openInviteStudenDialog();
	}

	public openMoreLessonInfoModal(booking): void {
		const { date, time, student } = booking;
		this.popupService.openMoreLessonInfoModal(date, time, student);
	}
	showAvailabilityCalendar() {
		this.popupService.openAvailabilityCalendar(this.selectedTeacher);
	}

	private setTeacherName(): void {
		if (this.lessonPackage) {
			this.teacherName = this.lessonPackage.teacherName?.split(" ")[0];
			this.lessonPackageStatus = this.setLessonPackageStatus(
				this.lessonPackage.status
			);
		}
	}

	private checkTeacherAvailabilities(): void {
		if (this.lessonPackage) {
			this.availabilityService
				.getAvailability(this.lessonPackage.teacherId, "regular")
				.pipe(
					map((availabilities) =>
						availabilities.some(
							(availability) => availability.startTime !== null
						)
					)
				)
				.subscribe((hasAvailabilities) => {
					this.showRequestLesson = hasAvailabilities;
				});
		}
	}

	handleSelectedTeacher(teacher) {
		this.selectedTeacher = teacher;
	}

	private setLessonPackageStatus(status: string): boolean {
		return (
			LessonsStatus.ACTIVE === status || LessonsStatus.PASTDUE === status
		);
	}
}

import { Component, EventEmitter, Input, Output } from "@angular/core";
import { CommonModule } from '@angular/common';
import { MediaDevicesService } from "src/app/lib-media-devices/services/media-devices.service";
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {UntypedFormGroup,UntypedFormControl } from '@angular/forms'; 
import {MatRadioModule} from '@angular/material/radio';
import { MatIconModule } from '@angular/material/icon';
import { debounceTime } from 'rxjs/operators';
import {
	MediaProperies,
	MediaProperiesUtil,
    ParamNumber,
	ParamNumberUtil,
} from "./setting-popup.interface";
import { EasyRtcService } from "src/app/lib-rtc/services/easy-rtc.service";
import {RtcStreamViewModel} from '../../components/call-room/service/viev-model/rtc-stream-view-model';
import {
    MatSlideToggleModule,
  } from '@angular/material/slide-toggle';
import { PlatformDetectorService } from '../../services/platform-detector/platform-detector.service';

const SAMPLE_RATE: ParamNumber[] = [
    { name: "video-settings.sample-size.low", param: 64000, default: true },
    { name: "video-settings.sample-size.medium", param: 128000 },
    { name: "video-settings.sample-size.high", param: 192000 },
];

import { Subscription } from "rxjs";

import { TranslateModule } from '@ngx-translate/core';

@Component({
	selector: "app-audio-menu",
	templateUrl: "./audio-menu.component.html",
	styleUrls: ["./audio-menu.component.scss"],
    standalone: true,
    imports: [MatRadioModule,FormsModule,ReactiveFormsModule,MatIconModule,CommonModule,MatSlideToggleModule, TranslateModule ]
})

export class AudioMenuComponent {
	@Output() public openAudioSettings = new EventEmitter<void>();
	@Output() public toggleMicro = new EventEmitter<void>();
	@Input() public isMicro: boolean;
	public audioInputDevices: MediaDeviceInfo[] = [];
	public audioOutputDevices: MediaDeviceInfo[] = [];
	public selectedSecondaryVideoInputDeviceId: string;
	public isNoiseReduction = false;
    public formGroup: UntypedFormGroup;
    private currentMediaProperies: MediaProperies | null = null;
    public audioSampleRates: ParamNumber[] = SAMPLE_RATE;
    public micMuted: boolean;
    public deviceChange$;
    private deviceChangeSubscription: Subscription;
    private mediaProperties: MediaProperies;

    public audioControls = {
		inputDeviceId: new UntypedFormControl(null, []),
		outputDeviceId: new UntypedFormControl(null, []), 
		sampleRate: new UntypedFormControl(null, []), 
		echoCancellation: new UntypedFormControl(true, []),
		noiseSuppression: new UntypedFormControl(false, []),
		autoGainControl: new UntypedFormControl(false, []),
		isStereoAudio: new UntypedFormControl(false, []),
	};

    public support = {
		// Audio
		isSampleRate: false,
		isEchoCancellation: false,
		isNoiseSuppression: true,
		isAutoGainControl: false,
		isStereoAudio: false,
		// Video
		isFrameRate: false,
	};

	constructor(private mediaDevicesService: MediaDevicesService, private easyRtcService: EasyRtcService, private rtcStreamViewModel: RtcStreamViewModel, private platformDetectorService: PlatformDetectorService) {

        this.formGroup = new UntypedFormGroup({
            audio: new UntypedFormGroup(this.audioControls)
        })

        const mediaTrackSupported: MediaTrackSupportedConstraints =
        this.mediaDevicesService.getSupportedConstraints();
        const supportedConstraints = Object.keys(mediaTrackSupported);
        // Audio
        this.support.isSampleRate = supportedConstraints.includes("sampleRate");
        this.support.isEchoCancellation =
            supportedConstraints.includes("echoCancellation");
        this.support.isNoiseSuppression =
            supportedConstraints.includes("noiseSuppression");
        this.support.isAutoGainControl =
            supportedConstraints.includes("autoGainControl");
    }

	async ngOnInit() {
        this.mediaProperties =
        MediaProperiesUtil.readMediaProperiesFromStorage();
		const deviceIds: MediaDeviceInfo[] =
			await this.mediaDevicesService.enumerateDevices(true);
		this.audioInputDevices =
			this.mediaDevicesService.getDeviceAudioInput(deviceIds);
		this.audioOutputDevices =
			this.mediaDevicesService.getDeviceAudioOutput(deviceIds);
        this.formGroup.controls?.audio?.valueChanges
        .pipe(debounceTime(500))
        .subscribe((value) => {
            this.registerSettingsChange()
        });
        const mediaProperies = (this.currentMediaProperies =
			MediaProperiesUtil.readMediaProperiesFromStorage());
        this.prepareAudio(mediaProperies);
    }

    async registerSettingsChange() {
		const mediaProperties = this.createMediaProperies();
		MediaProperiesUtil.writeMediaProperiesToStorage(mediaProperties);
		const mediaStream = EasyRtcService.getLocalStream();
		if (!!mediaStream) {
			await this.easyRtcService.stopMediaStreamTrack(
				mediaStream.getTracks()
			);
		}
		this.currentMediaProperies = mediaProperties;
		const constraints =
			MediaProperiesUtil.createConstraints(mediaProperties);
		await this.rtcStreamViewModel.changeSelectedStream(constraints);
	}

	private createMediaProperies(): MediaProperies {
		const audioEchoCancellation = this.audioControls.echoCancellation.value;
		const formValue = this.formGroup.getRawValue();
		return {
			audioInpDev: this.audioControls.inputDeviceId.value,
			audioOutDev: this.audioControls.outputDeviceId.value,
			audioSampleRate: this.mediaProperties.audioSampleRate,
			audioEchoCancellation,
			audioNoiseSuppression: this.mediaProperties.audioNoiseSuppression,
			audioAutoGainControl: this.mediaProperties.audioAutoGainControl,
			audioChannelCount:this.mediaProperties.audioChannelCount,
			videoInpDev: this.mediaProperties.videoInpDev,
			videoWidth:  this.mediaProperties.videoWidth,
			videoHeight: this.mediaProperties.videoHeight,
			videoFrameRate: this.mediaProperties.videoFrameRate,
			videoIsReflection: this.mediaProperties.videoIsReflection,
			secondCameraInputDevice: this.mediaProperties.secondCameraInputDevice,
			langId: this.mediaProperties.langId,
			bgColor: this.mediaProperties.bgColor,
			videoResolution: this.mediaProperties.videoResolution,
		};
	}

    private prepareAudio(mediaProperiesPrm: Partial<MediaProperies>): void {

		const mediaProperies: Partial<MediaProperies> = mediaProperiesPrm || {};

		if (this.audioInputDevices.length > 0) {
			//  Here we set the form value to the audio input device
			const audioInpDevInfo =
				this.mediaDevicesService.findMediaDeviceInfo(
					this.audioInputDevices,
					mediaProperies.audioInpDev
				);
			this.audioControls.inputDeviceId.setValue(
				!!audioInpDevInfo ? audioInpDevInfo.deviceId : null,
				{ emitEvent: false }
			);
		} else {
			//  Here we disable the controls because no input device was found
			this.audioControls.inputDeviceId.disable({ emitEvent: false });
		}

		if (this.audioOutputDevices.length > 0) {
			//  Here we set the form value to the audio output device
			const audioOutDevInfo =
				this.mediaDevicesService.findMediaDeviceInfo(
					this.audioOutputDevices,
					mediaProperies.audioOutDev
				);
			this.audioControls.outputDeviceId.setValue(
				audioOutDevInfo.deviceId,
				{ emitEvent: false }
			);
		} else {
			//  Here we disable the controls because no output device was found
			this.audioControls.outputDeviceId.disable({ emitEvent: false });
		}

		if (this.support.isSampleRate) {
			const sampleRateDefault: ParamNumber = ParamNumberUtil.findDefault(
				this.audioSampleRates
			);
			const sampleRate: number = mediaProperiesPrm.audioSampleRate;
			const sampleRateValue = ParamNumberUtil.findParam(
				this.audioSampleRates,
				sampleRate,
				sampleRateDefault
			);
			this.audioControls.sampleRate.setValue(
				!!sampleRateValue ? sampleRateValue.param : null,
				{ emitEvent: false }
			);

		}

		if (this.support.isEchoCancellation) {
			this.audioControls.echoCancellation.setValue(
				mediaProperies.audioEchoCancellation,
				{ emitEvent: false }
			);
		}
		if (this.support.isNoiseSuppression) {
			this.audioControls.noiseSuppression.setValue(
				mediaProperies.audioNoiseSuppression,
				{ emitEvent: false }
			);
		}
		if (this.support.isAutoGainControl) {
			this.audioControls.autoGainControl.setValue(
				mediaProperies.audioAutoGainControl,
				{ emitEvent: false }
			);
		}
		if (this.support.isStereoAudio) {
			this.audioControls.isStereoAudio.setValue(
				mediaProperies.audioChannelCount > 1 ? true : false,
				{ emitEvent: false }
			);
		}
	}

    public isSafari (){
        return this.platformDetectorService.isBrowserSafari();
    }

    public clickOpenAudioSettings() {
		this.openAudioSettings.emit();
	}

	public clickToggleMic() {
		this.toggleMicro.emit();
        this.micMuted = !this.micMuted; 
	}

    

}

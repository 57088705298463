<app-header *ngIf="(!((customThemeService.logoURL$ | async) && isLoginPage) || (authenticationService.isLoggedIn$ | async))
         && !isInTheRoom && showHeader" [menuStyle]="headerMenuStyle">
</app-header>
<router-outlet></router-outlet>
<app-cookies-policy></app-cookies-policy>
<!--<app-footer *ngIf="!isInTheRoom && showFooter" [style]="footerStyle"></app-footer>-->
<div *appLoading="isLoading"></div>
<div class="session-expiring" *ngIf="sessionExpiring">
  <button class="close" (click)="dismissSessionWarning()">x</button>
  {{ 'session-expiring.warning' | translate }}
  <a (click)="loginAgain($event)">{{ 'session-expiring.link' | translate }}</a>.
</div>
import { Component } from "@angular/core";
import { MatTabsModule } from "@angular/material/tabs";
import { SharedModule } from "@shared/shared.module";
import { TranslateModule } from "@ngx-translate/core";
import { ProfileService } from "../profile/profile.service";
import { CommonModule } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
	selector: "app-availabilities-tab",
	standalone: true,
	imports: [MatTabsModule, SharedModule, TranslateModule, CommonModule],
	templateUrl: "./availabilities-tab.component.html",
	styleUrl: "./availabilities-tab.component.scss"
})
export class AvailabilitiesTabComponent {
	private tabNames = ["trial", "regular"];

	constructor(
		private profileService: ProfileService,
		private route: ActivatedRoute,
		private router: Router
	) {
		console.log("AvailabilitiesTabComponent.constructor()");
	}
	selectedIndex = 0;

	isBetaTester = false;

	ngOnInit() {
		this.route.paramMap.subscribe((params) => {
			const tab = params.get("tab");
			if (tab) {
				this.selectedIndex = this.tabNames.indexOf(tab);
			}
		});

		this.profileService.get().subscribe((profile) => {
			if (!profile) {
				return;
			} else if (profile.betaTesting) {
				this.isBetaTester = profile.betaTesting;
			} else return;
		});
	}

	selectedIndexChange(index: number) {
		this.router.navigate([`platform/availability/${this.tabNames[index]}`]);
	}
}

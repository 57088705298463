import { NgModule, HostListener } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PianoComponent } from './piano/piano.component';
import { PianoOctaveComponent } from './piano-octave/piano-octave.component';
import { PianoKeyComponent } from './piano-key/piano-key.component';
import { TranslateModule } from '@ngx-translate/core';


import {FormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSliderModule} from '@angular/material/slider';


@NgModule({
  declarations: [
    PianoComponent,
    PianoOctaveComponent,
    PianoKeyComponent
  ],    
  imports: [
    CommonModule, MatFormFieldModule, MatSelectModule, MatInputModule, FormsModule, TranslateModule, MatSliderModule
  ],
  exports: [
    PianoComponent 
  ]
})
export class PianoModule { 

}

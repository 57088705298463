import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VolumeControlComponent } from './volume-control.component';
import { MatSliderModule } from '@angular/material/slider';
import { FormsModule } from '@angular/forms';


@NgModule({
  declarations: [VolumeControlComponent],
  imports: [
    CommonModule,
    MatSliderModule,
    FormsModule
  ],
  exports: [VolumeControlComponent]
})
export class VolumeControlModule { }

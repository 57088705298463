import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {OrganizationVO} from '../../../data/OrganizationVO';
import {NgForm} from '@angular/forms';
import {PopupService} from '../../../../../services/popup/popup.service';
import {ButtonType} from '../../../../call-room/data/ButtonType';
import {OrganizationDataService} from '../../../services/organization-data.service';
import {Router} from '@angular/router';
import { logger } from 'src/app/lib-core/logger';
import {AuthenticationService} from '../../../../../services/authentication/authentication.service';

@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.scss']
})
export class OrganizationComponent {
  @Input() organization: OrganizationVO;
  @ViewChild('img') image: NgForm;
  @Output() orgUpdate: EventEmitter<boolean> = new EventEmitter<boolean>();
  isEditor: boolean = false;
  isDelete: boolean = false;
  constructor(private popupService: PopupService,
              private dataService: OrganizationDataService,
              private router: Router,
              private authenticationService: AuthenticationService) { }
  onDelete() {
    this.popupService.openCustomMessagePopup(
      `Do you really want to delete ${this.organization.name} organization?`,
      [ {
        type: ButtonType.OK,
        text: 'Leave',
      }, {
        type: ButtonType.CANCEL,
        text: 'Delete',
        callBack: () => {
          this.deleteOrg();
        }
      }]);
  }
  onEditorClick() {
    if (this.isEditor) {
      this.onClose();
    } else {
      this.isEditor = true;
    }
  }
  onUsersClick() {
    this.router.navigate(['admin/users-list'],  {queryParams: {organization: this.organization.id, page: 1}})
      .then(result => logger.log('navigation result: ', result))
      .catch(error => console.error('navigation error: ', error));
  }
  onClose() {
    this.popupService.openCustomMessagePopup(
      `Do you want to end editing? Your changes will be deleted`,
      [ {
        type: ButtonType.OK,
        text: 'Stay',
      }, {
        type: ButtonType.CANCEL,
        text: 'Cancel',
        callBack: () => {
          this.isEditor = false;
        }
      }]);
  }
  onOrganizationEdited(editedOrg: OrganizationVO) {
    logger.log('onOrganizationEdited ', editedOrg);
    this.popupService.openCustomMessagePopup(
      `Do you really want to update ${this.organization.name} organization?`,
      [ {
        type: ButtonType.OK,
        text: 'Update',
        callBack: () => {
          this.updateOrg(editedOrg);
        }
      }, {
        type: ButtonType.CANCEL,
        text: 'Cancel'
      }] , true);
  }
  private deleteOrg () {
    this.dataService.delete(this.organization.id)
      .subscribe(deleteOrg => {
        if (deleteOrg.id === this.organization.id) {
          this.isDelete = true;
          this.orgUpdate.emit(true);
        }
      }, error => {
        this.popupService.openSnackBar('Error: Can\'t delete organization', 3000);
        console.error(error);
        if (error.status === 401 || error.status === 403) {
          this.authenticationService.logOut$().subscribe(res => {
            this.authenticationService.isLoggedIn$.next(false);
            this.router.navigate(['admin/login']);
          });
        }
      });
  }
  private updateOrg (org: OrganizationVO) {
    this.dataService.update(org)
      .subscribe(newOrg => {
        this.organization = newOrg;
        this.isEditor = false;
        this.orgUpdate.emit(true);
      }, error => {
        let errMsg = 'Error: Can\'t update organization';
        if (error.status === 400) {
          errMsg = 'Error: Name or Subdomain already exists';
        }
        this.popupService.openSnackBar(errMsg, 3000);
        console.error(error);
        if (error.status === 401 || error.status === 403) {
          this.authenticationService.logOut$().subscribe(res => {
            this.authenticationService.isLoggedIn$.next(false);
            this.router.navigate(['admin/login']);
          });
        }
      });
  }

}

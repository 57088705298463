<div class="mat-container mb-16">
	<div class="availability-container">
		<div class="save-wrapper">
			<div class="time-zone-wrapper">
				<div class="w-full flex justify-between items-center">
					<div class="time-title">
						{{ "availability.timezone" | translate }}
					</div>
					<button mat-flat-button class="timezone-button" color="primary" [routerLink]="['/platform/profile/account']">
						{{ "buttons.edit-2" | translate }}
					</button>
				</div>
				<div class="time-zone">
					{{selectedTimeZone?.text}}
				</div>
			</div>
			<div class="flex !bg-transparent">
				<span *ngIf="isSavedMessage" class="saved-message text-sirius-lilac">{{'buttons.saved' |
					translate}}</span>
				<button [disabled]="loading || !isFormDirty || formHasErrors(availabilityFrom)" class="default-ui-button input save-button" (click)="save()">{{'availability.save-btn' |
					translate}}</button>
			</div>
		</div>

		<form class="availability-form" [formGroup]="availabilityFrom">

			<ng-container *ngIf="availabilityFrom.controls['items']['controls']">

				<div formArrayName="items" *ngFor="let item of availabilityFrom.controls['items']['controls']; let i = index" class="form-container">


					<ng-container [formGroupName]="i">
						<!-- <div [ngStyle]="{'visibility': item['controls'].duplicate.value ? 'hidden' : 'unset'}" class="form-item-day"> -->

						<div [class.hide]="item['controls'].duplicate.value" class="form-item-day">
							<div *ngIf="!item['controls'].duplicate.value" class="checkbox-wrapper">
								<label class="switch">
									<input type="checkbox" formControlName="checked" (change)="toggleDay($event, item['controls'].dayName.value)">
									<span class="slider"></span>
								</label>
							</div>
							<div class="day">{{item['controls'].dayName.value}}</div>
						</div>

						<div class="form-row" *ngIf="item['controls'].checked.value">
							<div class="form-item-time">
								<div class="time-container">
									<div>

										<input readonly formControlName="startTime" #el (click)="toggleTimeInput(el, item['controls'], item['controls'].startTime.value, item['controls'].showStartTimeListBox, 'startTime')" class="start-time-value" autocapitalize="none" autocomplete="off" autocorrect="off" spellcheck="false" tabindex="0" type="text" aria-autocomplete="list" aria-expanded="false" aria-haspopup="true" role="combobox">

										<div *ngIf="item['controls'].showStartTimeListBox.value" class="select-list-box">

											<div class="blocker" tabindex="0" (click)="close(item['controls'].showStartTimeListBox)"></div>

											<div class="scrollbar-thin">

												<ng-container *ngFor="let sl of item['controls'].slots?.value">

													<div class="list-box-item" [ngClass]="{'selected': sl.checked}" (click)="selectSlot(sl.slot, item['controls'], item['controls']?.startTime,  item['controls'].showStartTimeListBox, 'startTime')">

														<span>{{sl.slot}}</span>

														<svg *ngIf="sl.checked" stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" class="h-4 w-4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
															<polyline points="20 6 9 17 4 12"></polyline>
														</svg>
													</div>

												</ng-container>

											</div>

										</div>

									</div>
								</div>

								<span> - </span>

								<div class="time-container">
									<div>
										<input formControlName="endTime" class="end-time-value" readonly #el1 (click)="toggleTimeInput(el1, item['controls'],  item['controls'].endTime.value, item['controls'].showEndTimeListBox, 'endTime')" autocapitalize="none" autocomplete="off" autocorrect="off" spellcheck="false" type="text" aria-autocomplete="list" aria-expanded="false" aria-haspopup="true" role="combobox">

										<div *ngIf="item['controls'].showEndTimeListBox.value" class="select-list-box">

											<div class="blocker" tabindex="0" (click)="close(item['controls'].showEndTimeListBox)"></div>

											<div class="scrollbar-thin">

												<ng-container *ngFor="let sl of item['controls'].slots?.value">
													<div class="list-box-item" (click)="selectSlot(sl.slot,  item['controls'], item['controls']?.endTime,  item['controls'].showEndTimeListBox, 'endTime')" [ngClass]="{'selected': sl.checked}">

														<span>{{sl.slot}}</span>

														<svg *ngIf="sl.checked" stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" class="h-4 w-4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
															<polyline points="20 6 9 17 4 12"></polyline>
														</svg>

													</div>

												</ng-container>

											</div>
										</div>
									</div>
								</div>
							</div>
							<div *ngIf="item['controls'].duplicate.value" class="form-item-add">
								<button type="button" (click)="remove(i)">
									<svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" class="inline-flex h-4 w-4 " height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
										<polyline points="3 6 5 6 21 6"></polyline>
										<path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2">
										</path>
									</svg>
								</button>
							</div>
							<div *ngIf="!item['controls'].duplicate.value" class="form-item-add">
								<button type="button" (click)="addNext(item['controls'].dayName.value, i)">
									<svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" class="inline-flex h-4 w-4 " height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
										<line x1="12" y1="5" x2="12" y2="19"></line>
										<line x1="5" y1="12" x2="19" y2="12"></line>
									</svg>
								</button>
							</div>
							<div [ngStyle]="{'visibility': item['controls'].duplicate.value ? 'hidden' : 'unset'}" tabindex="0" class="duplicate-container">
								<div tabindex="0" class="duplicate-wrapper">
									<div class="add-duplicate" (click)="toggleSelectListBox(item['controls'].showSelectListBox)" tabindex="0">
										<button class="toggle-duplicate-list" type="button">
											<svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" class="inline-flex h-4 w-4 " height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
												<rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
												<path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1">
												</path>
											</svg>
										</button>
									</div>
									<div *ngIf="item['controls'].showSelectListBox.value" class="select-list-box">
										<div class="blocker" (click)="close(item['controls'].showSelectListBox)"></div>
										<app-duplicate-select-list [selectedDay]="item['controls'].dayName.value" [days]="days" (apply)="handleSelectList($event, item['controls'])" (close)="close(item['controls'].showSelectListBox)">
										</app-duplicate-select-list>
									</div>
								</div>
							</div>
						</div>
						<span class="error text-xs text-alert mt-1 block" *ngIf="item.hasError('startTimeAfterEndTime')"> {{ "availability.endTimeGreater" | translate
							}}</span>
					</ng-container>
				</div>


			</ng-container>

		</form>

		<span class="text-xs text-alert mt-1 block" *ngIf="dayUpdated">{{"lessons.onChangeMessange" |
			translate}}</span>

		<!-- <ng-container>
            <p>removeOverrideDate</p>
            <ul>
                <li *ngFor="let date of removeOverrideDate">{{date.overrideDate}}</li>
            </ul>
        </ng-container>

        <ng-container>
            <p>addOverrideDate</p>
            <ul>
                <li *ngFor="let date of addOverrideDate">{{date.overrideDate}}</li>
            </ul>
        </ng-container> -->

		<div class="time-zone-wrapper mobile">
			<div class="w-full flex justify-between items-center">
				<div class="time-title">
					{{ "availability.timezone" | translate }}
				</div>
				<button mat-flat-button class="timezone-button" color="accent" [routerLink]="['/platform/profile/account']">
					{{ "buttons.edit-2" | translate }}
				</button>
			</div>
			<div class="time-zone">
				{{selectedTimeZone?.text}}
			</div>
		</div>

		<ng-container *ngIf="init">
			<app-update-notice [notice]="selectedNotice" [lessonType]="lessonType" (setNotice)="onNoticeChange($event)"></app-update-notice>
			<span class="text-xs text-alert mt-1 block" *ngIf="noticeUpdated">{{"lessons.onChangeMessange" |
				translate}}</span>
			<app-update-limit [limit]="selectedLimit" [lessonType]="lessonType" (setLimit)="onLimitChange($event)"> </app-update-limit>
			<span *ngIf="isSavedLimitMessage" class="text-xs text-sirius-lilac">{{'buttons.saved' |
				translate}}</span>
			<app-date-override [userId]="userId" [timeZone]="timeZone" [lessonType]="lessonType">
			</app-date-override>
			<span class="text-xs text-alert mt-1 block" *ngIf="overrideUpdated">{{"lessons.onChangeMessange" |
				translate}}</span>
		</ng-container>

	</div>
</div>
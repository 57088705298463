<img class="sirius-logo" src="/assets/icons/sirius/Sirius.png" alt="Sirius">

<img class="sirius-music-gfx" src="/assets/icons/sirius/redesign/sirius-music.png" alt="Sirius">
<img class="sirius-jupiter-gfx" src="/assets/icons/sirius/redesign/sirius-planet.png" alt="Sirius">
<img class="sirius-mars-gfx" src="/assets/icons/sirius/redesign/sirius-planeten-01.png" alt="Sirius">
<img class="sirius-blueplanet-gfx" src="/assets/icons/sirius/redesign/sirius-planeten-02.png" alt="Sirius">
<img class="sirius-bluebigplanet-gfx" src="/assets/icons/sirius/redesign/sirius-planeten-04.png" alt="Sirius">

<app-language-selector />

<div class="registration-outer box-content">
  <h1>{{ 'forgot-password.title' | translate }}</h1>
  <form (ngSubmit)="recover()" [formGroup]="forgotPassForm">
    <div class="registration-row">
      <label class="registration-label" for="userEmail">{{ 'forgot-password.email-label' | translate }}</label>
      <input type="email" id="userEmail" [placeholder]="'forgot-password.email-placeholder' | translate"
        formControlName="userEmail" maxlength="60">
      <div *ngIf="forgotPassForm.controls['userEmail'].invalid && forgotPassForm.controls['userEmail'].dirty"
        class="registration-error-message">
        {{ 'forgot-password.email-error' | translate }}
      </div>
      <div *ngIf="isMailError" class="registration-error-message">
        {{ 'forgot-password.email-dont-exists' | translate }}
      </div>
    </div>
    <div class="registration-row">
      <button type="submit" class="send default-ui-button blue-ui-button large" [disabled]="!forgotPassForm.valid">
        {{ 'buttons.send' | translate }}
      </button>
    </div>
  </form>
</div>
<!-- <div *ngIf="!isSchool">
  <div style="margin-top:24px">
    <p style="font-size:18px">Don’t have an account? <a routerLink="/signup">Sign up</a></p>
  </div>
</div> -->
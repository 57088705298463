<button class="toggle-chat" [ngClass]="displayChat ? 'chat-open' : 'chat-closed'" *ngIf="isStandaloneChat" (click)="toggleChat()">
	<p class="chat-icon-background"></p>
</button>
<mat-sidenav-container id="chat">
	<mat-sidenav
		#chatUI
		appDragndrop
		(fileDropped)="getFile($event)"
		[opened]="displayChat"
		[hidden]="!displayChat"
		position="end"
		class="chat-desktop"
	>
		<div role="region" [attr.aria-label]="'aria-label.chat-window' | translate" id="chatUI" tabindex="0">
			<h2 class="chat-title">
				{{ "chat.logo" | translate }}
			</h2>
			<button [attr.aria-label]="'aria-label.close-chat' | translate" class="close-chat" (click)="toggleChat()">
				<mat-icon svgIcon="close"></mat-icon>
			</button>
			<button [attr.aria-label]="'aria-label.delete-history' | translate" class="clear-chat" (click)="clearMessages()">
				<i class="fas fa-trash-alt"></i>
			</button>
			<div
				[attr.aria-label]="'aria-label.messages' | translate"
				role="list"
				class="chat-body"
				#chatBody
				(scroll)="chatScrollHandler()"
			>
				<div
					*ngFor="let message of chatMessages"
					class="message"
					role="listitem"
					[attr.aria-label]="'aria-label.message' | translate"
				>
					<p class="message-metadata">
						<span class="message-author">
							{{ message.name }}
						</span>
						<span class="message-date">
							{{ message.date | date : "HH:mm" }}
						</span>
					</p>
					<p
						[attr.aria-label]="'aria-label.message' | translate"
						class="message-text"
						[ngClass]="userName === message.name ? 'my-text' : 'others-text'"
						[innerHTML]="message.text"
					></p>
				</div>
			</div>
			<div class="chat-footer">
				<div style="text-align: center" [hidden]="!isLoading">
					Loading...
				</div>
				<div class="message-input-container">
					<form class="message-input">
						<div class="upload-btn-wrapper">
							<span #uploadButton id="upload-button" style="color: white"><i class="fas fa-paperclip"></i></span>
							<input
								[attr.aria-label]="'aria-label.attach-file' | translate"
								#uploadInput
								type="file"
								id="upload-input"
								multiple
							/>
						</div>
						<textarea
							matInput
							class="message-textarea"
							[placeholder]="'chat.enterText' | translate"
							[ngModelOptions]="{ standalone: true }"
							[(ngModel)]="currentMessage"
							(focus)="onFocus()"
							(blur)="onBlur()"
							(keypress)="processKeyPress($event)"
							[attr.aria-label]="'aria-label.text-input' | translate"
						>
						</textarea>
					</form>
				</div>
			</div>
		</div>
	</mat-sidenav>
</mat-sidenav-container>

<div *ngIf="users && users.length" class="participants-title-container">
        <span class="participants-title">
          {{('lobby-popup.participants'| translate)}}
        </span>
  <span>
          {{currentUsers}}/{{maxUsers}}
        </span>
</div>
<div class="scrollable-container">
  <div *ngFor="let user of users" class="user" [ngClass]="user.connected ? 'connected-user' : ''">
    <app-icon-user [color]="user.connected ? '#ffffff' : '#64607c'" [isCircle]="user.connected"></app-icon-user>
    <span [class.online]="user.connected" class="user-name">{{user.name}}</span>
  </div>
</div>

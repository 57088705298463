<div class="container">
  <video  [hidden]="!(isCamera$ | async)" [class.reflected-video]="user && user.self !== user.isReflection" autoplay playsinline
          [muted]="forceMute"
          (loadstart)="applyVideoParameters()"
          [class.full-bg]="isFillScale && !(isMainVideo && (isDisplay$ | async))"
          #thisVideo></video>
  <div *ngIf="!(isCamera$ | async)" class="content">
    <app-icon-user [hidden]="!(name$ | async)"
                   class="user-avatar"
                   [color]="'#00ACCD'">
    </app-icon-user>
    <app-icon-round-letter
      [textValue]="(name$ | async)"
      [hidden]="(name$ | async) === ''" class="user-avatar"
      [fillColor]="'#00ACCD'">
    </app-icon-round-letter>
  </div>
  <app-user-actions *ngIf="!isMainVideo" [user]="user" appearance="vertical"></app-user-actions>
</div>


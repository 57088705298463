<form (ngSubmit)="closeSettingsAndEnter()" [formGroup]="preCallSettingsForm">
  <app-loading [isUnderModal]="false" [isBgParent]="true" *ngIf="isLoading"></app-loading>
  <div class="videoComponent-new" [ngStyle]="{height: isMediaError ? '200px': 'inherit'}"
    [ngClass]="{'mobile-container-android': !isSafariOnIos() ,'mobile-container': isSafariOnIos() && roomState.isCamOn, 'video-background': (isSafariOnIos() || isSafariDesktopBrowser()), 'mozComponent-new': isFirefox}">
    <div class="room-link-container">
      {{roomLink}}
    </div>
    <div class="notify-banner" *ngIf="!roomState.isCamOn || !roomState.isMicroOn">
      <span
        *ngIf="!roomState.isMicroOn && !roomState.isCamOn; else oneOf">{{'pre-call-settings.notify-banner-mic-and-cam-off'
        | translate}}</span>
      <ng-template #oneOf>
        <span *ngIf="!roomState.isMicroOn">{{'pre-call-settings.notify-banner-mic-off' | translate}}</span>
        <span *ngIf="!roomState.isCamOn">{{'pre-call-settings.notify-banner-cam-off' | translate}}</span>
      </ng-template>
    </div>
    <app-waiting-room-video [hidden]="isMediaError || hideOnIosSafari" [srcObject]="localStream"
      _debugName_="'video-settings'" *ngIf="!videoSettingsCanBeClosed"></app-waiting-room-video>
    <div class="error" [hidden]="!isMediaError">
      <label class="err-label">{{ 'video-settings.camera-is-busy' | translate }}</label>
    </div>
    <div class="video-controls">
      <div class="control-item-wrapper" [class.red-active]="!roomState.isCamOn"
        (click)="toggleWebCam(!roomState.isCamOn)">
        <mat-icon svgIcon="cam{{ !roomState.isCamOn ? '-off': '' }}"></mat-icon>
      </div>
      <div class="control-item-wrapper" [class.red-active]="!roomState.isMicroOn"
        (click)="toggleMicro(!roomState.isMicroOn)">
        <mat-icon svgIcon="mic{{ !roomState.isMicroOn ? '-off': '' }}"></mat-icon>
      </div>
    </div>


    <div class="settings-container">

      <div [ngClass]="{'disabled-block': isMediaError && isFirefox}" class="row-media"
        *ngIf="videoDeviceList.length > 0">
        <div class="media-img icon camera"></div>
        <div class="media-prm">
          <mat-select color="primary" class="app-select video-settings-select pre-call" formControlName="videoInputDeviceId"
            panelClass="app-select-panel" disableOptionCentering="true" (selectionChange)="doChangeVideoInputDevice()">
            <mat-option *ngFor="let device of videoDeviceList" [value]="device.deviceId">{{ device.label ||
              device.groupId
              }}</mat-option>
          </mat-select>
        </div>
      </div>
      <div class="volume-meter-container">
        <app-volume-meter [volume]="currentVolume"></app-volume-meter>
      </div>
      <div [ngClass]="{'disabled-block': isMediaError && isFirefox}" class="row-media"
        *ngIf="audioDeviceList.length > 0">
        <div class="media-img icon microphone"></div>
        <div class="media-prm">
          <mat-select color="primary" class="app-select video-settings-select pre-call" formControlName="audioInputDeviceId"
            panelClass="app-select-panel" disableOptionCentering="true" (selectionChange)="doChangeAudioInputDevice()">
            <mat-option *ngFor="let device of audioDeviceList" [value]="device.deviceId">{{ device.label ||
              device.groupId
              }}</mat-option>
          </mat-select>
        </div>
      </div>
      <div [ngClass]="{'disabled-block': isMediaError && isFirefox}" class="row-media"
        *ngIf="outputDevicesList.length > 0">
        <div class="media-img icon speaker"></div>
        <div class="media-prm audio-devices">
          <mat-select color="primary" class="app-select video-settings-select pre-call" formControlName="audioOutputDeviceId"
            panelClass="app-select-panel" disableOptionCentering="true"
            (selectionChange)="doChangeAudioOutputDevice($event.value)">
            <mat-option *ngFor="let device of outputDevicesList" [value]="device.deviceId">{{ device.label ||
              device.groupId }}</mat-option>
          </mat-select>
          <span class="test-audio" (click)="testAudio()">Test</span>
        </div>
      </div>
      <div class="button-enter-wrapper">
        <ng-container *ngIf="isFirefox">
          <button *ngIf="!isMediaError; else tryAgain" class="new-button"
            [disabled]="!preCallSettingsForm.valid || isMediaError" type="submit">
            {{ 'buttons.join-lesson' | translate }}
          </button>
          <ng-template #tryAgain>
            <button (click)="returnToPermissionPage()" class="new-button">
              Try again
            </button>
          </ng-template>
        </ng-container>
        <ng-container *ngIf="!isFirefox">
          <button class="new-button" [disabled]="!preCallSettingsForm.valid || isMediaError" type="submit">
            {{ 'buttons.join-lesson' | translate }}
          </button>
        </ng-container>
      </div>

    </div>


  </div>


</form>
import { Component, Input, NgZone } from "@angular/core";
import { AudioContextService } from "src/app/services/audio-context/audio-context.service";

@Component({
	selector: "app-volume-meter-mini",
	standalone: true,
	imports: [],
	templateUrl: "./volume-meter-mini.component.html",
	styleUrl: "./volume-meter-mini.component.scss"
})
export class VolumeMeterMiniComponent {
	@Input() mediaStream: MediaStream;
	audioContext: AudioContext;
	analyser: AnalyserNode;
	javascriptNode: ScriptProcessorNode;
	currentVolume: number = 0;
	microphone: MediaStreamAudioSourceNode;

	constructor(
		private ngZone: NgZone,
		private audioContextService: AudioContextService
	) {}

	async ngOnInit() {
		this.audioContext = this.audioContextService.getAudioContext();
		await this.initAudioAnalyser(this.mediaStream);
	}

	public async initAudioAnalyser(stream: MediaStream): Promise<void> {
		if (!stream) {
			console.error("MediaStream is not provided");
			return;
		}

		this.microphone = this.audioContext.createMediaStreamSource(stream);
		this.analyser = this.audioContext.createAnalyser();

		await this.audioContext.audioWorklet.addModule(
			"/assets/javascript/volume-meter-processor.js"
		);

		const volumeMeterNode = new AudioWorkletNode(
			this.audioContext,
			"volume-meter-processor"
		);

		this.analyser.smoothingTimeConstant = 1;
		this.analyser.fftSize = 64;

		this.microphone.connect(this.analyser);
		this.analyser.connect(volumeMeterNode);
		volumeMeterNode.connect(this.audioContext.destination);

		volumeMeterNode.port.onmessage = (event) => {
			this.ngZone.run(() => {
				this.currentVolume = event.data;
			});
		};
	}

	get mainHeight() {
		// 22.22% is the minimum height of the volume meter (4px in 18px)
		return (this.currentVolume * 1.3) + 22.22 + "%";
	}

	get sideHeight() {
		return this.currentVolume / 2 + 33.33 + "%";
	}

	isSilent() {
		return this.currentVolume < 4;
	}
}

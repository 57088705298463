import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { getSubDomain } from '../../helpers';

@Injectable({
    providedIn: 'root'
})
export class RedirectGuard  {

  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if ( location.hostname.includes("localhost") || location.hostname.indexOf("192.") === 0 ) {
      window.location.href = "/login";
      return true;
    } else if (!getSubDomain() == true) {
    window.location.href = route.data['externalUrl'];
    }
    else
    window.location.href = "/login"
      return true;
  }
}
<a href="/">
	<img
		class="sirius-logo"
		[src]="'/assets/icons/sirius/Sirius.png?' + assetsVersion"
		alt="Sirius"
	/>
</a>
<app-language-selector />
<img
	class="sirius-music-gfx"
	src="/assets/icons/sirius/redesign/sirius-music.png"
	alt="Sirius"
/>
<img
	class="sirius-jupiter-gfx"
	src="/assets/icons/sirius/redesign/sirius-planet.png"
	alt="Sirius"
/>
<img
	class="sirius-mars-gfx"
	src="/assets/icons/sirius/redesign/sirius-planeten-01.png"
	alt="Sirius"
/>
<img
	class="sirius-blueplanet-gfx"
	src="/assets/icons/sirius/redesign/sirius-planeten-02.png"
	alt="Sirius"
/>
<img
	class="sirius-bluebigplanet-gfx"
	src="/assets/icons/sirius/redesign/sirius-planeten-04.png"
	alt="Sirius"
/>

<div class="login-form-background">
	<div class="registration-outer box-content">
		<img
			class="warning-icon"
			src="../../assets/icons/Warning-orange.svg"
			alt=""
		/>
		<div
			class="info-text text-text-1"
			[innerHTML]="'unsupportedBrowser.alert' | translate"
		></div>
		@if(isDesktop){
		<div
			class="info-text text-text-1"
			[innerHTML]="'unsupportedBrowser.desktop.downloadBrowser' | translate"
		></div>
		} @else {

		<div
			*ngIf="isAndroid"
			class="info-text text-text-1"
			[innerHTML]="
				'unsupportedBrowser.android.downloadBrowser' | translate
			"
		></div>
		<div
			*ngIf="!isAndroid"
			class="info-text text-text-1"
			[innerHTML]="
				'unsupportedBrowser.other.downloadBrowser' | translate
			"
		></div>
		}

		<button
			class="default-ui-button blue-ui-button"
			(click)="handleOkClick()"
		>
			{{ "buttons.ok" | translate }}
		</button>
	</div>
</div>

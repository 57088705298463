import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { TranslateModule, TranslateService } from "@ngx-translate/core";

@Component({
	selector: "app-student-info-panel",
	standalone: true,
	imports: [CommonModule, TranslateModule],
	templateUrl: "./student-info-panel.component.html",
	styleUrl: "./student-info-panel.component.scss",
})
export class StudentInfoPanelComponent {
	@Input() studentInfo;
	name: string = "";
	email: string = "";
	instruments = "";
	genre = "";
	ageGroup = "";
	favouriteSong = "";
	notesForTeacher = "";
	skillLevel = "";

	constructor(private translateService: TranslateService) {}

	ngOnInit(): void {
		this.name = this.studentInfo.name?.split(" ")[0]
		this.instruments = this.translateArray(
			"instruments",
			this.studentInfo.studentProfile?.instruments?.map((i) => i.instrument)
		);

		const genres = this.studentInfo.studentProfile?.instruments
			?.map((i) => i.genre)
			?.flat();

		const genresSetString = [...new Set(genres)] as string[];
		this.genre = this.translateArray(
			"musicGenres",
			genresSetString
		);
		this.ageGroup = this.studentInfo.studentProfile?.ageGroup;
		this.favouriteSong = this.studentInfo.studentProfile?.favoriteSong;
		this.notesForTeacher = this.studentInfo.studentProfile?.notes;
		this.skillLevel = this.studentInfo.studentProfile?.skillLevel;
	}

	private translateArray(key: string, array: string[]): string {
		if (!array || !key || !array.length) {
			return "";
		}
		return array
			.map((item) => this.translateService.instant(`${key}.${item}`))
			.join(", ");
	}

	public isStudentInfo(): boolean {
		if (!this.studentInfo?.studentProfile) return false;

		const hasInfo = Object.values(this.studentInfo).some((value) => {
			if (typeof value === "string") {
				return value.trim().length > 0;
			} else if (Array.isArray(value)) {
				return value.length > 0;
			}
			return Boolean(value);
		});
		return hasInfo;
	}
}

import { Component, HostListener, ChangeDetectorRef } from "@angular/core";
import { PianoService } from "../service/piano.service";
import { GA4Service } from "../../services/ga4.service";
import { MidiService } from "../../services/midi/midi.service";
import { getSubDomain } from "./../../helpers";
import { ChatService } from "src/app/components/chat/services/chat.service";

interface Octaves {
	value: number[];
	viewValue: string;
}

declare const navigator: any;

//import * as Tone from 'tone'
import { BehaviorSubject } from "rxjs";

@Component({
	selector: "app-piano",
	templateUrl: "./piano.component.html",
	styleUrls: ["./piano.component.scss"],
})
export class PianoComponent {
	constructor(
		public midiService: MidiService,
		public pianoService: PianoService,
		public changed: ChangeDetectorRef,
		private ga4: GA4Service,
		private chatService: ChatService
	) {}
	func: any;
	octaves = [1, 2, 3, 4, 5, 6];
	messages = [];
	public samplerIsLoaded: boolean = false;
	public bigKeyboard: boolean = false;
	public selected = "36";
	volume = 0;
	gain;
	public isChatOpen = false;

	$octaves = new BehaviorSubject<number[]>([1, 2, 3, 4, 5, 6]);

	displayChatSubscription;

	octaveNotes = [
		"C",
		"C#",
		"D",
		"D#",
		"E",
		"F",
		"F#",
		"G",
		"G#",
		"A",
		"A#",
		"B",
	];

	octavesNumber: Octaves[] = [
		{ value: [3, 4, 5], viewValue: "36" },
		{ value: [1, 2, 3, 4, 5, 6], viewValue: "72" },
	];

	sampler;

	ngOnDestroy() {
		this.pianoService.deleteKeys();
		if (this.displayChatSubscription) {
			this.displayChatSubscription.unsubscribe();
		}
	}

	async ngOnInit() {
		this.initMidiStream();
		this.$octaves.subscribe((value) => {});
		if (!getSubDomain()) {
			// console.log("triggered");
			this.ga4.open_piano();
		}

		const Tone = await import("tone");

		this.gain = new Tone.Volume(0).toDestination();

		this.displayChatSubscription = this.chatService.displayChat$.subscribe(
			(isChatOpen) => {
				this.isChatOpen = isChatOpen;
			}
		);

		this.sampler = new Tone.Sampler({
			urls: {
				C3: "C3.mp3",
				"D#3": "Ds3.mp3",
				"F#3": "Fs3.mp3",
				A3: "A3.mp3",
				C4: "C4.mp3",
				"D#4": "Ds4.mp3",
				"F#4": "Fs4.mp3",
				A4: "A4.mp3",
				C5: "C5.mp3",
				"D#5": "Ds5.mp3",
				"F#5": "Fs5.mp3",
				A5: "A5.mp3",
			},
			baseUrl: "/assets/media/piano_sounds/",
			onload: () => {
				this.samplerIsLoaded = true;
			},
		}).connect(this.gain);
	}

	onChange(volume) {
		this.gain.volume.value = volume;
	}

	playTriggeredNote(keyName) {
		if (!this.isChatOpen) {
			if (this.samplerIsLoaded) {
				this.sampler.triggerAttack([keyName]);
			}
		}
	}

	releaseNote(keyName) {
		if (!this.isChatOpen) {
			if (this.samplerIsLoaded) {
				this.sampler.triggerRelease([keyName]);
			}
		}
	}

	private async initMidiStream() {
		// const permissions = await this.midiService.getMidiPermissions();

		if (navigator.requestMIDIAccess) {
			this.midiService.getMidiMessages().subscribe((message) => {
				if (message.status == "PRESSED" && message.name != undefined) {
					this.pianoService.onKeyDownMidi(message.name);
				} else if (message.name != undefined) {
					this.pianoService.onKeyUpMidi(message.name);
				}
				this.messages.unshift(message);
				this.changed.detectChanges();
			});
		}
	}

	onMIDIFailure() {
		console.log("Could not access your MIDI devices.");
	}

	public sendDataDown(note) {
		this.pianoService.onKeyDownMidi(note);
	}

	public sendDataUp(note) {
		this.pianoService.onKeyDownMidi(note);
	}

	public setOctave(event) {
		this.bigKeyboard = !this.bigKeyboard;
	}

	@HostListener("window:keydown", ["$event"])
	onKeyUp(event: KeyboardEvent) {
		if (!this.isChatOpen) {
			this.pianoService.onKeyDown(event);
		}
	}

	@HostListener("window:keyup", ["$event"])
	onKeyDown(event: KeyboardEvent) {
		if (!this.isChatOpen) {
			this.pianoService.onKeyUp(event);
		}
	}
}

import { Component, Input, OnDestroy } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { logger } from "../lib-core/logger";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { GA4Service } from "src/app/services/ga4.service";

const UPLOAD_SIZE = 1024 * 512; // 512 KB in bytes
const REQUEST_COUNT = 60; // 60 requests in total = 30 MB
const UPLOAD_TIMEOUT_MS = 30 * 1000; // 30 seconds
const BAD_SPEED_THRESHOLD = 10; // Mbps
const MODERATE_SPEED_THRESHOLD = 20; // Mbps

export interface Result {
	date: number;
	speed: number;
}

// todo: refactor to use angular http client, clean up svgs

@Component({
	selector: "app-speed-test",
	templateUrl: "./speed-test.component.html",
	styleUrls: ["./speed-test.component.scss"]
})
export class SpeedTestComponent implements OnDestroy {
	@Input() isMobile: boolean;
	@Input() theme;
	isTesting = false;
	isPopupOpen = false;
	isError = false;
	latestResult: Result | null = null;
	popupTimeout;

	protected uploadTotalBytes = UPLOAD_SIZE * REQUEST_COUNT;
	protected uploadTimeoutSeconds = UPLOAD_TIMEOUT_MS / 1000;
	protected testStartTime: number;
	protected url = "https://sirius.timeboxing.eu/";

	//current test
	protected testTimeout;
	protected testRequests: XMLHttpRequest[] = [];
	protected testBytesUploaded = 0;
	protected testCurrentSpeed = 0;

	constructor(
		protected translateService: TranslateService,
		protected http: HttpClient,
		protected ga4: GA4Service
	) {
		const latestCheck = sessionStorage.getItem("latestCheck");
		if (latestCheck) {
			this.latestResult = JSON.parse(latestCheck);
		}
	}

	get headerHeight(): number {
		return this.isMobile ? 24 : 32;
	}

	get lastChecked(): string {
		return this.formatDate(this.latestResult?.date);
	}

	get connectionStatus(): string {
		return this.formatSpeed(this.latestResult?.speed);
	}

	get currentMbps(): string {
		return this.testCurrentSpeed.toFixed(1);
	}

	get uploadedMB(): string {
		return (this.testBytesUploaded / 1024 / 1024).toFixed(1);
	}

	ngOnDestroy(): void {
		this.cancelTest();
	}

	toggleTest(): void {
		this.isTesting ? this.cancelTest() : this.startTest();
		this.ga4.speedtest();
	}

	private warmup(): Observable<void> {
		return new Observable<void>((observer) => {
			this.http
				.post(this.url, { data: "k".repeat(1024 * 512) })
				.subscribe(() => {
					observer.next();
					observer.complete();
				});
		});
	}

	private startTest() {
		this.testStartTime = null;
		this.testRequests = [];
		this.testCurrentSpeed = 0;
		this.testBytesUploaded = 0;
		this.isError = false;
		this.isTesting = true;
		this.warmup().subscribe(() => {
			logger.log("warmup complete");
			this.testStartTime = performance.now();
			this.upload();
			this.testTimeout = setTimeout(() => {
				logger.log("timeout");
				if (this.testBytesUploaded) {
					this.captureResult();
					this.cancelTest();
				} else {
					this.isError = true;
					this.cancelTest();
				}
			}, UPLOAD_TIMEOUT_MS);
		});
	}

	private cancelTest(): void {
		this.isTesting = false;
		this.testRequests.forEach((request) => request.abort());
		if (this.testTimeout) {
			clearTimeout(this.testTimeout);
		}
	}

	private captureResult(): void {
		const result: Result = {
			date: Date.now(),
			speed: this.testCurrentSpeed
		};
		this.latestResult = result;
		sessionStorage.setItem("latestCheck", JSON.stringify(result));
		logger.log("result", result);
	}

	private upload(): void {
		const data = "k".repeat(UPLOAD_SIZE);
		const startTime = performance.now();

		const handleRequestStateChange = (): void => {
			const allRequestsComplete = this.testRequests.every(
				(req) => req.readyState === 4
			);
			const allRequestsStarted =
				this.testRequests.length === REQUEST_COUNT;

			if (allRequestsComplete && allRequestsStarted) {
				this.captureResult();
				this.cancelTest();
			}
		};

		for (let i = 0; i < REQUEST_COUNT; i++) {
			let lastBytesLoaded = 0;

			const handleProgressEvent = (event): void => {
				if (event.lengthComputable) {
					const bytesLoaded = event.loaded;
					this.testBytesUploaded += bytesLoaded - lastBytesLoaded;
					lastBytesLoaded = bytesLoaded;
					const now = performance.now();
					const megabits = (this.testBytesUploaded * 8) / 1024 / 1024;
					const elapsedSeconds = (now - startTime) / 1000;
					const speed = megabits / elapsedSeconds;
					this.testCurrentSpeed = speed;
					logger.log(
						`Current speed: ${speed.toFixed(
							1
						)}, elapsed: ${elapsedSeconds}s`
					);
				}
			};

			const xhr = new XMLHttpRequest();
			xhr.open("POST", this.url, true);
			xhr.upload.addEventListener("progress", handleProgressEvent);
			xhr.onreadystatechange = handleRequestStateChange;
			xhr.setRequestHeader("Content-Type", "application/json");
			xhr.onerror = (e) => {
				logger.log("error", e);
				this.isError = true;
				this.cancelTest();
			};
			xhr.send(JSON.stringify({ data }));
			this.testRequests.push(xhr);
		}
	}

	togglePopup(value: boolean): void {
		if (value === true) {
			this.isPopupOpen = true;
			clearTimeout(this.popupTimeout);
		} else if (value === false) {
			this.popupTimeout = setTimeout(() => {
				this.isPopupOpen = false;
			}, 500);
		}
	}

	private formatDate(date: number): string {
		const currentDate = new Date().getDate();
		const checkedDate = new Date(date);

		if (checkedDate.getDate() === currentDate) {
			return `${this.translateService.instant(
				"speed-test.today"
			)}, ${checkedDate.toLocaleTimeString([], {
				hour: "2-digit",
				minute: "2-digit"
			})}`;
		} else if (checkedDate.getDate() === currentDate - 1) {
			return `${this.translateService.instant(
				"speed-test.yesterday"
			)}, ${checkedDate.toLocaleTimeString([], {
				hour: "2-digit",
				minute: "2-digit"
			})}`;
		} else if (date) {
			return checkedDate.toLocaleString([], {
				hour: "2-digit",
				minute: "2-digit"
			});
		}
	}

	formatSpeed(speed: number): string {
		if (speed <= BAD_SPEED_THRESHOLD) return "bad";
		else if (speed <= MODERATE_SPEED_THRESHOLD) return "moderate";
		else if (speed > MODERATE_SPEED_THRESHOLD) return "good";
	}

	get latestSpeed(): string {
		return this.latestResult?.speed.toFixed(1);
	}

	get icon(): string {
		let imgUrl = "../../assets/icons/speed-test/";

		if (!this.isError && !this.isTesting && this.latestResult) {
			imgUrl += this.formatSpeed(this.latestResult.speed);
		} else {
			imgUrl += "default";
			if (this.theme.iconColor === "dark") imgUrl += "-dark";
		}

		return imgUrl + ".svg";
	}
}

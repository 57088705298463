import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-update-notice',
  templateUrl: './update-notice.component.html',
  styleUrls: ['./update-notice.component.scss']
})
export class UpdateNoticeComponent implements OnInit {
	noticeFrom: FormGroup;
	@Input() notice: number;
	@Input() lessonType: string;
	@Output() readonly setNotice: EventEmitter<number> = new EventEmitter();

	ngOnInit() {
		this.setNotice.emit(this.notice || 6)
		this.noticeFrom = new FormGroup({
			notice: new FormControl(this.notice || '6', [
				Validators.required,
				Validators.minLength(1),
				Validators.maxLength(3),
			]),
		});
		this.noticeFrom.controls.notice.valueChanges.subscribe(notice => this.setNotice.emit(notice));
	}

}

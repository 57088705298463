<a href="/">
	<img class="sirius-logo" [src]="'/assets/icons/sirius/Sirius.png?' + assetsVersion" alt="Sirius" />
</a>

<img class="sirius-music-gfx" src="/assets/icons/sirius/redesign/sirius-music.png" alt="Sirius" />
<img class="sirius-jupiter-gfx" src="/assets/icons/sirius/redesign/sirius-planet.png" alt="Sirius" />
<img class="sirius-mars-gfx" src="/assets/icons/sirius/redesign/sirius-planeten-01.png" alt="Sirius" />
<img class="sirius-blueplanet-gfx" src="/assets/icons/sirius/redesign/sirius-planeten-02.png" alt="Sirius" />
<img class="sirius-bluebigplanet-gfx" src="/assets/icons/sirius/redesign/sirius-planeten-04.png" alt="Sirius" />
<app-language-selector />
<div
	class="login-form-background"
	[class.custom-form]="customThemeService.backgroundURL$ | async"
	[ngStyle]="{
		backgroundImage: (customThemeService.backgroundURL$ | async) ? 'url(' + (customThemeService.backgroundURL$ | async) + ')' : ''
	}"
>
	<div *ngIf="!isStudentLead" class="registration-outer box-content" [class.custom-form]="customThemeService.logoURL$ | async">
		<h1 *ngIf="!(customThemeService.logoURL$ | async)">{{ "login.title" | translate }}</h1>
		<img class="logo" *ngIf="customThemeService.logoURL$ | async" src="{{ customThemeService.logoURL$ | async }}" />
		<form (ngSubmit)="login()" [formGroup]="loginForm">
			<div class="registration-row">
				<input
					type="text"
					[placeholder]="'login.email-placeholder' | translate"
					formControlName="userEmail"
					(focus)="setUserEmailFocus(true)"
					(blur)="setUserEmailFocus(false)"
					autocomplete="email"
					maxlength="60"
				/>
				<div
					*ngIf="loginForm.controls['userEmail'].invalid && loginForm.controls['userEmail'].dirty"
					class="registration-error-message"
				>
					{{ "login.email-error" | translate }}
				</div>
			</div>

			<div class="registration-forgot-password">
				<a tabindex="-1" routerLink="/forgot-password">{{ "login.forgot-password" | translate }}</a>
			</div>

			<div class="registration-row">
				<div class="password-field-outer">
					<input
						[placeholder]="'login.password-placeholder' | translate"
						formControlName="userPassword"
						[type]="showPass ? 'text' : 'password'"
						(focus)="setUserPasswordFocus(true)"
						(blur)="setUserPasswordFocus(false)"
						autocomplete="current-password"
						maxlength="60"
					/>
					<div class="toggle-password-visibility" [ngClass]="{ show: showPass, hide: !showPass }" (click)="togglePass()"></div>
				</div>
				<div *ngIf="isAuthError && authErrorStatus !== 403" class="registration-error-message">
					{{ "login.auth-error" | translate }}
				</div>
				<div *ngIf="isAuthError && authErrorStatus === 403" class="registration-error-message">
					{{ "login.auth-error-attempt" | translate }}
					<span class="reset-pass-button" (click)="resetPassword()">{{ "login.auth-error-reset-password" | translate }}</span>
				</div>
				<div *ngIf="handleSubDomain?.subDomainNotAllowed" class="registration-error-message">
					{{ "login.auth-error-sub-domain-not-allowed" | translate }}
					<a class="registration-error-message-link" target="_blank" [href]="handleSubDomain.redirectTo">{{
						"login.auth-error-redirect-link" | translate
					}}</a>
				</div>
				<div *ngIf="!!registrationNotFinishedEmail" class="registration-error-message">
					{{ "login.registration-not-finished" | translate : { email: registrationNotFinishedEmail } }}
				</div>
			</div>
			<div class="registration-row">
				<button type="submit" class="default-ui-button blue-ui-button large" [disabled]="!loginForm.valid">
					{{ "buttons.login" | translate }}
				</button>
			</div>
			<!-- <div class="login-bottom">
        <div class="remember-me">
          <input type="checkbox" id="stay-signed-in" name="stay-signed-in" formControlName="rememberUser">
          <label for="stay-signed-in">{{ 'login.remember-me' | translate }}</label>
        </div>
<div *ngIf="!customThemeService.subDomain" class="sign-up-free">
          {{ 'login.new-to-sirius' | translate }}
          <a routerLink="/signup">{{ 'buttons.sign-up-free' | translate }}</a>
        </div> 
      </div>-->
		</form>
	</div>
	<div *ngIf="isStudentLead" class="registration-outer">
		<div class="text-text-1 !block" [innerHTML]="'login.auth-error-student-lead' | translate"></div>
		<button class="back text-text-1 flex-shrink" (click)="onClickBack()">{{ "buttons.return-to-website" | translate }}</button>
	</div>
</div>
<div *ngIf="isSchool" class="login-footer">
	<a href="https://sirius.video"><span>Powered by</span><img style="width: 80px" src="/assets/icons/sirius/Sirius.png" alt="Sirius" /></a>
</div>

import {Component, Input} from '@angular/core';
import {Router} from '@angular/router';
import {ColorScheme} from '../types/color-scheme.type';
import { LanguageService } from '../services/languages/language.service';
import { ENGLISH, GERMAN } from '../constants/localstorage-constants';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: [
    './footer.component.scss'
  ]
})
export class FooterComponent {
  @Input() style: ColorScheme;
  public ENGLISH = ENGLISH;
  public GERMAN = GERMAN;

  constructor(
    private router: Router,
    public language: LanguageService
  ) { }

  contactUs(): void {

    window.location.href = 'mail' + 'to' + ':mail' + '@' + 'sirius.video';
  }

  termsClick(): void {
    this.router.navigate(['terms']);
  }

  policyClick(): void {
    this.router.navigate(['privacy']);
  }

  imprintClick(): void {
    this.router.navigate(['imprint']);
  }

  faqClick(): void {
    this.router.navigate(['faq']);
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AppointmentService {

  constructor(private http: HttpClient) {}

  convertVO(valueObject: any, schema: any): any {
    const result = {};
    Object.keys(schema).forEach((key) => {
      if (key !== 'users' && key !== 'newbies') {
        result[key] = valueObject[schema[key]];
      } else if (key === 'users') {
        result[key] = valueObject[schema[key]].map(item => item.id);
      }
    });
    return result;
  }
}

import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { PlatformDetectorService } from "../services/platform-detector/platform-detector.service";
import { AuthenticationService } from "../services/authentication/authentication.service";
import { environment } from "@env/environment";
@Component({
	selector: "app-unsupported-browser",
	templateUrl: "./unsupported-browser.component.html",
	styleUrls: ["./unsupported-browser.component.scss"],
})
export class UnsupportedBrowserComponent implements OnInit {
	private loggedIn = false;
	private deviceInfo;
	public os: string;
	public isIPad: boolean;
	public isPhone: boolean;
	assetsVersion = environment.assetsVersion;

	constructor(
		private router: Router,
		private platformDetectorService: PlatformDetectorService,
		private authenticationService: AuthenticationService
	) {}

	ngOnInit(): void {
		this.deviceInfo = this.platformDetectorService.deviceInfo;
		this.isIPad = this.platformDetectorService.isIPad();
		this.isPhone = this.platformDetectorService.isIphone();
		this.os = this.platformDetectorService.os;
		this.setLoggedIn();
	}

	handleOkClick(): void {
		this.router.navigate(this.loggedIn ? ["/platform/start"] : ["/"]);
	}

	setLoggedIn(): void {
		this.authenticationService
			.checkLoggedIn$()
			.toPromise()
			.then((loggedIn) => {
				this.loggedIn = loggedIn;
			});
	}

	get isDesktop(): boolean {
		return this.deviceType === "desktop";
	}

	get isChrome(): boolean {
		return this.browser === "Chrome";
	}

	get deviceType(): string {
		return this.deviceInfo?.deviceType;
	}

	get browser(): string {
		return this.deviceInfo.browser;
	}

	get isAndroid(): boolean {
		return (
			this.os === "android" &&
			(this.platformDetectorService.isMobile() ||
				this.platformDetectorService.isTablet())
		);
	}
}

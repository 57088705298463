<app-language-selector class="absolute"></app-language-selector>
<div class="absolute pl-10 !bg-transparent md:mt-4 logo-outer">
	<a
		><img alt="Sirius" class="logo" src="assets/icons/sirius/Sirius.png"
	/></a>
</div>
<h1 class="text-center">{{ "setup.title" | translate }}</h1>
<div class="form-container" [class.completed]="completed">
	<!-- progress bar -->
	<!-- todo : use grid -->
	<div class="progress-bar" [hidden]="completed">
		<div
			*ngFor="let step of setupSteps; let i = index"
			(click)="moveToStep(i)"
			class="progress-step"
			[style.width]="stepWidth"
			[ngClass]="[
				currentStep === i ? 'focused' : '',
				isClickable(i) ? 'clickable' : ''
			]"
		>
			<div class="text-sm progress-title">
				{{ "setup.progress-bar." + step | translate }}
			</div>
			<div class="progress-line"></div>
		</div>
	</div>
	<!-- content -->
	<div class="content" *ngIf="!completed" [ngSwitch]="currentStep">
		<app-device-browser
			[deviceInfo]="deviceInfo"
			(complete)="onCompleteStep(0)"
			*ngSwitchCase="0"
		></app-device-browser>
		<app-network-speed
			(complete)="onCompleteStep(1)"
			*ngSwitchCase="1"
		></app-network-speed>
		<app-audio-setup
			(complete)="onCompleteStep(2)"
			*ngSwitchCase="2"
		></app-audio-setup>
		<app-camera-setup
			(complete)="onCompleteStep(3)"
			*ngSwitchCase="3"
		></app-camera-setup>
	</div>
	<!-- complete -->
	<div class="content" *ngIf="completed">
		<div>
			<ng-container *ngIf="isSetupGood">
				<h2 class="text-center">
					{{ "setup.complete.title-good" | translate }}
				</h2>
				<mat-icon
					class="!w-16 !h-16 text-[4rem] text-sirius-success-green mb-8"
					>check_circle</mat-icon
				>
				<div
					class="text-sm"
					[innerHTML]="
						'setup.complete.setup-message-good' | translate
					"
				></div>
			</ng-container>

			<ng-container *ngIf="!isSetupGood">
				<h2 class="text-center">
					{{ "setup.complete.title-bad" | translate }}
				</h2>
				<mat-icon
					class="!w-16 !h-16 text-[4rem] text-sirius-info-blue mb-8"
					>info_circle</mat-icon
				>
				<div
					class="text-sm"
					[innerHTML]="'setup.complete.setup-message-bad' | translate"
				></div>
			</ng-container>

			<div class="button-section">
				<button class="blue-ui-button" (click)="onSetupComplete()">
					{{ "setup.complete.save-setup" | translate }}
				</button>
			</div>
		</div>
	</div>
</div>

<div
	class="!bg-transparent text-sm my-2.5 text-sirius-black visited:text-sirius-black"
>
	<p class="text-center">
		{{ "setup.footer-question" | translate }}
		<a
			class="text-sm font-bold underline text-sirius-black visited:text-sirius-black"
			href="https://support.sirius.video/"
			target="_blank"
		>
			{{ "setup.footer-link" | translate }}
		</a>
	</p>
	<p class="text-center">
		{{ "setup.footer-question-2" | translate }}
		<a
			class="text-sm font-bold underline text-sirius-black visited:text-sirius-black"
			href="https://go.sirius.video/support"
			target="_blank"
		>
			{{ "setup.footer-link-2" | translate }}
		</a>
	</p>
</div>

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map, shareReplay } from "rxjs/operators";

export type CountryListItem = { eng: string; deu: string; telPrefix: number };

@Injectable({
	providedIn: "root"
})
export class CountriesService {
	private countriesList$: Observable<CountryListItem[]>;

	constructor(private http: HttpClient) {}

	getCountries(): Observable<CountryListItem[]> {
		if (!this.countriesList$) {
			this.countriesList$ = this.http
				.get<CountryListItem[]>("assets/countries/countries.json")
				.pipe(
					map((res) => res),
					shareReplay(1)
				);
		}

		return this.countriesList$;
	}
}

import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  Output, TemplateRef,
  ViewEncapsulation
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PlatformDetectorService } from '../../../services/platform-detector/platform-detector.service';
import { UserVO } from 'src/app/services/data/UserVO';
import { UsersService } from 'src/app/services/users.service';
import { ConnectionService } from '../../../services/internet-connection/connection.service';
import { RtcStreamViewModel } from '../../../components/call-room/service/viev-model/rtc-stream-view-model';
import { RoomStateStorage } from '../../../components/call-room/service/storage/RoomStateStorage';
import { StorageUtil } from '../../../utils/storage.util';
import { RoomAuthService } from '../../../services/ws/room-auth.service';
import { ADAPTATION_MODE } from '../../../components/view-media/view-media.constants';
import { EmojiService } from 'src/app/emoji/emoji.service';
import { emojiFade } from 'src/app/emoji/emoji.animations';
import { PersonalRoomVO } from 'src/app/services/data/PersonalRoomVO';
import { ClipboardService } from 'src/app/services/clipboard.service';
import { BotService } from '../../../services/bot/bot.service';
import { GA4Service } from 'src/app/services/ga4.service';
import { MirroringService } from 'src/app/components/call-room/service/mirroring.service';
import { roomKeyFromUrl } from 'src/app/utils/url.util';
import { combineLatest } from 'rxjs';
import { VideoLayoutService } from 'src/app/services/video-layout/video-layout.service';

/* Scheme of applying classes to the "user initial" element depending on the display mode.
 *
 * <div class="user-initial">
 *  <span>{{ getInitial(user.name) }}</span>
 * </div>
 *
 * DESKTOP
 *    main        member      split 1     split 2     split 3 - 4
 *   +-------+   +-------+   +-------+   +-------+   +-------+
 *   |   -   |   |       |   |   -   |   |       |   |( ) ( )|
 *   | (   ) |   |  ( )  |   | (   ) |   |( ) ( )|   |       |
 *   |   -   |   |       |   |   -   |   |       |   |( ) ( )|
 *   +-------+   +-------+   +-------+   +-------+   +-------+
 * additional classes:
 *   "maxi"      "mini"      "middle"    "middle"    "middle"
 *                           "one"
 * TABLE
 *    main        member      split 1     split 2     split 3 - 4
 *   +-------+   +-------+   +-------+   +-------+   +-------+
 *   |   -   |   |       |   |   -   |   |       |   |( ) ( )|
 *   | (   ) |   |  ( )  |   | (   ) |   |( ) ( )|   |       |
 *   |   -   |   |       |   |   -   |   |       |   |( ) ( )|
 *   +-------+   +-------+   +-------+   +-------+   +-------+
 * additional classes:
 *   "maxi"      "mini"      "middle"    "middle"    "middle"
 *   "is-table"  "is-table"  "is-table"  "is-table"  "is-table"
 *                           "one"
 * MOBILE
 *    main        member      split 1     split 2     split 3 - 4
 *   +-------+   +-------+   +-------+   +-------+   +-------+
 *   |   -   |   |       |   |   -   |   |       |   |( ) ( )|
 *   | (   ) |   |  ( )  |   | (   ) |   |( ) ( )|   |       |
 *   |   -   |   |       |   |   -   |   |       |   |( ) ( )|
 *   +-------+   +-------+   +-------+   +-------+   +-------+
 * additional classes:
 *   "maxi"      "mini"      "middle"    "middle"    "middle"
 *   "is-mobile" "is-mobile" "is-mobile" "is-mobile" "is-mobile"
 *                           "one"
 */

@Component({
  selector: 'app-display-grid-participants',
  templateUrl: './display-grid-participants.component.html',
  styleUrls: ['./display-grid-participants.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [emojiFade]
})
export class DisplayGridParticipantsComponent implements OnInit {

  @Input()
  public isPiano = '';
  @Input()
  public isSplitView = false;
  @Input()
  public connectedUsers: UserVO[] = [];
  @Input()
  public isReflection = false;
  @Input()
  public isPortrait = false;
  @Input()
  public outputDeviceId = '';
  @Input()
  public isFullscreen = false;
  @Input()
  public isRecordingActive = false;
  @Input()
  public shouldNotMirrored = false;
  @Input()
  videoPlayer: TemplateRef<HTMLElement>;
  @Input()
  public maximizeSecondCam = false
  @Input()
  public currentStudent;

  @Output()
  readonly toggleMaximize: any = new EventEmitter();

  @Output()
  readonly toggleMaximizeSecondStream: any = new EventEmitter();

  @Output()
  readonly toggleFullscreen: EventEmitter<UserVO> = new EventEmitter();
  @Output()
  readonly toggleStopYouTube: EventEmitter<UserVO> = new EventEmitter();

  public isSafari: boolean;
  public isMobile: boolean;
  public isTablet: boolean;
  public isDesktop: boolean;
  public release: string;
  public updatePanelSplit = true;
  public adaptByHostSizes = 'adapt-by-host-sizes' as ADAPTATION_MODE;
  public adaptByHostSizesAndCover = 'adapt-by-host-sizes-and-cover' as ADAPTATION_MODE;
  public isScheduledLesson = false;
  private roomData: PersonalRoomVO;

  public checkInternet: boolean;
  get canDisplayAppViewMedia(): boolean {
    return (this.authService.isAuth() && this.route.snapshot.paramMap.get('roomid') === StorageUtil.readRoomIdToSessionStorage());
  }

  get canBeShowAppViewMediaForAndroid(): boolean {
    if (!this.platformDetectorService.isAndroid()) {
      return true;
    }
    return this.platformDetectorService.isAndroid() && this.isPortrait;
  }

  constructor(
    protected platformDetectorService: PlatformDetectorService,
    private usersService: UsersService,
    public roomState: RoomStateStorage,
    private rtcStreamViewModel: RtcStreamViewModel,
    private cdr: ChangeDetectorRef,
    private authService: RoomAuthService,
    private route: ActivatedRoute,
    private connection: ConnectionService,
    private emojiService: EmojiService,
    public clipboardService: ClipboardService,
    private ga4: GA4Service,
    private mirrorService: MirroringService,
	private videoLayoutService: VideoLayoutService
  ) {
  }

  @HostListener('window:orientationchange', ['$event'])
  onOrientationChange(event) {
    this.updatePanelSplit = false;
    setTimeout(() => {
      this.updatePanelSplit = true;
    }, 300);
  }
  @HostBinding('class.split') get isClassSplit(): boolean { return this.isSplitView; }
  @HostBinding('class.portrait-tablet') get isPortraitTablet(): boolean { return this.isPortrait && this.isTablet; }
  @HostBinding('class.landscape-tablet') get isPortraitLandscape(): boolean { return !this.isPortrait && this.isTablet; }

  @HostBinding('class.two-paddings') get isTwoUsers() { return this.isSplitView && this.connectedUsers.length === 2; }
  @HostBinding('class.more-paddings') get isMoreThanTwoUsers() { return this.isSplitView && this.connectedUsers.length > 2; }


  ngOnInit() {
    // console.log('DisplayGridParticipantsComponent------------------DisplayGridParticipantsComponent');
    this.release = this.platformDetectorService.getIOS_version().Full_Release;
    this.isMobile = this.platformDetectorService.isMobile();
    this.isTablet = this.platformDetectorService.isTablet();
    this.isDesktop = this.platformDetectorService.isDesktop();
    this.isSafari = this.platformDetectorService.isSafari()
    this.connection.handleInetConnection().subscribe(isOnline => this.checkInternet = isOnline);
    this.roomData = JSON.parse( localStorage.getItem('personalRoom'));

    const roomKey = roomKeyFromUrl()
    if (roomKey) this.isScheduledLesson = true;

	this.observeSecondStreams()

  }

  // ** Public API **

  public splitClass(userCount: number): string {
    let result = '';
    if (1 === userCount) {
      result = 'one-item';   //  1  w:100%  h:100%
    } else if (1 < userCount && userCount <= 2) {
      result = 'two-item';   //  2  w: 50%  h:100%
    } else if (2 < userCount && userCount <= 4) {
      result = 'four-item';  // 3,4  w: 50%  h: 50%
    } else if (4 < userCount && userCount <= 6) {
      result = 'six-item';   // 5,6  w: 50%  h: 33.32%
    } else if (6 < userCount && userCount <= 8) {
      result = 'eight-item'; // 7,8  w: 50%  h: 25%
    } else if (8 < userCount && userCount <= 9) {
      result = 'nine-item';  // 9  w: 33.32%  h: 33.32%
    } else if (9 < userCount && userCount <= 12) {
      result = 'twelve-item'; // 10,11,12  w: 33.32%  h: 25%
    }
    return result;
  }

  public identifyUser(index: number, userVO: UserVO): string {
    return userVO.id;
  }

  public identifyUser2ndStream(index: number, userVO: UserVO): string {
    return userVO.id + 2;
  }

  public isHideParticipant(user: UserVO): boolean {
    // hide a participant if:
    // - only one participant;
    // - only two participants and this participant on the large panel;
    // const count = this.connectedUsers.length;
    // return ((1 === count) || (2 === count && this.usersService.isUserOnPanel(user)));
    // https://jira.trembit.com/browse/SIRIUS-1182
    return this.usersService.isUserOnPanel(user);
  }

  public doToggleMaximize(user: UserVO, secondStream?): void {
    if (!!user) {
      if (!secondStream){
      this.toggleMaximize.emit(user);
      } else if (!!secondStream){
      this.toggleMaximize.emit({user:user, secondStream:secondStream});
      }

    }
  }

  public doToggleMaximizeSecondStream(user: UserVO, secondStream?): void {
    if (!!user) {
      if (!secondStream){
      this.toggleMaximizeSecondStream.emit(user);
      } else if (!!secondStream){
      this.toggleMaximizeSecondStream.emit({user:user, secondStream:secondStream});
      }

    }
  }

  public doToggleFullscreen(user: UserVO): void {
    if (!!user) {
      this.toggleFullscreen.emit(user);
    }
  }

  public doToggleStopYouTube(): void {
      this.toggleStopYouTube.emit();
  }

  public isSplitViewAndUserOne(): boolean {
    return (this.isSplitView && this.connectedUsers.length === 1);
  }

  public isIconRoundLetter(userName: string): boolean {
    return (userName !== '');
  }

  public getInitial(userName: string): string {
    return (userName || ' ').charAt(0).toUpperCase();
  }

  public isHideSelf(user: UserVO): boolean {
    return (user === this.usersService.selfUser);
  }

  public isSelectedUserName(user: UserVO): boolean {
    const count = this.usersService.getConnectedUsers().length;
    const isNotSelfUser = (user !== this.usersService.selfUser);
    return (2 === count && isNotSelfUser || count > 2 && this.usersService.isUserOnPanel(user));
  }

  public selfUser(): UserVO {
    return this.usersService.selfUser;
  }

  getUserEmoji(id){
    return this.emojiService.userEmoji(id)
  }

  get linkText(): string {
    if (!BotService.isBot) {
		// const queryString = window.location.search;
		// const urlParams = new URLSearchParams(queryString);
		// const roomKey = urlParams.get('roomKey');
		// const subRole = localStorage.getItem('subrole');
		// if (subRole === '3' && roomKey && sessionStorage.shareLink) {
		// 	return sessionStorage.shareLink;
		// }
      const {link, password} = this.roomData;
      const invitationText = `https://${link.split('?')[0]}?password=${password}`;
      return invitationText;
    } else {
      const pass = BotService.roomPass;
      const roomName = sessionStorage.getItem('room_id');
      const invitationTextForBot = `https://${window.location.host}/${roomName.split('?')[0]}?password=${pass}`;
      return invitationTextForBot;
    }
  }

	copyLink() {
		// const queryString = window.location.search;
		// const urlParams = new URLSearchParams(queryString);
		// const roomKey = urlParams.get('roomKey');
		// const subRole = localStorage.getItem('subrole');
		// if (subRole === '3' && roomKey && sessionStorage.shareLink) {
		// 	this.clipboardService.copyUrl(sessionStorage.shareLink);
		// 	this.ga4.copyLink();
		// 	return
		// }
		const {link, password} = this.roomData;
		this.clipboardService.copyLink(link, password);
		this.ga4.copyLink();
	}

  get isTeacher(): boolean {
    return this.roomState.isTeacher
  }

  isMirrored(user: UserVO): boolean {
    const status = this.mirrorService.mirroring[user.id]
    if (!this.shouldNotMirrored && !user.screenShareStream) {
      return status !== undefined ? status : user.self && this.isReflection
    }
    return false
  }

  isSecondCamOnMainPanel(user: UserVO): boolean {
	return this.usersService.isUserOnPanel(user) && this.maximizeSecondCam && !this.isSplitView
  }

  private observeSecondStreams(): void {
    const observables = this.connectedUsers.map(user => user.secondStreamActive$);

    combineLatest(observables).subscribe((values) => {
		setTimeout(() => {
			this.videoLayoutService.calculateLayout(true)
		},20);
    });
  }

}

import { Injectable } from "@angular/core";
import { SubRole } from "src/app/constants/subroles-constants";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { AuthenticationService } from "src/app/services/authentication/authentication.service";

const DEFAULT_RETURN_URL = "/platform/start";
const STUDENT_MATCHED_RETURN_URL = "/platform/lessons";
const ERROR_RETURN_URL = "/";

@Injectable({
  providedIn: "root",
})
export class SubRoleGuard  {
  constructor(private auth: AuthenticationService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const blockedSubroles = route.data.blockedSubroles;
    return this.checkRolesAndNavigate(blockedSubroles);
  }

  async checkRolesAndNavigate(blockedSubroles): Promise<boolean | UrlTree> {
    try {
      const { subrole } = await this.auth.profile;
      const returnUrl =
        subrole === SubRole.STUDENT_MATCHED
          ? STUDENT_MATCHED_RETURN_URL
          : DEFAULT_RETURN_URL;
      return blockedSubroles.includes(subrole)
        ? this.router.parseUrl(returnUrl)
        : true;
    } catch (error) {
      return this.router.parseUrl(ERROR_RETURN_URL);
    }
  }
}

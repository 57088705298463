import { Component } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { TranslateModule } from "@ngx-translate/core";

@Component({
	selector: "app-files-dropzone",
	standalone: true,
	imports: [MatIconModule, TranslateModule],
	templateUrl: "./files-dropzone.component.html",
	styleUrl: "./files-dropzone.component.scss"
})
export class FilesDropzoneComponent {}

import { Component, OnInit } from '@angular/core';
import {UserVO} from '../services/data/UserVO';
import {CallStorage} from '../components/call-room/service/storage/call-storage.service';
import {SELF_VIDEO} from '../components/call-room/data/local-constants';
import {AFFECT, HIDE, MUTE, WebSocketService} from '../services/ws/web-socket.service';

@Component({
  selector: 'app-interlocutor-controls',
  templateUrl: './interlocutor-controls.component.html',
  styleUrls: ['./interlocutor-controls.component.scss']
})
export class InterlocutorControlsComponent implements OnInit {
  user: UserVO =  new UserVO();
  backgroundColor =  '#15de96';
  constructor(private callStorage: CallStorage,
              private webSocketService: WebSocketService) { }

  ngOnInit() {
    this.callStorage.mainVideo$.subscribe(data => {
      if (data.currentVideo !== SELF_VIDEO) {
        this.user = this.callStorage.usersStorage.getUser(data.currentVideo);
      }
    });
  }
  muteUser (): void {
    this.webSocketService.dispatch(AFFECT, {option: MUTE, target: this.user.id});
  }

  hideUser (): void {
    this.webSocketService.dispatch(AFFECT, {option: HIDE, target: this.user.id});
  }

}

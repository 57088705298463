import {Component, Input, OnInit} from '@angular/core';
import {UserVO} from '../services/data/UserVO';

@Component({
  selector: 'app-participants-list',
  templateUrl: './participants-list.component.html',
  styleUrls: ['./participants-list.component.scss']
})
export class ParticipantsListComponent implements OnInit {
  @Input() users: UserVO[] = [];
  @Input() maxUsers: number = 0;
  @Input() currentUsers: number = 0;
  constructor() { }

  ngOnInit() {
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


import { TranslateModule } from '@ngx-translate/core';
import { VideoPlayerComponent } from './video-player.component';
import { YouTubePlayerModule } from '@angular/youtube-player';


@NgModule({
  declarations: [
    VideoPlayerComponent
  ],
  imports: [
    CommonModule,TranslateModule, YouTubePlayerModule
  ],
  exports: [VideoPlayerComponent]
})

export class VideoPlayerModule { }

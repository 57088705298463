import { Component, Input, ViewEncapsulation } from "@angular/core";
import { UserVO } from "../services/data/UserVO";
import { VolumeControlService } from "./volume-control.service";

@Component({
	selector: "app-volume-control",
	templateUrl: "./volume-control.component.html",
	styleUrls: ["./volume-control.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class VolumeControlComponent {
	@Input() user: UserVO;
	@Input() isMainPanel: boolean;
	value = 0;

	constructor(private volumeControlService: VolumeControlService) {}

	ngOnInit(): void {
		this.value = this.volumeControlService.getVolume(this.user) * 100;
	}

	onChange(event: any): void {
		this.value = event.target.value;
		this.volumeControlService.setVolume(
			this.user,
			event.target.value / 100,
			this.isMainPanel
		);
	}

	onDragEnd(): void {
		this.volumeControlService.saveToSessionStorage();
	}

	stopPropagation(event: MouseEvent): void {
		event.stopPropagation();
	}

	get volumeIcon(): string {
		let baseUrl = "../../assets/icons/volume-control/mic_";

		if (this.value === 0) {
			baseUrl += "off";
		} else if (this.value < 100) {
			baseUrl += "medium";
		} else {
			baseUrl += "full";
		}
		return baseUrl + ".svg";
	}
}

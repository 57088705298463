import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {createCustomElement} from '@angular/elements';
import {VideoComponent} from '../video/video.component';
import {CallStorage} from '../components/call-room/service/storage/call-storage.service';
import {Room} from '../components/call-room/component/call-room';
import {RtcStreamViewModel} from '../components/call-room/service/viev-model/rtc-stream-view-model';
import {RoomDataParserService} from '../services/ws/room-data-parser.service';
import {ActivatedRoute} from '@angular/router';
import {RoomAuthService} from '../services/ws/room-auth.service';
import { logger } from 'src/app/lib-core/logger';
import {Subscription} from 'rxjs';
import {EasyRtcService} from '../lib-rtc/services/easy-rtc.service';

@Component({
  selector: 'app-bot-room',
  templateUrl: './bot-room.component.html',
  styleUrls: ['./bot-room.component.scss']
})
export class BotRoomComponent implements Room, OnInit, OnDestroy {
  constructor(  private injector: Injector, public callStorage: CallStorage,
                private streamViewModel: RtcStreamViewModel,
                private route: ActivatedRoute,
                private roomDataParserService: RoomDataParserService,
                private auth: RoomAuthService) {
  }
  private userCount = 0;
  private subscribes: Array<Subscription> = new Array<Subscription>();
  public errorMessage: string = '';
  ngOnInit() {
    const el = createCustomElement(VideoComponent,
      { injector: this.injector });
    customElements.define('app-video-define', el);
    this.callStorage.roomId = this.route.snapshot.paramMap.get('roomid').substring(2);
    this.callStorage.role = this.route.snapshot.paramMap.get('roomid').substring(0, 2);
    this.auth.joinSpy().subscribe((answer) => { // add another error handling and move to .auth file
      if (answer.status) {
        this.addObservers();
        this.join();
      } else {
        this.errorMessage = `${answer.err.status} ${answer.err.message} <br/> ${answer.err.description}`;
      }
    });
  }
  ngOnDestroy(): void {
    this.subscribes.forEach(subscribe => {
      subscribe.unsubscribe();
    });
  }
  createNewVideo =  (rtcId: string): HTMLVideoElement => {
    const parent = document.getElementById('bot-container');
    const user = document.createElement('app-video-define');
    user.id = rtcId;
    user.title = rtcId;
    // @ts-ignore
    user.isFillScale = true;
    // todo refactor if possible
    this.setStyle(user);
    parent.append(user);
    const deactivate = this.callStorage.usersStorage.getUser(rtcId).connected$.subscribe(state => {
      if (!state) {
        deactivate.unsubscribe();
        this.removeChildById(rtcId);
      }
    });
    return user.children[0] as HTMLVideoElement;
  }
  getVideoById(id: string) {
    try {
      const item = document.getElementById(id);
      if (item.tagName && item.tagName.toLowerCase() === 'app-video-define') {
        return document.getElementById(id).children[0].children[0] as HTMLVideoElement;
      }
    } catch (e) {
      return null;
    }
  }
  removeChildById(id: string) {
    const item = document.getElementById(id);
    item.parentNode.removeChild(item);
  }
  updateById =  (id: string, stream: MediaStream, isInit?: boolean): void => {
    EasyRtcService.setVideoObjectSrc(this.getVideoById(id) , stream);
    const refreshStream = () => {
      const user = this.callStorage.usersStorage.getUser(id);
      if (user.connected && user.streamName &&  user.reconnectCalc > 0 ) {
        logger.log(`stream refreshed, left ${user.reconnectCalc - 1 } time`);
        user.reconnectCalc--;
        EasyRtcService.setVideoObjectSrc(this.getVideoById(id) , EasyRtcService.getRemoteStream(id, user.streamName));
        setTimeout( () => { refreshStream(); }, 3000);
      }
    };
    setTimeout( () => { refreshStream(); }, 6000);
  }
  private addObservers () {
    this.subscribes.push(this.callStorage.usersStorage.onUsersUpdate$.subscribe(() => {
      const userCount = this.callStorage.usersStorage.getUsersCount();
      if (userCount !== this.userCount) {
        this.userCount = userCount;
        this.updateStyles();
      }
    }));
  }
  private join() {
    logger.log( `bot user selfId: ${this.callStorage.usersStorage.selfUser.id}`);
    this.streamViewModel.connect( this.callStorage.roomId,  this.callStorage.usersStorage.selfUser.id, this );
  }
  private updateStyles () {
    Array.from(document.getElementById('bot-container').children).forEach((element) => {
      this.setStyle(element);
    });
  }
  private setStyle (item: any) {
    const getWidth = (userCount: number): string => {
      if (userCount === 1) {
        return '100%';
      } else if ( 1 < userCount && userCount < 5) {
        return '50%';
      } else if (  5 <= userCount) {
        return '33%';
      }
    };
    const getHeight = (userCount: number): string => {
      if (userCount < 3) {
        return '100%';
      } else if (  3 <= userCount && userCount < 7) {
        return '50%';
      } else if (  7 <= userCount) {
        return '33%';
      }
    };
    item.style.margin = 'auto';
    item.style.width = getWidth(this.userCount);
    item.style.height = getHeight(this.userCount);
    item.style.border = 'solid 8px $background-light';
  }



}

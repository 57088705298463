<div class="wrapper" (click)="stopPropagation($event)">
	<img class="icon" [src]="volumeIcon" />
	<mat-slider
		(change)="onDragEnd()"
		(input)="onChange($event)"
		[min]="0"
		[max]="100"
		[step]="1"
	>
		<input [(ngModel)]="value" matSliderThumb />
	</mat-slider>
</div>

<div class="spinner-box" *ngIf="isProgress"><div class="spinner"></div></div>

<mat-icon class="st-icon-close" (click)="close()">close</mat-icon>
<div class="st-card">
  <div class="m-row">
    <div class="m-header">{{ 'video-settings.settings-label' | translate }}</div>
    <div class="mat-tab-body-wrapper">
    </div>
  </div>
  <form class="st-card-content" [formGroup]="formGroup" novalidate>
    <mat-tab-group [(selectedIndex)]="tabIndex" class="st-card-tab-group">
      <mat-tab>
        <ng-template mat-tab-label>
          <b>{{ 'video-settings.audio-label' | translate }}</b>
        </ng-template>

        <div class="st-card-body" formGroupName="audio">

          <ng-container *ngIf="audioInputDevices.length > 0">
            <div class="st-row">
              <div class="st-row-label"></div>
              <div class="st-row-value st-label">{{ 'video-settings.micro' | translate }}</div>
            </div>
            <div class="st-row st-col">
              <div class="st-row-label"><mat-icon class="st-icon-audio">mic</mat-icon></div>
              <div class="st-row-value">
                <mat-select class="app-select" formControlName="inputDeviceId" panelClass="app-select-panel" disableOptionCentering="true">
                  <mat-option *ngFor="let device of audioInputDevices" [value]="device.deviceId">{{ device.label || device.groupId }}</mat-option>
                </mat-select>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="audioOutputDevices.length > 0">
            <div class="st-row">
              <div class="st-row-label"></div>
              <div class="st-row-value st-label">{{ 'video-settings.output' | translate }}</div>
            </div>
            <div class="st-row st-col">
              <div class="st-row-label"><mat-icon class="st-icon-speaker">volume_up</mat-icon></div>
              <div class="st-row-value media-prm audio-device">
                <mat-select class="app-select settings-app-select" formControlName="outputDeviceId" panelClass="app-select-panel" disableOptionCentering="true">
                  <mat-option *ngFor="let device of audioOutputDevices" [value]="device.deviceId">{{ device.label || device.groupId }}</mat-option>
                </mat-select>
                <span class="test-audio" (click)="testAudio()">Test</span>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="support.isSampleRate">
            <div class="st-row">
              <div class="st-row-label"></div>
              <div class="st-row-value st-label">{{ 'video-settings.sample-size.label' | translate }}</div>
            </div>
            <div class="st-row st-col">
              <div class="st-row-label"></div>
              <div class="st-row-value">
                <mat-select class="selector app-select" formControlName="sampleRate" panelClass="app-select-panel" disableOptionCentering="true">
                  <mat-option *ngFor="let option of audioSampleRates" [value]="option.param">{{ option.name | translate }}</mat-option>
                </mat-select>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="support.isEchoCancellation && !isSafari">
            <div class="st-row">
              <div class="st-row-label"></div>
              <div class="st-row-value">
                <input type="checkbox" class="app-checkbox" formControlName="echoCancellation" id="echoCancellation"
                  (change)="doChangeEchoCancellation()" />
                <label for="echoCancellation">{{ 'video-settings.checkbox-echo' | translate }}</label>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="support.isNoiseSuppression">
            <div class="st-row">
              <div class="st-row-label"></div>
              <div class="st-row-value">
                <input type="checkbox" class="app-checkbox" formControlName="noiseSuppression" id="noiseSuppression"/>
                <label for="noiseSuppression">{{ 'video-settings.checkbox-noise' | translate }}</label>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="support.isAutoGainControl">
            <div class="st-row">
              <div class="st-row-label"></div>
              <div class="st-row-value">
                <input type="checkbox" class="app-checkbox" formControlName="autoGainControl" id="autoGainControl"/>
                <label for="autoGainControl">{{ 'video-settings.checkbox-gain' | translate }}</label>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="support.isStereoAudio">
            <div class="st-row">
              <div class="st-row-label"></div>
              <div class="st-row-value">
                <input type="checkbox" class="app-checkbox" formControlName="isStereoAudio" id="isStereoAudio"
                  [attr.disabled]="support.isEchoCancellation && !!audioControls.echoCancellation.value ? true : null" />
                <label for="isStereoAudio" class="st-row-value-label">
                  <div>
                    <div class="st-row-value-text">{{ 'video-settings.checkbox-stereo-audio' | translate }}</div>
                    <div class="st-row-value-note" [hidden]="!audioControls.echoCancellation.value">
                      {{ 'video-settings.checkbox-stereo-audio-label' | translate }}
                    </div>
                  </div>
                </label>
              </div>
            </div>
          </ng-container>

        </div>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <b>{{ 'video-settings.video-label' | translate }}</b>
        </ng-template>

        <div class="st-card-body" formGroupName="video" *ngIf="videoInputDevices.length > 0">

          <div class="st-row">
            <div class="st-row-label"></div>
            <div class="st-row-value st-label">{{ 'video-settings.video' | translate }}</div>
          </div>
          <div class="st-row st-col">
            <div class="st-row-label"><mat-icon class="st-icon-video">videocam</mat-icon></div>
            <div class="st-row-value">
              <mat-select class="app-select" formControlName="inputDeviceId" panelClass="app-select-panel" disableOptionCentering="true">
                <mat-option *ngFor="let device of videoInputDevices" [value]="device.deviceId">{{ device.label || device.groupId }}</mat-option>
              </mat-select>
            </div>
          </div>

          <div class="st-row">
            <div class="st-row-label"></div>
            <div class="st-row-value st-label">{{ 'video-settings.video-quality.label' | translate }}</div>
          </div>
          <div class="st-row st-col">
            <div class="st-row-label"></div>
            <div class="st-row-value">
              <mat-select class="selector app-select" formControlName="resolution" panelClass="app-select-panel" disableOptionCentering="true">
                <mat-option *ngFor="let option of videoResolutions" [value]="option">{{ option.name | translate }}</mat-option>
              </mat-select>
            </div>
          </div>

          <ng-container *ngIf="support.isFrameRate">
            <div class="st-row">
              <div class="st-row-label"></div>
              <div class="st-row-value st-label">{{ 'video-settings.frame-rate.label' | translate }}</div>
            </div>
            <div class="st-row st-col">
              <div class="st-row-label">
              </div>
              <div class="st-row-value">
                <mat-select class="selector app-select" formControlName="frameRate" panelClass="app-select-panel" disableOptionCentering="true">
                  <mat-option *ngFor="let option of videoFrameRates" [value]="option.param">{{ option.name | translate }}</mat-option>
                </mat-select>
              </div>
            </div>
          </ng-container>

          <div class="st-row">
            <div class="st-row-label"></div>
            <div class="st-row-value">
              <input type="checkbox" class="app-checkbox" formControlName="isReflection" id="isReflection"
                (change)="doChangeReflection(videoControls.isReflection.value)" />
              <label for="isReflection">{{ 'video-settings.reflection' | translate }}</label>
            </div>
          </div>

        </div>

      </mat-tab>
      <!-- Room -->
      <mat-tab *ngIf="auth.accessToken">
        <ng-template mat-tab-label><b>{{ 'video-settings.room-label' | translate }}</b></ng-template>
        <div class="st-card-body room" formGroupName="video">
          <div class="st-row">
            <div class="st-row-label"></div>
            <div class="st-row-value st-label">{{ 'video-settings.invite-link' | translate }}</div>
          </div>
          <div class="st-row">
            <div class="st-row-label"></div>
            <div class="st-row-value">
              <!-- Link:
              password: -->
              <div class="invite-participants">
                <div class="invitation-data">
                  <div class="room-data">
                    <span class="data-label ellipsis">{{ 'video-settings.link' | translate }}</span>
                    <span class="ellipsis"> {{ roomData?.link }} </span>
                  </div>
                  <div class="room-data">
                    <span class="data-label ellipsis">{{ 'video-settings.password' | translate }}</span>
                    <span class="ellipsis"> {{ roomData?.password }} </span>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div class="st-row ">
            <div class="st-row-label"></div>
            <div class="st-row-value st-label">
              <button class="blue-ui-button st-button copy-data" type="button" (click)="copyLink()"
                [class.copy-data--success]="clipboardService.copySuccess">
                <span *ngIf="!clipboardService.copySuccess">{{ 'video-settings.copy' | translate }}</span>

                <mat-icon *ngIf="clipboardService.copySuccess" class="middle">done</mat-icon>
              </button>
            </div>
          </div>
          <!-- bg color -->
          <div class="st-row w-padding">
            <div class="st-row-label"></div>
            <div class="st-row-value st-label">{{ 'video-settings.background-color' | translate }}</div>
          </div>
          <div class="st-row st-col">
            <div class="st-row-label"></div>
            <div class="st-row-value">
              <!-- color selector -->
              <div class="color-selector">
                <div class="color" *ngFor="let color of bgColors; index as i" (click)="onChangeBgColor(i)">
                  <mat-icon *ngIf="color === currentBgColor" class="middle">check_circle</mat-icon>
                </div>
              </div>
              <!-- color selector -->
            </div>
          </div>
        </div>
      </mat-tab>
      <!-- general -->
      <mat-tab>
        <ng-template mat-tab-label><b>{{ 'video-settings.general-label' | translate }}</b></ng-template>
        <ng-container>
          <div class="st-card-body" formGroupName="general">
            <div class="st-row lang-row">
              <div class="st-row-label"></div>
              <div class="st-row-value st-label">{{ 'video-settings.language-label' | translate }}</div>
            </div>
            <div class="st-row st-col">
              <div class="st-row-label"></div>
              <div class="st-row-value">
                <mat-select class="app-select" formControlName="langId" panelClass="app-select-panel" disableOptionCentering="true"
                  (selectionChange)="doChangeLanguage()">
                  <mat-option *ngFor="let language of languageService.languages" [value]="language._id">{{ language.name }}</mat-option>
                </mat-select>
                <label for="isStereoAudio" class="st-row-value-label">
                  <div class="st-row-value-note" [hidden]="!audioControls.echoCancellation.value">

                  </div>
                </label>
              </div>
              <div class="st-row-label"></div>
            </div>
          </div>
        </ng-container>

      </mat-tab>

    </mat-tab-group>
  </form>
  <div class="m-row">
    <div class="m-header"></div>
    <div class="mat-tab-body-wrapper">
      <button [ngStyle]="{'font-size': hasAudioOrVideoChange ? '13px' : '20px'}" class="blue-ui-button st-button" form="formGroup" type="button" (click)="submit()"
          [disabled]="formGroup.invalid || formGroup.pristine">
        <span *ngIf="hasAudioOrVideoChange">{{'video-settings.accept-reload' | translate}}</span>
        <span *ngIf="!hasAudioOrVideoChange">{{ 'video-settings.accept' | translate }}</span>
      </button>
    </div>
  </div>
</div>

<button (click)="toggleMenu($event)" class="rounded-tr-md">
  <img src="assets/icons/sirius/Dots.svg" alt="dots">
</button>
<ul *ngIf="isMenuOpened" role="menubar" appClickOutside (clickOutside)="isMenuOpened = false">

  <ng-container *ngIf="!isSelfUser">
    <li class="sliderItem">
      <app-volume-control [user]="user" [isMainPanel]="isMainPanel"></app-volume-control>
    </li>
    <hr>
  </ng-container>

  <li>
    <a class="action" (click)="doToggleMaximise($event)">
      {{ (isMainPanel ? 'buttons.minimize' : 'buttons.maximize') | translate }}
    </a>
  </li>
  <li>
    <a *ngIf="!isMobile" class="action" (click)="doToggleFullscreen($event)">
      {{ (isFullscreen ? 'buttons.exit-fullscreen' : 'buttons.fullscreen') | translate }}
    </a>
  </li>
  <li *ngIf="!isYoutube">
    <a class="action" (click)="onMirrorClicked($event)">
      {{ 'buttons.mirror' | translate }}
    </a>
  </li>

  <ng-container *ngIf="isYoutube">
    <hr>
    <li>
      <a class="action remove" (click)="stopYouTube()">{{'youtube.stop-youtube' | translate}}</a>
    </li>
  </ng-container>

  <ng-container *ngIf="isApprover && !isSelfUser && !isYoutube">
    <hr>
    <li *ngIf="permissionsService.mute.value">
      <a class="action" (click)="switchStudentAudio($event, !user.isMicro, user.id)">
        {{ (user.isMicro ? 'buttons.mute' : 'buttons.request-mic-on') | translate }}
      </a>
    </li>
    <li *ngIf="permissionsService.hide.value">
      <a class="action" (click)="switchStudentVideo($event, !user.isCamera, user.id)">
        {{ (user.isCamera ? 'buttons.stop-video' : 'buttons.request-video-on') | translate }}
      </a>
    </li>
    <hr>
    <li *ngIf="permissionsService.kick.value">
      <a class="action remove" (click)="kickStudent()">{{ 'buttons.remove' | translate }}</a>
    </li>
  </ng-container>

</ul>

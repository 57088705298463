<!-- <div class="call-container" *appLoading="isLoading" [class.split-view]="isSplitViewEnabled" [class.mobile]="isMobile"> -->
<app-loading [isUnderModal]="false" *ngIf="(isLoading && isSign) || isProcessing"></app-loading>

<!-- <ng-container *ngIf="isDailyRoom$ | async">
        <div class="notification-panel-row not-overflow flexGrow">
            <div class="join-requests">
                <app-notification-panel [notJoinedList]="notJoinedList" (updateNotJoinedList)="updateNotJoinedList($event)">
                </app-notification-panel>
                <div class="join-requests-item" *ngFor="let joinItem of joinList">
                    <span [innerHTML]="'call-room.join-request' | translate : { name: joinItem.name } | safeHtml"></span>
                    <div class="join-requests-buttons">
                        <button class="default-ui-button white-ui-button" (click)="rejectJoin(joinItem.userId)">
                            {{ "buttons.reject" | translate }}
                        </button>
                        <button class="default-ui-button blue-ui-button" (click)="approveJoin(joinItem.userId)">
                            {{ "buttons.let-in" | translate }}
                        </button>
                    </div>
                </div>
                <div class="join-requests-item teacher" *ngFor="let teacherRequest of teacherRequestList">
                    <div class="teacher-request-title">
                        {{
                            (teacherRequest.unmute
                                ? "call-room.teacher-request-title-unmute"
                                : "call-room.teacher-request-title-unhide"
                            ) | translate
                        }}
                    </div>
                    <span
                        [innerHTML]="
                            (teacherRequest.unmute ? 'call-room.teacher-request-text-unmute' : 'call-room.teacher-request-text-unhide')
                                | translate : { name: getTeacherName(teacherRequest.name) }
                                | safeHtml
                        "
                    >
                    </span>
                    <div class="join-requests-buttons">
                        <button class="default-ui-button white-ui-button" (click)="rejectTeacher(teacherRequest)">
                            {{ "buttons.reject" | translate }}
                        </button>
                        <button class="default-ui-button blue-ui-button" (click)="approveTeacher(teacherRequest)">
                            {{ (teacherRequest.unmute ? "buttons.unmute-mic" : "buttons.start-video") | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    <div class="p-3">
    <p>You are in a Sirius test room!</p>   
    <app-daily-video (leaveCall)="doFinishCall(true,false)"/>
    <app-daily-video-settings/>
    </div>
</ng-container> -->

<div
	class="call-container"
	id="callContainer"
	[style.height]="innerHeight + 'px'"
	[style.backgroundColor]="bgColor"
	[class.landscape-ipad]="isLandscapeIpad"
	*ngIf="!isLoading"
	[class.split-view]="isSplitViewEnabled"
	[class.mobile]="isMobile"
	[class.portrait-tablet]="isPortraitTablet"
	[ngClass]="[isDailyRoom ? 'daily' : '']"
>
	<!--  <div class="video-component" [ngClass]="displayChat && isDesktop ? 'video-chat' : 'video-full'">-->
	<div id="video-component" class="video-component" [ngClass]="displayChat || displayNotes || displayCloud ? 'video-chat' : 'video-full'">
		<app-call-header
			*ngIf="!isLoading"
			[isMobile]="isMobile"
			[isFullscreen]="isFullscreen"
			[isTeacher]="isTeacher()"
			[showTimer]="showTimer"
			class="absolute z-[19] top-0 w-full"
			(exitFullscreen)="toggleFullScreen(false)"
			(toggleTimer)="doToggleTimer()"
			[theme]="theme"
			[subrole]="subrole"
			[betaTesting]="betaTesting"
			[studentId]="studentId"
			[isTrial]="isTrial$ | async"
		>
		</app-call-header>
		<img *ngIf="customThemeService.watermarkURL$ | async" class="watermark" src="{{ customThemeService.watermarkURL$ | async }}" />
		<div
			*ngIf="isMetronome"
			[ngClass]="['metronome-box', isMobile ? 'mobile' : '', isPortrait && isTuner ? 'is-portrait-and-tuner' : '']"
			[hidden]="isMobile && isEmoji"
		>
			<app-metronome tabindex="0" [isMobile]="isMobile" (clickClose)="metronomeClose()"></app-metronome>
		</div>

		<div
			class="flex align-middle"
			*ngIf="isTuner"
			#tunerElement
			(touchstart)="onTouchStart($event)"
			(touchmove)="onTouchMove($event)"
			(touchend)="onTouchEnd()"
			(mousedown)="onMouseDown($event)"
			(mousemove)="onMouseMove($event)"
			(mouseup)="onMouseUp()"
			[ngClass]="['tuner-box', isMobile ? 'mobile' : '', isPortrait ? 'is-portrait' : '', isMetronome ? 'is-metronome' : '']"
			[hidden]="isMobile && isEmoji"
		>
			<!-- <app-new-tuner /> -->

			<app-tuner
				[isMobile]="isMobile"
				[isPortrait]="isPortrait"
				[isBrowserSafari]="isBrowserSafari"
				(clickClose)="tunerClose()"
				(changeIsPortrait)="changeIsPortrait()"
			></app-tuner>
		</div>
		<div
			*ngIf="isEmoji"
			[ngClass]="[
				'emoji-box',
				isMobile ? 'mobile' : '',
				(isMetronome && !isTuner) || (!isMetronome && isTuner) ? 'is-metro-or-tuner' : '',
				isMetronome && isTuner ? 'is-metro-and-tuner' : ''
			]"
		>
			<app-emoji-picker
				tabindex="0"
				[isHandOn]="isHandOn"
				[isMobile]="isMobile"
				(emojiSelected)="onEmojiSelected($event)"
				(clickClose)="emojiClose()"
				(toggleHandOn)="doToggleHandOn($event)"
			></app-emoji-picker>
		</div>

		<ng-container *ngIf="!isOldVariant && !isCallEnded">
			<app-daily-video (leaveCall)="doFinishCall(true, false)" />
			<app-display-grid-participants
				*ngIf="!(isDailyRoom$ | async)"
				[hidden]="isFullscreen && !isSplitView"
				id="participants-grid-view"
				[isSplitView]="isSplitView"
				[isRecordingActive]="recordingState === 'recording'"
				[connectedUsers]="getConnectedUsers()"
				[isReflection]="isReflection"
				[isPortrait]="isPortrait"
				[isFullscreen]="isFullscreen"
				[shouldNotMirrored]="shouldNotMirrored"
				[outputDeviceId]="outputDeviceId"
				[maximizeSecondCam]="maximizeSecondCam"
				(toggleStopYouTube)="doToggleStopYouTube()"
				(toggleMaximize)="doToggleMaximize($event)"
				(toggleMaximizeSecondStream)="doToggleMaximizeSecondStream($event)"
				(toggleFullscreen)="doToggleFullscreen($event)"
				[class.isSidePanel]="displayChat || displayNotes || displayCloud"
				[class.split-view-safari-one-user]="
					userOnPanel()?.isCamera && isDesktop && isBrowserSafari && isSingleUser() && !isTeacher()
				"
				[class.is-mobile]="isMobile"
				[class.isPiano]="isPiano"
				[isPiano]="isPiano"
				[currentStudent]="currentStudent"
			>
			</app-display-grid-participants>

			<ng-container *ngIf="!!userOnPanel() && !(isDailyRoom$ | async)">
				<div
					mainPanel
					class="main-panel !rounded-md"
					[class.single-user]="isSingleUser()"
					[class.is-tablet]="isTablet"
					[class.split-view-firefox]="userOnPanel().isCamera && isDesktop && isBrowserFirefox"
					[class.split-view-safari-firefox]="userOnPanel().isCamera && isDesktop && (isBrowserSafari || isBrowserFirefox)"
					[ngStyle]="{ width: getCurrentWidth }"
					[class.is-cam-on]="userOnPanel().isCamera"
					[class.is-mobile]="isMobile"
					[hidden]="isSplitView"
					[class.is-fullscreen]="isFullscreen"
					[class.isPiano]="isPiano"
					[class.back-no-video]="!userOnPanel().isCamera"
				>
					<app-emoji-icon
						@emojiFade
						*ngIf="getUserEmoji(userOnPanel().id)"
						class="displayedEmoji"
						[ngClass]="{ mobile: isMobile, desktop: isDesktop, landscape: isLandscape }"
						[emoji]="getUserEmoji(userOnPanel().id)"
					>
					</app-emoji-icon>

					<div *ngIf="userOnPanel()?.isTeacher && recordingState === 'recording'" class="displayedRecording"></div>

					<app-view-media
						*ngIf="canDisplayAppViewMedia && canBeShowAppViewMediaForAndroid"
						class="bg-black bg-opacity-30"
						[hidden]="!(userOnPanel().isCamera$ | async)"
						[adaptationMode]="adaptByHostSizesAndCover"
						[ngClass]="{
							'screen-share': userOnPanel().screenShareStream || userOnPanel()?.mediaStream?.streamName === 'screenshare'
						}"
						[attr.uid]="userOnPanel().id"
						[mediaStream]="
							maximizeSecondCam && userOnPanel().mediaStreamSecondVideo
								? userOnPanel().mediaStreamSecondVideo
								: userOnPanel().screenShareStream || userOnPanel().mediaStream
						"
						[sinkId]="outputDeviceId"
						[muted]="true"
						[waitingRoom]="true"
						_debugName_="userOnPanel().name + '_OnPanel'"
						[isMirrored]="isMainPanelMirrored"
					>
					</app-view-media>

					<div
						*ngIf="!userOnPanel()?.hasYoutube"
						[hidden]="userOnPanel().isCamera$ | async"
						class="screen-saver"
						[class.is-mobile]="isMobile"
						[ngStyle]="{ width: screenSaverWidth }"
						[class.portrait-tablet]="isPortraitTablet"
					>
						<div class="circle">
							{{ getInitial(userOnPanel().name) }}
						</div>
					</div>

					<div
						*ngIf="isYouTube"
						[hidden]="!userOnPanel()?.hasYoutube"
						class="screen-saver youtube-container"
						[class.is-mobile]="isMobile"
						[ngStyle]="{ width: '100vw' }"
						[class.portrait-tablet]="isPortraitTablet"
					>
						<div class="youtube-header">
							<div class="leftCol">
								<mat-icon class="serviceIcon" svgIcon="service-icon"></mat-icon>
								<span>YouTube</span>
							</div>
						</div>
						<video-player
							[user]="userOnPanel()"
							(onPlaying)="doPlayerOnPlay($event)"
							(onPause)="doPlayerOnPause($event)"
							[activePlayer]="true"
							[videoId]="userOnPanel()?.youTubeVideoId"
						></video-player>
					</div>

					<div
						[ngClass]="{
							'user-name-panel-split': updatePanelSplit,
							'hide-split-panel-info': !isSplitView && !isHideSelf(userOnPanel())
						}"
						*ngIf="updatePanelSplit && !userOnPanel()?.hasYoutube"
					>
						<div
							*ngIf="
								userOnPanel().mediaStream &&
								userOnPanel().isMicro &&
								!(maximizeSecondCam && userOnPanel().mediaStreamSecondVideo)
							"
							class="user-name-item"
						>
							<!-- <app-volume-meter-mini [mediaStream]="userOnPanel().mediaStream"></app-volume-meter-mini> -->
						</div>

						<img *ngIf="!userOnPanel().isMicro" class="user-name-item" src="assets/icons/sirius/MicrophoneOffMini.svg" alt="" />
						<img *ngIf="!userOnPanel().isCamera" class="user-name-item" src="assets/icons/sirius/CameraOffMini.svg" alt="" />
						<div class="user-name-item ellipsis">
							{{
								maximizeSecondCam
									? ("video-settings.second-camera" | translate) + ", " + userOnPanel().name
									: userOnPanel().name
							}}{{ isHideSelf(userOnPanel()) ? ("call-room.you" | translate) : "" }}
						</div>
					</div>

					<div class="user-actions" [ngClass]="{ 'youtube-actions': isYouTube && userOnPanel()?.hasYoutube }">
						<app-user-actions
							[class.mobile]="isMobile"
							[hidden]="isSplitView"
							[isSelfUser]="isHideSelf(userOnPanel())"
							[user]="userOnPanel()"
							[isFullscreen]="isFullscreen"
							[isMainPanel]="true"
							[teacherName]="selfUser().name"
							appearance="vertical"
							[isMobile]="isMobile"
							[isYoutube]="isYouTube && userOnPanel()?.hasYoutube"
							(toggleMaximize)="doToggleMaximize(userOnPanel())"
							(toggleFullscreen)="doToggleFullscreen(userOnPanel())"
							(toggleStopYouTube)="doToggleStopYouTube()"
							[isMirrored]="isMainPanelMirrored"
						>
						</app-user-actions>
					</div>
				</div>
			</ng-container>
		</ng-container>

		<div class="notification-panel">
			<div class="notification-panel-row center">
				<div class="release-warning" *ngIf="isShowReleaseWarning">
					<button class="cr-icon-close" (click)="releaseWarningClose()"></button>
					<span [innerHTML]="'call-room.release-warning' | translate: { period: periodWarning } | safeHtml"></span>
				</div>
				<div class="release-warning" *ngIf="lowBandwidth">
					<span>{{ "call-room.bad-connection" | translate }} </span>
				</div>
				<div *ngIf="outputDeviceChange && !inputDeviceChange" class="recoding-warning">
					<span class="text-xs"> {{ "devices.connected-to" | translate }} {{ currentOutputDevice }}</span>
				</div>
				<div *ngIf="inputDeviceChange && !outputDeviceChange" class="recoding-warning">
					<span class="text-xs"> {{ "devices.connected-to" | translate }} {{ currentInputDevice }}</span>
				</div>
				<div *ngIf="inputDeviceChange && outputDeviceChange" class="recoding-warning">
					<span class="text-xs">
						{{ "devices.connected-to" | translate }} {{ currentOutputDevice }}, <br />
						{{ currentInputDevice }}
					</span>
				</div>
				<div class="recoding-warning" *ngIf="recordingState === 'recording' && !shouldHideRecordingNotification">
					<span>{{ "recordings.notify-start" | translate }}</span>
				</div>
				<div class="recoding-warning" *ngIf="recordingState === 'pending'">
					<span>{{ "recordings.notify-pending" | translate }}</span>
				</div>
				<div class="recoding-warning" *ngIf="recordingState === 'stop' && roomState.isTeacher">
					<span
						>{{ "recordings.notify-stop-teacher-part-1" | translate }}
						<a target="_blank" [routerLink]="['/platform/recordings']">
							{{ "recordings.notify-stop-teacher-part-2" | translate }}</a
						>
						{{ "recordings.notify-stop-teacher-part-3" | translate }}
					</span>
				</div>
				<div class="recoding-warning" *ngIf="showRecordingError && roomState.isTeacher">
					<span> {{ "recordings.notify-error" | translate }} </span>
				</div>
				<div class="recoding-warning" *ngIf="recordingState === 'stop' && !roomState.isTeacher">
					<span>{{ "recordings.notify-stop-student" | translate }}</span>
				</div>
			</div>

			<div class="notification-panel-row not-overflow flexGrow">
				<div class="join-requests">
					<app-notification-panel [notJoinedList]="notJoinedList" (updateNotJoinedList)="updateNotJoinedList($event)">
					</app-notification-panel>
					<div *ngIf="roomState.isTeacher && showNextLessonAlert" class="join-requests-item">
						<span>{{ "call-room.your-lesson-with" | translate }}</span>
						<span> {{ nextStudentName }} </span>
						<span>{{ "call-room.starts-now" | translate }}</span>
						<div class="join-requests-buttons">
							<button class="default-ui-button blue-ui-button" (click)="goToNextLesson()">
								{{ "buttons.switch-room" | translate }}
							</button>
						</div>
					</div>
					<div class="join-requests-item" *ngFor="let joinItem of joinList">
						<span [innerHTML]="'call-room.join-request' | translate: { name: joinItem.name } | safeHtml"></span>
						<div class="join-requests-buttons">
							<button class="default-ui-button white-ui-button" (click)="rejectJoin(joinItem.userId)">
								{{ "buttons.reject" | translate }}
							</button>
							<button class="default-ui-button blue-ui-button" (click)="approveJoin(joinItem.userId)">
								{{ "buttons.let-in" | translate }}
							</button>
						</div>
					</div>
					<div class="join-requests-item teacher" *ngFor="let teacherRequest of teacherRequestList">
						<div class="teacher-request-title">
							{{
								(teacherRequest.unmute
									? "call-room.teacher-request-title-unmute"
									: "call-room.teacher-request-title-unhide"
								) | translate
							}}
						</div>
						<span
							[innerHTML]="
								(teacherRequest.unmute ? 'call-room.teacher-request-text-unmute' : 'call-room.teacher-request-text-unhide')
									| translate: { name: getTeacherName(teacherRequest.name) }
									| safeHtml
							"
						>
						</span>
						<div class="join-requests-buttons">
							<button class="default-ui-button white-ui-button" (click)="rejectTeacher(teacherRequest)">
								{{ "buttons.reject" | translate }}
							</button>
							<button class="default-ui-button blue-ui-button" (click)="approveTeacher(teacherRequest)">
								{{ (teacherRequest.unmute ? "buttons.unmute-mic" : "buttons.start-video") | translate }}
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<ng-container *ngIf="isPiano" [ngClass]="[isMobile ? 'mobile' : '']">
			<app-piano [class.ipad-safari]="isIpadSafari" tabindex="0" />
		</ng-container>
		<app-call-controls
			[hidden]="isLandscape && isMobile"
			[ngClass]="{ 'controls-mobile': isMobile, 'controls-desktop': isDesktop, 'controls-tablet': isTablet }"
			[isHiddenSetting]="isHiddenSetting"
			[isMicro]="selfUser().isMicro"
			[isCamera]="selfUser().isCamera"
			[isHiddenInterrupt]="isHiddenInterrupt"
			[isHandOn]="isHandOn"
			[isHiddenMetronome]="isHiddenMetronome"
			[isMetronome]="isMetronome"
			[isHiddenTuner]="isHiddenTuner"
			[isTuner]="isTuner"
			[isHiddenEmoji]="isHiddenEmoji"
			[isPiano]="isPiano"
			[isEmoji]="isEmoji"
			[isHiddenScreenshare]="isHiddenScreenShare"
			[isTeacher]="roomState?.isTeacher"
			[startRecord]="isRecordingTurnOn"
			[isScreenhare]="isDisplayStatusActive"
			[isHiddenSplitView]="isHiddenSplitView"
			[isSplitView]="isSplitView"
			[isHiddenChat]="isHiddenChat"
			[isHiddenCloud]="isHiddenCloud"
			[hasUnreadMessages]="hasUnreadMessages"
			[hasTestRoom]="testRoom"
			[isHiddenInvitation]="isHiddenInvitation"
			[isBotOrProTeacher]="isBotOrProTeacher"
			[switchCam]="switchCam"
			[isYouTube]="isYouTube"
			[isHiddenLeaveButton]="isHiddenLeaveButton"
			[displayChat]="displayChat"
			[displayCloud]="displayCloud"
			(toggleSettings)="doToggleSettings()"
			(emitYoutubeVideoId)="doYoutubeVideoId($event)"
			(toggleCamera)="doToggleCamera($event, false)"
			(toggleMicro)="doToggleMicro($event, false)"
			[latencyTurnCamera]="latencyTurnCamera"
			(toggleHandOn)="doToggleHandOn($event)"
			(toggleMetronome)="doToggleMetronome($event)"
			(toggleSwitchCam)="doToggleSwitchCam($event)"
			(toggleShare)="doToggleShare($event)"
			(togglePiano)="doTogglePiano($event)"
			(toggleTuner)="doToggleTuner($event)"
			(toggleEmoji)="doToggleEmoji($event)"
			(toggleScreenhare)="doToggleScreenShare($event)"
			(toggleSplitView)="doToggleSplitView($event)"
			(toggleChat)="doToggleChat()"
			(toggleCloud)="doToggleCloud()"
			(emojiSelected)="onEmojiSelected($event)"
			(toggleRecord)="onToggleRecord($event)"
			(toggleInvitation)="doToggleSettings(2)"
			(toggleVideoSettings)="doToggleSettings(1)"
			(toggleAudioSettings)="doToggleSettings(0)"
			(finishCall)="doFinishCall(true, false)"
			toggleIsReflection="toggleIsReflection($event)"
			[displayNotes]="displayNotes"
			(toggleNotes)="doToggleNotes()"
			[isHiddenNotes]="isHiddenNotes"
			[theme]="theme"
			[subrole]="subrole"
			[isStudentTrial]="isStudentTrial()"
			[isDailyRoom]="isDailyRoom$ | async"
			[roomType]="roomType"
			[currentLesson]="currentLesson"
			[userProfile]="userProfile"
			[isTrial]="isTrial$ | async"
		>
		</app-call-controls>
	</div>
	@if ((subrole === 3 || subrole === 6) && !isStudentTrial() && isRoomIdCreated) {
		<app-talkjs-chat [studentId]="studentId" [isTeacher]="isTeacher" [displayChat]="displayChat"></app-talkjs-chat>
	} @else {
		<app-chat [userName]="userName" [displayChat]="displayChat"></app-chat>
	}
	@if (userProfile && !isHiddenCloud && (subrole === 3 || subrole === 6)) {
		<app-call-sirius-cloud
			[profile]="userProfile"
			[displayCloud]="displayCloud"
			[studentId]="studentId"
			[teacherId]="teacherId"
			[studentName]="isTeacher() ? nextStudentName : getStudentName()"
			[isStudent]="!isTeacher()"
			class="z-50"
		/>
	}

	<app-call-notes
		*ngIf="!isHiddenNotes"
		[displayNotes]="displayNotes"
		[studentId]="studentId"
		[teacherId]="teacherId"
		[isStudent]="!isTeacher()"
		class="z-50"
	>
	</app-call-notes>
</div>
<!--<app-interlocutor-controls *ngIf="this.roomState.role === '1a'"-->
<!--                           [hidden]="!isSingleUser"></app-interlocutor-controls>-->
<div class="loading-div" *ngIf="roomState.isErrorMediaSource">
	<p class="loading-text-p">{{ "call-room.loading-text" | translate }}</p>
</div>

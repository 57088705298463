import {
	AfterViewInit,
	Component,
	EventEmitter,
	OnDestroy,
	OnInit,
	Output,
	ViewChild
} from "@angular/core";
import timeGridPlugin from "@fullcalendar/timegrid";
import { AuthenticationService } from "../services/authentication/authentication.service";
import { LanguageService } from "../services/languages/language.service";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);
dayjs.extend(timezone);
dayjs.extend(utc);
import {
	FullCalendarComponent,
	FullCalendarModule
} from "@fullcalendar/angular";
import { CommonModule } from "@angular/common";
import {
	LessonsService
} from "../services/lessons/lessons.service";
import { map, takeUntil } from "rxjs/operators";
import { Observable, Subject, forkJoin } from "rxjs";
import { isInPast } from "../helpers/time";
import {
	AvailabilityService,
	AvailabilityType
} from "../services/availability/availability.service";
import { Calendar, CalendarOptions } from "@fullcalendar/core";
import interactionPlugin from "@fullcalendar/interaction";
import { PopupService } from "../services/popup/popup.service";
import { SubRole } from "../constants/subroles-constants";
import { ButtonType } from "../components/call-room/data/ButtonType";
import { MatDialog } from "@angular/material/dialog";
import { Booking, BookingType } from "@data-types/booking.type";

interface LessonInfoDialogOptions {
	booking: Booking;
	timeZone: string;
	subRole: SubRole;
	readOnly?: boolean;
	status: string;
}

@Component({
	selector: "app-calendar",
	templateUrl: "./calendar.component.html",
	styleUrls: ["./calendar.component.scss"],
	standalone: true,
	imports: [FullCalendarModule, CommonModule, TranslateModule]
})
export class CalendarComponent implements AfterViewInit, OnInit, OnDestroy {
	public lessonsList = [];
	public availabilities = [];
	public eventsList = [];
	public overridesList = [];
	public currentOpenPopover: string = null;
	public availabilityType: AvailabilityType;
	private destroy$ = new Subject();
	private timeZone: string;
	private userId: string;
	private popoverTimeout: any;
	private subRole: SubRole;

	@Output() onStartSession = new EventEmitter();

	@ViewChild("calendar") calendarComponent: FullCalendarComponent;

	public calendarOptions: CalendarOptions = {
		nowIndicatorClassNames: "now-indicator",
		eventBackgroundColor: "#FAEAEC",
		eventBorderColor: "#b2a7a9",
		eventTextColor: "#151515",
		plugins: [timeGridPlugin, interactionPlugin],
		initialView: "timeGridWeek",
		weekends: true,
		allDaySlot: false,
		nowIndicator: true,
		// slotDuration: "01:00:00",
		buttonText: {
			today: this.translateService.instant("calendar.today")
		},
		headerToolbar: {
			start: "prev,next title today",
			center: "",
			end: ""
		},
		titleFormat: { year: "numeric", month: "long", day: "numeric" },
		dayHeaderFormat: { weekday: "short", day: "numeric", omitCommas: true },
		slotLabelFormat: {
			hour: "numeric",
			minute: "2-digit",
			omitZeroMinute: false,
			meridiem: "short",
			hour12: false
		},
		eventTimeFormat: {
			hour: "numeric",
			minute: "2-digit",
			omitZeroMinute: false,
			meridiem: "short",
			hour12: false
		},
		scrollTime: "08:30:00",
		views: {
			timeGridOneDay: {
				type: "timeGrid",
				duration: { days: 1 }
			}
		},
		eventClick: this.onEventClick.bind(this),
		dateClick: this.onDateClick.bind(this),
		firstDay: 1,
		
	};

	constructor(
		private auth: AuthenticationService,
		private languageService: LanguageService,
		private translateService: TranslateService,
		private lessonService: LessonsService,
		private availabilityService: AvailabilityService,
		private popupService: PopupService,
		private dialog: MatDialog
	) {
		this.translateService.onLangChange.subscribe((event) => {
			this.calendarOptions.locale = event.lang;
			this.calendarOptions.buttonText.today =
				this.translateService.instant("calendar.today");
		});
	}

	ngAfterViewInit(): void {
		document.addEventListener(
			"scroll",
			this.removeAllPopovers.bind(this),
			true
		);
		this.calendarOptions.windowResize = this.onResize.bind(this);
		this.calendarOptions.datesSet = this.onDatesSet.bind(this);
		this.calendarOptions.timeZone = this.timeZone;
	}

	// to do: fix
	removeAllPopovers(): void {
		this.currentOpenPopover = null;
	}

	async ngOnInit(): Promise<void> {
		const profile = await this.auth.profile;

		this.timeZone = profile?.timezone;
		this.userId = profile?.userId;
		this.subRole = profile?.subrole;

		this.calendarOptions.locale = this.languageService.languages.find(
			(lang) => lang._id === profile?.languageId
		).iso;

		this.onResize();

		this.lessonService.updateBookingList$
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => {
				this.updateCalendar();
			});
		this.translateService.onLangChange
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => {
				this.updateCalendar();
			});
	}

	onResize(): void {
		this.removeAllPopovers();
		if (window.innerWidth < 768) {
			this.calendarOptions.height = window.innerHeight - 280;
			if (this.calendarAPI.view.type === "timeGridWeek") {
				this.calendarAPI.changeView("timeGridOneDay");
			}
		} else if (window.innerWidth < 1280) {
			this.calendarOptions.height = window.innerHeight - 260;
			if (this.calendarAPI.view.type === "timeGridOneDay") {
				this.calendarAPI.changeView("timeGridWeek");
			}
		} else {
			this.calendarOptions.height = window.innerHeight - 260;
			if (this.calendarAPI.view.type === "timeGridOneDay") {
				this.calendarAPI.changeView("timeGridWeek");
			}
		}
	}

	isPopoverOpen(arg): boolean {
		return this.currentOpenPopover !== arg.event.extendedProps.id;
	}

	get calendarAPI(): Calendar {
		return this.calendarComponent?.getApi();
	}

	updateCalendar(): void {
		const { currentStart, currentEnd } = this.calendarAPI.view;

		this.initEventsList(new Date(currentStart), new Date(currentEnd));
	}

	getPopoverElement(id): HTMLElement {
		return document.getElementById(id + "-popover");
	}

	openPopover(event): void {
		const { id } = event.extendedProps;

		clearTimeout(this.popoverTimeout);
		if (!this.isDisabled(event)) {
			if (this.currentOpenPopover === id) {
				return;
			}
			this.currentOpenPopover = id;

			const eventDOMRect: DOMRect = this.getBoundingClientRect(event);
			const isLastTwoColumns = this.isFridayOrSaturday(event);
			const popoverElement = this.getPopoverElement(id);

			if (popoverElement) {
				if (window.innerWidth > 768) {
					popoverElement.style.top = eventDOMRect.top - 2 + "px";
					if (!isLastTwoColumns) {
						popoverElement.style.left =
							eventDOMRect.right + 5 + "px";
					} else {
						popoverElement.style.left =
							eventDOMRect.left - 5 + "px";
						popoverElement.style.transform = "translateX(-100%)";
					}
				} else {
					popoverElement.style.top =
						eventDOMRect.top + eventDOMRect.height + 2 + "px";
					popoverElement.style.left = eventDOMRect.left - 5 + "px";
				}
			}
		}
	}

	closePopover(event): void {
		if (event) {
			this.popoverTimeout = setTimeout(() => {
				this.currentOpenPopover = null;
			}, 1000);
		}
	}

	isFridayOrSaturday(event): boolean {
		const day = dayjs(event.start).day();

		return day === 5 || day === 6;
	}

	getBoundingClientRect(event): DOMRect {
		const eventElement = document.getElementById(event.extendedProps.id);

		if (eventElement) {
			return eventElement.getBoundingClientRect();
		}
	}

	ngOnDestroy(): void {
		document.removeEventListener(
			"scroll",
			this.removeAllPopovers.bind(this),
			true
		);

		this.destroy$.next();
	}

	isTrialLesson(event): boolean {
		return event.extendedProps.type === "trial";
	}

	onDatesSet(arg): void {
		if (this.userId) this.updateCalendar();
	}

	formatTime(time: string): string {
		return dayjs(time).tz("Europe/Berlin").format();
	}

	initLessonsList(start: Date, end: Date): Observable<any> {
		return this.lessonService
			.getCalendarBookings(this.userId, start, end)
			.pipe(
				map((res) => {
					const allLessonsList = res.list.map((lesson: Booking) => {
						const lessonType = lesson.type;
						const status = lesson.status;

						const bgColors = {
							[BookingType.TRIAL]: "#FAEAEC",
							[BookingType.SINGLE]: "#908af5"
						};

						const eventTextColors = {
							[BookingType.TRIAL]: "#151515",
							[BookingType.SINGLE]: "#ffffff"
						};

						const isPast = isInPast(lesson.endTime, this.timeZone);
						const options = {
							start: this.formatTime(lesson.startTime),
							end: this.formatTime(lesson.endTime),
							title: lesson.student.name !== "undefined " ? lesson.student.name?.split(" ")[0] : " ",
							extendedProps: {
								...lesson,
								isInPast: isPast
							},
							backgroundColor: isPast
								? "#eeeded"
								: bgColors[lessonType],
							textColor: eventTextColors[lessonType]
						};
						if (!isPast) {
							options["borderColor"] = bgColors[lessonType];
						}
						if (status === "declined" && !isPast) {
							options["backgroundColor"] = "#b1a7a9";
							options["borderColor"] = "#b1a7a9";
						}
						if (status === "pending" && !isPast) {
							options["backgroundColor"] = "#d3a628";
							options["borderColor"] = "#d3a628";
						}
						return options;
					});
					this.lessonsList = allLessonsList.filter(
						({ extendedProps }) =>
							extendedProps.status !== "canceled"
					);
				})
			);
	}

	initAvailabilitiesAndOverrides(): Observable<void> {
		return this.availabilityService
			.getAvailability(this.userId, this.availabilityType)
			.pipe(
				map((availabilityData) => {
					const availability = availabilityData.filter(
						(a) => !a.overrideDate && a.startTime && a.endTime
					);

					const availabilityOverrides = availabilityData.filter(
						(a) => a.overrideDate
					);

					const businessHours = availability.map((a) => {
						return {
							daysOfWeek: [String(a.day)],
							startTime: a.startTime,
							endTime: a.endTime
						};
					});

					const overrides = availabilityOverrides.map((o) => {
						const start = o.startTime || "00:00";
						const end = o.endTime || "23:30";

						const startTime = this.formatOverrideTime(
							start,
							o.overrideDate
						);
						const endTime = this.formatOverrideTime(
							end,
							o.overrideDate
						);

						return {
							start: startTime,
							end: endTime,
							extendedProps: {
								isOverride: true
							},
							display: "background"
						};
					});

					this.overridesList = overrides;
					this.availabilities = businessHours;
					this.calendarAPI.setOption(
						"businessHours",
						this.availabilities
					);
				})
			);
	}

	initEventsList(start: Date, end: Date): void {
		forkJoin([
			this.initLessonsList(start, end),
			// this.initAvailabilitiesAndOverrides()
		]).subscribe(() => {
			this.eventsList = [...this.lessonsList, ...this.overridesList];
		});
	}

	formatAvailabilityTime(time): string {
		const timeHours = time?.split(":")[0];

		const timeMinutes = time?.split(":")[1];

		const date = new Date();
		date.setUTCHours(Number(timeHours), Number(timeMinutes), 0);
		const res = new Date(date.getTime()).toLocaleString("en-US", {
			// timeZone: this.timeZone,
			hour: "2-digit",
			minute: "2-digit",
			hour12: false
		});

		return res;
	}

	formatOverrideTime(time, date) {
		const timeHours = time?.split(":")[0];
		const timeMinutes = time?.split(":")[1];
		const dateObj = new Date(date);
		dateObj.setUTCHours(Number(timeHours), Number(timeMinutes), 0);

		const localized = dateObj.toLocaleString("en-US", {
			timeZone: this.timeZone
		});

		return dayjs(localized).format();
	}

	isOverHalfHour(event): boolean {
		const start = dayjs(event.start);
		const end = dayjs(event.end);
		const diff = end.diff(start, "minutes");

		return diff > 30;
	}

	onClickStartLesson(event): void {
		this.onStartSession.emit(event.extendedProps);
	}

	onclickReschedule(event): void {
		this.lessonService.rescheduleLesson(event.extendedProps, this.timeZone);
	}

	isLesson(event): boolean {
		return !event.extendedProps?.isOverride;
	}

	isOverride(event): boolean {
		return event.extendedProps?.isOverride;
	}

	isDisabled(event): boolean {
		const { isInPast, status } = event.extendedProps;
		return isInPast || status === "canceled";
	}

	onAvilabilityFilterChange({ target: { name, checked } }) {
		if (checked) {
			this.availabilityType = name;
			this.initAvailabilitiesAndOverrides().subscribe(() => {
				this.eventsList = [...this.lessonsList, ...this.overridesList];
			});
		} else {
			this.availabilityType = null;
			this.initAvailabilitiesAndOverrides().subscribe(() => {
				this.eventsList = this.lessonsList;
			});
			this.calendarAPI.setOption("businessHours", []);
		}
	}

	onEventClick({ event }): void {
		const { isOverride } = event.extendedProps;
		const { status } = event.extendedProps;

		const options: LessonInfoDialogOptions = {
			booking: { ...event.extendedProps },
			timeZone: this.timeZone,
			subRole: this.subRole,
			readOnly: event.extendedProps.isInPast,
			status: status
		};

		this.currentOpenPopover = null;
		if (!isOverride) {
			this.popupService.openLessonInfoDialog(options);
		}
	}

	onDateClick(info): void {
		const startTime = dayjs(info.dateStr);

		const isInPast = startTime.isBefore(dayjs());
		if (isInPast) {
			this.popupService.openCustomMessagePopup(
				"popUp.past-date",
				[
					{
						type: ButtonType.CANCEL,
						text: "Ok"
					}
				],
				false,
				true
			);
		} else {
			this.popupService.openCalendarEventDialog(
				this.userId,
				this.timeZone
			);
		}
	}
}

import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-update-limit',
  templateUrl: './update-limit.component.html',
  styleUrls: ['./update-limit.component.scss']
})
export class UpdateLimitComponent implements OnInit{

    limitFrom: FormGroup;
	@Input() limit: number;
	@Input() lessonType: string;
	@Output() readonly setLimit: EventEmitter<number> = new EventEmitter();

	ngOnInit() {
		this.limitFrom = new FormGroup({
			limit: new FormControl(this.limit || '0', [
				Validators.required
			]),
		});
		this.limitFrom.controls.limit.valueChanges.pipe(
            debounceTime(500)
          ).subscribe(limit =>{ 
            this.setLimit.emit(limit)});
	}

}

import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import * as Sentry from "@sentry/angular";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";
import { ensureGlobalObject } from "./app/services/utils";
import packageJson from "../package.json";

if (environment.production) {
	enableProdMode();
}

Sentry.init({
	dsn: environment.dsn,
	integrations: [Sentry.browserTracingIntegration()],
	tracesSampleRate: 1.0,
	tracePropagationTargets: [
		"localhost",
		"https://app-test.sirius.video, https://app.sirius.video",
	],
	environment: environment.ENV
});

ensureGlobalObject("APP").version = packageJson.version;

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.catch((err) => console.error(err));

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { VolumeMeterComponent } from './volume-meter.component';


@NgModule({
  declarations: [VolumeMeterComponent],
  imports: [
    CommonModule
  ],
  exports: [VolumeMeterComponent]
})
export class VolumeMeterModule { }

import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CallStorage } from '../call-room/service/storage/call-storage.service';
import { UserVO } from '../../services/data/UserVO';
import { Subscription } from 'rxjs';
import { AFFECT, HIDE, MUTE, WebSocketService } from '../../services/ws/web-socket.service';
import { RoomStateStorage } from '../call-room/service/storage/RoomStateStorage';
import { PopupService } from '../../services/popup/popup.service';
import { PlatformDetectorService } from '../../services/platform-detector/platform-detector.service';
import { PermissionsService } from '../../permissions.service';
import { USER_KEY } from '../../constants/localstorage-constants';

@Component({
  selector: 'app-user-call',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit, OnDestroy {
  // todo rework
  @Input() title = 'unknown';

  isSelected = false;
  isOnlyOneParticipant = false;
  user: UserVO;
  isMobile: boolean;
  userName: string = '';

  private subscribes = new Array<Subscription>();

  constructor(public callStorage: CallStorage,
              public permission: PermissionsService,
              private cd: ChangeDetectorRef,
              private webSocketService: WebSocketService,
              private popupService: PopupService,
              private platformDetectorService: PlatformDetectorService,
              public roomState: RoomStateStorage) {
  }

  ngOnInit() {
    this.user = this.callStorage.usersStorage.getUser(this.title);
    this.subscribes.push(this.callStorage.mainVideo$.subscribe((id) => {
      this.isSelected = id.currentVideo === this.title;
      this.cd.detectChanges();
    }));
    this.subscribes.push(this.user.connected$.subscribe(this.onDisconnect));
    if (this.callStorage.usersStorage.users.filter(user => user.connected).length === 1) {
      this.callStorage.updateMainVideo(this.user.rtcId);
    }
    this.subscribes.push(this.user.name$
      .subscribe(name => {
        this.userName = name;
      }));
    this.isMobile = this.platformDetectorService.isMobile();
    if (!this.userName || this.userName === '') {
      this.userName = sessionStorage.getItem(USER_KEY);
    }
    this.isOnlyOneParticipant = (this.callStorage.usersStorage.getUsersCount() === 1);
    this.subscribes.push(this.callStorage.usersStorage.onUsersUpdate$
      .subscribe(() => {
        this.isOnlyOneParticipant = (this.callStorage.usersStorage.getUsersCount() === 1);
        this.cd.detectChanges();
      }));
  }
  private onDisconnect = (status: boolean) => {
    if (!status) {
      const item = document.getElementById(this.title);
      item.parentNode.removeChild(item);
    }
  }
  ngOnDestroy(): void {
    this.unsubscribe();
  }
  private unsubscribe() {
    this.subscribes.map((subscribe) => subscribe.unsubscribe());
  }
  hideUser() {
    if (this.permission.hide.getValue()) {
      this.webSocketService.dispatch(AFFECT, { option: HIDE, target: this.user.id });
    }
  }
  muteUser() {
    if (this.permission.mute.getValue()) {
      this.webSocketService.dispatch(AFFECT, { option: MUTE, target: this.user.id });
    }
  }
}

import { Component, ElementRef, ViewChild } from "@angular/core";
import {
	AGE_GROUPS,
	GENDERS,
	GENRES,
	INSTRUMENTS,
	LANGUAGE_LEVELS,
	SPOKEN_LANGUAGES,
	MAX_DATE,
	MIN_DATE,
	STUDENT_EXPERIENCE_LEVELS,
	VAT_STATUS
} from "../profile.constants";
import {
	FormArray,
	FormBuilder,
	FormControl,
	FormGroup,
	Validators
} from "@angular/forms";
import {
	CountriesService,
	CountryListItem
} from "src/app/services/countries/countries.service";
import { Router } from "@angular/router";
import { AuthenticationService } from "src/app/services/authentication/authentication.service";
import { ProfileService } from "../profile.service";
import { Gender, Profile, TeacherStatus } from "src/app/types/profile.types";
import { debounceTime } from "rxjs/operators";
import { confirmPasswordValidator } from "src/app/validators/confirm-password";
import {
	DISALLOW_TRAILING_SPACES,
	PASS_VALIDATION_PATTERN,
	TEXT_NAME_MAX_LENGTH
} from "src/app/constants/generic.constants";
import { EMAIL_VALIDATION_REG } from "src/app/lib-core/constants/constants";
import { Timezone } from "src/app/models/timezone";
import { Language, LanguagesList } from "src/app/models/language";
import { LanguageService } from "src/app/services/languages/language.service";
import { TranslateService } from "@ngx-translate/core";
import { areArrayContentsSame } from "src/app/helpers";
import { PopupService } from "src/app/services/popup/popup.service";

//  to do: show saved message

@Component({
	selector: "app-profile-form",
	template: "",
	styles: []
})
export class ProfileFormBaseComponent {
	languagesList = SPOKEN_LANGUAGES;
	levelsList = LANGUAGE_LEVELS;
	ageGroupsList = AGE_GROUPS;
	skillLevelsList = STUDENT_EXPERIENCE_LEVELS;
	gendersList = GENDERS;
	studentgenderList;
	genres;
	instruments;
	vatStatus = VAT_STATUS;
	minDate = MIN_DATE;
	maxDate = MAX_DATE;
	form: FormGroup;
	isOtherGender = false;
	countriesList: CountryListItem[];
	isSubmitting = false;
	isSavingError = false;
	isShowErrors = false;
	timezones: Timezone[];
	languages: Language[];
	// Email/password Validators feedback values
	hasEnoughPassLength: boolean;
	hasNumberOrSpecial: boolean;
	isNotEmpty: boolean;
	disallowTrailingSpaces = DISALLOW_TRAILING_SPACES;
	displayPassEmail = false;
	isShowPassEmail: boolean;
	isShowPass: boolean;
	isShowNewPass: boolean;
	isShowConfPass: boolean;
	passwordUpdated = false;
	isSavedMessage = false;

	// for stopping autocomplete
	passwordHasBeenTouched = false;
	profile: Profile;
	handlePasswordFocus() {
		this.passwordHasBeenTouched = true;
	}

	protected userId: string;
	protected payoutDetailsComplete = false;
	protected teacherStatus: TeacherStatus;

	// do do: create form element component
	@ViewChild("dobInput") dobInput: ElementRef;

	get account() {
		return this.form.get("account") as FormGroup;
	}
	get spokenLanguages() {
		return this.about.get("spokenLanguages") as FormArray;
	}
	get socials() {
		return this.about.get("socials") as FormArray;
	}
	get profileDescription() {
		return this.form.get("profileDescription") as FormGroup;
	}
	get terms() {
		return this.form.get("terms") as FormGroup;
	}
	get students() {
		return this.form.get("students") as FormGroup;
	}
	get about() {
		return this.form.get("about") as FormGroup;
	}
	get photo() {
		return this.form.get("photo") as FormGroup;
	}
	get musicalDetails(): FormGroup {
		return this.form.get("musicalDetails") as FormGroup;
	}
	get payoutDetails() {
		return this.form.get("payoutDetails") as FormGroup;
	}
	get setup() {
		return this.form.get("setup") as FormGroup;
	}

	get instrumentsFormArray() {
		return this.musicalDetails.get("instruments") as FormArray;
	}

	get gendersPreferenceValue(): string[] {
		const formValue = this.students.value["gendersPreference"];
		return formValue.includes("noPreference")
			? this.allValues(this.gendersList)
			: formValue;
	}

	get agesPreferenceValue(): string[] {
		const formValue = this.students.value["agesPreference"];
		return formValue.includes("noPreference")
			? this.allValues(this.ageGroupsList)
			: formValue;
	}

	get skillLevelPreferenceValue(): string[] {
		const formValue = this.students.value["skillLevelPreference"];
		return formValue.includes("noPreference")
			? this.allValues(this.skillLevelsList)
			: formValue;
	}

	constructor(
		protected fb: FormBuilder,
		protected profileService: ProfileService,
		protected router: Router,
		protected authenticationService: AuthenticationService,
		protected countriesService: CountriesService,
		protected translate: TranslateService,
		protected languageService: LanguageService,
		protected popupService: PopupService
	) {
		this.studentgenderList = GENDERS.filter((option) => {
			return option.value !== "prefer-not";
		});
		this.loadInstrumentsAndGenres();
	}

	ngOnInit() {
		this.initCountries();
		this.translate.onLangChange.subscribe(() => {
			this.loadInstrumentsAndGenres();
			this.initCountries();
		});
		this.initForm();
		// this.initGenderControls();
		this.initPreferences();
		this.initEmailPasswordControls();
		this.loadTimezones()
			.then(() => {
				this.loadLanguages();
			})
			.then(() => {
				this.profileService.get().subscribe((profile: Profile) => {
					this.profile = profile;
					this.userId = profile.userId;
					this.teacherStatus = profile.teacherStatus;
					this.payoutDetailsComplete = profile.payoutDetailsComplete;

					if (this.teacherStatus !== TeacherStatus.REVIEW) {
						this.patchForm(profile);
					}
				});
			});
		this.initAutoSave();
		// this.initPhotoAutoSave();
	}

	saveGroup(group: FormGroup) {
		if (group.valid && !group.dirty) {
			group.markAsPristine();
			this.showSavedMessage();
		} else if (group.dirty && group.valid) {
			this.isSavingError = false;
			const data = group.value;

			if (group === this.students) {
				data.gendersPreference = this.gendersPreferenceValue;
				data.agesPreference = this.agesPreferenceValue;
				data.skillLevelPreference = this.skillLevelPreferenceValue;
			}

			this.profileService.save(group.value).subscribe(() => {
				group.markAsPristine();
				this.showSavedMessage();
			});
		} else {
			group.markAllAsTouched();
			this.isShowErrors = true;
		}
	}

	// to do : split into form groups
	async saveAccountGroup() {
		// console.log(this.account.value);
		const { firstName, lastName, languageId, timezone } =
			this.account.value;

		if (
			this.account.get("emailGroup").valid &&
			this.account.get("emailGroup").dirty
		) {
			await this.saveEmail();
		} else if (
			this.account.get("passwordGroup").valid &&
			this.account.get("passwordGroup").dirty
		) {
			await this.savePassword();
		}

		this.profileService
			.save({
				firstName,
				lastName,
				language: languageId._id,
				timezone
			})
			.subscribe(
				() => {
					["firstName", "lastName", "languageId", "timezone"].forEach(
						(c) => {
							this.account.get(c).markAsPristine();
						}
					);
					this.authenticationService.resetProfile();
					this.authenticationService.profile.finally();
					localStorage.setItem("language", languageId.iso);
					this.translate.use(languageId.iso);
					this.showSavedMessage();
				},
				() => {
					this.isSavingError = true;
				}
			);
	}

	showSavedMessage() {
		this.isSavedMessage = true;
		setTimeout(() => {
			this.isSavedMessage = false;
		}, 3000);
	}

	savePhoto() {
		if (this.photo.dirty) {
			this.isSavingError = false;
			const file = this.photo.get("photo").value;
			this.profileService.savePhoto(file).subscribe(
				(res) => {
					this.photo.patchValue({
						photo: res["photo"]
					});

					this.photo.markAsPristine();
				},
				() => {
					this.isSavingError = true;
				}
			);
		} else if (!this.photo.valid) {
			this.isShowErrors = true;
			this.photo.updateValueAndValidity();
		} else {
			this.photo.markAsPristine();
		}
	}

	isRequiredError(controlName: string): boolean {
		return (
			(this.isShowErrors || this.form.get(controlName).dirty) &&
			this.form.get(controlName).hasError("required")
		);
	}

	isPatternError(controlName: string): boolean {
		return (
			(this.isShowErrors || this.form.get(controlName).dirty) &&
			this.form.get(controlName).hasError("pattern")
		);
	}

	instrumentError(index: number, controlName: string): boolean {
		const control = this.instrumentsFormArray.at(index).get(controlName);

		return (
			(this.isShowErrors || control.dirty) && control.hasError("required")
		);
	}

	dobError(): "required" | "pattern" | undefined {
		const dobValue = this.dobInput?.nativeElement?.value.trim();
		const isEmpty = !dobValue;
		// this.isShowErrors || this.form.get(controlName).dirty
		if (this.about.get("dob").errors) {
			const errorTypes = Object.keys(this.about.get("dob").errors);
			if (isEmpty) {
				return "required";
			} else if (errorTypes.includes("matDatepickerParse")) {
				return "pattern";
			}
		}
		return;
	}

	addLanguage() {
		const languageForm = this.fb.group({
			language: ["", Validators.required],
			level: ["", Validators.required]
		});
		this.spokenLanguages.push(languageForm);
		this.spokenLanguages.updateValueAndValidity();
	}

	addInstrument() {
		const instrumentForm = this.fb.group({
			genre: [[], Validators.required],
			degree: [""],
			instrument: ["", Validators.required],
			certification: [""],
			higherEducation: [""],
			degreeDescription: [""]
		});

		this.instrumentsFormArray.push(instrumentForm);
		this.instrumentsFormArray.updateValueAndValidity();
	}
	deleteInstrument(index: number) {
		this.instrumentsFormArray.removeAt(index);
		this.instrumentsFormArray.markAsDirty();
	}

	addSocial() {
		const socialForm = this.fb.control("");
		this.socials.push(socialForm);
	}

	deleteSocial(index: number) {
		this.socials.removeAt(index);
		this.socials.markAsDirty();
	}

	deleteLanguage(index: number) {
		this.spokenLanguages.removeAt(index);
		this.spokenLanguages.markAsDirty();
	}

	public toggleShowPassEmail() {
		this.isShowPassEmail = !this.isShowPassEmail;
	}

	public toggleShowPass() {
		this.isShowPass = !this.isShowPass;
	}

	public toggleShowNewPass() {
		this.isShowNewPass = !this.isShowNewPass;
	}

	public toggleShowConfPass() {
		this.isShowConfPass = !this.isShowConfPass;
	}

	protected loadInstrumentsAndGenres() {
		this.genres = GENRES.map((genre) => ({
			value: genre.value,
			title: this.translate.instant(genre.title)
		})).sort((a, b) => a.title.localeCompare(b.title));

		this.instruments = INSTRUMENTS.map((inst) => ({
			value: inst.value,
			title: this.translate.instant(inst.title)
		})).sort((a, b) => a.title.localeCompare(b.title));
	}

	// do do: create form element component
	protected initCountries() {
		const currentLang = this.translate.currentLang;
		this.countriesService.getCountries().subscribe((countriesList) => {
			this.countriesList = countriesList.map((country) => ({
				...country,
				title: country[currentLang]
			}));
		});
	}

	protected initForm(): void {
		this.form = this.fb.group({
			account: this.fb.group({
				firstName: [
					"",
					[
						Validators.required,
						Validators.maxLength(TEXT_NAME_MAX_LENGTH)
					]
				],
				lastName: [
					"",
					[
						Validators.required,
						Validators.maxLength(TEXT_NAME_MAX_LENGTH)
					]
				],
				emailGroup: this.fb.group({
					email: [
						"",
						[
							Validators.required,
							Validators.pattern(EMAIL_VALIDATION_REG),
							Validators.maxLength(TEXT_NAME_MAX_LENGTH)
						]
					],
					password: [""]
				}),
				passwordGroup: this.fb.group(
					{
						password: [""],
						newPassword: [
							"",
							[
								Validators.minLength(8),
								Validators.pattern(PASS_VALIDATION_PATTERN)
							]
						],
						confirmPassword: [
							"",
							[Validators.pattern(PASS_VALIDATION_PATTERN)]
						]
					},
					{ validators: confirmPasswordValidator }
				),
				languageId: ["", [Validators.required]],
				timezone: ["", [Validators.required]]
			}),
			about: this.fb.group({
				gender: ["", Validators.required],
				otherGender: [""],
				spokenLanguages: this.fb.array([
					this.fb.group({
						language: [null, Validators.required],
						level: [null, Validators.required]
					})
				]),
				dob: [null, [Validators.required]],
				socials: this.fb.array([""]),
				phoneNumber: [
					null,
					[
						Validators.required,
						Validators.minLength(7),
						Validators.maxLength(15),
						Validators.pattern(/^\+\d*([ ]*\d+)*$/)
					]
				],
				ageConfirmation: [
					null,
					[Validators.requiredTrue, Validators.required]
				],
				country: ["", Validators.required]
			}),
			photo: this.fb.group({
				photo: ["", Validators.required]
			}),
			musicalDetails: this.fb.group({
				instruments: this.fb.array([])
			}),
			profileDescription: this.fb.group({
				teacherIntroduction: ["", Validators.required],
				teachingExperience: ["", Validators.required],
				motivateStudents: ["", Validators.required]
			}),
			setup: this.fb.group({
				device: ["", Validators.required],
				browser: ["", Validators.required],
				uploadMbps: ["", Validators.min(1)],
				externalMic: [false],
				externalCamera: [false],
				secondCamera: [false]
			}),
			students: this.fb.group({
				matchesNumberPreference: [
					null,
					[Validators.required, Validators.min(1)]
				],
				gendersPreference: [[], Validators.required],
				agesPreference: [[], Validators.required],
				skillLevelPreference: [[], Validators.required],
				preferenceComment: [""]
			}),
			payoutDetails: this.fb.group({
				taxNumber: ["", Validators.required],
				taxAddress: ["", Validators.required],
				vatStatus: ["", Validators.required],
				accountName: ["", Validators.required],
				iban: [
					"",
					[Validators.required, Validators.pattern(/^[a-zA-Z0-9 ]*$/)]
				],
				bic: [
					"",
					[Validators.required, Validators.pattern(/^[a-zA-Z0-9-]*$/)]
				]
			}),
			terms: this.fb.group({
				criminalHistoryConfirmation: [null, Validators.requiredTrue],
				termsConfirmation: [null, Validators.requiredTrue]
			})
		});
	}

	// protected initGenderControls(): void {
	// 	this.about.get("gender").valueChanges.subscribe((value) => {
	// 		if (value === "other") {
	// 			this.isOtherGender = true;
	// 		} else {
	// 			this.isOtherGender = false;
	// 		}
	// 	});
	// }

	/**
	 * Ensures that the "noPreference" option is selected only when no other option is selected, and vice versa.
	 * uses a closure to keep track of the previous selections
	 */
	protected initPreferences() {
		const createPreferenceHandler = (control: FormControl) => {
			let prevSelections: string[] = control.value;
			return (value) => {
				if (
					prevSelections.includes("noPreference") &&
					prevSelections.length === 1 &&
					value.length > 1
				) {
					control.setValue(
						value.filter((v) => v !== "noPreference"),
						{ emitEvent: false }
					);
				} else if (value.includes("noPreference") && value.length > 1) {
					control.setValue(["noPreference"], { emitEvent: false });
				}

				prevSelections = control.value;
			};
		};
		[
			this.students.get("agesPreference") as FormControl,
			this.students.get("gendersPreference") as FormControl,
			this.students.get("skillLevelPreference") as FormControl
		].forEach((control) => {
			control.valueChanges.subscribe(createPreferenceHandler(control));
		});
	}

	protected allValues(selectOptions: any[]) {
		return selectOptions.map((option) => option.value || option);
	}

	protected patchForm(profile: Profile): void {
		// this.isOtherGender =
		// 	!this.gendersList.some(
		// 		(option) => option.value === profile.gender
		// 	) && profile.gender !== null;

		const isSocials =
			Array.isArray(profile.socials) && profile?.socials?.length > 0;

		const numberOfLanguages = profile.spokenLanguages?.length || 1;

		for (let i = 1; i < numberOfLanguages; i++) {
			this.addLanguage();
		}
		const numberOfInstruments = profile.instruments?.length || 1;
		for (let i = 0; i < numberOfInstruments; i++) {
			this.addInstrument();
		}

		if (isSocials) {
			for (let i = 1; i < profile.socials.length; i++) {
				this.addSocial();
			}
		}

		const selectedLanguage = this.languages.find(
			(language) => language._id === profile.languageId
		);

		this.form.patchValue(
			{
				account: {
					firstName: profile.firstName,
					lastName: profile.lastName,
					emailGroup: {
						email: profile.email
					},
					languageId: selectedLanguage,
					timezone: profile.timezone
				},
				about: {
					teacherIntroduction: profile.teacherIntroduction,
					gender: this.genderMap(profile.gender),
					spokenLanguages: profile.spokenLanguages,
					dob: profile.dob?.split("T")[0],
					socials: isSocials ? profile.socials : [""],
					phoneNumber: profile.phoneNumber,
					ageConfirmation: profile.ageConfirmation,
					country: profile.country
				},
				photo: {
					photo: profile.photo
				},
				profileDescription: {
					teachingExperience: profile.teachingExperience,
					motivateStudents: profile.motivateStudents,
					teacherIntroduction: profile.teacherIntroduction
				},
				musicalDetails: {
					instruments: profile.instruments
				},
				setup: {
					device: profile.device,
					browser: profile.browser,
					uploadMbps: profile.uploadMbps,
					externalMic: profile.externalMic,
					externalCamera: profile.externalCamera,
					secondCamera: profile.secondCamera
				},
				students: {
					matchesNumberPreference: profile.matchesNumberPreference,
					gendersPreference: profile.gendersPreference?.length
						? this.preferencePatchValue(
								profile.gendersPreference,
								this.gendersList
						  )
						: ["noPreference"],
					agesPreference: profile.agesPreference?.length
						? this.preferencePatchValue(
								profile.agesPreference,
								this.ageGroupsList
						  )
						: ["noPreference"],
					skillLevelPreference: profile.skillLevelPreference?.length
						? this.preferencePatchValue(
								profile.skillLevelPreference,
								this.skillLevelsList
						  )
						: ["noPreference"],
					preferenceComment: profile.preferenceComment,
					// to do: migrate motivateStudents to studentPreferences on the backend
					studentPreferences: profile.studentPreferences
						? profile.studentPreferences
						: profile.motivateStudents
				},
				payoutDetails: {
					taxNumber: profile.taxNumber,
					taxAddress: profile.taxAddress,
					vatStatus: profile.vatStatus,
					accountName: profile.accountName,
					iban: profile.iban,
					bic: profile.bic
				},
				terms: {
					criminalHistoryConfirmation:
						profile.criminalHistoryConfirmation
					// termsConfirmation: profile.termsConfirmation
				}
			},
			{ emitEvent: false }
		);
	}

	protected initAutoSave() {
		[
			this.about,
			this.musicalDetails,
			this.profileDescription,
			this.setup,
			this.students,
			this.payoutDetails,
			this.terms
		].forEach((group: FormGroup) => {
			group.valueChanges.pipe(debounceTime(1000)).subscribe(() => {
				this.onAutoSave(group);
			});
		});
	}

	protected onAutoSave(group: FormGroup): void {
		const data = this.getDirtyAndValidControls(group);
		const dirtyValidControlNames = Object.keys(data);

		if (dirtyValidControlNames.length > 0) {
			if (dirtyValidControlNames.includes("gendersPreference")) {
				data.gendersPreference = this.gendersPreferenceValue;
			}
			if (dirtyValidControlNames.includes("agesPreference")) {
				data.agesPreference = this.agesPreferenceValue;
			}
			if (dirtyValidControlNames.includes("skillLevelPreference")) {
				data.skillLevelPreference = this.skillLevelPreferenceValue;
			}

			this.isSavingError = false;
			this.profileService.save(data).subscribe(
				() => {
					dirtyValidControlNames.forEach((controlName) => {
						group.get(controlName).markAsPristine();
					});
					this.isSavingError = false;
				},
				() => {
					this.isSavingError = true;
				}
			);
		}
	}

	protected initPhotoAutoSave() {
		this.photo.valueChanges.pipe(debounceTime(2000)).subscribe(() => {
			this.savePhoto();
		});
	}

	protected getDirtyAndValidControls(group: FormGroup): {
		[key: string]: any;
	} {
		const dirtyAndValidControls = {};
		for (const control in group.controls) {
			if (
				group.controls[control].dirty &&
				group.controls[control].valid
			) {
				dirtyAndValidControls[control] = group.controls[control].value;
			}
		}
		return dirtyAndValidControls;
	}
	protected loadTimezones(): Promise<any> {
		return new Promise<any>(
			(resolve: (value: any) => void, reject: (reason?: any) => void) => {
				this.profileService.getTimezones().subscribe(
					(timezones: Timezone[]) => {
						this.timezones = timezones;
						resolve(null);
					},
					(error) => {
						reject();
					}
				);
			}
		);
	}

	protected loadLanguages(): Promise<any> {
		return new Promise<any>(
			(resolve: (value: any) => void, reject: (reason?: any) => void) => {
				if (
					!this.languageService.languages ||
					!this.languageService.languages.length ||
					this.languageService.languages.length === 0
				) {
					this.profileService.getLanguages().subscribe(
						(languages: LanguagesList) => {
							this.languageService.languages = this.languages =
								languages.list;
							resolve(null);
						},
						(error) => {
							reject();
						}
					);
				} else {
					this.languages = this.languageService.languages;
					resolve(null);
				}
			}
		);
	}

	public updatePasswordValidity(): void {
		const passInput = this.account.get("passwordGroup.newPassword");
		const hasErrors = passInput?.errors;
		this.isNotEmpty =
			!hasErrors || (hasErrors && !passInput.errors.required);
		this.hasEnoughPassLength =
			!hasErrors ||
			(hasErrors && passInput.errors && !passInput.errors.minlength);
		this.hasNumberOrSpecial =
			!hasErrors ||
			(hasErrors && passInput.errors && !passInput.errors.pattern);
	}

	protected initEmailPasswordControls() {
		this.form
			.get("account.passwordGroup.newPassword")
			.valueChanges.subscribe(() => {
				this.updatePasswordValidity();
			});
		this.form
			.get("account.emailGroup.email")
			.valueChanges.subscribe((value) => {
				this.onEmailChange(value);
			});

		this.form
			.get("account.passwordGroup.newPassword")
			.valueChanges.subscribe((value) => {
				this.onPasswordChange(value);
			});
		// this.form.get("account.languageId").valueChanges.subscribe((value) => {
		// 	this.onLanguageChange(value);
		// });
	}

	// needs cleaning - old code
	protected onPasswordChange(value): void {
		if (value) {
			if (this.toggleEmailField) this.toggleEmailField(false);

			this.form
				.get("account.passwordGroup.password")
				.setValidators([Validators.required]);
		} else {
			this.toggleEmailField(true);
			this.form.get("account.passwordGroup.password").setValidators(null);

			this.form
				.get("account.passwordGroup.password")
				.updateValueAndValidity({ emitEvent: false });
		}
	}

	protected togglePasswordFields(enable: boolean): void {
		const passwordControls = [
			this.form.get("account.passwordGroup.password"),
			this.form.get("account.passwordGroup.newPassword"),
			this.form.get("account.passwordGroup.confirmPassword")
		];
		if (enable) {
			passwordControls.forEach((control) => {
				control.enable({ emitEvent: false });
			});
		} else {
			passwordControls.forEach((control) => {
				control.reset("", { emitEvent: false });
				control.setErrors(null, { emitEvent: false });
				control.removeValidators(Validators.required);
				control.disable({ emitEvent: false });
			});
		}
		this.form.get("account.passwordGroup").updateValueAndValidity({
			emitEvent: false
		});
	}

	protected onEmailChange(value): void {
		const prevEmail = this.authenticationService.profileInfo().email;
		if (value && value !== prevEmail) {
			this.togglePasswordFields(false);
			this.displayPassEmail = true;
			this.form
				.get("account.emailGroup.password")
				.setValidators([Validators.required]);
		} else {
			this.form.get("account.emailGroup.password").setValidators(null);
			this.displayPassEmail = false;
			this.togglePasswordFields(true);
		}

		this.form
			.get("account.emailGroup.password")
			.updateValueAndValidity({ emitEvent: false });
	}

	// protected onLanguageChange(value) {
	// 	const selectedLanguage = this.languages.find(
	// 		(language: Language) => language._id === value._id
	// 	);
	// 	if (selectedLanguage) {
	// 		console.log(selectedLanguage);
	// 		const selectedLanguageIso = selectedLanguage.iso;
	// 		localStorage.setItem("language", selectedLanguageIso);
	// 		this.translate.use(selectedLanguageIso);
	// 	}
	// }

	protected toggleEmailField(enable: boolean): void {
		const prevEmail = this.authenticationService.profileInfo().email;
		const emailControls = [
			this.form.get("account.emailGroup.email"),
			this.form.get("account.emailGroup.password")
		];
		if (enable) {
			emailControls.forEach((control) => {
				control.enable({ emitEvent: false });
			});
		} else {
			emailControls.forEach((control) => {
				control.reset(prevEmail, { emitEvent: false });
				control.setErrors(null, { emitEvent: false });
				control.disable({ emitEvent: false });
			});
		}
		this.form.get("account.emailGroup").updateValueAndValidity({
			emitEvent: false
		});
	}

	protected async savePassword() {
		try {
			const { password, newPassword } = this.form.get(
				"account.passwordGroup"
			).value;
			await this.profileService
				.save({ password: password, newPassword: newPassword })
				.toPromise();
			this.form.get("account.passwordGroup").markAsPristine();
			this.passwordUpdated = true;
		} catch (err) {
			this.handlePasswordError();
		}
	}

	protected async saveEmail() {
		try {
			const value = this.form.get("account.emailGroup").value;
			await this.profileService.save(value).toPromise();
			this.form.get("account.emailGroup").markAsPristine();
		} catch (err) {
			this.handleEmailError(err);
		}
	}

	protected handleEmailError(err) {
		const description = !!err.error ? err.error.description : null;
		const status = !!err.error ? err.error.status : null;
		const message = !!err.error ? err.error.message : null;
		let errorField = "";
		errorField =
			!errorField && description?.includes("Email has wrong format!")
				? "email"
				: errorField;
		errorField =
			(!errorField && description.includes("Wrong password!")) ||
			description === "Cannot update user profile"
				? "password"
				: errorField;
		if (!!errorField) {
			this.account
				.get("emailGroup." + errorField)
				.setErrors({ is: true });
		} else {
			errorField =
				description.includes("Data consistency problem") &&
				status === 409 &&
				message === "Conflict"
					? "email"
					: errorField;
			if (!!errorField) {
				this.account
					.get("emailGroup.email")
					.setErrors({ emailExists: true });
			}
		}
	}

	protected handlePasswordError() {
		this.account
			.get("passwordGroup.password")
			.setErrors({ is: true }, { emitEvent: false });
	}

	/**
	 * displays 'noPreference' as the selected value when all options are selected
	 *
	 */
	protected preferencePatchValue(
		profileValue: string[],
		options: (string | { value: string })[]
	): string[] {
		const isNoPreference = areArrayContentsSame(
			profileValue,
			this.allValues(options)
		);

		return isNoPreference ? ["noPreference"] : profileValue;
	}

	protected genderMap(value: any): Gender | "" {
		if (!value) {
			return "";
		}

		return [
			"female",
			"male",
			"non-binary",
			"transgender",
			"intersex",
			"other",
			"prefer-not"
		].includes(value)
			? value
			: "other";
	}
}

<div class="mobile-table-container">
	<table mat-table [dataSource]="dataSource" matSort (matSortChange)="onSortChange($event)" class="mobile-friendly-table">
		@for (column of columns; track column.key) {
		<ng-container [matColumnDef]="column.key">
			<th mat-header-cell *matHeaderCellDef [mat-sort-header]="column.isSortable ? '' : null">
				{{ column.label | translate }}
			</th>
			<td mat-cell *matCellDef="let row">
				<div class="mobile-cell-content">
					<span class="mobile-cell-value">
						<ng-container *ngIf="isDate(row[column.key]); else translatePipe">
							{{ row[column.key] | date : "dd.MM.yyyy - H:mm" }}
						</ng-container>
						<ng-template #translatePipe>
							{{ row[column.key] }}
						</ng-template>
					</span>
				</div>
			</td>
		</ng-container>
		} @if(hasMenu){
		<ng-container matColumnDef="actions">
			<th mat-header-cell *matHeaderCellDef></th>
			<td mat-cell *matCellDef="let row">
				<button mat-icon-button [matMenuTriggerFor]="menu" aria-haspopup="menu" aria-expanded="false" class="mobile-menu-button">
					<mat-icon>keyboard_arrow_down</mat-icon>
				</button>
				<mat-menu #menu="matMenu">
					@for(action of actions; track action.label){
					<button mat-menu-item (click)="onActionClick(action, row)">
						<mat-icon aria-hidden="true">{{ action.icon }}</mat-icon>
						<span>{{ action.label }}</span>
					</button>
					}
				</mat-menu>
			</td>
		</ng-container>
		}

		<tr mat-header-row *matHeaderRowDef="displayedColumns" class="hidden-mobile"></tr>
		<tr mat-row *matRowDef="let row; columns: displayedColumns" class="mobile-row"></tr>
	</table>
</div>

@if(hasPaginator){
<mat-paginator
	class="paginator"
	[length]="totalItems"
	[pageSizeOptions]="pageSizeOptions"
	[pageSize]="pageSize"
	(page)="onPageChange($event)"
>
</mat-paginator>
}

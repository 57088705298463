<form #userForm='ngForm'>
  <div class="school-title">
    <span *ngIf="!isEditForm">Create User</span>
    <span *ngIf="isEditForm">Edit User</span>
  </div>
  <div>
    <span>First Name</span>
    <input type="text" name="firstName" ngModel required maxlength="60" [pattern]="disallowTrailingSpaces">
  </div>
  <div>
    <span>Last Name</span>
    <input type="text" name="lastName" ngModel required maxlength="60" [pattern]="disallowTrailingSpaces">
  </div>
  <div>
    <span>Timezone</span>
    <select name="timeZone" ngModel required>
      <option value="">Please select</option>
      <option
        *ngFor="let timezone of timezones"
        [value]="timezone.value">{{ timezone.text }}</option>
    </select>
  </div>
  <div>
    <span>Email address</span>
    <input type="email" name="email" ngModel required maxlength="60">
  </div>
  <div>
    <span>Password</span>
    <input type="password" name="password" ngModel maxlength="60">
  </div>
  <div>
    <span>Language</span>
    <select name="language" ngModel required>
      <!-- <option value="">Please select</option> -->
      <option
        *ngFor="let language of languages"
        [value]="language._id"
        [selected]="language._id === userEntry.language">{{ language.name }}</option>
    </select>
  </div>
  <div class="controls">
    <button class="blue-ui-button" type="submit" [disabled]="userForm.invalid" (click)="onSubmit()">Save</button>
    <button class="default-ui-button transparent-ui-border-button blue cancel-button" (click)="onClose()">Cancel</button>
  </div>
</form>

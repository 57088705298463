import { Component, EventEmitter, Input, Output } from "@angular/core";
import { CommonModule } from '@angular/common';
import { MediaDevicesService } from "src/app/lib-media-devices/services/media-devices.service";
import { PopupService } from "src/app/services/popup/popup.service";
import { UntypedFormGroup,UntypedFormControl } from '@angular/forms'; 
import { MatRadioModule } from '@angular/material/radio';
import { MatIconModule } from '@angular/material/icon';
import { debounceTime } from 'rxjs/operators';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RtcStreamViewModel} from '../../components/call-room/service/viev-model/rtc-stream-view-model';
import {
    MatSlideToggleModule,
  } from '@angular/material/slide-toggle';
  import { EasyRtcService } from "src/app/lib-rtc/services/easy-rtc.service";
import {
	MediaProperies,
	MediaProperiesUtil,
} from "./setting-popup.interface";
import { UsersService } from "../../services/users.service";

import { TranslateModule } from '@ngx-translate/core';


@Component({
	selector: "app-video-menu",
	templateUrl: "./video-menu.component.html",
	styleUrls: ["./video-menu.component.scss"],
    standalone: true,
    imports: [MatRadioModule,FormsModule,ReactiveFormsModule,MatIconModule,CommonModule,MatSlideToggleModule,TranslateModule]
})
export class VideoMenuComponent {
	@Output() public openVideoSettings = new EventEmitter<void>();
	@Output() public toggleCamera = new EventEmitter<void>();
	@Input() public isCamera: boolean;
	public videoInputDevices: MediaDeviceInfo[];
    public selectedSecondaryVideoInputDeviceId: string;
    public formGroup: UntypedFormGroup;
    private currentMediaProperies: MediaProperies | null = null;
    public secondCamera = {
		secondCamera: new UntypedFormControl(null, []),
	};
    private mediaProperties: MediaProperies;


    public support = {
		// Audio
		isSampleRate: false,
		isEchoCancellation: false,
		isNoiseSuppression: false,
		isAutoGainControl: false,
		isStereoAudio: false,
		// Video
		isFrameRate: false,
	};


	public videoControls = {
		inputDeviceId: new UntypedFormControl(null, []), // videoInputDevices
	};


    constructor(
        private usersService: UsersService,
        private mediaDevicesService: MediaDevicesService,
        protected popupService: PopupService,
        private easyRtcService: EasyRtcService,
        private rtcStreamViewModel: RtcStreamViewModel
    ) {
        this.formGroup = new UntypedFormGroup({
            video: new UntypedFormGroup(this.videoControls),
            secondCamera: new UntypedFormGroup(this.secondCamera),
        })
    }


	async ngOnInit() {

        this.mediaProperties =
        MediaProperiesUtil.readMediaProperiesFromStorage();

		const deviceIds: MediaDeviceInfo[] =
			await this.mediaDevicesService.enumerateDevices(true);
		this.videoInputDevices =
			this.mediaDevicesService.getDeviceVideoInput(deviceIds);
        const mediaProperies = (this.currentMediaProperies =
			MediaProperiesUtil.readMediaProperiesFromStorage());

            this.formGroup.controls?.video?.valueChanges
            .pipe(debounceTime(500))
            .subscribe((value) => {
                this.registerSettingsChange()
            });
            this.prepareVideo(mediaProperies);
            this.formGroup.controls?.secondCamera?.valueChanges.subscribe(
                async (value) => {
                    if (value.secondCamera == "none") {
                        this.removeSecondCamera("secondStream");
                    } else {
                        this.removeSecondCamera("secondStream");
                        if (value !== null || value !== "none") {
    
                            this.easyRtcService.setVideoSource(value.secondCamera);
    
                            const stream =
                                await this.easyRtcService.initMediaSourceTwo();
    
                            this.usersService.selfUser.mediaStreamSecondVideo =
                                stream;
    
                            const self = this.usersService
                                .getConnectedUsers()
                                .filter((obj) => obj.self === true);
    
                            const externalConnectedUsers = this.usersService
                                .getConnectedUsersWithRecordingBot()
                                .filter((obj) => obj.self === false);
    
                            const array =
                                EasyRtcService.getRoomOccupantsAsArray("default");
    
                            const selfId = EasyRtcService.myEasyrtcid();
    
                            self[0].secondStreamActive = true;
    
                            const filteredArray = array.filter(
                                (item) => item !== selfId
                            );
    
                            if (externalConnectedUsers.length > 0) {
                                for (let i = 0; i < filteredArray.length; i++) {
                                    EasyRtcService.addStreamToCall(
                                        filteredArray[i],
                                        "secondStream",
                                        (user, streamName) => {
                                            console.log(user, streamName);
                                        }
                                    );
                                }
                            }
                        } else {
                            this.removeSecondCamera("secondStream");
                        }
                    }
                }
            );


	}

	public clickOpenVideoSettings() {
		this.openVideoSettings.emit();
	}

	public clickToggleCamera() {
		this.toggleCamera.emit();
	}

	public selectDevice(deviceId: string) {
		console.log(deviceId);
    }


	private createMediaProperies(): MediaProperies {
		const audioEchoCancellation = false;
		return {
			audioInpDev: this.mediaProperties.audioInpDev,
			audioOutDev: this.mediaProperties.audioOutDev,
			audioSampleRate: this.mediaProperties.audioSampleRate,
			audioEchoCancellation,
			audioNoiseSuppression: this.mediaProperties.audioNoiseSuppression,
			audioAutoGainControl: this.mediaProperties.audioAutoGainControl,
			audioChannelCount:this.mediaProperties.audioChannelCount,
			videoInpDev: this.videoControls.inputDeviceId.value,
			videoWidth:  this.mediaProperties.videoWidth,
			videoHeight: this.mediaProperties.videoHeight,
			videoFrameRate: this.mediaProperties.videoFrameRate,
			videoIsReflection: this.mediaProperties.videoIsReflection,
			secondCameraInputDevice: this.mediaProperties.secondCameraInputDevice,
			langId: this.mediaProperties.langId,
			bgColor: this.mediaProperties.bgColor,
			videoResolution: this.mediaProperties.videoResolution,
		};
	}


    async registerSettingsChange() {
		const mediaProperties = this.createMediaProperies();
		MediaProperiesUtil.writeMediaProperiesToStorage(mediaProperties);
		const mediaStream = EasyRtcService.getLocalStream();
		if (!!mediaStream) {
			await this.easyRtcService.stopMediaStreamTrack(
				mediaStream.getTracks()
			);
		}
		this.currentMediaProperies = mediaProperties;
		const constraints =
			MediaProperiesUtil.createConstraints(mediaProperties);
		await this.rtcStreamViewModel.changeSelectedStream(constraints);
	}


    removeSecondCamera(streamName) {
		let array = EasyRtcService.getRoomOccupantsAsArray("default");
		const stringToRemove = EasyRtcService.myEasyrtcid();
		const filteredArray = array.filter((item) => item !== stringToRemove);
		EasyRtcService.closeLocalMediaStream(streamName);
        filteredArray.forEach((userId) => {
            EasyRtcService.removeStreamFromCall(userId, streamName);
          });
		this.usersService.selfUser.mediaStreamSecondVideo = null;
		this.usersService.selfUser.secondStreamActive = false;
	}

    

    private prepareVideo(mediaProperiesPrm: Partial<MediaProperies>): void {
		const mediaProperies: Partial<MediaProperies> = mediaProperiesPrm || {};

		// videoDeviceInfo
		if (this.videoInputDevices.length > 0) {
			const videoDeviceInfo =
				this.mediaDevicesService.findMediaDeviceInfo(
					this.videoInputDevices,
					mediaProperies.videoInpDev
				);
			this.videoControls.inputDeviceId.setValue(
				videoDeviceInfo.deviceId,
				{ emitEvent: false }
			);
		} else {
			this.videoControls.inputDeviceId.disable({ emitEvent: false });
		}

		}


        



}

<div class="row-container-start-new">

    <div *ngIf="blockedDevices" class="permission-access-container">
        <div class="unblockPermissions">
            <p class="title">{{ 'pre-call-settings.blocked-devices' | translate }}</p>
            <a class="help-link" target="_blank" href="{{ 'pre-call-settings.how-to-link' | translate }}">{{
                'pre-call-settings.how-to-fix' | translate }}</a>
            <button class="new-button" (click)="accessPermission()">
                {{ 'pre-call-settings.try-again-button' | translate }}
            </button>
        </div>
    </div>

    <div *ngIf="noDevices" class="permission-access-container">
        <div class="unblockPermissions">
            <p class="notfound-error">
                {{ 'pre-call-settings.noDeviceFound' | translate }}
        </div>
    </div>

    <app-loading [isBgParent]="true" *ngIf="!noDevices && !blockedDevices"></app-loading>

</div>
import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ButtonType } from "../components/call-room/data/ButtonType";
import { PopupService } from "../services/popup/popup.service";
import { AvailabilityService } from "../services/availability/availability.service";
import { TranslateService } from "@ngx-translate/core";
import { ProfileService } from "../profile/profile.service";
import { flatMap } from "rxjs/internal/operators";
import { Profile } from "../types/profile.types";
import { DateAdapter } from "@angular/material/core";
@Component({
	selector: "app-date-override",
	templateUrl: "./date-override.component.html",
	styleUrls: ["./date-override.component.scss"]
})
export class DateOverrideComponent implements OnInit {
	date = new Date();
	minDate = new Date();
	public dateControl = new FormControl();
	public enableMeridian = false;
	public stepHour = 1;
	public stepMinute = 15;
	@ViewChild("picker") picker: any;
	@Input() userId;
	@Input() timeZone;
	// @Input OverideData Array is used to display the override times in the view
	public overrideData = [];
	public overrideFilterDates;
	public filterOverrides;
	needRemoveOverrideItems = [];
	needAddOverrideItems = [];
	@Input({ required: true }) lessonType: string;
	selectedLanguage: string;

	public formGroup = new FormGroup({
		date: new FormControl(null, [Validators.required]),
		date2: new FormControl(null, [Validators.required])
	});

	constructor(
		private popupService: PopupService,
		private availabilityService: AvailabilityService,
		private translateService: TranslateService,
		private profile: ProfileService,
		private dateAdapter: DateAdapter<Date>
	) {}

	dateFilter = (date: Date | null): boolean => {
		return !this.isBlockedDate(date, this.overrideFilterDates);
	};

	private isBlockedDate(date: Date | null, blockedDates: Date[]): boolean {
		return (
			date !== null &&
			date !== undefined &&
			blockedDates.some(
				(blockedDate) =>
					date.toISOString() === blockedDate.toISOString()
			)
		);
	}

	ngOnInit() {
		this.selectedLanguage = this.translateService.currentLang;
		this.initOverrides();
		this.getOverrideDates();
		this.dateAdapter.getFirstDayOfWeek = () => {
			return 1;
		};
		this.dateControl.valueChanges.subscribe((date) => {
			if (date == null) {
				return;
			}
			// convert date to YYYY.MM.DD
			const offset = date.getTimezoneOffset();
			date = new Date(date.getTime() - offset * 60 * 1000);
			const localDate = date.toISOString().split("T")[0];
			const startTime = new Date(date.getTime());
			const endDate = date;
			endDate.setMinutes(endDate.getMinutes() + 30);
			const endTime = new Date(endDate.getTime());
			const start = startTime.toUTCString();
			const end = endTime.toUTCString();

			// First step to create date for the override data (Next step in the add-override.component.ts)

			const data = {
				startTime: new Date(start).toISOString(),
				endTime: new Date(end).toISOString(),
				timeZone: this.timeZone,
				date: localDate
			};

			this.popupService.openAddOverrideDialog(data, [
				{
					type: ButtonType.CANCEL,
					text: "availability.cancel-override-btn"
				},

				{
					type: ButtonType.OK,
					text: "availability.add-override-btn-modal",
					callBack: ({ dialog, dates }) => {
						const overrideData: [] = dates;

						for (date of overrideData) {
							date.overrideDate = localDate;
							date.type = this.lessonType;
						}

						// HTTP request to add the new override time to the database

						const postObject = {
							override: overrideData
						};

						this.availabilityService
							.addOverride(this.userId, postObject)
							.subscribe((data) => {
								// merge the incoming data, then merge with the existing data

								this.mergeOverrides(data).forEach((entry) => {
									this.overrideData.push(entry);

									// sort the data by date

									this.overrideData.sort((a, b) => {
										const dateA = new Date(
											a.overrideDate
										).getTime();
										const dateB = new Date(
											b.overrideDate
										).getTime();
										return dateA - dateB;
									});
								});
							});

						const modal = dialog.getDialogById(
							"add-override-dialog"
						);
						this.dateControl.patchValue(null);
						this.initOverrides();
						this.needAddOverrideItems = [];
						modal.close();
					}
				}
			]);
		});
	}

	getOverrideDates() {
		this.profile
			.get()
			.pipe(
				flatMap((profile: Profile) => {
					this.userId = profile.userId;
					return this.availabilityService.getOverrides(
						this.userId,
						this.lessonType
					);
				})
			)
			.subscribe((overrideData) => {
				overrideData.forEach((override) => {
					override.startTime =
						override.startTime == null
							? "00:00"
							: override.startTime;
					override.endTime =
						override.endTime == null ? "23:30" : override.endTime;
				});
				const mergedData = this.mergeOverrides(overrideData);

				// sort the data by date

				mergedData.sort((a, b) => {
					const dateA = new Date(a.overrideDate).getTime();
					const dateB = new Date(b.overrideDate).getTime();
					return dateA - dateB;
				});

				this.overrideData = mergedData;
			});
	}

	convertDefaultTime(time): string {
		const timeHours = time?.split(":")[0];
		const timeMinutes = time?.split(":")[1];
		const date = new Date();
		date.setUTCHours(Number(timeHours), Number(timeMinutes), 0);
		return new Date(date.getTime()).toLocaleString("en-US", {
			timeZone: this.timeZone,
			hour: "2-digit",
			minute: "2-digit",
			hour12: false
		});
	}

	initOverrides() {
		this.profile
			.get()
			.pipe(
				flatMap((profile: Profile) => {
					this.userId = profile.userId;
					return this.availabilityService.getOverrides(
						this.userId,
						this.lessonType
					);
				})
			)
			.subscribe((overrideData) => {
				const overrideDatesArray = overrideData.map(
					(item) => item.overrideDate
				);
				this.overrideFilterDates = overrideDatesArray;
				const timestampArray = overrideDatesArray.map((item) => {
					const dateObject = new Date(item);
					dateObject.setHours(0, 0, 0, 0);
					return dateObject;
				});
				this.overrideFilterDates = timestampArray;
			});
	}

	convertOverrideTime(date: string, start: string, end: string): object {
		const fullDate = date;
		const startTime = start;
		const endTime = end;
		const formattedDate = new Date(fullDate);
		const selectedLanguage = this.translateService.currentLang;
		const dateLocal = selectedLanguage === "eng" ? "en-US" : "de-DE";
		const options: Intl.DateTimeFormatOptions = {
			weekday: "long",
			day: "numeric",
			month: "long"
		};
		const timezoneDateMinusOffset = new Date(
			formattedDate.getTime() - formattedDate.getTimezoneOffset() * 60000
		).toLocaleDateString(dateLocal, options);

		return {
			date: timezoneDateMinusOffset,
			time: `${startTime.substring(0, 5)} – ${endTime.substring(0, 5)}`
		};
	}

	open() {
		this.picker.open();
	}

	delete(override, index: number) {
		override.overrideTimes.forEach((timeSlot) => {
			if (timeSlot.overrideId) {
				this.needRemoveOverrideItems.push(timeSlot.overrideId);
				// HTTP Request to delete the override time from the database
				this.availabilityService
					.deleteOverride(timeSlot.overrideId)
					.subscribe(() => {});
			} else {
				return;
			}
		});
		this.needRemoveOverrideItems = [];
		this.overrideData.splice(index, 1);
		this.initOverrides();
	}

	datePickerOnOpen() {
		this.picker._hideTime = true;
	}

	mergeOverrides(overrideData) {
		// merge overrides with the same day

		const mergedData = [];
		overrideData.forEach((entry) => {
			const existingEntry = mergedData.find(
				(item) =>
					item.day === entry.day &&
					item.overrideDate === entry.overrideDate
			);

			if (existingEntry) {
				existingEntry.overrideTimes.push({
					startTime: entry.startTime,
					endTime: entry.endTime,
					overrideId: entry.id
				});
			} else {
				mergedData.push({
					day: entry.day,
					overrideDate: entry.overrideDate,
					overrideTimes: [
						{
							overrideId: entry.id,
							startTime: entry.startTime,
							endTime: entry.endTime
						}
					],
					notice: entry.notice,
					bookingLimit: entry.bookingLimit,
					type: entry.type
				});
			}
		});

		// remove seconds from the time

		const formatTime = (time) => time.slice(0, 5);

		// Remove seconds from the time
		mergedData.forEach((entry) => {
			entry.overrideTimes = entry.overrideTimes.map((time) => ({
				overrideId: time.overrideId,
				startTime: time.startTime
					? formatTime(time.startTime)
					: "00:00",
				endTime: time.endTime ? formatTime(time.endTime) : "23:30"
			}));
		});

		// Format the date to show the formatted day

		mergedData.forEach((entry) => {
			const date = new Date(entry.overrideDate);
			const dateLocal =
				this.selectedLanguage === "eng" ? "en-US" : "de-DE";
			const options: Intl.DateTimeFormatOptions = {
				weekday: "long",
				day: "numeric",
				month: "long"
			};
			const timezoneDateMinusOffset = new Date(
				date.getTime() - date.getTimezoneOffset() * 60000
			).toLocaleDateString(dateLocal, options);
			entry.formatDate = timezoneDateMinusOffset;
		});

		return mergedData;
	}
}

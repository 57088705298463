export class SoundPlayer {
  private audioContext: AudioContext;
  private audioBuffer: AudioBuffer;
  private audioBufferSourceNode: AudioBufferSourceNode;
  private masterGainNode: GainNode;

  constructor(audioContext: AudioContext) {
    this.audioContext = audioContext;
    this.masterGainNode = this.audioContext.createGain();
  }

  public setAudioBuffer(audioBuffer: AudioBuffer): void {
    this.audioBuffer = audioBuffer;
  }

  public play(when?: number, offset?: number, duration?: number): void {
    if (!!this.audioBuffer) {
      this.audioBufferSourceNode = this.audioContext.createBufferSource();
      this.audioBufferSourceNode.buffer = this.audioBuffer;
      this.audioBufferSourceNode.connect(this.masterGainNode);
      this.masterGainNode.connect(this.audioContext.destination);
      const startWhen = !!when ? this.audioContext.currentTime + when : when;
      this.audioBufferSourceNode.start(startWhen, offset, duration);
    }
  }

  public stop(when?: number): void {
    if (!!this.audioBufferSourceNode) {
      this.audioBufferSourceNode.stop(when);
    }
  }

  public setVolume(value: number): void {
    this.masterGainNode.gain.value = value;
  }

  public OnDestroy(): void {
    this.audioContext = null;
  }
}

import { Injectable } from "@angular/core";
import { Observable, Subject, BehaviorSubject } from "rxjs";
import {
	CHAT,
	SEND,
	ROOM,
    CLEAR_CHAT,
	WebSocketService,
} from "../../../services/ws/web-socket.service";
import { LoggingService } from "../../../services/logging/logging.service";
import { logger } from "src/app/lib-core/logger";
import { AuthenticationService } from "src/app/services/authentication/authentication.service";
import { BotService } from "../../../services/bot/bot.service";
import { PopupService } from "src/app/services/popup/popup.service";
import { TranslateService } from "@ngx-translate/core";
import { ButtonType } from "../../call-room/data/ButtonType";

@Injectable({
	providedIn: "root",
})
export class ChatService {
	chatMessages: any[] = [];

	protected messageColors = {};
	private maxChatPages: number = 1;
	private currentPage: number = 0;
	private roomID: string;
	private messages$: Subject<any[]> = new Subject<any[]>();
	public displayChat$: BehaviorSubject<boolean> =
		new BehaviorSubject<boolean>(false);
	private scroll$: Subject<boolean> = new Subject<boolean>();
	private hasUnreadMessages$: Subject<boolean> = new Subject<boolean>();
	private _chatInputStatus: boolean;

	public get chatInputStatus() {
		return this._chatInputStatus;
	}

	public set chatInputStatus(val) {
		this._chatInputStatus = val;
	}

	constructor(
		protected websocket: WebSocketService,
		private loggingService: LoggingService,
		private authenticationService: AuthenticationService,
		private botService: BotService,
		private popupService: PopupService,
		private translateService: TranslateService
	) {
		if (!BotService.isBot) {
			this.websocket.subscribe(ROOM, this.onSocketEvent);
		}
	}

	private onSocketEvent = (data) => {
		// logger.log('!!! onSocketEvent 2 !!!', data);
		// console.log('onSocketEvent ChatService', data);
		switch (data[0]._event) {
			case CHAT:
				this.chatHistoryHandler(data[0]);
				break;
			case SEND:
				this.messageHandler(data[0]);
				break;
			default:
				if (data[0].type === "Error" || data[0].status === 403) {
					logger.log("Chat registered an error", data[0]);
				} else {
					// TODO: add view case and update permisions
					// logger.log('Chat service default socket event', data[0]._event, 'with data', data[0]);
				}
		}
	};

	private generateMessageColor(): string {
		const red = 60 + 6 * ((Math.random() * 180) % 30);
		const green = 60 + 6 * ((Math.random() * 180) % 30);
		const blue = 60 + 6 * ((Math.random() * 180) % 30);
		return "rgb(" + red + ", " + green + ", " + blue + ")";
	}

	joinHandler(data): void {
		if (
			data.type !== "Error" &&
			data.status !== 403 &&
			this.currentPage < this.maxChatPages
			// && !!this.authenticationService.isApprover()
		) {
			this.requestNextChatPage();
		}
	}

    clearChatData() {
        this.websocket.dispatch(CLEAR_CHAT, {});
    }

	clearChatMessages(): void {
		this.chatMessages = [];
		this.currentPage = 0;
		this.maxChatPages = 1;
		this.messages$.next(this.chatMessages);
	}

	getDisplayChat$(): BehaviorSubject<boolean> {
		return this.displayChat$;
	}

	getUnreadMessages$(): Subject<boolean> {
		return this.hasUnreadMessages$;
	}

	chatMessages$(): Observable<any[]> {
		return this.messages$.asObservable();
	}

	chatScroll$(): Observable<boolean> {
		return this.scroll$.asObservable();
	}

	getCurrentPage(): number {
		return this.currentPage;
	}

	getMaxPages(): number {
		return this.maxChatPages;
	}

	setRoomID(roomid) {
		this.roomID = roomid;
	}

	getRoomID(): string {
		return this.roomID;
	}

	requestNextChatPage = (): void => {
		if (!BotService.isBot) {
			this.websocket.dispatch(CHAT, { page: this.currentPage + 1 });
		}
	};

	sendSocketMessage(chatMessage): void {
		logger.log("sendSocketMessage ", chatMessage);
		this.websocket.dispatch(SEND, {
			room: this.roomID,
			message: chatMessage,
			agent: this.loggingService.getAgent(),
			device: this.loggingService.getDevice(),
			camera: this.loggingService.getCamera(),
			microphone: this.loggingService.getMicrophone(),
		});
	}

	// Taken from open source: https://gist.github.com/kiennt2/c9a489369562c424c793b8883b98802e
	linkify(inputText) {
		// URLs starting with http://, https://, or ftp://
		const replacePattern1 =
			/(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;*]*[-A-Z0-9+&@#\/%=~_|])/gim;
		let replacedText = inputText.replace(
			replacePattern1,
			'<a class="chat-link" class="chat-link" href="$1" target="_blank">$1</a>'
		);

		// URLs starting with www. (without // before it, or it'd re-link the ones done above)
		const replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
		replacedText = replacedText.replace(
			replacePattern2,
			'$1<a class="chat-link" href="http://$2" target="_blank">$2</a>'
		);

		// Change email addresses to mailto:: links
		const replacePattern3 = /(\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,6})/gim;
		replacedText = replacedText.replace(
			replacePattern3,
			'<a class="chat-link" class="chat-link" href="mailto:$1">$1</a>'
		);

		return replacedText;
	}

	messageHandler(data): void {
		logger.log("[caht-service] messageHandler ", data);
		const messages = this.parseMessage(data);
		if (!this.authenticationService.accessToken) {
			messages.map((message) => this.saveMessageToHistory(message));
		}
		this.applyMessages(messages);
	}

	chatHistoryHandler(data): void {
		this.maxChatPages = data.pages;
		if (this.currentPage < this.maxChatPages) {
			let messages = [];
			data.list.reverse().forEach((message) => {
				messages = messages.concat(this.parseMessage(message));
			});
			this.applyMessages(messages);
			this.currentPage++;
		}
	}
	private parseMessage(message: any): object[] {
		const returnMessages = [];
		if (!this.messageColors[message.name]) {
			this.messageColors[message.name] = this.generateMessageColor();
		}
		const files = message.files || [];
		if (message.message) {
			returnMessages.push({
				name: message.name,
				date: message.created,
				type: "text",
				text: this.linkify(message.message),
				color: this.messageColors[message.name],
			});
		}
		files.forEach(
			(file: { url: string; extension: string; name: string }) => {
				// files parse
				const fileUrl = file.url.replace("http:", "https:");
				const isImageType = [
					"image/png",
					"image/jpeg",
					"image/jpg",
				].includes(file.extension);
				returnMessages.push({
					name: message.name,
					date: message.created,
					text: isImageType
						? this.toImage(fileUrl, file.name)
						: this.toFileLink(fileUrl, file.extension, file.name),
					type: file.extension,
					color: this.messageColors[message.name],
				});
			}
		);
		return returnMessages;
	}
	private toImage(text: string, name: string): string {
		// need to save file name somehow
		// return `<img width=100% src="${text}">` + `<span>${name}</span>`;
		return `<img width=100% src="${text}">`;
	}
	private toFileLink(text: string, type: string, name: string): string {
		type = type.replace("application/", "");
		return (
			`<a class="chat-link" target="_blank" href="${text}" type="data:text/plain;charset=utf-8" download="${name}">` +
			`<img width=40px src="https://i.pinimg.com/originals/7f/d2/e4/7fd2e46b2da9819e667fb75caf475cf7.png"/>` +
			`<span>${name}</span>` +
			`</a>`
		);
	}
	private applyMessages(messages: any[], unread = true) {
		this.chatMessages = this.chatMessages.concat(messages);
		this.messages$.next(this.chatMessages);
		this.hasUnreadMessages$.next(unread);
		this.scroll$.next(true);
	}
	public loadMessagesFromHistory() {
		this.applyMessages(this.getMessagesHistory(), false);
	}
	private getMessagesHistory(): any[] {
		let messages = [];
		try {
			const savedMessages = window.sessionStorage.getItem(
				`chat_history_${this.roomID}`
			);
			if (!!savedMessages) {
				messages = JSON.parse(savedMessages);
			}
		} catch (error) {
			console.error(error);
		}
		return messages;
	}
	private saveMessageToHistory(message: any) {
		const savedMessages = this.getMessagesHistory();
		window.sessionStorage.setItem(
			`chat_history_${this.roomID}`,
			JSON.stringify([...savedMessages, message])
		);
	}

	public openDeleteChatPopup() {
		const message = this.translateService.instant(
			"popUp.deleteChatConfirmation"
		);
		const buttons = [
			{
				type: ButtonType.OK,
				text: this.translateService.instant("popUp.delete"),
				callBack: () => {
					this.clearChatData();
					this.clearChatMessages();
				},
			},
			{
				type: ButtonType.CANCEL,
				text: this.translateService.instant("popUp.cancel"),
			},
		];

		this.popupService.openCustomMessagePopup(message, buttons);
	}
}

<img class="sirius-logo" src="/assets/icons/sirius/Sirius.png" alt="Sirius">


<img class="sirius-music-gfx" src="/assets/icons/sirius/redesign/sirius-music.png" alt="Sirius">
<img class="sirius-jupiter-gfx" src="/assets/icons/sirius/redesign/sirius-planet.png" alt="Sirius">
<img class="sirius-mars-gfx" src="/assets/icons/sirius/redesign/sirius-planeten-01.png" alt="Sirius">
<img class="sirius-blueplanet-gfx" src="/assets/icons/sirius/redesign/sirius-planeten-02.png" alt="Sirius">
<img class="sirius-bluebigplanet-gfx" src="/assets/icons/sirius/redesign/sirius-planeten-04.png" alt="Sirius">

<app-language-selector />

<div class="registration-outer box-content">
    <h1>{{ 'create-profile.title' | translate }}</h1>
    <!-- <p>{{ 'create-profile.account-created' | translate: {email: email} }}</p> -->
    <p class="signup-details">{{ 'create-profile.enter-name-and-password' | translate }}</p>
    <form (ngSubmit)="register()" [formGroup]="loginForm" (change)="formUpdate()" (keyup)="formUpdate()"
        autocomplete="off">
        <div class="registration-row">
            <input type="text" [placeholder]="'create-profile.first-name-placeholder' | translate"
                formControlName="firstName" [pattern]="disallowTrailingSpaces" autocomplete="given-name" maxlength="60">
            <div *ngIf="loginForm.controls['firstName'].invalid && loginForm.controls['firstName'].dirty"
                class="registration-error-message">
                {{ 'create-profile.first-name-error' | translate }}
            </div>
        </div>

        <div class="registration-row">
            <input type="text" [placeholder]="'create-profile.last-name-placeholder' | translate"
                formControlName="lastName" [pattern]="disallowTrailingSpaces" autocomplete="family-name" maxlength="60">
            <div *ngIf="loginForm.controls['lastName'].invalid && loginForm.controls['lastName'].dirty"
                class="registration-error-message">
                {{ 'create-profile.last-name-error' | translate }}
            </div>
        </div>

        <div class="registration-row">
            <select formControlName="timeZone">
                <option value="">{{ 'select.please-select-timezone' | translate }}</option>
                <option *ngFor="let timezone of timezones" [value]="timezone">{{ timezone}} </option>
                <!-- <option *ngFor="let timezone of timezones" [value]="timezone.value">{{ timezone.text }}</option> -->
            </select>
            <label class="mt-1">{{'profile.timezone-label' | translate}}</label>
        </div>

        <div class="registration-row">
            <div style="position: relative">
                <div class="password-field-outer">
                    <input class="password" [placeholder]="'create-profile.password-placeholder' | translate"
                        (focus)="onPasswordFocus()" formControlName="userPassword"
                        type="{{showPass ? 'text' : 'password'}}" autocomplete="new-password">
                    <div class="toggle-password-visibility" [ngClass]="{'show': showPass, 'hide': !showPass}"
                        (click)="togglePass()"></div>
                </div>
            </div>
            <div *ngIf="isAuthError || loginForm.controls['userPassword'].invalid && loginForm.controls['userPassword'].dirty"
                class="registration-error-message">
                {{ 'create-profile.password-error' | translate }}
            </div>
        </div>

        <div class="registration-row">
            <div class="password-field-outer">
                <input class="password" [placeholder]="'create-profile.confirm-password-placeholder' | translate"
                    formControlName="confirmPassword" type="{{showConfirmPass ? 'text' : 'password'}}"
                    autocomplete="new-password">
                <div class="toggle-password-visibility" [ngClass]="{'show': showConfirmPass, 'hide': !showConfirmPass}"
                    (click)="toggleConfirmPass()"></div>
            </div>
            <div *ngIf="loginForm.value.userPassword !== loginForm.value.confirmPassword"
                class="registration-error-message">
                {{ 'create-profile.confirm-password-error' | translate }}
            </div>
        </div>
        <app-password-requirements [hasEnoughPassLength]="hasEnoughPassLength" [hasNumberOrSpecial]="hasNumberOrSpecial"
            [isNotEmpty]="isNotEmpty">
        </app-password-requirements>

        <!--<div class="registration-row">
      <input type="text"
             [placeholder]="'create-profile.security-code-placeholder' | translate"
             (focus)="onCodeFocus()"
             maxlength="4"
             formControlName="securityCode"
             autocomplete="off">
      <div class="registration-error-message"
           *ngIf="loginForm.controls['securityCode'].invalid && loginForm.controls['securityCode'].dirty">
        {{ 'create-profile.security-code-error' | translate }}
      </div>
      <div *ngIf="isCodeError" class="registration-error-message">
        {{ 'create-profile.security-code-incorrect' | translate }}
      </div>
      <div *ngIf="isAlreadyRegistered" class="registration-error-message"
           [innerHTML]="'create-profile.already-registered' | translate | safeHtml" appRouteTransformer>
      </div>
    </div>-->

        <div class="registration-row row">
            <input type="checkbox" class="app-checkbox" formControlName="isPolicyAndConfirming"
                id="isPolicyAndConfirming" />
            <label for="isPolicyAndConfirming">{{ 'create-profile.is-policy-and-confirming' | translate }}</label>
        </div>

        <div class="registration-row row">
            <input type="checkbox" class="app-checkbox" formControlName="isMessageSubscription"
                id="isMessageSubscription" />
            <label for="isMessageSubscription">{{ 'create-profile.is-message-subscription' | translate }}</label>
        </div>

        <div class="registration-privacy-agree" [innerHTML]="'create-profile.agree-info' | translate | safeHtml"
            appRouteTransformer></div>

        <div class="registration-row">
            <button type="submit" class="default-ui-button blue-ui-button large"
                [disabled]="isDisabled() ? true : null">
                {{ 'buttons.continue' | translate }}
            </button>
        </div>

        <div class="registration-row">
            <div *ngIf="isAlreadyRegistered" class="registration-error-message"
                [innerHTML]="'create-profile.already-registered' | translate | safeHtml" appRouteTransformer>
            </div>
            <div *ngIf="!email" class="registration-error-message">User email is missing.</div>
        </div>
    </form>
</div>
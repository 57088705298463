import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import {Observable} from 'rxjs';
import {RoleService} from '../../../services/role.service';
import {CONTACT_URL} from '../../../constants/generic.constants';

@Injectable()
export class RoomLinkAuth  {
  constructor (private  roleService: RoleService) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<boolean | UrlTree> | boolean | UrlTree {
    const role = route.params.roomid.toString().substring(0, 2);
    return new Observable<boolean>(observer => {
      const isRole = this.roleService.checkRole(role);
      if (!isRole) {
        window.location.href =  CONTACT_URL;
      }
      observer.next(isRole);
    });
  }

}

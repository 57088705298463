
export class FormatTimeUtil {
  // timeInp - milli seconds
  public static toString(timeInp: number, isHideHours?: boolean, isHideMinutes?: boolean, isHideSeconds?: boolean): string {
    let result = '';
    if (timeInp != null) {
      let time = Math.trunc(timeInp / 1000);

      const hours = Math.trunc(time / 3600);
      if (hours > 0) {
        time = (time - hours * 3600);
      }
      const hoursStr = ('00' + hours).slice(-2);

      const minutes = Math.trunc(time / 60);
      if (minutes > 0) {
        time = (time - minutes * 60);
      }
      const minutesStr = ('00' + minutes).slice(-2);

      const seconds = time;
      const secondsStr = ('00' + seconds).slice(-2);

      result = (!isHideHours ? hoursStr + ':' : '') + (!isHideMinutes ? minutesStr + ':' : '')  + (!isHideSeconds ? secondsStr : '');
    }
    return result;
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewMediaComponent } from './view-media.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
      ViewMediaComponent
  ],
  imports: [
    CommonModule,TranslateModule
    
  ],
  exports: [ViewMediaComponent]
})

export class ViewMediaModule { }

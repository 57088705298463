import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { logger } from "src/app/lib-core/logger";

import { ADAPTATION_MODE, VIDEO_EVENT } from "./view-media.constants";
import { BehaviorSubject, Observable } from "rxjs";
import { distinctUntilChanged } from "rxjs/operators";
import { ViewMediaService } from "./view-media.service";
import { CallStorage } from "../call-room/service/storage/call-storage.service";
import { EasyRtcService } from "../../lib-rtc/services/easy-rtc.service";

import { ConnectionInfoModel } from "../../models/connection-info";
import { WebRTCStats } from "@peermetrics/webrtc-stats";
import { UsersService } from "../../services/users.service";
import { SpeakerTimeDetectorService } from '../../services/speaker-time-detector.service';
import { RoomStateStorage } from '../call-room/service/storage/RoomStateStorage';
import { PlatformDetectorService } from '../../services/platform-detector/platform-detector.service';
import { VolumeControlService } from "src/app/volume-control/volume-control.service";

import { VideoLayoutService } from "src/app/services/video-layout/video-layout.service";

@Component({
  selector: "app-view-media",
  exportAs: "appViewMedia",
  templateUrl: "./view-media.component.html",
  styleUrls: ["./view-media.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewMediaComponent implements OnChanges, OnInit, OnDestroy {
  @HostBinding("class.host-width-100")
  get isClassHostWidth100(): boolean {
    return this.isHostWidth100;
  }

  @HostBinding("class.host-height-100")
  get isClassHostHeight100(): boolean {
    return this.isHostHeight100;
  }

  get canvas(): HTMLCanvasElement {
    return this.canvasElementRef.nativeElement;
  }

  get canvasContext(): CanvasRenderingContext2D {
    return this.canvas.getContext("2d");
  }

  get volume(): number {
    return this.volumeControlService.getVolume(this.user);
  }

  public techData: ConnectionInfoModel | any = {};
  techData$ = new BehaviorSubject<ConnectionInfoModel>(this.techData);
  private webrtcStats: any = null;
  // In Safari, a hidden video element can show a black screen.
  // See https://bugs.webkit.org/show_bug.cgi?id=241152 for more information.
  get requiresVideoPlayWorkaround(): boolean {
    return this.platformDetector.isBrowserSafari();
  }

  constructor(
    private cdr: ChangeDetectorRef,
    // This is a ref-link to the current component.
    private hostElementRef: ElementRef<HTMLDivElement>,
    private viewMediaService: ViewMediaService,
    private callStorage: CallStorage,
    // private connection: ConnectionService,
    private usersService: UsersService,
    private speakerTimeDetectorService: SpeakerTimeDetectorService,
    private roomState: RoomStateStorage,
    private platformDetector: PlatformDetectorService,
    private volumeControlService: VolumeControlService,
    // private echoDetectorService: EchoDetectorService,
    // private PianoService: PianoService,
	private videoLayoutService: VideoLayoutService
  ) {
    setTimeout(() => {
      this.onResize();
    }, 100);
  }

  @Input()
  public adaptationMode: ADAPTATION_MODE;
  @Input()
  public connectedUsers: any;

  public readonly mediaStream$: BehaviorSubject<MediaStream | null> =
    new BehaviorSubject<MediaStream | null>(null);
  @Input()
  public set mediaStream(value: MediaStream) {
    this.mediaStream$.next(value);
  }
  public get mediaStream(): MediaStream | null {
    return this.mediaStream$.getValue();
  }

  @Input()
  public isPiano: boolean;
  @Input()
  public autoplay: boolean;
  @Input()
  public muted: boolean;
  @Input()
  public isMirrored: boolean;
  @Input()
  public debugName: string;
  @Input()
  public sinkId: string;
  @Input()
  public waitingRoom: string | boolean;
  @Input()
  private user: any;

  @ViewChild("video", { static: true })
  public videoElementRef: ElementRef<HTMLVideoElement>;
  @ViewChild("canvas", { static: true })
  public canvasElementRef: ElementRef<HTMLCanvasElement>;

  @Output()
  readonly changePlaying: EventEmitter<boolean> = new EventEmitter();
  public isPlaying = false;
  public isVideoWidth100: boolean;
  public isVideoHeight100: boolean;
  public isFullCover: boolean;
  public blurBg = !!sessionStorage.getItem("blur");
  public lowBandwidth = false;
  private isHostWidth100: boolean;
  private isHostHeight100: boolean;
  private prevBgUpdate: number;
  private bgTimer: number;
  private changingDevice = false;
  private timeOut = null;

//   @HostListener("window:resize", ["$event"])
  onResize(event?) {
    clearTimeout(this.timeOut);
    this.timeOut = setTimeout(() => {
      this.videoLayoutService.calculateLayout(event);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    // if (!!changes.isPiano) {
	// 	console.log('view-media.component.ts !!changes.isPiano')
	// 	this.videoLayoutService.calculateLayout(event);
	// }
    const currentUsers =
      changes.connectedUsers && changes.connectedUsers.currentValue;
    const prevUsers =
      changes.connectedUsers && changes.connectedUsers.previousValue;
    if (currentUsers && prevUsers && currentUsers.length !== prevUsers.length) {
      this.onResize();
    }
    if (!!changes.adaptationMode) {
      // When the video is displayed, the dimensions of the host may increase and for this, a width or height limitation is added.
      this.settingByAdaptationMode(this.adaptationMode);
    }
    if (!!changes.muted) {
      this.videoElementRef.nativeElement.muted = this.muted;
    }
    if (
      !!changes.sinkId &&
      this.sinkId &&
      this.sinkId !== "any" &&
      (!this.muted || this.waitingRoom)
    ) {
      this.setOutputDevice(this.sinkId);
    }
  }

  private setOutputDevice(deviceId: string) {
    if (!!deviceId) {
      const videoEl = this.videoElementRef.nativeElement as any; // supress typescript compiler errors until sinkId experimental
      if (videoEl.setSinkId) {
        if (this.getOutputDevice() !== this.sinkId && !this.changingDevice) {
          this.changingDevice = true;
          videoEl
            .setSinkId(this.sinkId)
            .then(() => {
              this.changingDevice = false;
            //   logger.log("Changed audio output device", this.sinkId, videoEl);
            })
            .catch((error) => {
              console.error(error);
            })
            .finally(() => {
              this.changingDevice = false;
              this.cdr.markForCheck();
            });
        }
      } else {
        console.warn(`Selecting audio output is not supported`);
      }
    }
  }

  private getOutputDevice(): string {
    const videoEl = this.videoElementRef.nativeElement as any; // supress typescript compiler errors until sinkId experimental
    return videoEl.sinkId || null;
  }

  async ngOnInit(): Promise<void> {
    this.webrtcStats = new WebRTCStats({ getStatsInterval: 1000, debug: true });
    this.viewMediaService.prevMediaStream$.next(null);

    this.videoElementRef.nativeElement.addEventListener(
      VIDEO_EVENT.ENDED,
      this.eventEndedStop
    );
    this.videoElementRef.nativeElement.addEventListener(
      VIDEO_EVENT.ERROR,
      this.eventError
    );
    this.videoElementRef.nativeElement.addEventListener(
      VIDEO_EVENT.LOADEDMETADATA,
      this.eventLoadedmetadata
    );
    this.videoElementRef.nativeElement.addEventListener(
      VIDEO_EVENT.PLAYING,
      this.eventPlaying
    );
    this.videoElementRef.nativeElement.addEventListener(
      VIDEO_EVENT.STALLED,
      this.eventStalled
    );
    this.videoElementRef.nativeElement.addEventListener(
      VIDEO_EVENT.SUSPEND,
      this.eventSuspend
    );
    this.videoElementRef.nativeElement.addEventListener(
      VIDEO_EVENT.WAITING,
      this.eventWaiting
    );
    this.mediaStream$
      .pipe(
        distinctUntilChanged(
          (s1, s2) => !!s1 === !!s2 && (!s1 || s1.id === s2.id)
        )
      )
      .subscribe((mediaStream) => {
        if (mediaStream) {
          if (this.videoElementRef.nativeElement) {
            this.videoElementRef.nativeElement.srcObject = null;
          }
          const videoPlayWorkaround: boolean =
            (this.videoElementRef.nativeElement && this.videoElementRef.nativeElement.paused && this.requiresVideoPlayWorkaround);
          this.playMediaStream(
              this.videoElementRef.nativeElement,
              mediaStream,
              this.muted,
              this.sinkId,
              videoPlayWorkaround,
            );
          this.handleStats(mediaStream, this.videoElementRef.nativeElement);
        }
      });
  }

  // Get peer connection for peerme
  getPeerConnection() {
    if (this.user && this.user.id && !this.user.self) {
        const peerUser = this.callStorage.usersStorage.users.find(
          (user) => user?.id === this.user?.id
        );
        const pc = EasyRtcService.getPeerConnectionByUserId(peerUser.rtcId);
        return pc
  }
  else return null;
}

  ngOnDestroy(): void {
    this.videoElementRef.nativeElement.removeEventListener(
      VIDEO_EVENT.ENDED,
      this.eventEndedStop
    );
    this.videoElementRef.nativeElement.removeEventListener(
      VIDEO_EVENT.ERROR,
      this.eventError
    );
    this.videoElementRef.nativeElement.removeEventListener(
      VIDEO_EVENT.LOADEDMETADATA,
      this.eventLoadedmetadata
    );
    this.videoElementRef.nativeElement.removeEventListener(
      VIDEO_EVENT.PLAYING,
      this.eventPlaying
    );
    this.videoElementRef.nativeElement.removeEventListener(
      VIDEO_EVENT.STALLED,
      this.eventStalled
    );
    this.videoElementRef.nativeElement.removeEventListener(
      VIDEO_EVENT.SUSPEND,
      this.eventSuspend
    );
    this.videoElementRef.nativeElement.removeEventListener(
      VIDEO_EVENT.WAITING,
      this.eventWaiting
    );
  }

  private handleStats(
    mediaStream: MediaStream,
    videoElementRef: HTMLVideoElement
  ) {
    let packetsLostLive:number;
    let packetsLost:number;
    if (this.user && this.user.id) {
      const peerUser = this.callStorage.usersStorage.users.find(
        (user) => user?.id === this.user?.id
      );
      if (!peerUser && mediaStream) {
        if (this.roomState.isMicroOn) {
          // TODO echo detection
          // this.echoDetectorService.registerAdaptiveFilter(mediaStream);
        }
          if (this.roomState.isTeacher && this.roomState.isMicroOn) {
            this.speakerTimeDetectorService.calculateLocalSpeakTime(this.user);
          }
      }


      if (peerUser && mediaStream) {
        const pc = EasyRtcService.getPeerConnectionByUserId(peerUser.rtcId);
        if (pc && pc instanceof RTCPeerConnection) {
          this.getPCStats(pc, peerUser.id, videoElementRef, mediaStream);
        }
        this.techData$.subscribe((techData) => {
          // console.log(techData, "----techData-----");
          if (techData.videoInbound) {
            packetsLostLive = techData.videoInbound.packetsLost - packetsLost;
            packetsLost = techData.videoInbound.packetsLost;
            // logger.log(
            //   this.user.id + ", lostPackages ins 2 Seconds: " + packetsLostLive
            // );
          }
          if (packetsLostLive >= 20) {
            this.lowBandwidth = true;
          } else {
            this.lowBandwidth = false;
          }
        });
      }
    }
  }

   /* detectEcho(audioTrack: any, inboundAudioLevel: number) {
     const outboundAudioLevel = audioTrack?.audioLevel;
     const echoReturnLoss = audioTrack?.echoReturnLoss;
     const echoReturnLossEnhancement = audioTrack?.echoReturnLossEnhancement;
     if (inboundAudioLevel > outboundAudioLevel && echoReturnLoss > 10 && echoReturnLossEnhancement > 3) {
       console.log('%c Echo detected! ', 'background: orange; color: black');
     }
   } */


  getPCStats(
    pc: RTCPeerConnection,
    userId: string,
    videoElementRef: any,
    mediaStream: MediaStream
  ): Observable<ConnectionInfoModel> {
    if (!this.webrtcStats) {
      return;
    }
    // console.log(this.webrtcStats);
    this.webrtcStats.removePeer(userId);
    this.techData = {};
    if (
      mediaStream &&
      mediaStream.getVideoTracks() &&
      mediaStream.getVideoTracks()[0] &&
      mediaStream.getVideoTracks()[0].getSettings()
    ) {
      const settings = mediaStream.getVideoTracks()[0].getSettings();
      Object.assign(this.techData, settings);
    }
    this.webrtcStats.on("stats", (ev: any) => {
      if (this.roomState.isTeacher) {
        if (ev?.peerId === this.speakerTimeDetectorService.userList[0].id) {
          this.speakerTimeDetectorService.onStats$.next();
        }
        this.speakerTimeDetectorService.calculateSpeakerTime(ev.data.audio.inbound[0].audioLevel, userId);
      }
      if (ev.data.audio.outbound && ev.data.audio.inbound) {
        // TODO echo detection
        // const audioLevel = ev.data.audio.inbound[0].audioLevel;
        // const outboundAudioTrack = ev.data.audio.outbound[0].track;
       //  this.echoDetectorService.detectEcho();
      }
      if (
        ev.data.video.outbound &&
        ev.data.video.outbound[0]?.encoderImplementation
      ) {
        this.techData.videoCodec =
          ev.data.video.outbound[0]?.encoderImplementation;
      }
      this.techData.bitrate = ev.data.video;
      this.generateStringBitrate(ev.data.video, videoElementRef);
      this.techData.videoInbound = ev.data.video.inbound[0];
      this.techData.audioInbound = ev.data.audio.inbound[0];
      this.techData.videoOutbound = ev.data.video.outbound[0];
      this.techData.audioOutbound = ev.data.audio.outbound[0];
      this.techData$.next(this.techData);
      this.techData.connectedUsers =
        this.usersService.getConnectedUsers().length; // Todo: Get User as array
    });
    this.webrtcStats.addConnection({
      pc,
      peerId: userId, // any string that helps you identify this peer,
      // remote: false // optional, override the global remote flag
    });
  }

  private generateStringBitrate(
    videoInfo: {
      inbound?: { bitrate: number; frameHeight: number; frameWidth: number }[];
      outbound: { bitrate: number }[];
    },
    videoElementRef: any
  ) {
    const sumBitrates = (items: { bitrate: number }[]) =>
      items.map((e) => e.bitrate).reduce((e1, e2) => e1 + e2, 0);
    const toKbps = (v: number) => `${(v / 1024).toFixed(2)}`;

    const inbound = sumBitrates(videoInfo.inbound || []);
    const outbound = sumBitrates(videoInfo.outbound);

    if (videoInfo.inbound && videoInfo.inbound[0]) {
      if (videoInfo.inbound[0].frameHeight && videoInfo.inbound[0].frameWidth) {
        this.techData.width = videoInfo.inbound[0].frameWidth;
        this.techData.height = videoInfo.inbound[0].frameHeight;
      } else if (videoElementRef && videoElementRef.nativeElement) {
        this.techData.width = videoElementRef.nativeElement.videoWidth;
        this.techData.height = videoElementRef.nativeElement.videoHeight;
      }
    }

    this.techData.bitrate = inbound || outbound; // whatever doesn't equal 0

    // console.log(this.id + ' out: ', outbound, videoInfo.outbound);
    // console.log(this.id + ' in: ', inbound, videoInfo.inbound);
    // [' + videoInfo.outbound.length + '] - for simulcast

    const part1 = inbound > 0 ? [`IN ${toKbps(inbound)}`] : [];
    const part2 =
      outbound > 0
        ? [`OUT ${toKbps(outbound)} [${videoInfo.outbound.length}]`]
        : [];
    this.techData.bitrateString = part1.concat(part2).join(", ");
  }

  // ** Public API **

  // Playback has stopped because the end of the media was reached.
  public eventEndedStop = (event: Event): void => {
    if (!!this.debugName) {
      logger.log(`VM.EndedStop("${this.debugName}")`, [event]);
    }
    this.isPlaying = false;
    this.cdr.markForCheck();
    this.changePlaying.emit(this.isPlaying);
  };
  // Fires when an error occurred during the loading of an audio/video
  public eventError = (event: ErrorEvent): void => {
    console.error(event);
    this.isPlaying = false;
    if (this.bgTimer) {
      cancelAnimationFrame(this.bgTimer);
    }
    this.changePlaying.emit(this.isPlaying);
    this.cdr.markForCheck();
  };
  // The metadata has been loaded.
  public eventLoadedmetadata = (event: Event): void => {
    if (!!this.debugName) {
      logger.log(`VM.loadedmetadata("${this.debugName}")`, [event]);
    }
    this.alignContainerToVideoSize(
      this.adaptationMode,
      this.hostElementRef.nativeElement,
      this.videoElementRef.nativeElement
    );
    this.cdr.markForCheck();
  };
  // Playback is ready to start after having been paused or delayed due to lack of data.
  public eventPlaying = (event: Event): void => {
    if (!!this.debugName) {
      logger.log(`VM.playing("${this.debugName}")`, [event]);
    }
    this.isPlaying = true;
    if (this.blurBg) {
      this.bgTimer = requestAnimationFrame(this.updateBackground.bind(this));
    }
    this.changePlaying.emit(this.isPlaying);
    this.cdr.markForCheck();
  };
  // The user agent is trying to fetch media data, but data is unexpectedly not forthcoming.
  public eventStalled = (event: Event): void => {
    if (!!this.debugName) {
      logger.log(`VM.stalled("${this.debugName}")`, [event]);
    }
  };
  // Media data loading has been suspended.
  public eventSuspend = (event: Event): void => {
    if (!!this.debugName) {
      logger.log(`VM.suspend("${this.debugName}")`, [event]);
    }
  };
  // Playback has stopped because of a temporary lack of data
  public eventWaiting = (event: Event): void => {
    if (!!this.debugName) {
      logger.log(`VM.waiting("${this.debugName}")`, [event]);
      const that = this;
      setTimeout(() => {
        const readyState = that.videoElementRef.nativeElement.readyState;
        if (readyState <= 2) {
          logger.log(`VM.("${that.debugName}") readyState: ${readyState} `, [
            that.mediaStream,
          ]);
        }
      }, 5000);
    }
  };

  // ** Private API **

  private getMediaTrackSettings(mediaStream: MediaStream): MediaTrackSettings {
    const mediaStreamTrack: MediaStreamTrack = !!mediaStream
      ? mediaStream.getVideoTracks()[0]
      : null;
    return !!mediaStreamTrack ? mediaStreamTrack.getSettings() : null;
  }

  private updateBackground(timestamp?: DOMHighResTimeStamp): number {
    const mediaTrackSettings = this.getMediaTrackSettings(this.mediaStream);
    if (!!mediaTrackSettings) {
      if (
        !this.prevBgUpdate ||
        timestamp - this.prevBgUpdate > mediaTrackSettings.frameRate / 2
      ) {
        this.prevBgUpdate = timestamp;
        this.canvasContext.drawImage(
          this.videoElementRef.nativeElement,
          0,
          0,
          mediaTrackSettings.width,
          mediaTrackSettings.height,
          0,
          0,
          this.canvas.width,
          this.canvas.height
        );
      }
    }
    this.bgTimer = requestAnimationFrame(this.updateBackground.bind(this));
    return this.bgTimer;
  }

  private playMediaStream(
    videoElement: HTMLVideoElement,
    mediaStream: MediaStream,
    muted: boolean,
    sinkId: string,
    playWorkaround: boolean,
  ): Promise<void> {
    if (
      !!videoElement &&
      !!mediaStream &&
      videoElement.srcObject !== mediaStream
    ) {
      if (!!this.isPlaying) {
        videoElement.pause();
      }
      videoElement.srcObject = mediaStream;
      videoElement.muted = muted;
      if (playWorkaround) {
        const promise = videoElement.play();
        if (promise !== undefined) {
          return promise
            .catch(error => {
              console.warn('Error playing video in Safari', error);
              if (this.bgTimer) {
                cancelAnimationFrame(this.bgTimer);
              }
              this.cdr.markForCheck();
            })
            .then(() => {
              console.log('Video played successfully in Safari');
              this.viewMediaService.prevMediaStream$.next(mediaStream);
              if (!muted && sinkId) {
                this.setOutputDevice(sinkId);
              }
            }).finally(() => this.cdr.markForCheck());
        }
      }
      return videoElement
        .play()
        .then(() => {
          this.viewMediaService.prevMediaStream$.next(mediaStream);
          if (!muted && sinkId) {
            this.setOutputDevice(sinkId);
          }
        })
        .catch((error) => this.eventError(error))
        .finally(() => this.cdr.markForCheck());
    } else {
      return Promise.resolve(null);
    }
  }

  private settingByAdaptationMode(adaptationMode: ADAPTATION_MODE): void {
    switch (adaptationMode) {
      case ADAPTATION_MODE.FIT_VIDEO_HEIGHT:
        this.isHostWidth100 = false;
        this.isHostHeight100 = true;
        this.isVideoWidth100 = false;
        this.isVideoHeight100 = true;
        this.isFullCover = false;
        break;
      case ADAPTATION_MODE.FIT_VIDEO_WIDTH:
        this.isHostWidth100 = true;
        this.isHostHeight100 = false;
        this.isVideoWidth100 = true;
        this.isVideoHeight100 = false;
        this.isFullCover = false;
        break;
      case ADAPTATION_MODE.ADAPT_BY_HOST_SIZES:
        this.isHostWidth100 = true;
        this.isHostHeight100 = true;
        this.isVideoWidth100 = true;
        this.isVideoHeight100 = true;
        this.isFullCover = false;
        break;
      case ADAPTATION_MODE.ADAPT_BY_HOST_SIZES_AND_COVER:
        this.isHostWidth100 = true;
        this.isHostHeight100 = true;
        this.isVideoWidth100 = true;
        this.isVideoHeight100 = true;
        this.isFullCover = true;
        break;
    }
  }

  private getProportions(width: number, height: number): number {
    return width > 0 && height > 0 ? width / height : null;
  }

  private alignContainerToVideoSize(
    adaptationMode: ADAPTATION_MODE,
    hostElement: HTMLDivElement,
    videoElement: HTMLVideoElement
  ): void {
    const offsetWidth = !!hostElement ? hostElement.offsetWidth : null;
    const offsetHeight = !!hostElement ? hostElement.offsetHeight : null;
    const hostProportions = this.getProportions(offsetWidth, offsetHeight);
    const videoWidth = !!videoElement ? videoElement.videoWidth : null;
    const videoHeight = !!videoElement ? videoElement.videoHeight : null;
    const videoProportions = this.getProportions(videoWidth, videoHeight);

    if (!!adaptationMode && hostProportions > 0.0 && videoProportions > 0.0) {
      this.settingByAdaptationMode(this.adaptationMode);
      videoElement.removeAttribute("width");
      videoElement.removeAttribute("height");
      let countOfChanges = 0;
      switch (adaptationMode) {
        case ADAPTATION_MODE.FIT_VIDEO_HEIGHT:
          const newWidth = Math.ceil(
            videoProportions * hostElement.offsetHeight
          );
          countOfChanges += videoElement.width !== newWidth ? 1 : 0;
          videoElement.setAttribute("width", `${newWidth}px`);
          break;
        case ADAPTATION_MODE.FIT_VIDEO_WIDTH:
          const newHeight = Math.ceil(
            hostElement.offsetWidth / videoProportions
          );
          countOfChanges += videoElement.height !== newHeight ? 1 : 0;
          videoElement.setAttribute("height", `${newHeight}px`);
          break;
        case ADAPTATION_MODE.ADAPT_BY_HOST_SIZES:
        case ADAPTATION_MODE.ADAPT_BY_HOST_SIZES_AND_COVER:
          countOfChanges += 1;
          break;
      }
      if (countOfChanges > 0) {
        this.cdr.markForCheck();
      }
    }
  }
}

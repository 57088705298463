
export enum ADAPTATION_MODE {
  NONE = 'none',
  // Fit video height. Host(Height:100%), Video(Height:100%)
  FIT_VIDEO_HEIGHT = 'fit-video-height',
  // Fit video width. Host(Width:100%), Video(Width:100%)
  FIT_VIDEO_WIDTH = 'fit-video-width',
  // Host(Width:100%, Height:100%), Video(Width:100%, Height:100%)
  ADAPT_BY_HOST_SIZES = 'adapt-by-host-sizes',
  // Host(Width:100%, Height:100%), Video(Width:100%, Height:100%) + fit:cover
  ADAPT_BY_HOST_SIZES_AND_COVER = 'adapt-by-host-sizes-and-cover',
}

export enum VIDEO_EVENT {
  ENDED = 'ended',
  ERROR = 'error',
  LOADEDMETADATA = 'loadedmetadata',
  PLAYING = 'playing',
  STALLED = 'stalled',
  SUSPEND = 'suspend',
  WAITING = 'waiting'
}

<div class="platform-container w-full">
    <h1 class="text-left">{{ "students.title" | translate }}</h1>
    <button *ngIf="allowExternalStudents" class="btn mb-2 ml-2 sm:ml-0" (click)="inviteExternalStudent()">
        {{"lessons.addStudent" | translate}}
    </button>
    <div *ngIf="!isStudents && !isLoading">
        {{ "students.empty-list-message" | translate }}
    </div>

    <ul *ngIf="isStudents" class="student-list w-full rounded-border-radius">
        <li *ngFor="let student of students" class="p-2 mb-6 bg-white student-card">
            @let subrole = student.subrole;
            <div class="flex items-center justify-between flex-wrap">
                <div class="student-name">
                    <span class="font-bold">{{ student.name !== "null " ? student.name : student.email }}</span>
                    <span *ngIf="subrole == 7"> ({{'lessons.external'|translate}})</span>
                    <span class="xs:ml-1 ml-3" [ngStyle]="{
							color: returnColor(student.activationStatus)
						}">{{ returnActivityStatus(student.activationStatus) }}
                    </span>
                </div>
                <div class="gap-3 w-full md:w-auto mt-2 md:mt-0">
                    @if (isActiveStudent(student)){
                    <button *ngIf="!isEditorOpen(student)" (click)="openEditor(student)" class="blue-ui-button">
                        {{ "buttons.edit-notes" | translate }}
                    </button>

                    <button *ngIf="isEditorOpen(student)" (click)="closeEditor()" class="flex items-center mr-6">
                        <img src="../../assets/icons/common/less.svg" alt="less" />
                    </button>
                    } @if (isPendingStudent(student)){ @if(student.displayEmailSentMessage){
                    <span class="text-sm text-sirius-lilac max-md:hidden mr-2">{{ "students.email-sent" | translate
                        }}</span>
                    }
                    <button *ngIf="!isEditorOpen(student)" (click)="sendEmail(student)" [disabled]="student.isEmailSent"
                        class="blue-ui-button">
                        {{ "buttons.resend-login-info" | translate }}
                    </button>
                    @if(student.displayEmailSentMessage){
                    <span class="text-sm text-sirius-lilac md:hidden ml-2">{{ "students.email-sent" | translate
                        }}</span>
                    } }
                </div>
            </div>

            <div *ngIf="isEditorOpen(student)" class="mt-3">
                <hr />
                <app-notes-editor [teacherId]="userId" [studentId]="student.id"></app-notes-editor>
            </div>
        </li>
    </ul>
    <mat-paginator [ngStyle]="{ display: size > 10 ? 'block' : 'none' }" (page)="pageChanged($event)"
        class="rounded-b-border-radius" #paginator [length]="size" [hidePageSize]="true" [pageSize]="10"
        showFirstLastButtons aria-label="Select page">
    </mat-paginator>
</div>
<ng-container *ngIf="isMediaError">
    <h2 class="audio-setup text-center">{{'setup.audio-tab.mic-blocked' | translate}}</h2>
    <p class="text-center mb-2">{{'setup.grant-access' | translate}}</p>
    <img class="audio-input-mic mb-2" src="../../assets/images/setup/restricted.png">
    <!-- <p class="text-center mb-2"><a class="underline" href="">More about permissions</a>
    </p> -->
    <p></p>

</ng-container>
<form [formGroup]="preCallSettingsForm" [ngSwitch]="audioSettings">


    <ng-container *ngIf="!isMediaError">
        <ng-container *ngSwitchCase="'audioInput'">
            <h2 class="audio-setup text-center">{{'setup.audio-tab.input-title' | translate}}</h2>

            <img class="audio-input-mic mb-2" src="../../assets/images/setup/audio-input-mic.svg" width="60" height="60"
                alt="">
            <p class="text-center mb-6 text-sm">{{'setup.audio-tab.question-1' | translate}}</p>
            <div class="pre-setup-settings-container">
                <div class="volume-meter-container mb-6">
                    <app-volume-meter [volume]="currentVolume"></app-volume-meter>
                </div>
                <div [ngClass]="{'disabled-block': isMediaError}" class="row-media mb-6"
                    *ngIf="audioDeviceList.length > 0">
                    <div class="media-img icon microphone"></div>
                    <div class="media-prm">
                        <mat-select (selectionChange)="updateAudioSettings()" class="app-select video-settings-select"
                            formControlName="audioInputDeviceId" panelClass="app-select-panel"
                            disableOptionCentering="true">
                            <mat-option *ngFor="let device of audioDeviceList" [value]="device.deviceId">{{ device.label
                                ||
                                device.groupId
                                }}</mat-option>
                        </mat-select>
                    </div>
                </div>
                <div class="button-section default-ui-button new-design-button-copy input">
                    <a target="_blank"
                        href="https://support.sirius.video/">
                        {{'setup.audio-tab.answer-1-no' | translate}}
                    </a>
                    <button class="blue-ui-button default-ui-button new-design-button-copy input"
                        (click)="showOutputSettings()">
                        {{'setup.audio-tab.answer-1-yes' | translate}}<span class=" ml-2 leading-6 material-symbols-outlined">arrow_forward</span>
                    </button>
                </div>
            </div>
        </ng-container>


        <ng-container *ngSwitchCase="'audioOutput'">
            <h2 class="audio-setup text-center">{{'setup.audio-tab.output-title' | translate}}</h2>
            <img class="audio-input-mic mb-2" src="../../assets/images/setup/audio-output-speaker.svg " width=" 60"
                height="60" alt="">
            <p class="text-center mb-3 text-sm" [innerHTML]="'setup.audio-tab.question-2' | translate"></p>
            <p class="diplay-block"><span
                    class="underline text-sirius-violet block cursor-pointer test-audio disabled-block text-center mb-4"
                    (click)="testAudio()">{{'setup.audio-tab.test-speaker' | translate}}</span></p>
            <div [ngClass]="{'disabled-block': isMediaError && isFirefox}" class="row-media mb-3"
                *ngIf="outputDevicesList.length > 0">
                <div class="media-img icon speaker"></div>
                <div class="media-prm audio-devices">
                    <mat-select class="app-select video-settings-select" formControlName="audioOutputDeviceId"
                        panelClass="app-select-panel" disableOptionCentering="true"
                        (selectionChange)="doChangeAudioOutputDevice($event.value)">
                        <mat-option *ngFor="let device of outputDevicesList" [value]="device.deviceId">{{ device.label
                            ||
                            device.groupId }}</mat-option>
                    </mat-select>
                </div>
            </div>


            <div class="button-section default-ui-button new-design-button-copy input">
                <a target="_blank"
                    href="https://support.sirius.video/">
                    {{'setup.audio-tab.answer-2-no' | translate}}
                </a>
                <button class="blue-ui-button" (click)="onCompleted()">
                    {{'setup.audio-tab.answer-2-yes' | translate}}<span class=" ml-2 leading-6 material-symbols-outlined">arrow_forward</span>
                </button>
            </div>

        </ng-container>

    </ng-container>

</form>
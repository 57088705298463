import { Injectable } from "@angular/core";
import { REGULAR_TEACHERS } from "../constants/connection-constants";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { AuthenticationService } from "./authentication/authentication.service";

@Injectable({
	providedIn: "root"
})
export class TeachersService {
	constructor(
		private http: HttpClient,
		private auth: AuthenticationService
	) {}

	getTeachers(userId): Observable<any> {
		const url = REGULAR_TEACHERS.replace(":userId", userId);
		return this.http.get(url, {
			headers: this.auth.headers,
			withCredentials: true
		});
	}
}

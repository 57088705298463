import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';

@Injectable()
export class LoggedInGuard  {
  constructor(private auth: AuthenticationService, private router: Router) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.auth.checkLoggedIn$().toPromise().then((loggedIn) => {
      if (!!loggedIn) {
        return this.router.parseUrl('/platform/start');
      } else {
        return true;
      }
    });
  }
}

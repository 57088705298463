import { Component, OnInit } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { TranslateModule } from "@ngx-translate/core";
import { Location } from "@angular/common";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { AuthenticationService } from "src/app/services/authentication/authentication.service";
import { LessonsOverviewTableComponent } from "../../components/lessons-overview-table/lessons-overview-table.component";
import { ActivatedRoute } from "@angular/router";

@Component({
	selector: "app-sub-menu",
	standalone: true,
	imports: [MatIconModule, TranslateModule, LessonsOverviewTableComponent],
	templateUrl: "./sub-menu.component.html",
	styleUrl: "./sub-menu.component.scss"
})
export class SubMenuComponent implements OnInit {
	isMobile: boolean = true;
	showMenu: boolean = false;
	selectedTab: number;
	userId: string;
	timeZone: string;
	studentName: string;

	constructor(
		private location: Location,
		private breakpointObserver: BreakpointObserver,
		private authenticationService: AuthenticationService,
		private route: ActivatedRoute
	) {}

	async ngOnInit() {
		this.breakpointObserver
			.observe([Breakpoints.Handset])
			.subscribe((result) => {
				this.isMobile = result.matches;
				this.handleIsMobile(this.isMobile);
			});
		this.route.queryParams.subscribe((params) => {
			this.studentName = params["name"];
		});
		this.init();
	}

	async init() {
		const profile = await this.authenticationService.profile;
		this.userId = profile.userId;
		this.timeZone = profile.timezone;
	}

	goBackStudentsMenu() {
		this.location.back();
	}
	goBackSubMenu() {
		this.showMenu = false;
	}

	selectOption(option: number) {
		this.selectedTab = option;
		this.showMenu = true;
	}

	handleIsMobile(isMobile) {
		if (!isMobile) {
			this.goBackStudentsMenu();
		} else {
			return;
		}
	}
	setOption(option: number): string {
		if (option === 1) return "lessons-overview.submenu.hour-overview";
		if (option === 2) return "lessons-overview.submenu.notes";
		if (option === 3) return "lessons-overview.submenu.recordings";
	}
}

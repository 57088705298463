import { Component, Input } from "@angular/core";

@Component({
	selector: "app-countdown",
	standalone: true,
	imports: [],
	templateUrl: "./countdown.component.html",
	styleUrl: "./countdown.component.scss"
})
export class CountdownComponent {
	@Input() endDate: Date;
	hours: string = "0";
	minutes: string = "0";
	seconds: string = "0";

	initCountdown() {
		const interval = setInterval(() => {
			const now = new Date().getTime();
			const distance = this.endDate.getTime() - now;

			if (distance < 0) {
				clearInterval(interval);
				this.hours = "00";
				this.minutes = "00";
				this.seconds = "00";
			}

			const hours = Math.floor(
				(distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
			);
			const minutes = Math.floor(
				(distance % (1000 * 60 * 60)) / (1000 * 60)
			);
			const seconds = Math.floor((distance % (1000 * 60)) / 1000);

			this.hours = hours < 10 ? "0" + hours : "" + hours;
			this.minutes = minutes < 10 ? "0" + minutes : "" + minutes;
			this.seconds = seconds < 10 ? "0" + seconds : "" + seconds;
		}, 1000);
	}

	ngOnInit() {
		if (this.endDate) this.initCountdown();
	}
}

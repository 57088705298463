import { Injectable, OnDestroy } from "@angular/core";
import { SoundPlayer } from "../lib-sound-player/sound-player";
import { SoundPlayerUtil } from "../lib-sound-player/sound-player.util";
import { AudioContextService } from "../services/audio-context/audio-context.service";

export interface Emoji {
	emoji: string;
	userId: string;
}

@Injectable()
export class EmojiService {
	private audioContext: AudioContext;
	private soundPlayers: { [key: string]: SoundPlayer } = {};
	private roomUserEmojis = {};

	public emojis = [
		"up",
		"down",
		"clap",
		"horns",
		"party",
		"love",
		"mega",
		"laugh"
	];

	constructor(private audioContextService: AudioContextService) {
		this.initAudioContext();
	}

	public async triggerEmoji({ emoji, userId }: Emoji) {
		this.roomUserEmojis[userId] = emoji;
		if (emoji in this.soundPlayers) {
			this.soundPlayers[emoji].play();
		}
		setTimeout(() => {
			if (this.roomUserEmojis[userId] === emoji) {
				this.roomUserEmojis[userId] = null;
			}
		}, 3000);
	}

	public userEmoji(id): string {
		return this.roomUserEmojis[id];
	}

	private async initAudioContext(): Promise<void> {
		this.audioContext = this.audioContextService.getAudioContext();
		await this.initSoundPlayers(this.audioContext);
	}

	private async initSoundPlayers(audioContext: AudioContext): Promise<void> {
		if (!!audioContext) {
			const soundFiles = {
				clap: "/assets/clap.mp3",
				mega: "/assets/airhorn.mp3",
				party: "/assets/party.mp3",
				bell: "/assets/BellBouncer_short.mp3"
			};

			for (const [emoji, soundFile] of Object.entries(soundFiles)) {
				const soundPlayer = new SoundPlayer(audioContext);
				const audioBuffer =
					await SoundPlayerUtil.fetchAndCreateBufferSource(
						audioContext,
						soundFile
					);
				await soundPlayer.setAudioBuffer(audioBuffer);
				this.soundPlayers[emoji] = soundPlayer;
				this.setVolume(0.2);
			}
		}
	}

	public setVolume(value: number): void {
		for (const soundPlayer of Object.values(this.soundPlayers)) {
			soundPlayer.setVolume(value);
		}
	}

	public closeAudioContext() {
		if (this.audioContext && this.audioContext.state !== "closed") {
			this.audioContext.close();
		}
		for (const soundPlayer of Object.values(this.soundPlayers)) {
			soundPlayer.OnDestroy();
		}
	}
}

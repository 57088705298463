<div class="calendar-content">
    <ng-container *ngIf="overrideFilterDates">

        <input [matDatepickerFilter]="dateFilter" style="opacity: 0" matInput [min]="minDate" [matDatepicker]="picker"
            placeholder="Choose a date" [formControl]="dateControl">

        <mat-datepicker startAt="startDate" #picker (opened)="datePickerOnOpen()"
            panelClass='calendar-content-date-override'></mat-datepicker>

    </ng-container>

</div>

<form class="override-container" novalidate>
    <div class="title-desc-wrapper">
        <div class="override-title">
            {{'availability.overrides-title' | translate}}
        </div>
        <div class="override-description">
            {{'availability.overrides-description' | translate}}
        </div>
    </div>

    <ul *ngIf="!!overrideData.length" class="override-list-block border rounded border-gray-200">
        <li *ngFor="let override of overrideData; let i = index"
            class="override-list flex justify-between border-b px-5 py-4 last:border-b-0">
            <div class="description">
                <h3 class="text-gray-900">{{override?.formatDate}}</h3>
                <p class="text-neutral-500">
                    @for (timeslot of override.overrideTimes; track timeslot ) {
                    <span class="inline-block mb-1">{{ timeslot.startTime }} - {{ timeslot.endTime
                        }}</span><br>
                    }
                </p>
                <p class="text-neutral-500">{{override?.formatTime?.time}}</p>
            </div>
            <div class="space-x-2">
                <button class="delete-btn" (click)="delete(override, i)" data-state="closed" type="button">
                    <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round"
                        stroke-linejoin="round" class="inline-flex h-4 w-4 " height="1em" width="1em"
                        xmlns="http://www.w3.org/2000/svg">
                        <polyline points="3 6 5 6 21 6"></polyline>
                        <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                        <line x1="10" y1="11" x2="10" y2="17"></line>
                        <line x1="14" y1="11" x2="14" y2="17"></line>
                    </svg>
                </button>
            </div>
        </li>
    </ul>


    <button (click)=open()>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M8.00004 3.33325V12.6666M3.33337 7.99992H12.6667" stroke="#6B7280" stroke-width="1.5"
                stroke-linecap="round" stroke-linejoin="round" />
        </svg>{{'availability.add-override-btn' | translate}}</button>
</form>
import { Component, EventEmitter, HostBinding, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { UserVO } from '../../services/data/UserVO';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { ButtonType } from '../../components/call-room/data/ButtonType';
import { PopupService } from '../../services/popup/popup.service';
import { AFFECT, MUTE, UNMUTE, HIDE, UNHIDE, KICK, WebSocketService } from '../../services/ws/web-socket.service';
import { TranslateService } from '@ngx-translate/core';
import { PermissionsService } from '../../permissions.service';
import { EventSocketService } from 'src/app/services/ws/event-socket.service';
import { UsersService } from 'src/app/services/users.service';
import { UserActionsService } from './user-actions.service';
import { GA4Service } from 'src/app/services/ga4.service';
import { logger } from 'src/app/lib-core/logger';
import { MirroringService } from '../call-room/service/mirroring.service';

@Component({
  selector: 'app-user-actions',
  templateUrl: './user-actions.component.html',
  styleUrls: ['./user-actions.component.scss'],
})
export class UserActionsComponent implements OnInit {
  @Input() user: UserVO;
  @Input() teacherName: string;
  @Input() appearance: 'gorizontal' | 'vertical' = 'gorizontal';
  @Input() isMainPanel = false;
  @Input() isFullscreen = false;
  @Input() isSelfUser = false;
  @Input() isMobile = false;
  @Input() isYoutube = false;
  @Input() isMirrored = false;
  @Output() toggleMaximize = new EventEmitter<void>();
  @Output() toggleFullscreen = new EventEmitter<void>();
  @Output() toggleStopYouTube = new EventEmitter<void>();
  public isApprover = false;
  public isMenuOpened = false;

  @HostBinding('class') get appearanceClass () {
    return this.appearance;
  }

  constructor(
    private authenticationService: AuthenticationService,
    private popupService: PopupService,
    private webSocketService: WebSocketService,
    private translateService: TranslateService,
    public permissionsService: PermissionsService,
    private eventSocketService: EventSocketService,
    private userActionsService: UserActionsService,
    private ga4: GA4Service,
    private mirrorService: MirroringService,
  ) { }

  ngOnInit(): void {
    this.isApprover = !!this.authenticationService.isApprover();
    this.userActionsService.onMenuOpenedEvent.subscribe((user: UserVO) => {
      this.isMenuOpened = this.user.id === user.id;
    })
  }

  toggleMenu(event: MouseEvent): void {
    event.stopPropagation();
    if (!this.isMenuOpened) {
      this.userActionsService.onMenuOpened(this.user)
    } else {
      this.isMenuOpened = false;
    }
    this.ga4.threedotmenu();
  }

  switchStudentAudio(event: MouseEvent, isMicro: boolean, userId: string): void {
    event.stopPropagation();
    this.isMenuOpened = false;
    if (!isMicro) {
      this.webSocketService.dispatch(AFFECT, {option: (!isMicro ? MUTE : UNMUTE), target: this.user.id});
    } else if (!!userId) {
      this.eventSocketService.exchange(this.teacherName, true, false, userId);
    }
  }

  switchStudentVideo(event: MouseEvent, isCamera: boolean, userId: string): void {
    event.stopPropagation();
    this.isMenuOpened = false;
    if (!isCamera) {
      this.webSocketService.dispatch(AFFECT, {option: (!isCamera ? HIDE : UNHIDE), target: this.user.id});
    } else if (!!userId) {
		this.eventSocketService.exchange(this.teacherName, false, true, userId);
    }
  }

  // We have component app-icon-cross, but i will do new one
  kickStudent(): void {
    this.isMenuOpened = false;
    const message = this.translateService.instant('popUp.removeConfirmation', { name: this.user.name });
    this.popupService.openCustomMessagePopup(message,
      [ {
        type: ButtonType.OK,
        text: this.translateService.instant('popUp.cancel')
      }, {
        type: ButtonType.CANCEL,
        text: this.translateService.instant('popUp.remove'),
        callBack: () => {
          this.webSocketService.dispatch(AFFECT, {option: KICK, target: this.user.id});
        }
      }]);
  }

  doToggleMaximise(event: MouseEvent){
    event.stopPropagation();
    this.isMenuOpened = false;
    this.toggleMaximize.emit();
    this.ga4.threedotMaximize();
  }

  doToggleFullscreen(event: MouseEvent){
    event.stopPropagation();
    this.isMenuOpened = false;
    this.toggleFullscreen.emit();
    this.ga4.threedotFullscreen();
  }

  stopYouTube() {
    this.toggleStopYouTube.emit();
  }

  onMirrorClicked(event: MouseEvent) {
    event.stopPropagation();
    this.isMenuOpened = false;
    logger.log('onMirrorClicked', this.user);
    this.mirrorService.setValue(this.user, !this.isMirrored);
  }

}

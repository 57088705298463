import {
  MEDIA_AUDIO_CHANNEL_COUNT,
  MEDIA_AUDIO_ECHO_CANCELLATION, MEDIA_AUDIO_GAIN_CONTROL, MEDIA_AUDIO_INPUT_DEVICE, MEDIA_AUDIO_NOISE_SUPPRESSION,
  MEDIA_AUDIO_OUTPUT_DEVICE, MEDIA_AUDIO_SAMPLE_RATE, MEDIA_VIDEO_FRAME_RATE, MEDIA_VIDEO_HEIGHT,
  MEDIA_VIDEO_INPUT_DEVICE, MEDIA_VIDEO_IS_REFLECTION, MEDIA_VIDEO_RESOLUTION, MEDIA_VIDEO_WIDTH,MEDIA_VIDEO_SECOND_INPUT_DEVICE
} from 'src/app/lib-core/constants/constants';
import { LocalStorageUtil } from 'src/app/lib-core/utils/local-storage.util';
import { environment } from 'src/environments/environment';

export interface ParamNumber {
  name: string;
  default?: boolean;
  param: number;
}

export class ParamNumberUtil {
  public static findParam(paramNumberList: ParamNumber[], paramNumber: number, valueDefault?: ParamNumber): ParamNumber | null {
    let result: ParamNumber | null = null;
    if (paramNumberList.length > 0) {
      result = paramNumberList.find((item) => item.param === paramNumber);
    }
    return (result || valueDefault);
  }
  public static findDefault(paramNumberList: ParamNumber[]): ParamNumber | null {
    let result: ParamNumber | null = null;
    if (paramNumberList.length > 0) {
      result = paramNumberList.find((item) => !!item.default);
      if (!result) {
        result = paramNumberList[0];
      }
    }
    return result;
  }
}
export interface ParamString {
  name: string;
  default?: boolean;
  param: string;
}

export interface SampleSize {
  name: string;
  default?: boolean;
  sampleSize: number;
}

export interface Resolution {
  id: number;
  name: string;
  default?: boolean;
  width: number;
  height: number;
}

export interface MediaProperies {
  audioInpDev: string;
  audioOutDev: string;
  audioSampleRate: number;
  sampleSize?: number;
  audioEchoCancellation: boolean;
  audioNoiseSuppression: boolean;
  audioAutoGainControl: boolean;
  audioChannelCount: number;
  videoInpDev: string;
  videoWidth: number;
  videoHeight: number;
  videoFrameRate: number;
  videoIsReflection: boolean;
  langId?: string;
  bgColor?: string;
  videoResolution?: Resolution;
  facingMode?: object;
  secondCameraInputDevice?: string;
}

export interface SettingPopupData {
// TODO ?  returnedType: 'MediaStreamConstraints' | 'MediaStream';
  params?: any;
}


export class MediaProperiesUtil {
  public static createConstraints(mediaProperies: Partial<MediaProperies> | null): MediaStreamConstraints | null {
    if (!mediaProperies) {
      return null;
    }
    const audio: MediaTrackConstraints = ({} as MediaTrackConstraints);
    const video: MediaTrackConstraints = ({} as MediaTrackConstraints);

    // Audio
    if (!!mediaProperies.audioInpDev) {
      audio.deviceId = { exact: mediaProperies.audioInpDev };
    }
    if (mediaProperies.audioSampleRate != null) {
      audio.sampleRate = mediaProperies.audioSampleRate;
    }
    if (mediaProperies.sampleSize != null) {
      audio.sampleSize = mediaProperies.sampleSize;
    }
    if (mediaProperies.audioEchoCancellation != null) {
      audio.echoCancellation = mediaProperies.audioEchoCancellation;
    }
    if (mediaProperies.audioNoiseSuppression != null) {
      (audio as any).noiseSuppression = {exact: mediaProperies.audioNoiseSuppression};
    }
    if (mediaProperies.audioAutoGainControl != null) {
      (audio as any).autoGainControl = mediaProperies.audioAutoGainControl;
    }
    if (mediaProperies.audioChannelCount != null) {
      (audio as any).channelCount = mediaProperies.audioChannelCount;
    }

    // Video
    if (!!mediaProperies.videoInpDev) {
      video.deviceId = { exact: mediaProperies.videoInpDev };
    }
    if (!!mediaProperies.videoWidth) {
      video.width = { ideal: mediaProperies.videoWidth };
    }
    if (!!mediaProperies.videoHeight) {
      video.height = { ideal: mediaProperies.videoHeight };
    }
    if (!!mediaProperies.videoFrameRate) {
      video.frameRate = mediaProperies.videoFrameRate;
    }
    if (!!mediaProperies.facingMode) {
      video.facingMode = mediaProperies.facingMode;
    }

    return {
      audio: (Object.keys(audio).length > 0 ? audio : true),
      video: (Object.keys(video).length > 0 ? video : true)
    };
  }

  public static writeAudioInputDeviceToStorage(audioInpDev: string): void {
    LocalStorageUtil.setItem(MEDIA_AUDIO_INPUT_DEVICE, audioInpDev);
  }
  public static writeAudioOutputDeviceToStorage(audioOutDev: string): void {
    LocalStorageUtil.setItem(MEDIA_AUDIO_OUTPUT_DEVICE, audioOutDev);
  }
  public static writeAudioChannelCountToStorage(audioChannelCount: number): void {
    LocalStorageUtil.setItem(MEDIA_AUDIO_CHANNEL_COUNT, String(audioChannelCount));
  }
  public static writeVideoInputDeviceToStorage(videoInpDev: string): void {
    LocalStorageUtil.setItem(MEDIA_VIDEO_INPUT_DEVICE, videoInpDev);
  }
  public static writeVideoIsReflectionToStorage(videoIsReflection: boolean): void {
    LocalStorageUtil.setItem(MEDIA_VIDEO_IS_REFLECTION, String(videoIsReflection));
  }
  public static writeAudioGainControlStorage(audioGainControl: boolean): void {
    LocalStorageUtil.setItem(MEDIA_AUDIO_GAIN_CONTROL, String(audioGainControl));
  }
  public static writeAudioEchoCancellation(audioEchoCancellation: boolean): void {
    LocalStorageUtil.setItem(MEDIA_AUDIO_ECHO_CANCELLATION, String(audioEchoCancellation));
  }
  public static writeAudioNoiseSuppression(audioNoiseSuppression: boolean): void {
    LocalStorageUtil.setItem(MEDIA_AUDIO_NOISE_SUPPRESSION, String(audioNoiseSuppression));
  }
  public static writeSecondCameraInputDevice(audioInDevice: string): void {
    LocalStorageUtil.setItem(MEDIA_VIDEO_SECOND_INPUT_DEVICE, String(audioInDevice));
  }



  public static writeMediaProperiesToStorage(mediaProperies: MediaProperies): void {
    if (!mediaProperies) {
      return;
    }
    // Audio
    MediaProperiesUtil.writeAudioInputDeviceToStorage(mediaProperies.audioInpDev);
    MediaProperiesUtil.writeAudioOutputDeviceToStorage(mediaProperies.audioOutDev);
    LocalStorageUtil.setItem(MEDIA_AUDIO_SAMPLE_RATE, String(mediaProperies.audioSampleRate)),
    LocalStorageUtil.setItem(MEDIA_AUDIO_ECHO_CANCELLATION, String(mediaProperies.audioEchoCancellation)),
    LocalStorageUtil.setItem(MEDIA_AUDIO_NOISE_SUPPRESSION, String(mediaProperies.audioNoiseSuppression)),
    LocalStorageUtil.setItem(MEDIA_AUDIO_GAIN_CONTROL, String(mediaProperies.audioAutoGainControl)),
    MediaProperiesUtil.writeAudioChannelCountToStorage(mediaProperies.audioChannelCount);
    // Video
    MediaProperiesUtil.writeVideoInputDeviceToStorage(mediaProperies.videoInpDev);
    LocalStorageUtil.setItem(MEDIA_VIDEO_WIDTH, String(mediaProperies.videoWidth));
    LocalStorageUtil.setItem(MEDIA_VIDEO_HEIGHT, String(mediaProperies.videoHeight));
    LocalStorageUtil.setItem(MEDIA_VIDEO_FRAME_RATE, String(mediaProperies.videoFrameRate));
    LocalStorageUtil.setItem(MEDIA_VIDEO_RESOLUTION, JSON.stringify(mediaProperies.videoResolution));
    MediaProperiesUtil.writeVideoIsReflectionToStorage(mediaProperies.videoIsReflection);

    LocalStorageUtil.setItem('secondCameraInputDevice', mediaProperies.secondCameraInputDevice);

                    
  }

  public static readAudioInputDeviceToStorage(): string {
    return LocalStorageUtil.getItem(MEDIA_AUDIO_INPUT_DEVICE);
  }
  public static readAudioOutputDeviceToStorage(): string {
    return LocalStorageUtil.getItem(MEDIA_AUDIO_OUTPUT_DEVICE);
  }
  public static readVideoInputDeviceToStorage(): string {
    return LocalStorageUtil.getItem(MEDIA_VIDEO_INPUT_DEVICE);
  }
  public static readVideoIsReflectionToStorage(): boolean {
    return MediaProperiesUtil.mapBoolean(LocalStorageUtil.getItem(MEDIA_VIDEO_IS_REFLECTION), true);
  }


  public static readMediaProperiesFromStorage(): MediaProperies {
    const audioSampleRate = LocalStorageUtil.getItem(MEDIA_AUDIO_SAMPLE_RATE);
    const audioChannelCount = LocalStorageUtil.getItem(MEDIA_AUDIO_CHANNEL_COUNT);
    const videoWidth = LocalStorageUtil.getItem(MEDIA_VIDEO_WIDTH);
    const videoHeight = LocalStorageUtil.getItem(MEDIA_VIDEO_HEIGHT);
    const videoFrameRate = LocalStorageUtil.getItem(MEDIA_VIDEO_FRAME_RATE);
    const videoResolution = LocalStorageUtil.getItem(MEDIA_VIDEO_RESOLUTION);

    return {
      // Audio
      audioInpDev: MediaProperiesUtil.readAudioInputDeviceToStorage(),
      audioOutDev: MediaProperiesUtil.readAudioOutputDeviceToStorage(),
      audioSampleRate: (!!audioSampleRate ? parseFloat(audioSampleRate) : 192000),
      audioEchoCancellation: MediaProperiesUtil.mapBoolean(LocalStorageUtil.getItem(MEDIA_AUDIO_ECHO_CANCELLATION), true),
      audioNoiseSuppression: MediaProperiesUtil.mapBoolean(LocalStorageUtil.getItem(MEDIA_AUDIO_NOISE_SUPPRESSION), false),
      audioAutoGainControl: MediaProperiesUtil.mapBoolean(LocalStorageUtil.getItem(MEDIA_AUDIO_GAIN_CONTROL), false),
      audioChannelCount: (!!audioChannelCount ? parseFloat(audioChannelCount) : 1),
      // Video
      videoInpDev: MediaProperiesUtil.readVideoInputDeviceToStorage(),
      videoWidth: (!!videoWidth ? parseFloat(videoWidth) : environment.video.defaultWidth), // default if no data
      videoHeight: (!!videoHeight ? parseFloat(videoHeight) : environment.video.defaultHeight), // default if no data
      videoFrameRate: (!!videoFrameRate ? parseFloat(videoFrameRate) : undefined),
      videoIsReflection: MediaProperiesUtil.readVideoIsReflectionToStorage(),
      videoResolution: videoResolution ? JSON.parse(videoResolution) : null,
    //   Second Camera
      secondCameraInputDevice: LocalStorageUtil.getItem('secondCameraInputDevice')  
    };
  }
  private static mapBoolean(valueStr: string, defaultValue: boolean): boolean {
    return (valueStr != null ? (valueStr === 'true') : defaultValue);
  }

}

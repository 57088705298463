import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output
} from "@angular/core";
import {
	UntypedFormControl,
	UntypedFormGroup,
	Validators
} from "@angular/forms";
import { first, takeUntil } from "rxjs/operators";

import {
	DEBUG_VALUE_22,
	LocationUtil,
	PRM_DEBUG
} from "src/app/lib-core/utils/location.util";

import { CaptureMediaService } from "../../services/capture-media.service";
import { RoomStateStorage } from "../../components/call-room/service/storage/RoomStateStorage";
import { WebSocketService } from "../../services/ws/web-socket.service";
import { CallStorage } from "../../components/call-room/service/storage/call-storage.service";
import {
	CODE_MAX_LENGTH,
	DISALLOW_TRAILING_SPACES,
	TEXT_NAME_MAX_LENGTH
} from "../../constants/generic.constants";
import { RoomAuthService } from "../../services/ws/room-auth.service";
import { StorageUtil } from "../../utils/storage.util";
import { VideoSettingsService } from "../service/video-settings.service";
import { USER_KEY } from "../../constants/localstorage-constants";
import { BotService } from "../../services/bot/bot.service";
import { PlatformDetectorService } from "../../services/platform-detector/platform-detector.service";
import { LessonsService } from "../../services/lessons/lessons.service";
import { Subject } from "rxjs";

@Component({
	selector: "app-pre-call-info",
	templateUrl: "./pre-call-info.component.html"
})
export class PreCallInfoComponent implements OnInit, OnDestroy {
	public isSettingsDisplay: boolean;
	@Input() set displaySettings(val: boolean) {
		this.isSettingsDisplay = val;
	}
	@Output() doInputUserPassword = new EventEmitter<boolean>();
	@Output() displayPermission = new EventEmitter<boolean>();

	public showPass: boolean = false;
	private isDebug = false;
	public disallowTrailingSpaces = DISALLOW_TRAILING_SPACES;

	preCallInfoForm: UntypedFormGroup;
	destroy$ = new Subject();
	hidePassword = false;

	constructor(
		private captureMediaService: CaptureMediaService,
		private changeDetectorRef: ChangeDetectorRef,
		public roomState: RoomStateStorage,
		public callStorage: CallStorage,
		protected webSocketService: WebSocketService,
		private roomAuthService: RoomAuthService,
		private videoSettingsService: VideoSettingsService,
		private platformDetectorService: PlatformDetectorService
	) {
		this.isDebug =
			LocationUtil.findGetParameter(PRM_DEBUG) === DEBUG_VALUE_22;
		const password = LocationUtil.findGetParameter("password") || "";

		this.preCallInfoForm = new UntypedFormGroup({
			userName: new UntypedFormControl(
				window.localStorage.getItem("last_username") || "",
				[
					Validators.required,
					Validators.minLength(1),
					Validators.maxLength(TEXT_NAME_MAX_LENGTH)
				]
			),
			userPassword: new UntypedFormControl(
				sessionStorage.getItem("roomPassword") || password,
				[
					Validators.required,
					Validators.minLength(CODE_MAX_LENGTH),
					Validators.maxLength(TEXT_NAME_MAX_LENGTH)
				]
			)
		});

		this.preCallInfoForm.get("userName").valueChanges.subscribe((value) => {
			if (this.preCallInfoForm.get("userName").valid) {
				window.localStorage.setItem("last_username", value.trim());
			}
		});
		window.localStorage.setItem("callInfoComponent", "true");
	}

	ngOnInit() {
		this.hidePassword = sessionStorage.getItem("hidePassword") === "true";
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		const roomKey = urlParams.get("roomKey");
		if (roomKey) {
			this.hidePassword = true;
		}
		BotService.recordingBotEvent$.subscribe((event: any) => {
			if (event.type === "continueWaitingRoom") {
				sessionStorage.setItem(USER_KEY, event.userName.trim());
				window.localStorage.setItem(
					"last_username",
					event.userName.trim()
				);
				this.continueWaitingRoom(false);
			}
		});
		BotService.componentComplete$.next("preCallInfo");
		LessonsService.startSession$
			.pipe(takeUntil(this.destroy$))
			.subscribe((start) => {
				if (start) {
					this.continueWaitingRoom();
				}
			});
	}

	ngOnDestroy(): void {
		this.videoSettingsService.unsubscribe();
		this.destroy$.next();
		this.destroy$.complete();
	}

	public replaceSpace(event) {
		event.target.value = event.target.value.replace(/\s/g, "");
	}
	public disableContinueSettingsButton() {
		if (this.isSettingsDisplay || this.hidePassword) {
			return !this.preCallInfoForm.get("userName").valid;
		}
		return (
			!this.preCallInfoForm.get("userName").valid ||
			!this.preCallInfoForm.get("userPassword").valid
		);
	}

	public togglePass() {
		this.showPass = !this.showPass;
	}

	async handleContinueWaitingRoom(event) {
		event.preventDefault();
		event.stopPropagation();
		this.videoSettingsService.checkPermissions().subscribe(async (data) => {
			if (typeof data === "object" && data !== null) {
				const { cam, mic } = data;
				let hasPermission = (cam && mic) || (mic && !cam);
				if (
					this.platformDetectorService.isBrowserSafari() &&
					this.platformDetectorService.getSafariVersion() >= 16
				) {
					hasPermission = true;
				}
				this.displayPermission.emit(!hasPermission);
			} else {
				this.displayPermission.emit(!data);
			}
		});
		this.continueWaitingRoom();
	}

	continueWaitingRoom(registerName = true) {
		const userName = this.preCallInfoForm.get("userName").value.trim();
		if (registerName) {
			sessionStorage.setItem(USER_KEY, userName);
		}
		if (this.isSettingsDisplay || this.hidePassword) {
			this.enableCamAndMicByDefault();
			this.videoSettingsService.saveNameEnteredState(true);
		} else {
			sessionStorage.roomPassword =
				this.preCallInfoForm.value.userPassword;
			this.roomAuthService
				.checkJoinPossibility(this.callStorage.roomId)
				.pipe(first())
				.subscribe((value) => {
					if (value.error) {
						// todo remove
						this.preCallInfoForm
							.get("userPassword")
							.setErrors({ incorrect: true });
						this.preCallInfoForm.get("userPassword").markAsDirty();
					} else {
						this.enableCamAndMicByDefault();
						this.isSettingsDisplay = true;
						this.videoSettingsService.saveNameEnteredState(true);
					}
				});
		}
		window.localStorage.removeItem("callInfoComponent");
		this.changeDetectorRef.markForCheck();
	}

	private enableCamAndMicByDefault() {
		this.roomState.isCamOnImSure = this.roomState.isCamOn;
		StorageUtil.writeMediaPrmsToSessionStorage({
			isCam: this.roomState.isCamOn
		});
		this.roomState.isMicroOnImSure = this.roomState.isMicroOn;
		StorageUtil.writeMediaPrmsToSessionStorage({
			isMicro: this.roomState.isMicroOn
		});
	}
}

<div class="mat-container">

	@if (isBetaTester) {
	<!-- <div class="title-wrapper">
		<h1 class="availability-title">{{ "availability.page-title-beta" | translate }}</h1>
	</div> -->
	<mat-tab-group (selectedIndexChange)="selectedIndexChange($event)" [(selectedIndex)]="selectedIndex">
		<mat-tab labelClass="availability-tab" label="{{ 'availability.for-trial-lessons' | translate }}">
			<app-availability lessonType="trial" />
		</mat-tab>
		<mat-tab labelClass="availability-tab" label="{{ 'availability.for-regular-lessons' | translate }}">
			<app-availability lessonType="regular" />
		</mat-tab>
	</mat-tab-group>
	} @else {
	<!-- <div class="title-wrapper">
		<h1 class="availability-title">{{ "availability.page-title" | translate }}</h1>
	</div> -->
	<app-availability lessonType="trial" />
	}

</div>
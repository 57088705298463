<form [formGroup]="preCallInfoForm">
	<div class="row-container-start-new">
		<label>{{ "video-settings.user-name-label" | translate }}</label>
		<div class="user-name">
			<input
				id="userName"
				class="form-control-new"
				formControlName="userName"
				type="text"
				[placeholder]="'video-settings.waiting-room-placeholder-1' | translate"
				maxlength="60"
			/>
			<h6 [hidden]="preCallInfoForm.get('userName').valid || !preCallInfoForm.get('userName').dirty" class="error-message">
				{{ "video-settings.enter-name" | translate }}
			</h6>
			<h6 [hidden]="!preCallInfoForm.get('userName').valid && preCallInfoForm.get('userName').dirty" class="error-message">&nbsp;</h6>
		</div>
		<span *ngIf="!isSettingsDisplay && !hidePassword">
			<label>{{ "lobby-popup-desktop.password-label-2" | translate }}</label>
			<div class="user-name">
				<div class="password-field-outer">
					<input
						id="userPassword"
						class="form-control-new"
						[placeholder]="'video-settings.waiting-room-placeholder-2' | translate"
						formControlName="userPassword"
						onkeyup="this.value = this.value.replace(/\s/g,'');"
						(change)="replaceSpace($event)"
						(input)="doInputUserPassword.emit()"
						type="{{ showPass ? 'text' : 'password' }}"
						maxlength="60"
					/>
					<div class="toggle-password-visibility" [ngClass]="{ show: showPass, hide: !showPass }" (click)="togglePass()"></div>
				</div>
				<h6
					[hidden]="preCallInfoForm.get('userPassword').valid || !preCallInfoForm.get('userPassword').dirty"
					class="error-message pass"
				>
					{{ "lobby-popup-desktop.password-incorrect" | translate }}
				</h6>
			</div>
		</span>
	</div>
	<div *ngIf="!isSettingsDisplay && !hidePassword" class="form-footer">
		<div class="terms-conditions">
			{{ "video-settings.terms-conditions" | translate }}
			<a href="https://sirius.video/terms" target="_blank">{{ "video-settings.terms" | translate }}</a>
			{{ "video-settings.and" | translate }}
			<a href="https://sirius.video/privacy" target="_blank">{{ "video-settings.policy" | translate }}</a
			>.
		</div>
	</div>
	<button class="new-button" [disabled]="disableContinueSettingsButton()" (click)="handleContinueWaitingRoom($event)">
		{{ "buttons.continue" | translate }}
	</button>
	<div *ngIf="!isSettingsDisplay && !hidePassword" class="form-footer teacher-question">
		<div class="question-log">
			{{ "video-settings.question-log" | translate }} <a routerLink="/login">{{ "video-settings.log-in" | translate }}</a>
		</div>
	</div>
</form>

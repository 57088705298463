import { Component, ViewChild } from "@angular/core";
import { LessonsService } from "../services/lessons/lessons.service";
import { ProfileService } from "../profile/profile.service";
import { Profile } from "../types/profile.types";
import { catchError, flatMap, map, switchMap } from "rxjs/operators";
import { forkJoin, of } from "rxjs";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { QualityRatingService } from "../lm-quality-rating/services/quality-rating.service";
import { STUDENT_ATTENDANCES } from "../lm-quality-rating/lm-quality-rating.constants";
import {
	ActivationStatus,
	Student,
	StudentsService
} from "../students/students.service";
import { PopupService } from "../services/popup/popup.service";
import { LessonsCommunicationService } from "../lessons-overview/services/lessons-communication.service";

@Component({
	selector: "app-lesson-statistics",
	templateUrl: "./lesson-statistics.component.html",
	styleUrls: ["./lesson-statistics.component.scss"]
})
export class LessonStatisticsComponent {
	public dataSource = new MatTableDataSource<any>();
	public total: number;
	public columnsToDisplay = [
		"student",
		"date",
		"time",
		"duration",
		"status",
		"menu"
	];
	public lessonsList = [];
	public months = [
		"january",
		"february",
		"march",
		"april",
		"may",
		"june",
		"july",
		"august",
		"september",
		"october",
		"november",
		"december"
	];
	public years = [2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030];
	public selectedMonth: number;
	public selectedYear: number;
	public lessonTypes = ["trial", "regular"];
	public pageIndex = 0;
	// public selectedLessonType: "trial" | "regular" = "trial";
	public selectedLessonType: "trial" | "regular" = "trial";
	public students: Student[];
	public selectedStudent: Student["id"] | "all" = "all";
	private userId: string;
	private subrole: number;
	private timeZone: string;

	@ViewChild(MatPaginator) paginator: MatPaginator;

	constructor(
		private lessonService: LessonsService,
		private profile: ProfileService,
		private qualityRatingService: QualityRatingService,
		private studentsService: StudentsService,
		private popupService: PopupService,
		private lessonsComunication: LessonsCommunicationService
	) {
		const date = new Date();
		this.selectedMonth = date.getMonth();
		this.selectedYear = date.getFullYear();
		this.lessonsComunication.dataUpdated$.subscribe(() => {
			this.initLessonsList(true);
			this.initStudents();
		});
	}

	ngOnInit(): void {
		this.profile.get().subscribe((profile: Profile) => {
			this.userId = profile.userId;
			this.subrole = profile.subrole;
			this.timeZone = profile.timezone;
			this.initLessonsList();
			this.initStudents();
		});
	}

	initStudents(): void {
		this.studentsService
			.getStudents(this.userId, "1", "1000")
			.subscribe((res) => {
				this.students = res.list.filter(
					(student) =>
						student.activationStatus === ActivationStatus.ACTIVATED
				);
			});
	}

	initLessonsList(firstPage?: boolean): void {
		function getLocalTimeFromTimeStamp(
			timestamp: string,
			timeZone: string
		) {
			const date = new Date(timestamp);
			const localTime = new Intl.DateTimeFormat("en-US", {
				timeZone: timeZone,
				hour: "2-digit",
				minute: "2-digit",
				hour12: false
			}).format(date);
			return localTime;
		}

		function getDateFromTimeStamp(timestamp: string, timeZone: string) {
			const date = new Date(timestamp);
			const localDate = new Intl.DateTimeFormat("en-UK", {
				timeZone: timeZone,
				day: "2-digit",
				month: "2-digit",
				year: "numeric"
			}).format(date);
			return localDate.replace(/\//g, ".");
		}

		function getAttendanceTitle(attendance: string) {
			const attendanceItem = STUDENT_ATTENDANCES.find(
				(item) => item.value === attendance
			);
			return attendanceItem ? attendanceItem.title : attendance;
		}

		this.lessonService
			.getPastBookingsByMonth({
				userId: this.userId,
				page: String(this.pageIndex + 1),
				limit: "10",
				month: this.selectedMonth,
				year: this.selectedYear,
				studentId:
					this.selectedStudent === "all" ? "" : this.selectedStudent,
				lessonType: this.selectedLessonType
			})
			.pipe(
				switchMap((responseList) => {
					const { list, count } = responseList; // bookings list

					this.total = count;

					if (!count) return of([]);

					// to do: add to booking response
					const requests = list.map((item) => {
						return this.qualityRatingService
							.getLessonFeedback(item.id)
							.pipe(
								map((requestData) => {
									return {
										...item,
										studentAttendance:
											requestData["studentAttendance"]
									};
								}),
								catchError((error) => {
									return of({
										...item,
										studentAttendance: null
									});
								})
							);
					});

					return forkJoin(requests);
				})
			)
			.subscribe((mergedList) => {
				this.lessonsList = mergedList
					.filter((item) => item !== null) // Filter out any null values resulting from errors in the requests
					.map((item) => ({
						student: item?.student?.name !== "undefined " ? item?.student?.name : "  ",
						date: getDateFromTimeStamp(
							item.startTime,
							this.timeZone
						),
						time: getLocalTimeFromTimeStamp(
							item.startTime,
							this.timeZone
						),
						duration: item.duration,
						studentAttendance: getAttendanceTitle(
							item.studentAttendance
						),
						targetTeacher: item.targetTeacher,
						id: item.id,
						teacher: item.teacher,
						manualAttendanceStatus: item.manualAttendanceStatus,
						bookingType: item.type
					}));
				if (firstPage) {
					this.paginator.firstPage();
				}
				this.dataSource.data = this.lessonsList;
			});
	}

	onFilterChange(): void {
		this.pageIndex = 0;
		this.initLessonsList(true);
	}

	edit(lesson) {
		this.popupService.openLessonSummaryDialog(lesson);
	}

	onPageChange(event) {
		this.pageIndex = event.pageIndex;
		this.initLessonsList();
	}
}

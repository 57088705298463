import { Injectable } from "@angular/core";

@Injectable({
	providedIn: "root",
})
export class QuillService {
	private increment = 0;
	constructor() {}

	get toolbarId(): string {
		return `toolbar-${this.increment++}`;
	}
    
}

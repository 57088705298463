<div class="page">
  <div class="org-list">
    <div class="controls">
      <button class="blue-ui-button new-button"
              (click)="onNewClicked()">Create New</button>
    </div>
    <app-user *ngFor="let user of users" [userEntry]="user" (userUpdate)="updateList()">
    </app-user>
  </div>
  <div class="pagination" *ngIf="maxPages >1">
    <a *ngIf="pageNumber - 1 > 0"
       [routerLink]="['/admin/users-list']"
       [queryParams]="{ organization: organizationID, page : pageNumber - 1 }">
      <
    </a>
    <a *ngFor="let i of pages"
       [class.selected]="pageNumber == i"
       [routerLink]="['/admin/users-list']"
       [queryParams]="{ organization: organizationID, page : i }">
      {{i}}
    </a>
    <a *ngIf="pageNumber < maxPages"
       [routerLink]="['/admin/users-list']"
       [queryParams]="{ organization: organizationID, page : pageNumber + 1 }">
      >
    </a>
  </div>
</div>

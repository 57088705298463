<h1>{{ "profile.title" | translate }}</h1>

<form class="profile-table" [formGroup]="form">
	<div class="flex flex-wrap justify-between mb-4 profile-row md:flex-nowrap">
		<div class="order-1 profile-param basis-40">
			{{ "profile.first-name-label" | translate }}
		</div>
		<div class="order-3 profile-param basis-full md:basis-60 md:order-2">
			<div class="param-view" *ngIf="!showFields['firstName']">
				<label class="param-label">{{
					form.get("firstName").value
				}}</label>
			</div>
			<ng-container *ngIf="showFields['firstName']">
				<div class="profile-field">
					<input
						type="text"
						class="default-ui-field"
						formControlName="firstName"
						[pattern]="disallowTrailingSpaces"
						[placeholder]="
							'profile.first-name-placeholder' | translate
						"
						maxlength="60"
					/>
					<div
						class="message error"
						*ngIf="
							form.get('firstName').dirty &&
							form.get('firstName').invalid
						"
					>
						{{ "profile.first-name-error" | translate }}
					</div>
				</div>
				<div class="profile-buttons">
					<button
						class="default-ui-button blue-ui-button"
						[disabled]="form.get('firstName').invalid"
						(click)="
							saveField('firstName', {
								firstname: form.get('firstName').value
							})
						"
					>
						{{ "buttons.save" | translate }}
					</button>
					<button
						class="default-ui-button white-ui-button"
						(click)="cancelField('firstName')"
					>
						{{ "buttons.cancel" | translate }}
					</button>
				</div>
			</ng-container>
		</div>
		<div class="order-2 profile-param basis-32 md:order-3">
			<button
				*ngIf="!showFields['firstName']"
				class="transparent-ui-button"
				(click)="showField('firstName')"
			>
				{{ "buttons.edit" | translate }}
			</button>
		</div>
	</div>

	<div class="flex flex-wrap justify-between mb-4 profile-row md:flex-nowrap">
		<div class="order-1 profile-param basis-40">
			{{ "profile.last-name-label" | translate }}
		</div>
		<div class="order-3 profile-param basis-full md:basis-60 md:order-2">
			<div class="param-view" *ngIf="!showFields['lastName']">
				<label class="param-label">{{
					form.get("lastName").value
				}}</label>
			</div>
			<ng-container *ngIf="showFields['lastName']">
				<div class="profile-field">
					<input
						type="text"
						class="default-ui-field"
						formControlName="lastName"
						[pattern]="disallowTrailingSpaces"
						[placeholder]="
							'profile.last-name-placeholder' | translate
						"
						maxlength="60"
					/>
					<div
						class="message error"
						*ngIf="
							form.get('lastName').dirty &&
							form.get('lastName').invalid
						"
					>
						{{ "profile.last-name-error" | translate }}
					</div>
				</div>
				<div class="profile-buttons">
					<button
						class="default-ui-button blue-ui-button"
						[disabled]="form.get('lastName').invalid"
						(click)="
							saveField('lastName', {
								lastname: form.get('lastName').value
							})
						"
					>
						{{ "buttons.save" | translate }}
					</button>
					<button
						class="default-ui-button white-ui-button"
						(click)="cancelField('lastName')"
					>
						{{ "buttons.cancel" | translate }}
					</button>
				</div>
			</ng-container>
		</div>
		<div class="order-2 profile-param basis-32 md:order-3">
			<button
				*ngIf="!showFields['lastName']"
				class="transparent-ui-button"
				(click)="showField('lastName')"
			>
				{{ "buttons.edit" | translate }}
			</button>
		</div>
	</div>

	<div class="flex flex-wrap justify-between mb-4 profile-row md:flex-nowrap">
		<div class="order-1 profile-param basis-40">
			{{ "profile.instruments-label" | translate }}
		</div>
		<div class="order-3 profile-param basis-full md:basis-60 md:order-2">
			<div class="param-view" *ngIf="!showFields['instruments']">
				<label class="param-label">
					<ng-container
						*ngFor="let i of form.get('instruments').value"
					>
						{{ "instruments." + i | translate }}
					</ng-container>
				</label>
			</div>
			<ng-container *ngIf="showFields['instruments']">
				<div class="profile-field">
					<mat-select
						formControlName="instruments"
						class="s-mat-select app-select"
						panelClass="s-mat-checkbox app-select-panel s-mat-select-panel border"
						multiple
						[placeholder]="
							'teacher-onboarding.instrument.placeholder'
								| translate
						"
					>
						<mat-option
							*ngFor="let i of instrumentsOptions"
							[value]="i.value"
						>
							{{ i.title | translate }}
						</mat-option>
					</mat-select>
					<div
						class="message error"
						*ngIf="
							form.get('instruments').dirty &&
							form.get('instruments').invalid
						"
					>
						{{ "profile.instruments-error" | translate }}
					</div>
				</div>
				<div class="profile-buttons">
					<button
						class="default-ui-button blue-ui-button"
						[disabled]="form.get('instruments').invalid"
						(click)="
							saveField('instruments', {
								instruments: form.get('instruments').value
							})
						"
					>
						{{ "buttons.save" | translate }}
					</button>
					<button
						class="default-ui-button white-ui-button"
						(click)="cancelField('instruments')"
					>
						{{ "buttons.cancel" | translate }}
					</button>
				</div>
			</ng-container>
		</div>
		<div class="order-2 profile-param basis-32 md:order-3">
			<button
				*ngIf="!showFields['instruments']"
				class="transparent-ui-button"
				(click)="showField('instruments')"
			>
				{{ "buttons.edit" | translate }}
			</button>
		</div>
	</div>

	<div class="flex flex-wrap justify-between mb-4 profile-row md:flex-nowrap">
		<div class="order-1 profile-param basis-40">
			{{ "profile.musicGenres-label" | translate }}
		</div>
		<div class="order-3 profile-param basis-full md:basis-60 md:order-2">
			<div class="param-view" *ngIf="!showFields['musicGenres']">
				<label class="param-label">
					<ng-container
						*ngFor="let i of form.get('musicGenres').value"
					>
						{{ "musicGenres." + i | translate }}
					</ng-container>
				</label>
			</div>
			<ng-container *ngIf="showFields['musicGenres']">
				<div class="profile-field">
					<mat-select
						formControlName="musicGenres"
						class="s-mat-select app-select"
						panelClass="s-mat-checkbox app-select-panel s-mat-select-panel border"
						multiple
						[placeholder]="
							'teacher-onboarding.genre.placeholder' | translate
						"
					>
						<mat-option
							*ngFor="let i of genresOptions"
							[value]="i.value"
						>
							{{ i.title | translate }}
						</mat-option>
					</mat-select>
					<div
						class="message error"
						*ngIf="
							form.get('musicGenres').dirty &&
							form.get('musicGenres').invalid
						"
					>
						{{ "profile.musicGenres-error" | translate }}
					</div>
				</div>
				<div class="profile-buttons">
					<button
						class="default-ui-button blue-ui-button"
						[disabled]="form.get('musicGenres').invalid"
						(click)="
							saveField('musicGenres', {
								musicGenres: form.get('musicGenres').value
							})
						"
					>
						{{ "buttons.save" | translate }}
					</button>
					<button
						class="default-ui-button white-ui-button"
						(click)="cancelField('musicGenres')"
					>
						{{ "buttons.cancel" | translate }}
					</button>
				</div>
			</ng-container>
		</div>
		<div class="order-2 profile-param basis-32 md:order-3">
			<button
				*ngIf="!showFields['musicGenres']"
				class="transparent-ui-button"
				(click)="showField('musicGenres')"
			>
				{{ "buttons.edit" | translate }}
			</button>
		</div>
	</div>

	<div class="flex flex-wrap justify-between mb-4 profile-row md:flex-nowrap">
		<div class="order-1 profile-param basis-40">
			{{ "profile.timezone-label" | translate }}
		</div>
		<div class="order-3 profile-param basis-full md:basis-60 md:order-2">
			<div class="param-view" *ngIf="!showFields['timezone']">
				<div class="param-label">
					<ng-container *ngFor="let timezone of timezones">
						<ng-container *ngIf="timezone === timeZone">
							<label class="line-clamp">{{ timezone }}</label>
						</ng-container>
					</ng-container>
				</div>
			</div>
			<ng-container *ngIf="showFields['timezone']">
				<div class="profile-field">
					<mat-select
						formControlName="timezone"
						class="s-mat-select app-select"
						panelClass="s-mat-checkbox app-select-panel s-mat-select-panel border"
					>
						<mat-option
							*ngFor="let timezone of timezones"
							[value]="timezone"
						>
							{{ timezone }}
						</mat-option>
					</mat-select>
				</div>
				<div class="profile-buttons">
					<button
						class="default-ui-button blue-ui-button"
						[disabled]="form.get('timezone').invalid"
						(click)="
							saveField('timezone', { timezone: this.timeZone })
						"
					>
						{{ "buttons.save" | translate }}
					</button>
					<button
						class="default-ui-button white-ui-button"
						(click)="cancelField('timezone')"
					>
						{{ "buttons.cancel" | translate }}
					</button>
				</div>
			</ng-container>
		</div>
		<div class="order-2 profile-param basis-32 md:order-3">
			<button
				*ngIf="!showFields['timezone']"
				class="transparent-ui-button"
				(click)="showField('timezone')"
			>
				{{ "buttons.edit" | translate }}
			</button>
		</div>
	</div>

	<div class="flex flex-wrap justify-between mb-4 profile-row md:flex-nowrap">
		<div class="order-1 profile-param basis-40">
			{{ "profile.email-label" | translate }}
		</div>
		<div class="order-3 profile-param basis-full md:basis-60 md:order-2">
			<div class="param-view" *ngIf="!showFields['email']">
				<label class="param-label ellipsis">{{
					form.get("emailGroup.email").value
				}}</label>
			</div>
			<div *ngIf="showFields['email']" formGroupName="emailGroup">
				<div class="profile-field">
					<input
						type="text"
						class="default-ui-field"
						formControlName="email"
						[placeholder]="'profile.email-placeholder' | translate"
						maxlength="60"
					/>
					<div
						class="message error"
						*ngIf="
							form.get('emailGroup.email').dirty &&
							form.get('emailGroup.email').invalid &&
							!form
								.get('emailGroup.email')
								.hasError('emailExists')
						"
					>
						{{ "profile.email-error" | translate }}
					</div>
					<div
						class="message error"
						*ngIf="
							form.get('emailGroup.email').hasError('emailExists')
						"
					>
						{{ "sign-up.email-exists" | translate }}
					</div>
				</div>
				<div class="profile-field">
					<div class="message">
						{{ "profile.password-label" | translate }}
					</div>
					<div class="password-field-outer">
						<input
							autocomplete="new-password"
							[type]="isShowPassEmail ? 'text' : 'password'"
							class="default-ui-field"
							formControlName="password"
							[placeholder]="
								'profile.password-placeholder' | translate
							"
						/>
						<div
							class="toggle-password-visibility"
							[ngClass]="[isShowPassEmail ? 'show' : 'hide']"
							(click)="toggleShowPassEmail()"
						></div>
					</div>
				</div>
				<div
					class="message error"
					*ngIf="form.get('emailGroup.password').hasError('is')"
				>
					{{ "lobby-popup-desktop.password-incorrect" | translate }}
				</div>
				<div class="profile-buttons">
					<button
						class="default-ui-button blue-ui-button"
						[disabled]="form.get('emailGroup').invalid"
						(click)="
							saveField(
								'email',
								{
									email: form.get('emailGroup.email').value,
									password: form.get('emailGroup.password')
										.value
								},
								'emailGroup'
							)
						"
					>
						{{ "buttons.save" | translate }}
					</button>
					<button
						class="default-ui-button white-ui-button"
						(click)="cancelField('email', 'emailGroup')"
					>
						{{ "buttons.cancel" | translate }}
					</button>
				</div>
			</div>
		</div>
		<div class="order-2 profile-param basis-32 md:order-3">
			<button
				*ngIf="!showFields['email']"
				class="transparent-ui-button"
				(click)="showField('email')"
			>
				{{ "buttons.edit" | translate }}
			</button>
		</div>
	</div>

	<div class="flex flex-wrap justify-between mb-4 profile-row md:flex-nowrap">
		<div class="order-1 profile-param basis-40">
			{{ "profile.login-password-label" | translate }}
		</div>
		<div class="order-3 profile-param basis-full md:basis-60 md:order-2">
			<div class="param-view" *ngIf="!showFields['password']">
				<div class="param-label">
					<label>{{
						"profile.login-password-value" | translate
					}}</label>
					<div class="message success" *ngIf="passwordUpdated">
						{{ "profile.login-password-changed" | translate }}
					</div>
				</div>
			</div>
			<div
				class="password-column"
				*ngIf="showFields['password']"
				formGroupName="passwordGroup"
			>
				<div class="profile-field">
					<div class="password-field-outer">
						<input
							autocomplete="off"
							[type]="isShowPass ? 'text' : 'password'"
							class="default-ui-field"
							formControlName="password"
							[placeholder]="
								'profile.old-password-placeholder' | translate
							"
						/>
						<div
							class="toggle-password-visibility"
							[ngClass]="[isShowPass ? 'show' : 'hide']"
							(click)="toggleShowPass()"
						></div>
					</div>
				</div>
				<div class="profile-field">
					<div class="field-new-password">
						<div class="password-field-outer">
							<input
								autocomplete="off"
								[type]="isShowNewPass ? 'text' : 'password'"
								class="default-ui-field"
								formControlName="newPassword"
								[placeholder]="
									'profile.new-password-placeholder'
										| translate
								"
							/>
							<div
								class="toggle-password-visibility"
								[ngClass]="[isShowNewPass ? 'show' : 'hide']"
								(click)="toggleShowNewPass()"
							></div>
						</div>
						<app-password-requirements
							class="password-requirements"
							[hasEnoughPassLength]="hasEnoughPassLength"
							[hasNumberOrSpecial]="hasNumberOrSpecial"
							[isNotEmpty]="isNotEmpty"
						>
						</app-password-requirements>
					</div>
					<div
						class="message error"
						*ngIf="
							form.get('passwordGroup.newPassword').dirty &&
							form.get('passwordGroup.newPassword').invalid
						"
					>
						{{ "profile.new-password-error" | translate }}
					</div>
				</div>
				<div class="profile-field">
					<div class="password-field-outer">
						<input
							autocomplete="off"
							[type]="isShowConfPass ? 'text' : 'password'"
							class="default-ui-field"
							formControlName="confirmPassword"
							[placeholder]="
								'profile.confirm-password-placeholder'
									| translate
							"
						/>
						<div
							class="toggle-password-visibility"
							[ngClass]="[isShowConfPass ? 'show' : 'hide']"
							(click)="toggleShowConfPass()"
						></div>
					</div>
					<div
						class="message error"
						*ngIf="
							form.get('passwordGroup.newPassword').touched &&
							form.get('passwordGroup').errors?.passwordsMismatch
						"
					>
						{{ "profile.passwords-mismatch-error" | translate }}
					</div>
				</div>
				<div
					class="message error"
					*ngIf="form.get('passwordGroup.password').hasError('is')"
				>
					{{ "lobby-popup-desktop.password-incorrect" | translate }}
				</div>
				<div class="profile-buttons">
					<button
						class="default-ui-button blue-ui-button"
						[disabled]="form.get('passwordGroup').invalid"
						(click)="
							saveField(
								'password',
								{
									password: form.get('passwordGroup.password')
										.value,
									new_password: form.get(
										'passwordGroup.newPassword'
									).value
								},
								'passwordGroup'
							)
						"
					>
						{{ "buttons.save" | translate }}
					</button>
					<button
						class="default-ui-button white-ui-button"
						(click)="cancelField('password', 'passwordGroup')"
					>
						{{ "buttons.cancel" | translate }}
					</button>
				</div>
			</div>
		</div>
		<div class="order-2 profile-param basis-32 md:order-3">
			<button
				*ngIf="!showFields['password']"
				class="transparent-ui-button"
				(click)="showField('password')"
			>
				{{ "buttons.edit" | translate }}
			</button>
		</div>
	</div>

	<div class="flex flex-wrap justify-between mb-4 profile-row md:flex-nowrap">
		<div class="order-1 profile-param basis-40">
			{{ "profile.language-label" | translate }}
		</div>
		<div class="order-3 profile-param basis-full md:basis-60 md:order-2">
			<div class="param-view">
				<div class="param-label">
					<ng-container *ngFor="let language of languages">
						<ng-container
							*ngIf="language._id === form.get('language').value"
						>
							<label>{{ language.name }}</label>
						</ng-container>
					</ng-container>
				</div>
			</div>
			<ng-container *ngIf="showFields['language']">
				<div class="profile-field">
					<select formControlName="language">
						<!-- <option value="">{{ 'select.please-select' | translate }}</option> -->
						<option
							*ngFor="let language of languages"
							[value]="language._id"
							[selected]="
								language._id === form.get('language').value
							"
						>
							{{ language.name }}
						</option>
					</select>
				</div>
				<div class="profile-buttons">
					<button
						class="default-ui-button blue-ui-button"
						[disabled]="form.get('language').invalid"
						(click)="
							saveField('language', {
								language: form.get('language').value
							})
						"
					>
						{{ "buttons.save" | translate }}
					</button>
					<button
						class="default-ui-button white-ui-button"
						(click)="cancelField('language')"
					>
						{{ "buttons.cancel" | translate }}
					</button>
				</div>
			</ng-container>
		</div>
		<div class="order-2 profile-param basis-32 md:order-3">
			<button
				*ngIf="!showFields['language']"
				class="transparent-ui-button"
				(click)="showField('language')"
			>
				{{ "buttons.edit" | translate }}
			</button>
		</div>
	</div>

	<ng-container
		*hideForSubroles="['studentMatched', 'teacherMatched', 'secretary']"
	>
		<div
			class="flex flex-wrap justify-between mb-3 profile-row md:flex-nowrap"
			*ngIf="tariffType !== Tariff.INSTITUTIONS"
		>
			<div class="order-1 profile-param basis-40">
				{{ "profile.subscription" | translate }}
			</div>
			<div
				class="order-3 profile-param basis-full md:basis-60 md:order-2"
			>
				<div class="param-view" *ngIf="!showFields['tariff']">
					<label class="param-label upper-case ellipsis">
						{{ tariffLabel | translate }}
						{{
							(isTariffExpired
								? "profile.subscription-expired"
								: ""
							) | translate
						}}
					</label>
				</div>
				<ng-container *ngIf="showFields['tariff']">
					<label class="param-label upper-case param-tariff">
						{{ tariffLabel | translate }}
						{{
							(isTariffExpired
								? "profile.subscription-expired"
								: ""
							) | translate
						}}
					</label>
					<div
						class="message tariff-subscription"
						[innerHTML]="
							'profile.terminate-subscription' | translate
						"
					></div>
					<button
						class="default-ui-button white-ui-button button-half"
						(click)="showFields.tariff = false"
					>
						{{ "buttons.cancel" | translate }}
					</button>
				</ng-container>
			</div>
			<div class="order-2 profile-param basis-32 md:order-3">
				<button
					*ngIf="!showFields['tariff']"
					class="transparent-ui-button"
					(click)="showField('tariff')"
				>
					{{ "buttons.edit" | translate }}
				</button>
			</div>
		</div>
	</ng-container>

	<ng-container *showForSubroles="['studentMatched']">
		<div
			class="flex flex-wrap justify-between mb-3 profile-row md:flex-nowrap"
		>
			<div class="order-1 profile-param basis-40">
				{{ "profile.billing-label" | translate }}
			</div>
			<div
				class="order-2 profile-param basis-full md:basis-60 md:order-2"
			>
				<div class="param-view">
					<a class="text-black billing-link" (click)="openPortal()">
						<label
							class="flex align-middle param-label billing-link"
							>{{ "profile.billing-link" | translate }}
							<mat-icon class="portal-icon billing-link"
								>open_in_new</mat-icon
							>
						</label>
					</a>
				</div>
			</div>

			<!-- spacer -->
			<div
				[style.visibility]="'hidden'"
				class="order-2 profile-param basis-32 md:order-3"
			>
				<button>{{ "buttons.edit" | translate }}</button>
			</div>
		</div>
	</ng-container>
</form>

<h2>
    {{ 'setup.device-browser-tab.title' | translate }}
</h2>

<div>
    <div class="flex mb-4" [ngClass]="[(isDesktop ? 'text-sirius-success-green' : 'text-sirius-info-blue')]">
        <div class="mt-1 mr-4">
            <mat-icon class="!w-8 !h-8 text-[2rem]">{{isDesktop ? 'check_circle' : 'info_circle'}}</mat-icon>
        </div>
        <div>
            <p class="font-bold">{{ 'setup.device-browser-tab.device' | translate }}: {{deviceType | titlecase}}</p>
            <p class="!text-sirius-black">
                {{ (isDesktop ? 'setup.device-browser-tab.good-device-message' :
                'setup.device-browser-tab.bad-device-message') | translate }}
            </p>
        </div>
    </div>
    <div class="flex" [ngClass]="[(isGoodBrowser ? 'text-sirius-success-green' : 'text-sirius-info-blue')]">
        <div class="mt-1 mr-4">
            <mat-icon class="!w-8 !h-8 text-[2rem]">{{isGoodBrowser ? 'check_circle' : 'info_circle'}}</mat-icon>
        </div>
        <div>
            <p class="font-bold">{{ 'setup.device-browser-tab.browser' | translate }}: {{browser}}</p>
            <p class="!text-sirius-black">
                {{ (isGoodBrowser ? goodBrowserMessage : badBrowserMessage) | translate }}
            </p>

            <!-- todo - add safari dl -->
            <div *ngIf="!isChrome && !isGoodBrowser" class="!text-sirius-black font-bold flex mt-4 items-center">
                <span class="material-symbols-outlined">
                    download
                </span>
                <a class="underline visited:text-sirius-black" target="_blank" [href]="googleDownloadLink">
                    {{'setup.device-browser-tab.download-chrome' | translate}}
                </a>
            </div>

        </div>
    </div>
</div>

<div class="button-section">
    <button class="blue-ui-button" (click)="onCompleted()">
        {{'setup.device-browser-tab.continue' | translate}}<span
            class="ml-2 leading-6 material-symbols-outlined">arrow_forward</span>
    </button>
</div>
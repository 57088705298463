import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { getSubDomain } from "src/app/helpers";
import { Tariff } from "src/app/types/tariff-constants";
import { SubRole } from "src/app/constants/subroles-constants";
import { AuthenticationService } from "../authentication/authentication.service";

@Injectable({
	providedIn: "root",
})
export class AccessControlService {
	private isSchool: boolean;
	private accessControl;
	private subrole: SubRole;
	private tariff: Tariff;
	private loaded: Promise<void>;

	constructor(
		private http: HttpClient,
		private authenticationService: AuthenticationService
	) {
		this.isSchool = !!getSubDomain();
		this.authenticationService.isLoggedIn$.subscribe((isLoggedIn) => {
			if (!isLoggedIn) {
				this.subrole = null;
				this.tariff = null;
				this.loaded = null;
			} else if (isLoggedIn) {
				this.loaded = this.loadData();
			}
		});
	}

	private async loadData(): Promise<void> {
		if (!this.accessControl) {
			const accessControl = await this.http
				.get("assets/access-control.json")
				.toPromise();
			this.accessControl = accessControl;
		}
		const { subrole, subscription } = await this.authenticationService
			.profile;
		this.subrole = subrole;
		this.tariff = subscription.type;
	}

	public async hasAccess(resource: string): Promise<boolean> {
		await this.loaded;
		return this.getResourceAccess(resource);
	}

	private getResourceAccess(resource: string): boolean {
		const [parent, child] = resource.split(".");
		let accessPath;
		if (this.isSchool) {
			accessPath = this.accessControl["school"];
		} else if (
			this.subrole === SubRole.TEACHER_MATCHED ||
			this.subrole === SubRole.STUDENT_MATCHED
		) {
			accessPath = this.accessControl[this.subrole];
		} else if (this.subrole === SubRole.TEACHER_SELF) {
			const tariff = this.tariff === Tariff.FREE ? "free" : "pro";
			accessPath = this.accessControl[this.subrole][tariff];
		}
		if (!accessPath) return false;

		return child
			? accessPath[parent]?.[child]
			: accessPath[parent] ?? false;
	}
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatSliderModule } from '@angular/material/slider';
import { TranslateModule } from '@ngx-translate/core';
import { MetronomeComponent } from './metronome.component';
import { MatIconModule } from '@angular/material/icon';


@NgModule({
  declarations: [MetronomeComponent],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    MatSliderModule,
    MatIconModule
  ],
  exports: [MetronomeComponent]
})
export class MetronomeModule { }

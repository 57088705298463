export enum Resources {
	RECORDING = "recording",
	NOTES = "notes",
	STUDENTS_LIST = "studentsList",
	LESSONS_LIST = "lessonsList",
	AVAILABILITY = "availability",
	MESSAGES = "messages",
	CALCOM = "calcom",
	SUBSCRIPTION_UPGRADE = "subscriptionUpgrade",
	PERSONAL_ROOM = "personalRoom",
	REFERRAL = "referral",
	STATISTICS = "statistics",
	LESSON_OVERVIEW = "lessonOverview",
	PRACTICE_ROOM = "practiceRoom",
	PROFILE_NOTIFICATIONS = "profile.notifications",
	PROFILE_MEMBERSHIP = "profile.membership",
	PROFILE_SUBSCRIPTION = "profile.subscription",
	PROFILE_ACCOUNT = "profile.accountDetails",
	PROFILE_PERSONAL = "profile.personalDetails",
	PROFILE_MUSICAL = "profile.musicalDetails",
	PROFILE_PAYOUT = "profile.payoutDetails",
	UNLIMITED_CALL_TIME = "unlimitedCallTime",
	SUPPORT = "support"
}

import { Component, ElementRef, ViewChild } from "@angular/core";
import { ReferralService } from "../services/referral/referral.service";
import { AuthenticationService } from "../services/authentication/authentication.service";

@Component({
	selector: "app-referral",
	templateUrl: "./referral.component.html",
	styleUrls: ["./referral.component.scss"],
})
export class ReferralComponent {
	public subrole: number;

	constructor(
		private referralService: ReferralService,
		private authService: AuthenticationService
	) {}

	async ngOnInit() {
		const profile = await this.authService.profile;
		const { email, subrole, firstName, lastName } = profile;

		this.subrole = subrole;

		await this.referralService.participateInViralLoops(
			{ email, firstName, lastName },
			subrole
		);
	}
}

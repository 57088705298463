<form #orgForm='ngForm'>
  <div class="school-title">
    <span *ngIf="isEditForm">{{ (isEditForm ? 'Edit School' : 'Create School') }}</span>
  </div>
  <div>
    <span>Name</span>
    <input type="text" name="name" ngModel required pattern="^[a-zA-Z0-9\-_]{1,60}$" maxlength="60">
  </div>
  <div>
    <span>Subdomain</span>
    <input type="text" name="subdomain" ngModel required pattern="^[a-zA-Z0-9\-_]{1,60}$" maxlength="60">
  </div>
  <div>
    <span>Logo</span>
    <div>
      <label class="blue-ui-button custom-input">
        Choose file
        <input type="file" accept=".png, .jpg, .jpeg" (change)="fileShare('logo', $event)">
      </label>
      <img *ngIf="logo || organization.logo" [src]="logo || organization.logo" alt="logo">
    </div>
  </div>
  <div>
    <span>Video Session Logo</span>
    <div>
      <label class="blue-ui-button custom-input">
        Choose file
        <input type="file" accept="image/x-png,image/jpg,image/jpeg" (change)="fileShare('watermark', $event)">
      </label>
      <img *ngIf="watermark || organization.watermark" [src]="watermark || organization.watermark" alt="watermark">
    </div>
  </div>
  <div>
    <span>Background Image</span>
    <div>
      <label class="blue-ui-button custom-input">
        Choose file
        <input type="file" accept="image/x-png,image/jpg,image/jpeg" (change)="fileShare('background', $event)">
      </label>
      <img *ngIf="background || organization.backgroundImage" [src]="background || organization.backgroundImage" alt="background image">
    </div>
  </div>
  <div>
    <span>API key</span>
    <div class="org-value">
      <label class="ellipsis">{{ organization.apiKey }}</label>
    </div>
  </div>

  <div class="controls">
    <button class="blue-ui-button" type="submit" [disabled]="orgForm.invalid" (click)="onSubmit()">Save</button>
    <button class="default-ui-button transparent-ui-border-button blue cancel-button" (click)="onClose()">Cancel</button>
  </div>

</form>

@if(!isMobile){
<button class="emj-btn-close" (click)="close()">
	<mat-icon>close</mat-icon>
</button>
}

<div class="flex items-center w-full h-full">
	<div
		class="flex flex-wrap items-center justify-center emojis-container gap-[6px] px-[3px]"
		[class.mobile]="isMobile"
		[class.border-right]="!isMobile"
	>
		<button
			[attr.aria-label]="'aria-label.send-thumbs-up' | translate"
			[ngClass]="['emoji-card', isMobile ? 'mobile' : '']"
			(click)="onSelectEmoji('up')"
		>
			<app-emoji-icon [emoji]="'up'"></app-emoji-icon>
		</button>
		<button
			[attr.aria-label]="'aria-label.send-thumbs-down' | translate"
			[ngClass]="['emoji-card', isMobile ? 'mobile' : '']"
			(click)="onSelectEmoji('down')"
		>
			<app-emoji-icon [emoji]="'down'"></app-emoji-icon>
		</button>
		<button
			[attr.aria-label]="'aria-label.send-clap' | translate"
			[ngClass]="['emoji-card', isMobile ? 'mobile' : '']"
			(click)="onSelectEmoji('clap')"
		>
			<app-emoji-icon [emoji]="'clap'"></app-emoji-icon>
		</button>
		<button
			[attr.aria-label]="'aria-label.send-metal-horns' | translate"
			[ngClass]="['emoji-card', isMobile ? 'mobile' : '']"
			(click)="onSelectEmoji('horns')"
		>
			<app-emoji-icon [emoji]="'horns'"></app-emoji-icon>
		</button>
		<button
			[attr.aria-label]="'aria-label.send-celebration' | translate"
			[ngClass]="['emoji-card', isMobile ? 'mobile' : '']"
			(click)="onSelectEmoji('party')"
		>
			<app-emoji-icon [emoji]="'party'"></app-emoji-icon>
		</button>
		<button
			[attr.aria-label]="'aria-label.send-love' | translate"
			[ngClass]="['emoji-card', isMobile ? 'mobile' : '']"
			(click)="onSelectEmoji('love')"
		>
			<app-emoji-icon [emoji]="'love'"></app-emoji-icon>
		</button>
		<button
			[attr.aria-label]="'aria-label.send-horn' | translate"
			[ngClass]="['emoji-card', isMobile ? 'mobile' : '']"
			(click)="onSelectEmoji('mega')"
		>
			<app-emoji-icon [emoji]="'mega'"></app-emoji-icon>
		</button>
		<button
			[attr.aria-label]="'aria-label.send-laugh' | translate"
			[ngClass]="['emoji-card', isMobile ? 'mobile' : '']"
			(click)="onSelectEmoji('laugh')"
		>
			<app-emoji-icon [emoji]="'laugh'"></app-emoji-icon>
		</button>
	</div>
	<div *ngIf="!isMobile" class="px-[6px]">
		<button
			[attr.aria-label]="'aria-label.send-bell' | translate"
			[ngClass]="['emoji-card', 'bell', isMobile ? 'mobile' : '']"
			(click)="doToggleHandOn(!isHandOn)"
		>
			<app-emoji-icon [emoji]="'bell'"></app-emoji-icon>
		</button>
	</div>
</div>

import {
	ChangeDetectorRef,
	Component,
	HostListener,
	ViewChild
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Title } from "@angular/platform-browser";
import { MatTableDataSource, MatTable } from "@angular/material/table";
import { MatMenuTrigger } from "@angular/material/menu";
import { BehaviorSubject, from, Observable } from "rxjs";
import { MatSort } from "@angular/material/sort";
import { PopupService } from "../services/popup/popup.service";
import { ModulesForMaterialComponent } from "./material.modules"; // Barrel file with modules
import { AuthenticationService } from "../services/authentication/authentication.service";
import { FilesCloudService } from "../services/material-cloud/files-cloud.service";
import { Profile } from "@data-types/profile.types";
import { concatMap } from "rxjs/operators";
import { getFileTypeByExtension } from "../helpers/getFileTypeByExtension";
import { FilesDropzoneComponent } from "./components/files-dropzone/files-dropzone.component";
import { IntercomService } from "../services/intercom/intercom.service";

export const weirdTypes = {
	"application/octet-stream": "document"
};

@Component({
	selector: "app-material",
	standalone: true,
	templateUrl: "./material.component.html",
	styleUrl: "./material.component.scss",
	imports: [ModulesForMaterialComponent, FilesDropzoneComponent]
})
export class MaterialComponent {
	@ViewChild(MatMenuTrigger)
	menuContext: MatMenuTrigger;
	displayedMobileColumns = ["title", "menu"];

	private screenSizeSubject = new BehaviorSubject<string>("");
	private profile: Profile;
	screenSize: string = "";
	displayedDesktopColumns: string[] = [
		"name",
		"extension",
		"created",
		"menu"
	];
	selectedItem: any;
	isDragging: boolean = false;
	// To Do - Use the correct data type instead of "any"
	dataSource = new MatTableDataSource<any>();
	dataSourceLength: number;
	count: number;
	page: number = 1;
	pages: number;
	limit: number = 10;
	dragCounter: number = 0;
	isTeacher: boolean;
	@ViewChild(MatTable) table: MatTable<any>;
	@ViewChild(MatSort) sort: MatSort;

	constructor(
		private title: Title,
		private auth: AuthenticationService,
		private filesService: FilesCloudService,
		private translateService: TranslateService,
		private cdRef: ChangeDetectorRef,
		private popupService: PopupService,
		private intercom: IntercomService
	) {
		this.title.setTitle(
			this.translateService.instant("material.seo-title")
		);
		this.detectScreenSize();
	}

	async ngOnInit() {
		await this.intercom.initialezeLoggedInUser();
		this.screenSizeSubject.subscribe((size) => {
			this.screenSize = size;
			this.cdRef.detectChanges();
		});
		this.profile = await this.auth.profile;
		this.isTeacher = this.checkSubRole(this.profile.subrole);

		if (this.isTeacher) {
			this.getAllFiles();
			this.displayedDesktopColumns.splice(
				this.displayedDesktopColumns.length - 1,
				0,
				"accessList"
			);
		} else {
			this.getAllStudentFiles();
		}
	}

	@HostListener("window:resize", ["$event"])
	private onResize(event): void {
		this.detectScreenSize();
	}

	getAllFiles(): void {
		this.filesService
			.getMyFiles(this.profile.userId, this.limit, this.page)
			.subscribe(
				(files) => {
					const { count, list, page, pages } = files;
					this.page = page;
					this.count = count;
					this.pages = pages;
					this.dataSource = list;
					this.dataSourceLength = list.length;
				},
				(error) => {
					console.error("Error fetching files:", error);
				}
			);
	}
	getAllStudentFiles(): void {
		this.filesService
			.getSharedFilesByUser(this.profile.userId, this.limit, this.page)
			.subscribe(
				(files) => {
					const { count, list, page, pages } = files;
					this.page = page;
					this.count = count;
					this.pages = pages;
					this.dataSource = list;
					this.dataSourceLength = list.length;
				},
				(error) => {
					console.error("Error fetching files:", error);
				}
			);
	}

	onFileSelected(event: Event): void {
		const input = event.target as HTMLInputElement;
		if (input?.files) {
			const files = input.files;
			const modifiedFiles: File[] = Array.from(files).map((file) => {
				const extension = file.name.split(".").pop();
				const mimeType = getFileTypeByExtension(extension!);
				return new File([file], file.name, { type: mimeType });
			});
			const dialogRef = this.popupService.openMaterialConfirmUploadDialog(
				modifiedFiles,
				() => this.uploadFiles(files)
			);

			dialogRef.afterClosed().subscribe(() => {
				this.getAllFiles();
			});
		}
	}

	private detectScreenSize(): void {
		const width = window.innerWidth;
		if (width < 768) {
			this.screenSizeSubject.next("mobile");
		} else if (width < 1279) {
			this.screenSizeSubject.next("tablet");
		} else {
			this.screenSizeSubject.next("desktop");
		}
	}

	public onDragEnter(e: DragEvent): void {
		e.preventDefault();
		e.stopPropagation();
		this.dragCounter++;
		this.isDragging = this.isTeacher ? true : false;
	}

	public onDragOver(e: DragEvent): void {
		e.preventDefault();
		e.stopPropagation();
		this.isDragging = this.isTeacher ? true : false;
	}

	public onDragLeave(e: DragEvent): void {
		e.preventDefault();
		e.stopPropagation();
		this.dragCounter--;

		if (this.dragCounter === 0) {
			this.isDragging = false;
		}
	}

	public onDrop(e: DragEvent): void {
		e.preventDefault();
		e.stopPropagation();
		this.isDragging = false;
		this.dragCounter = 0;

		if (this.isTeacher) {
			const files = e.dataTransfer?.files;

			if (files) {
				const modifiedFiles: File[] = Array.from(files).map((file) => {
					const extension = file.name.split(".").pop();
					const mimeType = getFileTypeByExtension(extension!);

					return new File([file], file.name, { type: mimeType });
				});
				const dialogRef =
					this.popupService.openMaterialConfirmUploadDialog(
						modifiedFiles,
						() => this.uploadFiles(files)
					);

				dialogRef.afterClosed().subscribe(() => {
					this.getAllFiles();
				});
			}
		}
	}
	uploadFiles(files: FileList): Observable<any> {
		const fileArray = Array.from(files);

		return from(fileArray).pipe(
			concatMap((file) =>
				this.filesService.addFile(this.profile.userId, file, "material")
			)
		);
	}

	deleteFile(file): void {
		setTimeout(() => {
			const dialogRef = this.popupService.openMaterialConfirmDeleteDialog(
				file,
				() =>
					this.filesService.removeFile(file.id).subscribe(
						(resp) => {},
						(error) => console.error(error),
						() => {
							if (this.dataSourceLength === 1 && this.page > 1)
								this.page--;
							this.getAllFiles();
							dialogRef.close();
						}
					)
			);
		}, 50);
	}

	fileSharing(item) {
		setTimeout(() => {
			const dialogRef = this.popupService.openMaterialFileSharingDialog(
				item,
				this.profile
			);
			dialogRef.afterClosed().subscribe(
				(resp) => {},
				(error) => console.error(error),
				() => {
					this.getAllFiles();
					dialogRef.close();
				}
			);
		}, 50);
	}

	renameFile(file, newName: string): void {
		this.filesService.renameFile(file.id, newName).subscribe((resp) => {
			this.getAllFiles();
		});
	}

	openRenameFileDialog(fileData): void {
		setTimeout(() => {
			const dialogRef =
				this.popupService.openMaterialRenameFileDialog(fileData);
			dialogRef.afterClosed().subscribe(({ newName }) => {
				if (newName) {
					this.renameFile(fileData, newName);
				}
			});
		}, 50);
	}

	downloadFile(url, fileName) {
		const anchor = document.createElement("a");
		anchor.href = url;
		anchor.target = "_blank";
		anchor.download = fileName || "download";
		document.body.appendChild(anchor);
		anchor.click();
		document.body.removeChild(anchor);
	}

	setStudnetsLengthText(list: any): string {
		return list?.accessList?.length > 0
			? `${list.accessList.length} ${this.translateService.instant(
					"material.students"
				)}`
			: "";
	}

	pageChanged(event) {
		this.page = event.pageIndex + 1;
		this.isTeacher ? this.getAllFiles() : this.getAllStudentFiles();
	}

	checkSubRole(subRole: number): boolean {
		return subRole < 4 && subRole > 1;
	}
	convertToFriendlyDataType(extension) {
		return weirdTypes[extension] || extension;
	}
}

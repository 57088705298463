<div class="audio-controls">
    <div class="title-row">
        <mat-icon class="text-sirius-violet mr-1">microphone</mat-icon>
        <div class="font-bold">{{ 'call-controls.microphone' | translate }}</div>
    </div>
    <form [formGroup]="formGroup" novalidate>
        <div class="input-device" formGroupName="audio">
            <mat-radio-group class="vertical-radio-group" formControlName="inputDeviceId">
                <mat-radio-button *ngFor="let inputDevice of audioInputDevices, index as i;"
                    [value]="inputDevice.deviceId" class="radio-button"
                    value="default">{{inputDevice.label}}</mat-radio-button>
            </mat-radio-group>
        </div>
        <hr />
        <div *ngIf="!isSafari()" class="title-row">
            <mat-icon class="text-sirius-violet mr-1">volume_up</mat-icon>
            <div class="font-bold">{{ 'call-controls.speaker' | translate }}</div>
        </div>
        <div class="input-device" formGroupName="audio">
            <mat-radio-group class="vertical-radio-group" formControlName="outputDeviceId">
                <mat-radio-button *ngFor="let outputDevice of audioOutputDevices, index as i;"
                    [value]="outputDevice.deviceId" class="radio-button" value="default">{{outputDevice.label}}
                </mat-radio-button>
            </mat-radio-group>
        </div>
        <hr />
        <!-- <div class="title-row" formGroupName="audio">
            <span class="noise-reduction mr-1 text-left">Noise reduction
            </span>
            <mat-slide-toggle formControlName="noiseSuppression" />
        </div> 
        <hr /> -->
        <!-- <div class="title-row">
            <a class="text-sirius-black flex w-full items-center">
                Audio settings
            </a>
        </div> -->
        <!-- <hr /> -->
        <div class="title-row">
            <a class="text-sirius-black flex w-full items-center" (click)="clickToggleMic()">
                <span class="text-left" style="cursor: pointer;" *ngIf="isMicro">{{ 'call-controls.mute-mic' | translate
                    }}</span>
                <span class="text-red-500" style="cursor: pointer;" *ngIf="!isMicro">{{ 'call-controls.unmute-mic' |
                    translate
                    }}</span>
            </a>
        </div>
    </form>
</div>
<!--<div class="green-cross" (click)="closeBetaInfo()" [hidden]="!isBetaInfoDisplayed">-->
<!--</div>-->
<h1 [innerHTML]="'lobby-info.welcome-message' | translate: {username: username}"></h1>


<div class="start-outer new-design-start-outer">
    <div class="form-block new-design-form-block">
        <!-- Personal room data -->
        <div *ngIf="personalRoomData" class="new-design-start-block">
            <div class="title-row">
                <div>
                    <p class="new-design-sirius-title title-room">{{'start.title-message' | translate}}</p>
                </div>
                <div class="edit-block">
                    <button class="default-ui-button new-design-button-edit input" (click)="deletePersonalRoom()">
                        {{'start.edit-room' | translate}}
                    </button>
                </div>
            </div>
            <div class="personal-data-container md:flex">
                <div class="flex-1">
                    <div class="label">{{'start.room-link' | translate}}</div>
                    <div class="value">{{ personalRoomData.link.split('?')[0] }}</div>
                    <div class="label">{{'lobby-popup-desktop.password-placeholder' | translate}}</div>
                    <div class="value">{{ personalRoomData.password }}</div>
                </div>
                <div *showForSubroles="['teacherMatched']"
                    class="flex-1 !bg-yellow-100 md:text-xs border-yellow-400 border p-2 flex items-center flex-col">
                    <p class="!font-semibold">{{'lessons.infoMessage' | translate}}</p>
                    <button [routerLink]="['/platform/lessons']"
                        class=" default-ui-button mt-1 md:!text-xs !font-semibold rounded-full bg-premium text-white">{{'lessons.toTrialLessons'
                        | translate}}</button>
                </div>
            </div>

            <div class="new-design-personal-data-btn-group">
                <div class="flex w-full gap-2 justify-between">
                    <button [class.copy-data]="clipboardService.copySuccess"
                        class="default-ui-button new-design-button-copy input" (click)="copyLink()">
                        {{'video-settings.copy-link' | translate}}
                    </button>
                    <div class="copied-info" *ngIf="clipboardService.copySuccess">
                        <div class="copied-info-message">{{'start.copy' | translate}}</div>
                    </div>
                    <button class="default-ui-button new-design-button-edit-mobile input"
                        (click)="deletePersonalRoom()">
                        {{'start.edit-room' | translate}}
                    </button>
                </div>
                <button class="default-ui-button input new-design-button-join-room start-session"
                    (click)="startPersonalRoom()">
                    {{'start.start-session' | translate}}
                </button>
            </div>
        </div>
        <!-- Personal room creation -->
        <div *ngIf="!personalRoomData" class="new-design-start-block">
            <p class="new-design-sirius-title">{{'start.create-personal-room' | translate}}</p>
            <form class="container-column" (ngSubmit)="siriusPersonal()" [formGroup]="personalRoomForm">
                <input type="text" maxlength="32" [placeholder]="'start.room-name-placeholder' | translate"
                    formControlName="roomName" (focusin)="resetPersonalRoomValues()" (keyup)="updateName()">
                <h6 *ngIf="isPersonalRoomError" class="error-message room-message new-design-error-message">
                    {{ 'start.room-taken-error' | translate }}
                </h6>
                <h6 *ngIf="!isPersonalRoomError"
                    class="requirements-message room-message new-design-requirements-message"
                    [class.error-message]="personalRoomForm.controls['roomName'].invalid && personalRoomForm.controls['roomName'].dirty">
                    {{ 'start.room-name-error' | translate }}
                </h6>
                <input type="text" maxlength="60" [placeholder]="'start.room-pass-placeholder' | translate"
                    (change)="updatePassword()" formControlName="roomPassword" (focusin)="resetPersonalRoomValues()"
                    (keyup)="updatePassword()">
                <h6 *ngIf="!personalRoomForm.controls['roomPassword'].valid && personalRoomForm.controls['roomPassword'].dirty; else requirments"
                    class="error-message password-message new-design-error-message">
                    {{ 'start.room-password-error' | translate }}
                </h6>
                <ng-template #requirments>
                    <h6 class="requirements-message room-message new-design-requirements-message">
                        {{'start.password-warning' | translate}}
                    </h6>
                </ng-template>
                <button class="default-ui-button input new-design-button-create-room" type="submit"
                    [disabled]="!personalRoomForm.valid">
                    {{ 'buttons.personal-room' | translate }}
                </button>
            </form>
        </div>
    </div>
</div>

<div class="start-outer new-design-start-outer">
    <div class="form-block new-design-form-block">
        <div class="new-design-start-block">
            <p class="new-design-sirius-title">{{ 'start.instructions-title' | translate}}</p>
            <div class="link-container-column">
                <div class="link-container">
                    <div class="instruction-container">
                        <div class="instruction-title" (click)="toggleTitle(title, instruction)" #title>
                            <div class="toggle-title">></div>
                            {{'start.first-instruction-title' | translate}}
                        </div>
                        <div class="instruction" #instruction
                            [innerHTML]="'start.first-instruction' | translate | safeHtml"></div>
                    </div>
                    <div class="instruction-container">
                        <div class="instruction-title" (click)="toggleTitle(title1, instruction1)" #title1><span
                                class="toggle-title">></span> {{'start.second-instruction-title' | translate}}
                        </div>
                        <div class="instruction" #instruction1
                            [innerHTML]="'start.second-instruction' | translate | safeHtml">
                        </div>
                    </div>
                    <div class="instruction-container">
                        <div class="instruction-title" (click)="toggleTitle(title2, instruction2)" #title2><span
                                class="toggle-title">></span> {{'start.third-instruction-title' | translate}}
                        </div>
                        <div class="instruction" #instruction2
                            [innerHTML]="'start.third-instruction' | translate | safeHtml"></div>
                    </div>
                </div>
                <div class="link-container end">
                    <div class="instruction-container">
                        <div class="instruction-title" (click)="toggleTitle(title3, instruction3)" #title3><span
                                class="toggle-title">></span> {{'start.fourth-instruction-title' | translate}}
                        </div>
                        <div class="instruction" #instruction3
                            [innerHTML]="'start.fourth-instruction' | translate | safeHtml">
                        </div>
                    </div>
                    <div class="instruction-container">
                        <div class="instruction-title" (click)="toggleTitle(title4, instruction4)" #title4><span
                                class="toggle-title">></span> {{'start.fifth-instruction-title' | translate}}
                        </div>
                        <div class="instruction" #instruction4
                            [innerHTML]="'start.fifth-instruction' | translate | safeHtml"></div>
                    </div>
                    <div class="instruction-container">
                        <div class="instruction-title" (click)="toggleTitle(title5, instruction5)" #title5><span
                                class="toggle-title">></span> {{'start.sixth-instruction-title' | translate}}
                        </div>
                        <div class="instruction" #instruction5
                            [innerHTML]="'start.sixth-instruction' | translate | safeHtml">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="start-outer new-design-start-outer">
    <div class="form-block new-design-form-block">
        <div class="new-design-start-block">
            <p class="new-design-sirius-title">{{'start.join-room-title' | translate}}</p>
            <form class="room-link-container" (ngSubmit)="enterRoom()" [formGroup]="enterRoomForm">
                <input type="text" maxlength="60" [placeholder]="'start.other-room-name-placeholder' | translate"
                    formControlName="roomLink" (focusin)="resetValues()" (focusout)="checkLinkLength()">
                <button class="default-ui-button input new-design-button-join-room" type="submit"
                    [disabled]="!enterRoomForm.valid">
                    {{'start.join-room-btn' | translate}}
                </button>
            </form>
            <h6 [hidden]="!linkLengthError && !roomAccessError" class="error-message link-error">
                {{ 'start.room-link-error' | translate }}
            </h6>
        </div>
    </div>
</div>

import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
declare const Glide: any;

const RATINGS = [
	{
		rating: 5,
		headline: "Ich konnte immer super flexibel meine Termine ausmachen",
		text: "Ich konnte immer super flexibel meine Termine ausmachen und der Online-Unterricht funktioniert für mich sehr gut. :)",
		name: "Umali",
		ratingTime: "a month ago",
		new: true,
		reviews: 2
	},
	{
		rating: 5,
		headline:
			"Ich habe tollen Gesangsunterricht bei Julianna über Sirius gebucht.",
		text: "Ich habe tollen Gesangsunterricht bei Julianna über Sirius gebucht. Wirklich eine gute Musikschule. Kann ich sehr weiterempfehlen.",
		name: "Valentin M",
		ratingTime: "a year ago",
		new: false,
		reviews: 3
	},
	{
		rating: 5,
		headline: "Ist fantastich wenn man anfangen will zu singen",
		text: "Ist fantastich wenn man anfangen will zu singen, sehr beginnerfreundlich, preis ist auch gut",
		name: "Yeeesi",
		ratingTime: "a month ago",
		new: true,
		reviews: 4
	},
	{
		rating: 5,
		headline: "Die Kommunikation mit Sirius ist einwandfrei",
		text: "Die Kommunikation mit Sirius ist einwandfrei, mit meinem Lehrer bin ich auch sehr zufrieden. Kann ich nur weiterempfehlen!",
		name: "Moritz Voss",
		ratingTime: "a month ago",
		new: true,
		reviews: 1
	},
	{
		rating: 5,
		headline: "Die Kommunikation mit Sirius ist einwandfrei",
		text: "Die Kommunikation mit Sirius ist einwandfrei, mit meinem Lehrer bin ich auch sehr zufrieden. Kann ich nur weiterempfehlen!",
		name: "Jan K.",
		ratingTime: "six months ago",
		new: false,
		reviews: 1
	},
	{
		rating: 5,
		headline: "Die Kommunikation mit Sirius ist einwandfrei",
		text: "Das hat sehr gut geklappt, 👍",
		name: "Julia K",
		ratingTime: "two weeks ago",
		new: true,
		reviews: 1
	}
];

@Component({
	selector: "app-review-carousel",
	standalone: true,
	imports: [CommonModule],
	templateUrl: "./review-carousel.component.html",
	styleUrl: "./review-carousel.component.scss"
})
export class ReviewCarouselComponent {
	ratings = RATINGS;

	ngAfterViewInit(): void {
		this.loadScript("https://cdn.jsdelivr.net/npm/@glidejs/glide")
			.then(() => {
				this.initializeGlide();
			})
			.catch((error) => console.error("Error loading script:", error));
	}

	private loadScript(src: string): Promise<void> {
		return new Promise((resolve, reject) => {
			const script = document.createElement("script");
			script.src = src;
			script.async = true;
			script.onload = () => resolve();
			script.onerror = () =>
				reject(new Error(`Script load error for ${src}`));
			document.head.appendChild(script);
		});
	}

	private initializeGlide(): void {
		const options = {
			type: "carousel",
			startAt: 0,
			perView: 3,
			breakpoints: {
				800: {
					perView: 1
				},
				1279: {
					perView: 2
				}
			}
		};
		new Glide(".glide", options).mount();
	}
}

import { Component, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthenticationService } from "src/app/services/authentication/authentication.service";
import { UsersDataService } from "../../../services/users-data.service";

@Component({
  selector: "app-account-delete-form",
  templateUrl: "./account-delete-form.component.html",
  styleUrls: ["./account-delete-form.component.scss"],
})
export class AccountDeleteFormComponent {
  @ViewChild("deleteForm") deleteForm: NgForm;
  userMessage: string;
  status: "error" | "success";

  constructor(
    private dataService: UsersDataService,
    private authenticationService: AuthenticationService,
    private router: Router
  ) {}

  onSubmit() {
    const { email } = this.deleteForm.value;
    this.dataService.delete(email).subscribe(
      (deleteUser) => {
        this.status = "success";
        this.userMessage = "User successfully deleted!";
        this.deleteForm.resetForm();
      },
      (error) => {
        this.status = "error";
        this.userMessage = "User does not exist on database";
        if (error.status === 401 || error.status === 403) {
          this.authenticationService.logOut$().subscribe((res) => {
            this.authenticationService.isLoggedIn$.next(false);
            this.router.navigate(["admin/login"]);
          });
        }
      }
    );
  }
}

import { Component, ViewChild, ElementRef } from "@angular/core";
import { DailyRtcService } from "../lib-rtc/services/daily-rtc.service";
import { EventEmitter, Output } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { Renderer2 } from "@angular/core";
interface Track {
	persistentTrack: any;
	state: string;
	subsribed: boolean;
}

@Component({
	selector: "app-daily-video",
	standalone: true,
	imports: [MatButtonModule],
	templateUrl: "./daily-video.component.html",
	styleUrl: "./daily-video.component.scss"
})


export class DailyVideoComponent {
	@ViewChild("videos") videosElement!: ElementRef;
	@Output() leaveCall = new EventEmitter<void>();
	isMuted: boolean = false;
	selfSessionId: string;

	constructor(
		private dailyRtcService: DailyRtcService,
		private renderer: Renderer2
	) {}

	ngAfterViewInit() {
		this.dailyRtcService.joinedMeeting$.subscribe((event) => {
		});

		this.dailyRtcService.participantJoined$.pipe().subscribe((event) => {
			this.onParticipantJoinedOrUpdated(event);
		});

		this.dailyRtcService.participantUpdated$.subscribe((event) => {
			this.onParticipantJoinedOrUpdated(event);
		});

		this.dailyRtcService.participantLeft$.subscribe((event) => {
			this.handleParticipantLeft(event);
		});

		this.dailyRtcService.$rejoin.subscribe(() => {{
			document.getElementById(`video-container-${this.selfSessionId}`)?.remove();
		}})

	}

	ngOnDestroy() {
		this.dailyRtcService.leave();
	}

	toggleMicrophone() {
		this.isMuted = !this.isMuted;
		this.dailyRtcService.toggleMicrophone();
	}

	async onParticipantJoinedOrUpdated(event) {
		const isLocal = event.participant.local;
		if (isLocal){
		this.selfSessionId = event.participant.session_id;
		}
		
		if (
			!document.getElementById(
				`video-container-${event.participant.session_id}`
			)
		) {
			await this.createVideoContainer(
				event.participant.session_id,
				this.videosElement.nativeElement
			);
		}
		if (
			!document.getElementById(`audio-${event.participant.session_id}`) &&
			!isLocal
		) {
			this.createAudioElement(event.participant.session_id);
		}

		const videoContainerExists = document.getElementById(
			`video-container-${event.participant.session_id}`
		)
	
		this.convertMediaStreams(event)}



	createVideoContainer(participantId: string, videosContainer: HTMLElement) {
		try {
		const videoContainer = this.renderer.createElement("div");
		this.renderer.addClass(videoContainer, "flex-1"); // Add flex class
		this.renderer.addClass(videoContainer, "video-container"); // Add video-container class
		this.renderer.setAttribute(
			videoContainer,
			"id",
			`video-container-${participantId}`
		);
		this.renderer.appendChild(videosContainer, videoContainer);
		const videoEl = this.renderer.createElement("video");
		this.renderer.addClass(videoEl, "video-element");
		this.renderer.appendChild(videoContainer, videoEl);
		  return new Promise<void>((resolve) => {
			resolve();
		  });
	}	catch (e) {
		console.error(e)
	}
	}

	createAudioElement(participantId: string) {
		const audioEl = document.createElement("audio");
		audioEl.id = `audio-${participantId}`;
		document.body.appendChild(audioEl);
	}

	convertMediaStreams(event) {
		const tracks = event.participant.tracks;
		const participantId = event.participant.session_id;
		const isLocal = event.participant.local;

		(Object.entries(tracks) as [string, Track][]).forEach(
			([key, value]) => {
				if (value.persistentTrack) {
					if (!(isLocal && key === "audio")) {
						this.updateOrAddTrack({ key, value, participantId });
					}
				}
			}
		);
	}

	updateOrAddTrack(track) {

		const selector =
			track.key === "video"
				? `#video-container-${track.participantId} video.video-element`
				: `audio-${track.participantId}`;

		const trackEl =
			track.key === "video"
				? (document.querySelector(selector) as HTMLVideoElement)
				: (document.getElementById(selector) as HTMLMediaElement);

		if (!trackEl) {
			console.error(
				`${track.key} element does not exist for participant: ${track.participantId}`
			);
			return;
		}

		trackEl.srcObject = new MediaStream([track.value.persistentTrack]);

		trackEl.onloadedmetadata = () => {
			trackEl.play().catch((e) => {
				console.error(
					`Error playing ${track.key} for participant ${track.participantId}:`,
					e
				);
			});
		};
	}

	handleParticipantLeft(event) {
		const participantId = event.participant.session_id;
		this.destroyTracks(["video", "audio"], participantId);
		document.getElementById(`video-container-${participantId}`)?.remove();
	}

	destroyTracks(trackTypes, participantId) {
		trackTypes.forEach((trackType) => {
			const elementId = `${trackType}-${participantId}`;
			const element = document.getElementById(
				elementId
			) as HTMLMediaElement;
			if (element) {
				element.srcObject = null;
				element.parentNode.removeChild(element);
			}
		});
	}

	leaveMeeting() {
		this.dailyRtcService.leave();
		this.leaveCall.emit();
	}

	rejoin(){
		document.getElementById(`video-container-${this.selfSessionId}`)?.remove();
		this.dailyRtcService.joinWithNewSetting(null)
	}

}


export class VideoStreamDisplayUtil {

  // Attach audio output device to video element using device/sink ID.
  public static attachSinkId(element: any, sinkId: string): Promise<string> {
    return new Promise((resolve, reject) => {
      if (!element) {
        const textMessage = 'The input parameter "element" is not defined.';
        console.warn(textMessage);
        return reject(textMessage);
      }
      if (typeof element.sinkId === 'undefined') {
        const warnMessage = 'Browser does not support output device selection (sinkId).';
        console.warn(warnMessage);
        return reject(warnMessage);
      }
      if (element.sinkId === sinkId) {
        return resolve('');
      }
      return element.setSinkId(sinkId)
        .then(() => {
          // console.log(`Success, audio output device attached: ${sinkId}`);
          return resolve('');
        })
        .catch((error: any) => {
          let errorMessage = error;
          if (error.name === 'SecurityError') {
            errorMessage = `You need to use HTTPS for selecting audio output device: ${error}`;
          }
          console.error(errorMessage);
          // Jump back to first output device in the list as it's the default.
          return reject(errorMessage);
        });
    });
  }

}

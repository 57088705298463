import {UserEntryVO} from './UserEntryVO';

export class UserEntryParser {
  static parse (dataList: any[]): UserEntryVO[] {
    return dataList.map(item => {
      const user = new UserEntryVO();
      user.id = item._id;
      user.email = item.email;
      user.firstName = item.firstname;
      user.language = item.language;
      user.lastName = item.lastname;
      user.timeZone = item.timezone;
      return user;
    });
  }
  static toServerUpdate(user: UserEntryVO): any {
    const updateData: any = {};
    updateData.email = user.email.length > 0 ? user.email : undefined;
    updateData.firstname = user.firstName.length > 0 ? user.firstName : undefined;
    updateData.language = user.language.length > 0 ? user.language : undefined;
    updateData.lastname = user.lastName.length > 0 ? user.lastName : undefined;
    updateData.timezone = user.timeZone.length > 0 ? user.timeZone : undefined;
    updateData.password = user.password.length > 0 ? user.password : undefined;
    return updateData;
  }
}

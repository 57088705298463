import {
	UntypedFormControl,
	ValidatorFn,
	AsyncValidatorFn
} from "@angular/forms";

export class TrimmedFormControl extends UntypedFormControl {
	constructor(
		formState?: any,
		validatorOrOpts?: ValidatorFn | ValidatorFn[] | null,
		asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null
	) {
		super(formState, validatorOrOpts, asyncValidator);
	}

	setValue(value: any, options: any = {}) {
		if (typeof value === "string") {
			value = value.replace(/\s+/g, "");
		}
		super.setValue(value, { ...options, emitModelToViewChange: true });
	}
}

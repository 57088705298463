import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserIconComponent } from './user-icon.component';


@NgModule({
  declarations: [UserIconComponent],
  imports: [
    CommonModule
  ],
  exports: [UserIconComponent]
})
export class UserIconModule { }

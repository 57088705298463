<div class="page-container">
  <h1>{{ 'imprint.title' | translate }}</h1>
  <div class="content-text">
    <p>{{ 'imprint.info' | translate }}</p>
    <p [innerHTML]="'imprint.gmbh' | translate"></p>
    <p [innerHTML]="'imprint.representer' | translate | safeHtml" appRouteTransformer></p>
    <p [innerHTML]="'imprint.register-info' | translate"></p>
    <p [innerHTML]="'imprint.eu-commission' | translate"></p>
    <p>{{ 'imprint.сopyright' | translate }}</p>
  </div>
</div>

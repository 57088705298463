
export enum ADAPTATION {
  NONE = 'none',
  // Fit video height. Host(Height:100%), Video(Height:100%)
  FIT_VIDEO_HEIGHT = 'fit-video-height',
  // Fit video width. Host(Width:100%), Video(Width:100%)
  FIT_VIDEO_WIDTH = 'fit-video-width',
  // Host(Width:100%, Height:100%), Video(Width:100%, Height:100%)
  ADAPT_BY_HOST_SIZES = 'adapt-by-host-sizes',
  // Host(Width:100%, Height:100%), Video(Width:100%, Height:100%) + fit:cover
  ADAPT_BY_HOST_SIZES_AND_COVER = 'adapt-by-host-sizes-and-cover',
}

export const ENDED = 'ended';
export const ERROR = 'error';
export const LOADEDMETADATA = 'loadedmetadata';
export const PLAYING = 'playing';
export const STALLED = 'stalled';
export const SUSPEND = 'suspend';
export const WAITING = 'waiting';


export class AdaptattionUtil {
  public static create(value: string): ADAPTATION {
    let result: ADAPTATION = ADAPTATION.NONE;
    switch (value) {
      case ADAPTATION.FIT_VIDEO_HEIGHT.valueOf(): result = ADAPTATION.FIT_VIDEO_HEIGHT; break;
      case ADAPTATION.FIT_VIDEO_WIDTH.valueOf(): result = ADAPTATION.FIT_VIDEO_WIDTH; break;
      case ADAPTATION.ADAPT_BY_HOST_SIZES.valueOf(): result = ADAPTATION.ADAPT_BY_HOST_SIZES; break;
      case ADAPTATION.ADAPT_BY_HOST_SIZES_AND_COVER.valueOf(): result = ADAPTATION.ADAPT_BY_HOST_SIZES_AND_COVER; break;
    }
    return result;
  }
}
import { DOCUMENT } from '@angular/common';
import { Injectable,Inject } from '@angular/core';
import { UsersService } from '../../services/users.service';
import { BehaviorSubject } from 'rxjs';
import {PianoKeyComponent} from '../piano-key/piano-key.component'
import { EventSocketService } from '../../services/ws/event-socket.service';
import { CallStorage } from '../../components/call-room/service/storage/call-storage.service';

@Injectable({
  providedIn: 'root'
})

export class PianoService {

  constructor(private eventSocketService:EventSocketService, private callStorage:CallStorage, protected usersService: UsersService) {
    }

      // The piano keys are being added by the order they appear on the screen
      pianoKeys:PianoKeyComponent[] = [];

      startKeycode = 49;
      endKeycode = 57;

      // This object works for the keyboard mapping. (keyboard key 81 will trigger note 0) Used in keyup / keydown event
      keyboard = {
        /* C3 */
        81: '24',
        50: '25',
        87: '26',
        51: '27',
        69: '28',
        82: '29',
        53: '30',
        84: '31',
        54: '32',
        90: '33',
        55: '34',
        85: '35',
        89: '36',
        83: '37',
        88: '38',
        68: '39',
        67: '40',
        86: '41',
        71: '42',
        66: '43',
        72: '44',
        78: '45',
        74: '46',
        77: '47',
      };

      public isPiano: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  
      // Returns the associated piano key with this key
      getAssociatedPianoKey(event: KeyboardEvent):PianoKeyComponent {
          let keycode = event.keyCode;
          // console.log("keycode: "+ keycode)
          // console.log(this.componentIndex(keycode));
          return this.pianoKeys[this.componentIndex(keycode)]
      }

      public deleteKeys():void {
        this.pianoKeys = []
      }

      public getPianoKeys():PianoKeyComponent[] {
        return this.pianoKeys
      }

      // Registers a piano key (each piano key component calls this method)
      registerPianoKey(key: PianoKeyComponent) {
          this.pianoKeys.push(key);
      }

      componentIndex(key: number):string{
        return this.keyboard[key]
      }

      getPianoHeight():number {
        return 123;
      }

      onKeyDownMidi(key:number) { 
        if(key !== undefined){
        this.pianoKeys[key].onPianoKeyDownMidi()
        this.eventSocketService.notify(this.callStorage.roomId, {userId: this.usersService.selfUser.id, pianoNoteData: key,noteHold:1})
        }
      }
      
      onKeyUpMidi(key:number) { 
        if(key !== undefined){
        this.pianoKeys[key].onPianoKeyUpMidi()
        this.eventSocketService.notify(this.callStorage.roomId,{userId: this.usersService.selfUser.id, pianoNoteData: key,noteHold:0})
        }
      }

      onKeyDown(event: KeyboardEvent) {
          let keyToPlay = this.getAssociatedPianoKey(event); 
          if (keyToPlay) keyToPlay.onPianoKeyDown(event); // This triggers the function inside piano-key.component, key to play grabs the component out of the array (Yes, it is possible to trigger it from outside)
          this.eventSocketService.notify(this.callStorage.roomId, {userId: this.usersService.selfUser.id, pianoNoteData: this.componentIndex(event.keyCode),noteHold:1})
        }
  
      onKeyUp(event: KeyboardEvent) {
          let keyToPlay = this.getAssociatedPianoKey(event); 
          if (keyToPlay) keyToPlay.onPianoKeyUp(event); // This triggers the function inside piano-key.component, key to play grabs the component out of the array (Yes, it is possible to trigger it from outside)
          this.eventSocketService.notify(this.callStorage.roomId,{userId: this.usersService.selfUser.id, pianoNoteData: this.componentIndex(event.keyCode),noteHold:0})
        }

        public triggerNote(data): void {
        //   console.log("noteHold:" + data.noteHold, "note value: " + data.pianoNoteData) 
        //   console.log(data)
          if (this.pianoKeys.length > 0) {
          if(data.noteHold == 1 && this.usersService.selfUser.id !== data.userId ) {
          this.pianoKeys[data.pianoNoteData].onPianoKeyDownWS(); 
          }
      
          else if(data.noteHold == 0 && this.usersService.selfUser.id !== data.userId) {
          this.pianoKeys[data.pianoNoteData].onPianoKeyDownUPWS(); 
          }
          }
          // let keyToPlay = this.getAssociatedPianoKey(data); 
          // if (keyToPlay) keyToPlay.onPianoKeyDown(data); 
        }      

        // function to find index of object in array to be used in mouseDownWs and mouseUpWs

        findObjectIndex(arr, targetObj) {
            for (let i = 0; i < arr.length; i++) {
              if (arr[i].index === targetObj.index && arr[i].note === targetObj.note) {
                return i;
              }
            }
            return -1;
          }
    
        mouseDownWs(note?:string,index?:number) {
            const noteValue = this.findObjectIndex(this.pianoKeys, {note: note, index: index})
            this.eventSocketService.notify(this.callStorage.roomId,{userId: this.usersService.selfUser.id, pianoNoteData: noteValue,noteHold:1})
            return this.pianoKeys;
        }

        mouseUpWs(note?:string,index?:number) {
            const noteValue = this.findObjectIndex(this.pianoKeys, {note: note, index: index})
            this.eventSocketService.notify(this.callStorage.roomId,{userId: this.usersService.selfUser.id, pianoNoteData: noteValue,noteHold:0})
            return this.pianoKeys;
        }


  }


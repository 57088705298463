import { Component, Input, Output, ViewChild } from "@angular/core";
import { MatSidenav } from "@angular/material/sidenav";
import { logger } from "src/app/lib-core/logger";
import { NotesService } from "src/app/services/notes/notes.service";

@Component({
	selector: "app-call-notes",
	templateUrl: "./call-notes.component.html",
	styleUrls: ["./call-notes.component.scss"],
})
export class CallNotesComponent {
	@Input() displayNotes: boolean;
    @Input() teacherId: string;
    @Input() studentId: string;
    @Input() isStudent = false;

	@Output() notes: string;
	@ViewChild("notesUI") notesUI: MatSidenav;

	constructor(private notesService: NotesService) {}

	ngOnInit(): void {
		this.notesService.getDisplayNotes$().next(this.displayNotes);
	}

	toggleNotes(): void {
		if (this.displayNotes) {
			this.notesUI.open();
		} else {
			this.notesUI.close();
		}
		this.displayNotes = !this.displayNotes;
		this.notesService.getDisplayNotes$().next(this.displayNotes);
	}
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ViewMediaModule } from 'src/app/components/view-media/view-media.module';
import { UserActionsModule } from 'src/app/components/user-actions/user-actions.module';
import { UserIconModule } from 'src/app/user-icon/user-icon.module';

import { DisplayGridParticipantsComponent } from './display-grid-participants.component';
import { VideoLayoutsDirective } from './video-layouts.directive';
import { TranslateModule } from '@ngx-translate/core';
import { EmojiModule } from 'src/app/emoji/emoji.module';
import { VideoPlayerModule } from '../../../components/video-player/video-player.module';
import { MatIconModule } from '@angular/material/icon';
import { SecondCameraStreamComponent } from 'src/app/second-camera-stream/second-camera-stream.component'
import { StudentInfoPanelComponent } from "../../../student-info-panel/student-info-panel.component";
import { VolumeMeterMiniComponent } from 'src/app/lib-visual/components/volume-meter-mini/volume-meter-mini.component';

@NgModule({
    declarations: [DisplayGridParticipantsComponent, VideoLayoutsDirective, SecondCameraStreamComponent],
    exports: [DisplayGridParticipantsComponent, SecondCameraStreamComponent],
    imports: [
        CommonModule,
        ViewMediaModule,
        UserActionsModule,
        UserIconModule,
        TranslateModule,
        EmojiModule,
        VideoPlayerModule,
        MatIconModule,
        StudentInfoPanelComponent,
		VolumeMeterMiniComponent
    ]
})
export class DisplayGridParticipantsModule { }

import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Inject,
	NgZone,
	OnInit,
	ViewChild,
	ViewEncapsulation
} from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { Platform } from "@angular/cdk/platform";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MediaDevicesService } from "src/app/lib-media-devices/services/media-devices.service";
import { PlatformDetectorService } from "../../../services/platform-detector/platform-detector.service";
import { VideoStreamDisplayComponent } from "src/app/lib-visual/components/video-stream-display/video-stream-display.component";
import {
	MediaProperies,
	MediaProperiesUtil,
	ParamNumber,
	ParamNumberUtil,
	Resolution,
	SettingPopupData
} from "../setting-popup/setting-popup.interface";
import {
	DEBUG_VALUE_21,
	LocationUtil,
	PRM_DEBUG
} from "src/app/lib-core/utils/location.util";
import { EasyRtcService } from "src/app/lib-rtc/services/easy-rtc.service";
import { LanguageService } from "../../../services/languages/language.service";
import { ProfileService } from "src/app/profile/profile.service";
import { Profile } from "src/app/types/profile.types";
import { PersonalRoomVO } from "src/app/services/data/PersonalRoomVO";
import { ClipboardService } from "src/app/services/clipboard.service";
import { AuthenticationService } from "src/app/services/authentication/authentication.service";
import { BG_COLORS } from "src/app/components/call-room/data/themes";

const SAMPLE_RATE: ParamNumber[] = [
	{ name: "video-settings.sample-size.low", param: 64000, default: true },
	{ name: "video-settings.sample-size.medium", param: 128000 },
	{ name: "video-settings.sample-size.high", param: 192000 }
];
const VIDEO_RESOLUTIONS = [
	{
		id: 0,
		name: "video-settings.video-quality.mobile-low",
		width: 480,
		height: 270
	},
	{
		id: 1,
		name: "video-settings.video-quality.medium",
		width: 480,
		height: 360,
		default: true
	},
	{
		id: 2,
		name: "video-settings.video-quality.low",
		width: 640,
		height: 360
	},

	// {
	// 	id: 3,
	// 	name: "video-settings.video-quality.medium",
	// 	width: 640,
	// 	height: 480,
	// },
	{
		id: 3,
		name: "video-settings.video-quality.high",
		width: 1280,
		height: 720
	},
	{
		id: 4,
		name: "HD 4:3 (960p)",
		width: 1280,
		height: 960
	}
];
const VIDEO_FRAME_RATES: ParamNumber[] = [
	{ name: "video-settings.frame-rate.low", param: 12 },
	{ name: "video-settings.frame-rate.medium", param: 18, default: true },
	{ name: "video-settings.frame-rate.high", param: 24 },
	{ name: "video-settings.frame-rate.extra", param: 30 }
];

@Component({
	selector: "app-setting-popup-without-video",
	templateUrl: "./setting-popup-without-video.component.html",
	styleUrls: ["./setting-popup-without-video.component.scss"],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.Default
})
export class SettingPopupWithoutVideoComponent implements OnInit {
	// Setting: audio
	public audioSampleRates: ParamNumber[] = SAMPLE_RATE;
	// Setting: video
	public videoResolutions: Resolution[] = VIDEO_RESOLUTIONS;
	public videoFrameRates: ParamNumber[] = VIDEO_FRAME_RATES;

	@ViewChild("videoStreamDisplay")
	public videoStreamDisplay: VideoStreamDisplayComponent | undefined;

	public audioInputDevices: MediaDeviceInfo[] = [];
	public audioOutputDevices: MediaDeviceInfo[] = [];
	public videoInputDevices: MediaDeviceInfo[] = [];

	public formGroup: UntypedFormGroup;
	public isProgress = false;

	public bgColors = BG_COLORS;
	public currentBgColor = localStorage.getItem("bgColor") || "#162329";
	public roomData: PersonalRoomVO;

	public support = {
		// Audio
		isSampleRate: false,
		isEchoCancellation: false,
		isNoiseSuppression: false,
		isAutoGainControl: false,
		isStereoAudio: false,
		// Video
		isFrameRate: false
	};
	public audioControls = {
		inputDeviceId: new UntypedFormControl(null, []), // audioInputDevices
		outputDeviceId: new UntypedFormControl(null, []), // audioOutputDevices
		sampleRate: new UntypedFormControl(null, []), // audioSampleRates
		echoCancellation: new UntypedFormControl(true, []),
		noiseSuppression: new UntypedFormControl(false, []),
		autoGainControl: new UntypedFormControl(false, []),
		isStereoAudio: new UntypedFormControl(false, [])
	};

	public videoControls = {
		inputDeviceId: new UntypedFormControl(null, []), // videoInputDevices
		resolution: new UntypedFormControl(null, []), // videoResolutions
		frameRate: new UntypedFormControl(null, []), // videoFrameRates
		isReflection: new UntypedFormControl(null, [])
	};

	private isDebug = false;
	private isBrowserSafari = false;
	private currentMediaProperies: MediaProperies | null = null;
	public tabIndex = 0;
	public isSafari: boolean;
	public hasAudioOrVideoChange: boolean = false;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: SettingPopupData,
		private matDialogRef: MatDialogRef<MediaStreamConstraints>,
		private changeDetectorRef: ChangeDetectorRef,
		private platform: Platform,
		private mediaDevicesService: MediaDevicesService,
		private easyRtcService: EasyRtcService,
		private ngZone: NgZone,
		public auth: AuthenticationService,
		private profileService: ProfileService,
		public clipboardService: ClipboardService,
		public languageService: LanguageService,
		private platformDetectorService: PlatformDetectorService
	) {
		if (data.params.switchToRoomTab) {
			this.tabIndex = 2; // The Room tab (with Link)
		}
		this.isDebug =
			LocationUtil.findGetParameter(PRM_DEBUG) === DEBUG_VALUE_21;
		this.formGroup = new UntypedFormGroup({
			audio: new UntypedFormGroup(this.audioControls),
			video: new UntypedFormGroup(this.videoControls),
			general: new UntypedFormGroup({
				langId: new UntypedFormControl(
					this.languageService.selectedLangId ||
						this.languageService.languages[0]._id,
					[]
				)
			}),
			room: new UntypedFormGroup({
				bgColor: new UntypedFormControl(this.currentBgColor, [])
			})
		});
		const mediaTrackSupported: MediaTrackSupportedConstraints =
			this.mediaDevicesService.getSupportedConstraints();
		const supportedConstraints = Object.keys(mediaTrackSupported);
		// Audio
		this.support.isSampleRate = supportedConstraints.includes("sampleRate");
		this.support.isEchoCancellation =
			supportedConstraints.includes("echoCancellation");
		this.support.isNoiseSuppression =
			supportedConstraints.includes("noiseSuppression");
		this.support.isAutoGainControl =
			supportedConstraints.includes("autoGainControl");
		// Video
		this.support.isFrameRate = supportedConstraints.includes("frameRate");
	}

	async ngOnInit() {
		this.isSafari = this.platformDetectorService.isSafari();
		this.isBrowserSafari = this.platformDetectorService.isBrowserSafari();
		this.setDefaultVideo();
		this.isProgress = true;
		this.markForCheck();
		const deviceIds: MediaDeviceInfo[] =
			await this.mediaDevicesService.enumerateDevices(true);
		this.audioInputDevices =
			this.mediaDevicesService.getDeviceAudioInput(deviceIds);
		this.audioOutputDevices =
			this.mediaDevicesService.getDeviceAudioOutput(deviceIds);
		this.videoInputDevices =
			this.mediaDevicesService.getDeviceVideoInput(deviceIds);

		const mediaProperies = (this.currentMediaProperies =
			MediaProperiesUtil.readMediaProperiesFromStorage());
		const defaultVideoSettings = this.getDefaultVideo();
		if (defaultVideoSettings.width) {
			mediaProperies.videoWidth = defaultVideoSettings.width;
		}
		if (!defaultVideoSettings.height) {
			mediaProperies.videoHeight = defaultVideoSettings.height;
		}
		//   // Audio
		this.prepareAudio(mediaProperies);
		//   // Video
		this.prepareVideo(null, mediaProperies);
		this.videoControls.isReflection.setValue(
			!!mediaProperies.videoIsReflection,
			{ emitEvent: false }
		);
		this.isProgress = false;
		this.loadRoomInfo();
		this.getProfileInfo();

		this.markForCheck();

		const initialVideoValue = this.formGroup.controls.video;
		const initialAudioValue = this.formGroup.controls.audio;
		this.formGroup.controls?.video?.valueChanges.subscribe((value) => {
			this.hasAudioOrVideoChange = Object.keys(initialVideoValue).some(
				(key) => this.formGroup.value[key] !== initialVideoValue[key]
			);
		});
		this.formGroup.controls?.audio?.valueChanges.subscribe((value) => {
			this.hasAudioOrVideoChange = Object.keys(initialAudioValue).some(
				(key) => this.formGroup.value[key] !== initialAudioValue[key]
			);
		});
	}

	getProfileInfo() {
		this.profileService.get().subscribe((profile: Profile) => {
			if (!profile) {
				return;
			}
			const selectedLanguage = this.languageService.languages.find(
				(lang) => lang._id === profile.languageId
			);
			if (selectedLanguage) {
				this.languageService.selectedLangId = selectedLanguage._id;
				this.formGroup.patchValue({
					general: { langId: selectedLanguage._id }
				});
			}
		});
	}

	onChangeBgColor(i: number) {
		this.currentBgColor = this.bgColors[i];
		this.formGroup.patchValue(
			{ room: { bgColor: this.currentBgColor } },
			{ emitEvent: true }
		);
		this.formGroup.markAsDirty();
	}

	loadRoomInfo() {
		this.roomData = JSON.parse(localStorage.getItem("personalRoom"));
	}

	copyLink() {
		this.clipboardService.copyLink(
			this.roomData.link,
			this.roomData.password
		);
	}

	setDefaultVideo(): void {
		const uAgentData = this.mediaDevicesService.getUserAgentData();
		if (
			(!uAgentData && this.platformDetectorService.isMobile()) ||
			(uAgentData &&
				uAgentData.mobile &&
				this.platformDetectorService.isMobile())
		) {
			this.videoResolutions = this.videoResolutions.map((res) => {
				res.default = res.id === 0;
				return res;
			});
		}
	}

	getDefaultVideo(): Resolution {
		if (this.currentMediaProperies.videoResolution) {
			return this.videoResolutions.find(
				(res) =>
					res.id === this.currentMediaProperies.videoResolution.id
			);
		}
		return this.videoResolutions.find((res) => res.default);
	}
	// ** Public API **

	public close(): void {
		this.closeModal(null).then();
	}

	public async testAudio() {
		const audioEnter = new Audio("/assets/EnterSound.mp3");
		await (audioEnter as any).setSinkId(
			this.audioControls.outputDeviceId.value
		);
		await audioEnter.play();
	}

	public doChangeReflection(videoIsReflection: boolean): void {
		this.markForCheck();
	}

	public doChangeEchoCancellation(): void {
		this.changeAudioEchoCancellation();
	}
	public submit(): void {
		if (this.formGroup.valid) {
			this.closeModal(this.createMediaProperties());
		}
	}

	// ** Private API **

	private changeAudioEchoCancellation(): void {
		let isStereoAudioValue = this.audioControls.isStereoAudio.value;
		const audioEchoCancellation = this.audioControls.echoCancellation.value;
		if (
			this.support.isStereoAudio &&
			isStereoAudioValue &&
			!!audioEchoCancellation
		) {
			isStereoAudioValue = false;
		}
		if (this.audioControls.isStereoAudio.value !== isStereoAudioValue) {
			this.audioControls.isStereoAudio.setValue(isStereoAudioValue);
			MediaProperiesUtil.writeAudioChannelCountToStorage(
				isStereoAudioValue ? 2 : 1
			);
		}
	}

	public doChangeLanguage() {
		this.markForCheck();
	}

	private prepareAudio(mediaProperiesPrm: Partial<MediaProperies>): void {
		const mediaProperies: Partial<MediaProperies> = mediaProperiesPrm || {};

		if (this.audioInputDevices.length > 0) {
			const audioInpDevInfo =
				this.mediaDevicesService.findMediaDeviceInfo(
					this.audioInputDevices,
					mediaProperies.audioInpDev
				);
			this.audioControls.inputDeviceId.setValue(
				!!audioInpDevInfo ? audioInpDevInfo.deviceId : null,
				{ emitEvent: false }
			);
		} else {
			this.audioControls.inputDeviceId.disable({ emitEvent: false });
		}

		if (this.audioOutputDevices.length > 0) {
			const audioOutDevInfo =
				this.mediaDevicesService.findMediaDeviceInfo(
					this.audioOutputDevices,
					mediaProperies.audioOutDev
				);
			this.audioControls.outputDeviceId.setValue(
				audioOutDevInfo.deviceId,
				{ emitEvent: false }
			);
		} else {
			this.audioControls.outputDeviceId.disable({ emitEvent: false });
		}

		if (this.support.isSampleRate) {
			const sampleRateDefault: ParamNumber = ParamNumberUtil.findDefault(
				this.audioSampleRates
			);
			const sampleRate: number = mediaProperiesPrm.audioSampleRate;
			const sampleRateValue = ParamNumberUtil.findParam(
				this.audioSampleRates,
				sampleRate,
				sampleRateDefault
			);
			this.audioControls.sampleRate.setValue(
				!!sampleRateValue ? sampleRateValue.param : null,
				{ emitEvent: false }
			);
		}
		if (this.support.isEchoCancellation) {
			this.audioControls.echoCancellation.setValue(
				mediaProperies.audioEchoCancellation,
				{ emitEvent: false }
			);
		}
		if (this.support.isNoiseSuppression) {
			this.audioControls.noiseSuppression.setValue(
				mediaProperies.audioNoiseSuppression,
				{ emitEvent: false }
			);
		}
		if (this.support.isAutoGainControl) {
			this.audioControls.autoGainControl.setValue(
				mediaProperies.audioAutoGainControl,
				{ emitEvent: false }
			);
		}
		if (this.support.isStereoAudio) {
			this.audioControls.isStereoAudio.setValue(
				mediaProperies.audioChannelCount > 1 ? true : false,
				{ emitEvent: false }
			);
		}
	}

	private prepareVideo(
		videoTrackPrm: MediaTrackSettings,
		mediaProperiesPrm: Partial<MediaProperies>
	): void {
		const mediaProperies: Partial<MediaProperies> = mediaProperiesPrm || {};

		// videoDeviceInfo
		if (this.videoInputDevices.length > 0) {
			const videoDeviceInfo =
				this.mediaDevicesService.findMediaDeviceInfo(
					this.videoInputDevices,
					mediaProperies.videoInpDev
				);
			this.videoControls.inputDeviceId.setValue(
				videoDeviceInfo.deviceId,
				{ emitEvent: false }
			);
		} else {
			this.videoControls.inputDeviceId.disable({ emitEvent: false });
		}

		// videoFrameRate
		if (this.support.isFrameRate) {
			const frameRateDefault: ParamNumber = ParamNumberUtil.findDefault(
				this.videoFrameRates
			);
			const frameRate: number = mediaProperiesPrm.videoFrameRate;
			const frameRateValue = ParamNumberUtil.findParam(
				this.videoFrameRates,
				frameRate,
				frameRateDefault
			);
			this.videoControls.frameRate.setValue(
				!!frameRateValue ? frameRateValue.param : null,
				{ emitEvent: false }
			);
		}

		// resolution
		const resolution: Resolution = this.getDefaultVideo();
		this.videoControls.resolution.setValue(
			!!resolution ? resolution : null,
			{ emitEvent: false }
		);
	}

	private markForCheck(): void {
		if (this.isBrowserSafari) {
			this.changeDetectorRef.detectChanges();
		} else {
			this.changeDetectorRef.markForCheck();
		}
	}

	private async closeModal(mediaProperties: MediaProperies): Promise<void> {
		let result: MediaProperies | null = null;
		this.isProgress = true;
		this.markForCheck();

		if (!!mediaProperties) {
			MediaProperiesUtil.writeMediaProperiesToStorage(mediaProperties);
			result = mediaProperties;
		}
		const hasAudioOrVideoChange = this.hasAudioOrVideoChange;

		if (!!result) {
			this.matDialogRef.close({ result, hasAudioOrVideoChange });
		} else {
			this.matDialogRef.close();
		}
	}

	private createMediaProperties(): MediaProperies {
		const resolution: Resolution = this.videoControls.resolution
			.value as Resolution;
		const audioEchoCancellation = this.audioControls.echoCancellation.value;
		const formValue = this.formGroup.getRawValue();
		return {
			// Audio
			audioInpDev: this.audioControls.inputDeviceId.value,
			audioOutDev: this.audioControls.outputDeviceId.value,
			audioSampleRate: this.audioControls.sampleRate.value,
			audioEchoCancellation,
			audioNoiseSuppression: this.audioControls.noiseSuppression.value,
			audioAutoGainControl: this.audioControls.autoGainControl.value,
			audioChannelCount:
				!audioEchoCancellation && this.audioControls.isStereoAudio.value
					? 2
					: 1,
			// Video
			videoInpDev: this.videoControls.inputDeviceId.value,
			videoWidth: !!resolution ? resolution.width : undefined,
			videoHeight: !!resolution ? resolution.height : undefined,
			videoFrameRate: this.videoControls.frameRate.value,
			videoIsReflection: this.videoControls.isReflection.value,
			// langulage
			langId: formValue.general.langId,
			bgColor: formValue.room.bgColor,
			videoResolution: resolution
		};
	}
}

import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import {
	RecordingListItem,
	RecordingService
} from "../services/recording/recording.service";
import { ProfileService } from "../profile/profile.service";
import { flatMap, tap } from "rxjs/internal/operators";
import { Profile } from "../types/profile.types";
import { IconService } from "@shared/services/icon.service";
import { Title } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
// import { HelpScoutService } from "../services/help-scout-service.service";
import { PopupService } from "../services/popup/popup.service";
import { ButtonType } from "../components/call-room/data/ButtonType";

@Component({
	selector: "app-recordings",
	templateUrl: "./recordings.component.html",
	styleUrls: ["./recordings.component.scss"]
})
export class RecordingsComponent implements OnInit {
	@Input() studentId: string;

	displayedColumns: string[] = [
		"title",
		"date",
		"room",
		"size",
		"download",
		"btn"
	];
	recordingsList = [];
	dataSource = new MatTableDataSource<any>();
	isListMenuOpen = false;
	dateRegexp = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/g;
	size: number;
	currentSize: number = 4;
	page: string = "1";
	limit: string;
	filteredData;
	public isStudent: boolean;
	private profileId: string;
	@ViewChild(MatPaginator) paginator: any;

	// @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
	//   this.paginator = mp;
	//   this.setDataSourceAttributes();
	// }

	// setDataSourceAttributes() {
	//   if (this.paginator && this.dataSource) {
	//     this.dataSource.paginator = this.paginator;
	//   }
	// }

	constructor(
		private recordingService: RecordingService,
		private profile: ProfileService,
		private iconService: IconService,
		private title: Title,
		private translateService: TranslateService,
		// private helpScoutService: HelpScoutService,
		private popupService: PopupService
	) {}

	async ngOnInit() {
		this.title.setTitle(
			this.translateService.instant("recordings.seo-title")
		);
		this.profile
			.get()
			.pipe(
				flatMap((profile: Profile) => {
					this.profileId = profile.userId;
					this.isStudent = this.checkSubRole(profile.subrole);
					return this.loadRecordings(this.profileId);
				})
			)
			.subscribe();
		// this.helpScoutService.showBeacon();
	}

	handleSelectedTeacher(teacher: { id: string } | "all") {
		if (teacher !== "all") {
			const teacherId = teacher.id;
			if (this.dataSource) {
				this.filteredData = this.recordingsList.filter(
					(recording) => recording.teacher_id === teacherId
				);
				this.updateDataSource(this.filteredData);
			}
		} else {
			this.loadRecordings(this.profileId).subscribe(() => {
				this.filteredData = [...this.recordingsList];
				this.updateDataSource(this.filteredData);
			});
		}
	}

	loadRecordings(userId: string) {
		const fetchRecordings$ =
			this.studentId || this.isStudent
				? this.recordingService.getListByStudentId(userId)
				: this.recordingService.getListByUserId(userId);

		return fetchRecordings$.pipe(
			tap(({ list, count }) => {
				this.recordingsList = list.map(
					this.mapRecordingItem.bind(this)
				);
				this.size = count;

				this.filteredData = [...this.recordingsList];
				this.updateDataSource(this.filteredData);
			})
		);
	}

	private updateDataSource(data: RecordingListItem[]) {
		this.dataSource = new MatTableDataSource<RecordingListItem>(data);
		this.dataSource.paginator = this.paginator;
	}

	private mapRecordingItem(listItem: RecordingListItem) {
		return {
			...listItem,
			title: listItem.name,
			room: listItem.url.split("sirius.video/")[1]?.split("_")[0],
			date: this.formatDate(listItem.url),
			size: this.convertBytesToMb(listItem.size),
			downloadLink: listItem.url
		};
	}

	formatDate(url: string): string {
		const date: string = url
			.split("sirius.video/")[1]
			?.split("_")[1]
			?.split(".")[0];

		const time: string = date.replace(/([^\-]*\-){3}/, "");
		let formatTime = time.replace(/\-/g, ":");
		let formatDate = date.replace(`-${time}`, "");
		formatDate = formatDate.replace(/\-/g, ".").split("-").join("-");
		formatDate = formatDate.split(".").reverse().join(".");
		formatTime = formatTime.slice(0, -10);
		return `${formatDate}, ${formatTime}`;
	}

	// convertTitle(title: string): string {
	// 	let result = `sirius-${title.replace("_", "-")}`;
	// 	const dotIndex = result.split("").lastIndexOf(".");
	// 	result = result.slice(0, dotIndex - 3) + result.slice(dotIndex);
	// 	let dateToReverse;
	// 	if (
	// 		result.match(this.dateRegexp) &&
	// 		result.match(this.dateRegexp).length
	// 	) {
	// 		dateToReverse = result.match(this.dateRegexp)[0];
	// 	}
	// 	if (dateToReverse) {
	// 		const reverseData = dateToReverse.split("-").reverse().join("-");
	// 		result = result.replace(dateToReverse, reverseData);
	// 	}
	// 	return result;
	// }

	convertBytesToMb(bytes: number): string {
		return Number((bytes / Math.pow(1024, 2)).toFixed(2)) + " " + "MB";
	}

	openDownloadMenuList(recordListItem: RecordingListItem) {
		recordListItem.isDownloadListMenuOpen =
			!recordListItem.isDownloadListMenuOpen;
	}

	copyLink(recordListItem: RecordingListItem) {
		navigator.clipboard
			.writeText(recordListItem.url)
			.then()
			.catch((e) => console.error(e));
	}

	deleteRecording(recordListItem: RecordingListItem) {
		this.recordingService
			.deleteRecording(recordListItem.id)
			.subscribe((data) => {
				if (data) {
					this.recordingsList = this.recordingsList.filter(
						(recItem) => recItem.id !== recordListItem.id
					);
					this.dataSource = new MatTableDataSource<RecordingListItem>(
						this.recordingsList
					);
					this.dataSource.paginator = this.paginator;
				}
			});
	}

	downloadRecording(recordListItem: RecordingListItem) {
		window.open(recordListItem.url, "_blank");

		//  this.recordingService.downloadBlob(recordListItem.url).subscribe(
		//   data => {
		//     this.downLoadFile(data,'video/mp4');
		//   }
		// )
	}

	downLoadFile(data: any, type: string) {
		const blob = new Blob([data], { type });
		// @ts-ignore
		const url = window.URL.createObjectURL(blob);
		const pwa = window.open(url);
		if (!pwa || pwa.closed || typeof pwa.closed === "undefined") {
			console.warn("Please try again.");
		}
	}

	openDeleteRecordingDialog(list) {
		this.popupService.openCustomMessagePopup(
			"recordings.delete-recording-question",
			[
				{
					text: "popUp.delete",
					type: ButtonType.OK,
					callBack: () => {
						this.deleteRecording(list);
					}
				},
				{
					text: "buttons.cancel",
					type: ButtonType.CANCEL
				}
			]
		);
	}

	pageChanged(event) {
		let pageIndex = event.pageIndex + 1;
		let pageSize = event.pageSize;
		let calcSize = event.pageIndex || 1;
		let previousSize = pageSize * calcSize;
		this.getNextData(
			previousSize,
			pageIndex.toString(),
			pageSize.toString()
		);
	}

	openEditNameDialog(recording: RecordingListItem) {
		const dialogRef =
			this.popupService.openRecordingDetailsDialog(recording);

		dialogRef.afterClosed().subscribe((result) => {
			if (result) {
				this.saveName(recording, result);
			}
		});
	}

	saveName(recording, name) {
		this.recordingService
			.updateRecording(recording.id, {
				name
			})
			.subscribe(
				(res: RecordingListItem) => {
					const index = this.dataSource.data.findIndex(
						(i) => i.id === res.id
					);
					if (index !== -1) {
						this.dataSource.data[index]["name"] = res.name;
						this.dataSource.data[index]["title"] = res.name;
						this.dataSource = new MatTableDataSource(
							this.dataSource.data
						);
						this.dataSource.paginator = this.paginator;
					}
				},
				(error) => {
					console.error("error editing recording details", error);
				}
			);
	}

	getNextData(currentSize: number, page: string, limit: string) {
		this.currentSize = currentSize;
		this.page = page;
		this.limit = limit;

		this.recordingService
			.getListByUserId(this.profileId, page, limit)
			.subscribe(({ list, count }) => {
				this.size = count;

				if (count > 10) {
					this.recordingsList.length = currentSize;
					this.recordingsList.push(
						...list.map((item) => this.mapRecordingItem(item))
					);
				} else {
					this.recordingsList = list.map((item) =>
						this.mapRecordingItem(item)
					);
				}

				if (count > 10) {
					this.recordingsList.length = count;
				}

				this.dataSource = new MatTableDataSource<RecordingListItem>(
					this.recordingsList
				);
				this.dataSource._updateChangeSubscription();
				this.dataSource.paginator = this.paginator;
			});
	}

	checkSubRole(subRole: number): boolean {
		return subRole > 3;
	}
}

import { Component } from "@angular/core";
import { SubRole } from "src/app/constants/subroles-constants";
import { AuthenticationService } from "src/app/services/authentication/authentication.service";

@Component({
	selector: "app-profile-page",
	templateUrl: "./profile-page.component.html"
})
export class ProfilePageComponent {
	constructor(private auth: AuthenticationService) {}
	subrole: SubRole;
	async ngOnInit() {
		const profile = await this.auth.profile;
		if (profile) {
			this.subrole = profile.subrole;
		}
	}
}

<mat-sidenav-container id="chat">
	<mat-sidenav #chatUI [opened]="displayChat" [hidden]="!displayChat" position="end" class="chat-desktop">
		<div class="chat-title" [ngClass]="displayChat ? 'chat-open' : 'chat-closed'">
			<span>Chat</span>
			<button class="toggle-chat" (click)="toggleChat()">
				<mat-icon aria-hidden="false" aria-label="back button" fontIcon="close"></mat-icon>
			</button>
		</div>
		<div class="messages-container">
			<div id="talkjs-container" [style.height.px]="viewportHeight">Loading chat...</div>
		</div>
	</mat-sidenav>
</mat-sidenav-container>

import { Directive, Input, TemplateRef, ViewContainerRef } from "@angular/core";
import { AccessControlService } from "../services/access-control/access-control.service";

@Directive({
	selector: "[accessControl]",
})
export class AccessControlDirective {
	@Input() accessControl: string;
	constructor(
		private accessControlService: AccessControlService,
		private templateRef: TemplateRef<any>,
		private viewContainer: ViewContainerRef
	) {}

	async ngOnInit() {
		const hasAccess = await this.accessControlService.hasAccess(
			this.accessControl
		);
		if (hasAccess) {
			this.viewContainer.createEmbeddedView(this.templateRef);
		} else {
			this.viewContainer.clear();
		}
	}
}




<div #videos class="videos-container video-grid flex h-full">
</div>

<!-- <button mat-flat-button color="primary" class="mr-2" (click)="toggleMicrophone()">{{ isMuted ? 'Unmute' : 'Mute' }}</button>
<button mat-flat-button color="warning" class="mr-2" (click)="leaveMeeting()">Leave Meeting</button>
<button mat-flat-button color="warning" class="mr-2" (click)="rejoin()">Rejoin</button> -->


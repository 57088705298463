import { Component, OnInit } from "@angular/core";
import {
	UntypedFormControl,
	UntypedFormGroup,
	Validators
} from "@angular/forms";
import { Router } from "@angular/router";
import { AuthenticationService } from "../services/authentication/authentication.service";
import { LanguagesList } from "../models/language";
import { ProfileService } from "../profile/profile.service";
import { LanguageService } from "../services/languages/language.service";
import { TEXT_NAME_MAX_LENGTH } from "../constants/generic.constants";
import { logger } from "src/app/lib-core/logger";
import { EMAIL_VALIDATION_REG } from "../lib-core/constants/constants";
import { GA4Service } from "src/app/services/ga4.service";
import { getSubDomain } from "../helpers";

declare const fbq: any;

const TEACH_SIGNUP_URL = "teach-signup";

@Component({
	selector: "app-sign-up",
	templateUrl: "./sign-up.component.html",
	styleUrls: ["./sign-up.component.scss"]
})
export class SignUpComponent implements OnInit {
	public isSchool: boolean;
	loginForm: UntypedFormGroup;
	isMailError: boolean;
	teachSignup: boolean;

	constructor(
		private router: Router,
		private authService: AuthenticationService,
		private ga4: GA4Service,
		private profileService: ProfileService,
		private languageService: LanguageService
	) {
		this.loginForm = new UntypedFormGroup({
			userEmail: new UntypedFormControl("", [
				Validators.required,
				Validators.pattern(EMAIL_VALIDATION_REG), // Validators.email,
				Validators.maxLength(TEXT_NAME_MAX_LENGTH)
			])
		});
	}

	ngOnInit() {
		this.isSchool = !!getSubDomain();
		this.teachSignup = this.getIsTeachSignup();
	}

	signUp(): void {
		if (!this.loginForm.valid) {
			logger.log("signUp form is invalid ");
		} else {
			this.authService.email = this.loginForm.value.userEmail;
			localStorage.email = this.authService.email;
			this.profileService
				.getLanguages()
				.subscribe((languages: LanguagesList) => {
					this.languageService.languages = languages.list;
					const selectedLanguage =
						this.languageService.languages.find(
							(lang) => lang.iso === localStorage.language
						);
					if (selectedLanguage) {
						this.languageService.selectedLangId =
							selectedLanguage._id;
						this.languageService.selectedLocale =
							selectedLanguage.iso;
					} else {
						this.languageService.selectedLangId =
							this.languageService.languages[0]._id;
						this.languageService.selectedLocale =
							this.languageService.languages[0].iso;
					}

					(this.teachSignup
						? this.authService.teachSignup$()
						: this.authService.signup$()
					).subscribe(
						(response) => {
							if (response._event === "error") {
								this.isMailError = true;
							} else {
								this.isMailError = false;
								localStorage.email = this.authService.email;

								// do we need this any more?
								if (typeof fbq === "function") {
									fbq("track", "Lead");
								}
								if (!!this.ga4.hasConsent) {
									this.ga4.signedUp();
								}

								this.router.navigate(["signup-details"]);
							}
						},
						(error) => {
							this.isMailError = true;
						}
					);
				});
		}
	}

	getIsTeachSignup() {
		return this.router.url.includes(TEACH_SIGNUP_URL);
	}
}

import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter,
  HostBinding, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation
} from '@angular/core';

import {
  ADAPTATION, AdaptattionUtil, ENDED, ERROR, LOADEDMETADATA, PLAYING, STALLED, SUSPEND, WAITING
} from './video-stream-display.constants';
import { VideoStreamDisplayUtil } from './video-stream-display.util';
import { PlatformDetectorService } from '../../../services/platform-detector/platform-detector.service';

@Component({
  selector: 'app-video-stream-display',
  exportAs: 'VideoStreamDisplay',
  templateUrl: './video-stream-display.component.html',
  styleUrls: ['./video-stream-display.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoStreamDisplayComponent implements OnChanges, OnInit, OnDestroy {
  @Input()
  public adaptation: string = ''; // ADAPTATION
  @Input()
  public mediaStream: MediaStream | null = null;
  @Input()
  public autoplay = false;
  @Input()
  public muted = false;
  @Input()
  public isMirrored = false;
  @Input()
  public audioOutputId = '';
  @Input()
  public debugName = '';

  @ViewChild('video', { static: true })
  public videoElementRef: ElementRef<HTMLVideoElement> | null = null;

  @Output()
  readonly changePlaying: EventEmitter<boolean> = new EventEmitter();

  @HostBinding('class.host-width-100')
  get isClassHostWidth100(): boolean { return this.isHostWidth100; }
  @HostBinding('class.host-height-100')
  get isClassHostHeight100(): boolean { return this.isHostHeight100; }

  public adaptationMode: ADAPTATION = AdaptattionUtil.create(this.adaptation);
  public isPlaying = false;
  public isVideoWidth100 = false;
  public isVideoHeight100 = false;
  public isFullCover = false;
  public get nativeElement(): HTMLVideoElement | null { return (!this.videoElementRef ? null : this.videoElementRef.nativeElement); }

  private isHostWidth100 = false;
  private isHostHeight100 = false;

  public isPhone: boolean;
  public isIPad: boolean;

  constructor(
    private cdr: ChangeDetectorRef,
    private hostElementRef: ElementRef<HTMLDivElement>,
    private platformDetectorService: PlatformDetectorService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.adaptation) {
      this.adaptationMode = AdaptattionUtil.create(this.adaptation);
      // When the video is displayed, the dimensions of the host may increase and for this, a width or height limitation is added.
      this.settingByAdaptationMode(this.adaptationMode);
    }
    // If the video stream arrives late, then start playback.
    // !firstChange ensures that the init has already been executed and the event handlers are already installed.
    if (!!changes.mediaStream && !changes.mediaStream.firstChange && !!this.nativeElement) {
      // this.playMediaStream(this.nativeElement, this.mediaStream, this.muted, this.audioOutputId);
    }
    if (!!changes.muted && !!this.nativeElement) {
      this.nativeElement.muted = this.muted;
    }
    if (!!changes.audioOutputId && !!this.audioOutputId && this.audioOutputId !== 'any' && !!this.nativeElement) {
      this.audioOutputId = (this.audioOutputId === 'any' ? 'default' : this.audioOutputId);
      this.setAudioOutputDevice(this.nativeElement, this.audioOutputId); // && !this.muted // TODO aam ??
    }
  }

  async ngOnInit(): Promise<void> {
    this.isIPad = this.platformDetectorService.isIPad();
    this.isPhone = this.platformDetectorService.isIphone();
    if (!!this.nativeElement) {
      this.nativeElement.addEventListener(ENDED, this.eventEndedStop);
      this.nativeElement.addEventListener(ERROR, this.eventError);
      this.nativeElement.addEventListener(LOADEDMETADATA, this.eventLoadedmetadata);
      this.nativeElement.addEventListener(PLAYING, this.eventPlaying);
      this.nativeElement.addEventListener(STALLED, this.eventStalled);
      this.nativeElement.addEventListener(SUSPEND, this.eventSuspend);
      this.nativeElement.addEventListener(WAITING, this.eventWaiting);

      // this.playMediaStream(this.nativeElement, this.mediaStream, this.muted, this.audioOutputId);
    }
  }

  ngOnDestroy(): void {
    if (!!this.nativeElement) {
      this.nativeElement.removeEventListener(ENDED, this.eventEndedStop);
      this.nativeElement.removeEventListener(ERROR, this.eventError);
      this.nativeElement.removeEventListener(LOADEDMETADATA, this.eventLoadedmetadata);
      this.nativeElement.removeEventListener(PLAYING, this.eventPlaying);
      this.nativeElement.removeEventListener(STALLED, this.eventStalled);
      this.nativeElement.removeEventListener(SUSPEND, this.eventSuspend);
      this.nativeElement.removeEventListener(WAITING, this.eventWaiting);
    }
  }

  // ** Public API **

  // Playback has stopped because the end of the media was reached.
  public eventEndedStop = (event: Event): void => {
    this.isPlaying = false;
    if (this.debugName) { console.log(`VSD.EndedStop("${this.debugName}")`, event, this.isPlaying); }
    this.cdr.markForCheck();
    this.changePlaying.emit(this.isPlaying);
  }
  // Fires when an error occurred during the loading of an audio/video
  public eventError = (event: ErrorEvent): void => {
    console.error(event);
    this.isPlaying = false;
    this.cdr.markForCheck();
    this.changePlaying.emit(this.isPlaying);
  }
  // The metadata has been loaded.
  public eventLoadedmetadata = (event: Event): void => {
    if (this.debugName) { console.log(`VSD.loadedmetadata("${this.debugName}")`, [event]); }
    if (!!this.hostElementRef.nativeElement && !!this.nativeElement) {
      this.alignContainerToVideoSize(this.adaptationMode, this.hostElementRef.nativeElement, this.nativeElement);
      this.cdr.markForCheck();
    }
  }
  // Playback is ready to start after having been paused or delayed due to lack of data.
  public eventPlaying = (event: Event): void => {
    this.isPlaying = true;
    if (this.debugName) { console.log(`VSD.playing("${this.debugName}")`, event, this.isPlaying); }
    this.cdr.markForCheck();
    this.changePlaying.emit(this.isPlaying);
  }
  // The user agent is trying to fetch media data, but data is unexpectedly not forthcoming.
  public eventStalled = (event: Event): void => {
    if (this.debugName) { console.log(`VSD.stalled("${this.debugName}")`, [event]); }
  }
  // Media data loading has been suspended.
  public eventSuspend = (event: Event): void => {
    if (this.debugName) { console.log(`VSD.suspend("${this.debugName}")`, [event]); }
  }
  // Playback has stopped because of a temporary lack of data
  public eventWaiting = (event: Event): void => {
    if (this.debugName) { console.log(`VSD.waiting("${this.debugName}")`, [event]); }
    if (!!this.videoElementRef) {
      const that = this;
      const nativeElement = this.nativeElement;
      setTimeout(() => {
        const readyState = nativeElement.readyState;
        if (readyState <= 2) {
          console.log(`VSD.("${that.debugName}") readyState: ${readyState} `, [that.mediaStream]);
        }
      }, 5000);
    }
  }

  // ** Private API **

  private playMediaStream(
    videoElement: HTMLVideoElement, mediaStream: MediaStream | null, muted: boolean, audioOutputId: string
  ): Promise<void> {
    if (!!videoElement && !!mediaStream && videoElement.srcObject !== mediaStream) {
      if (this.isPlaying) {
        videoElement.pause();
      }
      videoElement.srcObject = mediaStream;
      videoElement.muted = muted;

      return videoElement.play()
        .then(() => {
          this.setAudioOutputDevice(videoElement, audioOutputId);
        })
        .catch((error) =>
          this.eventError(error))
        .finally(() =>
          this.cdr.markForCheck());
    } else {
      return Promise.resolve();
    }
  }

  private settingByAdaptationMode(adaptationMode: ADAPTATION): void {
    switch (adaptationMode) {
      case ADAPTATION.FIT_VIDEO_HEIGHT:
        this.isHostWidth100 = false;
        this.isHostHeight100 = true;
        this.isVideoWidth100 = false;
        this.isVideoHeight100 = true;
        this.isFullCover = false;
        break;
      case ADAPTATION.FIT_VIDEO_WIDTH:
        this.isHostWidth100 = true;
        this.isHostHeight100 = false;
        this.isVideoWidth100 = true;
        this.isVideoHeight100 = false;
        this.isFullCover = false;
        break;
      case ADAPTATION.ADAPT_BY_HOST_SIZES:
        this.isHostWidth100 = true;
        this.isHostHeight100 = true;
        this.isVideoWidth100 = true;
        this.isVideoHeight100 = true;
        this.isFullCover = false;
        break;
      case ADAPTATION.ADAPT_BY_HOST_SIZES_AND_COVER:
        this.isHostWidth100 = true;
        this.isHostHeight100 = true;
        this.isVideoWidth100 = true;
        this.isVideoHeight100 = true;
        this.isFullCover = true;
        break;
    }
  }

  private getProportions(width: number, height: number): number {
    return (width > 0 && height > 0 ? width / height : 0.0);
  }

  private alignContainerToVideoSize(adaptationMode: ADAPTATION, hostElement: HTMLDivElement, videoElement: HTMLVideoElement): void {
    const offsetWidth = (!!hostElement ? hostElement.offsetWidth : 0.0);
    const offsetHeight = (!!hostElement ? hostElement.offsetHeight : 0.0);
    const hostProportions = this.getProportions(offsetWidth, offsetHeight);
    const videoWidth = (!!videoElement ? videoElement.videoWidth : 0.0);
    const videoHeight = (!!videoElement ? videoElement.videoHeight : 0.0);
    const videoProportions = this.getProportions(videoWidth, videoHeight);

    if (!!adaptationMode && hostProportions > 0.0 && videoProportions > 0.0) {
      this.settingByAdaptationMode(this.adaptationMode);
      videoElement.removeAttribute('width');
      videoElement.removeAttribute('height');
      let countOfChanges = 0;
      switch (adaptationMode) {
        case ADAPTATION.FIT_VIDEO_HEIGHT:
          const newWidth = Math.ceil(videoProportions * hostElement.offsetHeight);
          countOfChanges += (videoElement.width !== newWidth ? 1 : 0);
          videoElement.setAttribute('width', `${newWidth}px`);
          break;
        case ADAPTATION.FIT_VIDEO_WIDTH:
          const newHeight = Math.ceil(hostElement.offsetWidth / videoProportions);
          countOfChanges += (videoElement.height !== newHeight ? 1 : 0);
          videoElement.setAttribute('height', `${newHeight}px`);
          break;
        case ADAPTATION.ADAPT_BY_HOST_SIZES:
        case ADAPTATION.ADAPT_BY_HOST_SIZES_AND_COVER:
          countOfChanges += 1;
          break;
      }
      if (countOfChanges > 0) {
        this.cdr.markForCheck();
      }
    }
  }

  private async setAudioOutputDevice(element: any, audioOutputId: string): Promise<string> {
    // Attach audio output device to video element using device/sink ID.
    return await VideoStreamDisplayUtil.attachSinkId(element, audioOutputId)
      .catch((reason: string | void) => {
        return '';
      });
  }

}

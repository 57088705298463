import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {UserEntryVO} from '../../../data/UserEntryVO';
import {UsersDataService} from '../../../services/users-data.service';
import {AuthenticationService} from '../../../../../services/authentication/authentication.service';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss']
})
export class UsersListComponent implements OnInit, OnDestroy {
  pageNumber: number;
  users: UserEntryVO[];
  maxPages: number;
  pages: number[] = [];
  organizationID: string;

  constructor(private dataService: UsersDataService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private authenticationService: AuthenticationService) { }
  private queryParamsSubscribe: Subscription;

  ngOnInit() {
    this.queryParamsSubscribe = this.activatedRoute.queryParams
      .subscribe(queryParams => {
        if (queryParams.organization) {
          this.organizationID = this.dataService.organizationID = queryParams.organization;
        }
        if (!queryParams.page || queryParams.page === '0') {
          const queryParameters = this.dataService.organizationID ? {
              organization: this.dataService.organizationID,
              page: 1
            } : { page: 1 };
          this.router.navigate([], {relativeTo: this.activatedRoute, queryParams: queryParameters});
        } else {
          this.pageNumber = parseInt(queryParams.page) || 1;
          this.dataService.get(this.dataService.organizationID, this.pageNumber)
            .subscribe(userData => {
              this.maxPages = userData.length;
              this.users = userData.items;
              this.definePages();
            }, error => {
              if (error.status === 401 || error.status === 403) {
                this.authenticationService.logOut$().subscribe(res => {
                  this.authenticationService.isLoggedIn$.next(false);
                  this.router.navigate(['admin/login']);
                });
              }
              console.error(error);
            });
        }
    });
  }
  ngOnDestroy(): void {
    if (this.queryParamsSubscribe) {
      this.queryParamsSubscribe.unsubscribe();
      this.queryParamsSubscribe = null;
    }
  }
  updateList(): void {
    this.dataService.get(this.dataService.organizationID, this.pageNumber)
      .subscribe(userData => {
        this.maxPages = userData.length;
        this.users = userData.items;
        this.definePages();
      }, error => {
        if (error.status === 401 || error.status === 403) {
          this.authenticationService.logOut$().subscribe(res => {
            this.authenticationService.isLoggedIn$.next(false);
            this.router.navigate(['admin/login']);
          });
        }
        console.error(error);
      });
  }
  onNewClicked() {
    this.router.navigate(['admin/create-user'], {queryParams: {organization: this.dataService.organizationID}});
  }
  private definePages() {
    this.pages = [];
    const startNumber = Math.max(0, this.pageNumber - 5);
    const endNumber = Math.min(startNumber + 10, this.maxPages);
    for (let i = startNumber; i < endNumber; i++) {
      this.pages.push(i + 1);
    }
  }

}

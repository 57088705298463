import { Component, OnDestroy, OnInit, Input } from "@angular/core";
import {
	Availability,
	AvailabilityService
} from "../services/availability/availability.service";
import { flatMap } from "rxjs/internal/operators";
import { Profile } from "../types/profile.types";
import { ProfileService } from "../profile/profile.service";
import {
	FormArray,
	FormBuilder,
	FormControl,
	FormGroup,
	Validators,
	ValidatorFn,
	ValidationErrors
} from "@angular/forms";
import { of, Subject } from "rxjs";
import timeZone from "./../../assets/time-zone/time-zone.json";
import { TranslateService } from "@ngx-translate/core";
import { takeUntil } from "rxjs/operators";
import { Router } from "@angular/router";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

@Component({
	selector: "app-availability",
	templateUrl: "./availability.component.html",
	styleUrls: ["./availability.component.scss"]
})
export class AvailabilityComponent implements OnInit, OnDestroy {
	userId: string;
	days: string[] = [];
	availabilityFrom: FormGroup;
	timeZone: string;
	defaultSlots = this.availabilityService.defaultSlots;
	loading = false;
	selectedNotice: number;
	init = false;
	overrideData: Availability[];
	removeOverrideDate: Availability[] = [];
	addOverrideDate = [];
	selectedTimeZone;
	formUpdated: boolean = false;
	dayUpdated: boolean = false;
	noticeUpdated: boolean = false;
	overrideUpdated: boolean = false;
	isFormDirty: boolean = false;
	noticeChangeCounter: number = 0;
	destroy$ = new Subject();
	savedTimeout: any;
	savedLimitTimeout: any;
	isSavedMessage = false;
	isSavedLimitMessage = false;
	selectedLimit: number;
	@Input({ required: true }) lessonType: string;

	canAddTime(day): boolean {
		const arrayControl = this.availabilityFrom.controls.items as FormArray;
		const lastDuplicateDate = arrayControl.value?.findLast(
			(item) => item.duplicate && item.dayName === day
		);
		if (lastDuplicateDate && lastDuplicateDate?.endTime) {
			const endTime = lastDuplicateDate?.endTime.slice(0, 2);
			if (Number(endTime) > 18) {
				return false;
			}
		}
		return true;
	}

	constructor(
		private availabilityService: AvailabilityService,
		private profile: ProfileService,
		private formBuilder: FormBuilder,
		private translateService: TranslateService,
		private router: Router
	) {
		this.days = [
			this.translateService.instant("availability.sunday"),
			this.translateService.instant("availability.monday"),
			this.translateService.instant("availability.tuesday"),
			this.translateService.instant("availability.wednesday"),
			this.translateService.instant("availability.thursday"),
			this.translateService.instant("availability.friday"),
			this.translateService.instant("availability.saturday")
		];
		this.translateService.onLangChange
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => {
				this.router
					.navigateByUrl("platform", { skipLocationChange: true })
					.then(() => {
						this.router.navigate(["platform/availability"]);
					});
			});
	}

	ngOnInit() {
		this.availabilityFrom = this.formBuilder.group({
			items: this.formBuilder.array([])
		});
		this.initAvailability();
	}

	formHasErrors(formGroup: FormGroup | FormArray): boolean {
		if (!formGroup) {
			return false;
		}
		for (const key in formGroup.controls) {
			const control = formGroup.get(key);

			if (control instanceof FormGroup || control instanceof FormArray) {
				if (this.formHasErrors(control)) {
					return true;
				}
			}
			if (control.invalid) {
				return true;
			}
		}

		return false;
	}

	// to start start the week from Monday (sort form array afterwards)

	moveFirstToEnd(formArray: FormArray) {
		if (formArray.length > 0) {
			const firstItem = formArray.at(0);
			formArray.removeAt(0);
			formArray.push(firstItem);
		}
	}

	initAvailability() {
		//  Fetches the profile, sets the userId and timezone. Fetches the availability data and sets the default availability data if none is found.

		this.profile
			.get()
			.pipe(
				flatMap((profile: Profile) => {
					this.userId = profile.userId;
					this.timeZone = profile.timezone;
					return this.availabilityService.getAvailability(
						this.userId,
						this.lessonType
					);
				}),
				flatMap((availability: any) => {
					if (availability.length < 1) {
						const availability =
							this.availabilityService.getDefaultAvailabilityData(
								this.lessonType
							);

						return this.availabilityService.addAvailability(
							this.userId,
							availability
						);
					} else {
						return of(availability);
					}
				})
			)
			.subscribe((availabilityData: Availability[]) => {
				// filter out the override dates
				const availability = availabilityData.filter(
					(a) => !a.overrideDate
				);

				// filter out the non override dates
				this.overrideData = availabilityData.filter(
					(a) => a.overrideDate
				);

				this.overrideData.forEach((override) => {
					override.startTime =
						override.startTime == null
							? "00:00"
							: this.convertDefaultTime(override.startTime);
					override.endTime =
						override.endTime == null
							? "23:30"
							: this.convertDefaultTime(override.endTime);
				});

				availability.forEach((a) => {
					const day = this.days[a.day];

					this.selectedNotice = a.notice;
					this.selectedLimit = a.bookingLimit;

					let startTime;

					if (a.startTime) {
						startTime = this.convertDefaultTime(a.startTime);
					} else {
						startTime = this.convertDefaultTime("08:00:00");
					}

					const slots = this.defaultSlots;

					let endTime;

					if (a.endTime) {
						endTime = this.convertDefaultTime(a.endTime);
					} else {
						endTime = this.convertDefaultTime("08:30:00");
					}

					const isDuplicate = (
						this.availabilityFrom.controls.items as FormArray
					).value.find((item) => item.dayName === this.days[a.day]);

					// Here we push the init data to the form array

					(this.availabilityFrom.controls.items as FormArray).push(
						this.createItem(
							day,
							!!isDuplicate,
							!!a.startTime && !!a.endTime,
							0,
							startTime,
							endTime,
							slots,
							true
						)
					);

					if (a.day === 6) {
						this.moveFirstToEnd(
							this.availabilityFrom.controls.items as FormArray
						);
					}

					this.init = true;
				});

				this.selectedTimeZone = timeZone.find((tz) =>
					tz.utc.includes(this.timeZone)
				);
				this.availabilityFrom.valueChanges.subscribe(() => {
					this.formUpdated = true;
					this.isFormDirty = true;
				});
			});
	}

	onNoticeChange(notice: number) {
		if (this.noticeChangeCounter > 0) {
			if (this.noticeUpdated != true) {
				this.noticeUpdated = true;
				this.isFormDirty = true;
			}
		}
		this.selectedNotice = notice;
		this.noticeChangeCounter++;
	}

	ngOnDestroy() {
		this.destroy$.next();
	}

	convertDefaultTime(time): string {
		let dayjsDate = dayjs().tz(this.timeZone);
		dayjsDate = dayjsDate.set("hour", time.split(":")[0]);
		dayjsDate = dayjsDate.set("minute", time.split(":")[1]);
		const timeHours = time?.split(":")[0];
		const timeMinutes = time?.split(":")[1];
		const date = new Date();
		date.setHours(Number(timeHours), Number(timeMinutes), 0);
		return dayjsDate.format("HH:mm");
	}

	calculateTimeInterval(startTime: string, endTime: string) {
		const startHours = startTime?.split(":")[0];
		const startMinutes = startTime?.split(":")[1];
		const endHours = endTime?.split(":")[0];
		const endMinutes = endTime?.split(":")[1];
		let slots = [];
		const start = new Date();
		start.setHours(Number(startHours), Number(startMinutes), 0);
		const end = new Date();
		end.setHours(Number(endHours), Number(endMinutes), 0);
		if (start && end) {
			while (start <= end) {
				slots.push(
					start.toLocaleString("en-US", {
						hour: "2-digit",
						minute: "2-digit",
						hour12: false
					})
				);
				start.setMinutes(start.getMinutes() + 30);
			}
		}
		return slots;
	}

	createItem(
		dayName: string,
		duplicate = false,
		checked = false,
		index = 0,
		startTime?,
		endTime?,
		slots?,
		init = false
	) {
		return this.formBuilder.group(
			{
				checked: new FormControl(checked, [Validators.required]),

				dayName: new FormControl(dayName, [Validators.required]),

				startTime: new FormControl(
					init
						? startTime
						: (this.availabilityFrom.controls.items as FormArray)
								.at(index - 1)
								?.get("startTime").value || startTime,
					[Validators.required]
				),

				endTime: new FormControl(
					init
						? endTime
						: (this.availabilityFrom.controls.items as FormArray)
								.at(index - 1)
								?.get("endTime").value || endTime,
					[Validators.required]
				),

				duplicate: new FormControl(duplicate),

				slots: new FormControl(
					slots
						? slots
						: (this.availabilityFrom.controls.items as FormArray)
								.at(index - 1)
								?.get("slots").value
				),

				showSelectListBox: new FormControl(false),

				showStartTimeListBox: new FormControl(false),

				showEndTimeListBox: new FormControl(false)
			},
			{ validators: this.customValidator }
		);
	}

	convertTime(date): string {
		const hours = date.getHours().toString().padStart(2, "0");
		const minutes = date.getMinutes().toString().padStart(2, "0");
		return `${hours}:${minutes}`;
	}

	getControlTime(index: number, controlName: string) {
		const arrayControl = this.availabilityFrom.controls.items as FormArray;
		const previousTime = arrayControl.at(index).get(controlName);
		if (controlName === "startTime") {
			const endTimeControl = arrayControl.at(index).get("endTime");
			previousTime.setValue(endTimeControl.value);
			return;
		}
		const previousTimeHours = previousTime?.value?.split(":")[0];
		const previousTimeMinutes = previousTime?.value?.split(":")[1];
		const date = new Date();
		date.setHours(
			Number(previousTimeHours),
			Number(previousTimeMinutes),
			0
		);
		const currentTime = new Date(
			date.getTime() + 30 * 60000
		).toLocaleString("en-US", {
			hour: "2-digit",
			minute: "2-digit",
			hour12: false
		});
		previousTime.setValue(currentTime);
	}

	addNext(day: string, index: any) {
		let i = ++index;
		const arrayControl = this.availabilityFrom.controls.items as FormArray;
		const subIndexes = arrayControl.value?.flatMap((item, i) =>
			item.duplicate && item.dayName === day ? i : []
		);
		if (subIndexes[subIndexes.length - 1]) {
			i = i + subIndexes.length;
		}
		(this.availabilityFrom.controls.items as FormArray).insert(
			i,
			this.createItem(day, true, true, i)
		);
		this.getControlTime(i, "startTime");
		this.getControlTime(i, "endTime");
	}

	addDuplicate(day: string, index: any, startTime: string, endTime: string) {
		let i = ++index;
		const arrayControl = this.availabilityFrom.controls.items as FormArray;
		const subIndexes = arrayControl.value?.flatMap((item, i) =>
			item.duplicate && item.dayName === day ? i : []
		);
		if (subIndexes[subIndexes.length - 1]) {
			i = i + subIndexes.length;
		}
		(this.availabilityFrom.controls.items as FormArray).insert(
			i,
			this.createDuplicateItem(day, true, startTime, endTime, i)
		);
	}

	createDuplicateItem(
		dayName: string,
		checked = false,
		startTime: string,
		endTime: string,
		index = 0
	) {
		return this.formBuilder.group({
			checked: new FormControl(checked, [Validators.required]),
			dayName: new FormControl(dayName, [Validators.required]),
			startTime: new FormControl(startTime, [Validators.required]),
			endTime: new FormControl(endTime, [Validators.required]),
			duplicate: new FormControl(true),
			showSelectListBox: new FormControl(false),
			showStartTimeListBox: new FormControl(false),
			showEndTimeListBox: new FormControl(false)
		});
	}

	customValidator: ValidatorFn = (
		control: FormGroup
	): ValidationErrors | null => {
		const checked = control.get("checked").value;

		if (!checked) {
			return null; // Return null to indicate that the validation passes when 'checked' is false
		}

		const startTime = control.get("startTime").value;
		const endTime = control.get("endTime").value;

		if (startTime && endTime && startTime >= endTime) {
			return { startTimeAfterEndTime: true };
		} else {
			return null;
		}
	};

	toggleDay(event, day: string) {
		if (!this.dayUpdated) {
			this.dayUpdated = true;
		}
		if (!event.target.checked) {
			const arrayControl = this.availabilityFrom.controls
				.items as FormArray;
			const subIndexes = arrayControl.value?.flatMap((item, i) =>
				item.duplicate && item.dayName === day ? i : []
			);
			subIndexes
				.reverse()
				.forEach((index) => arrayControl.removeAt(index));
		}
	}

	remove(index: number) {
		const arrayControl = this.availabilityFrom.controls.items as FormArray;
		arrayControl.removeAt(index);
	}

	focus(el: HTMLInputElement, slotsControl, time: string) {
		el.focus();
		el.setSelectionRange(0, 0);
		slotsControl.value.forEach((slot) => {
			slot.checked = slot.slot === time;
		});
		slotsControl.setValue(slotsControl.value);
	}

	toggleTimeInput(
		el: HTMLInputElement,
		control,
		time: string,
		timeControl,
		type: string
	) {
		const itemControl = control;
		itemControl?.slots?.value.forEach((slot) => {
			slot.checked = slot.slot === time;
		});
		itemControl?.slots.setValue(itemControl?.slots.value);
		timeControl.setValue(!timeControl.value);
		if (type === "endTime") {
			const slotsControl = control.slots;
			// check start index in defaultSlots array
			const selectedStartIndex = slotsControl.value.findIndex(
				(slot) => slot.slot === control.startTime.value
			);
			slotsControl.value.forEach((slot, index) => {
				// console.log(index);
				// compares index with with start index
				// slot.hide = index <= selectedStartIndex;
			});
			slotsControl.setValue(slotsControl.value);
			itemControl.showStartTimeListBox.setValue(false);
		} else {
			const slotsControl = control.slots;
			// check end index in defaultSlots array
			const selectedEndIndex = slotsControl.value.findIndex(
				(slot) => slot.slot === control.endTime.value
			);
			// console.log(selectedEndIndex);
			slotsControl.value.forEach((slot, index) => {
				// console.log(index);
				// compares index with with end index
				// slot.hide = index >= selectedEndIndex;
			});
			slotsControl.setValue(slotsControl.value);
			itemControl.showEndTimeListBox.setValue(false);
		}
	}

	selectSlot(slot, control, timeControl, showTimeListBoxControl, type) {
		timeControl.setValue(slot);
		showTimeListBoxControl.setValue(false);
	}

	toggleSelectListBox(control: FormControl<boolean>) {
		control.setValue(!control.value);
	}

	close(control: FormControl<boolean>) {
		control.setValue(false);
	}

	handleSelectList(days, selectedControl) {
		const arrayControl = this.availabilityFrom.controls.items as FormArray;
		const selectedControls = arrayControl.controls.filter(
			(controls: any) =>
				controls &&
				controls.controls &&
				days.includes(controls.controls.dayName.value) &&
				!controls.controls.duplicate.value
		);

		const dayName = selectedControl?.dayName.value;
		const checked = selectedControl?.checked.value;
		const startTime = selectedControl?.startTime.value;
		const endTime = selectedControl?.endTime.value;
		const duplicate = selectedControl?.duplicate.value;

		const selectedControlDuplicates = arrayControl.controls.filter(
			(controls: any) =>
				controls &&
				controls.controls &&
				controls.controls.dayName.value === dayName &&
				controls.controls.duplicate.value
		);

		const selectedControlsDays = [];
		selectedControls.forEach((controls: any) => {
			selectedControlsDays.push(controls.controls.dayName.value);
			controls.controls.checked.setValue(checked);
			controls.controls.startTime.setValue(startTime);
			controls.controls.endTime.setValue(endTime);
			controls.controls.duplicate.setValue(duplicate);
			controls.controls.showSelectListBox.setValue(false);
			controls.controls.showStartTimeListBox.setValue(false);
			controls.controls.showEndTimeListBox.setValue(false);
		});
		const selectedControlsDuplicatesIndexes = arrayControl.controls.flatMap(
			(controls: any, i) =>
				controls &&
				controls.controls &&
				selectedControlsDays.includes(
					controls.controls.dayName.value
				) &&
				controls.controls.duplicate.value
					? i
					: []
		);
		selectedControlsDuplicatesIndexes
			.reverse()
			.forEach((i) => this.remove(i));

		const selectedControlIndexes = arrayControl.controls.flatMap(
			(controls: any, i) =>
				controls &&
				controls.controls &&
				selectedControlsDays.includes(
					controls.controls.dayName.value
				) &&
				!controls.controls.duplicate.value
					? i
					: []
		);
		selectedControlIndexes.reverse().forEach((index) => {
			this.createDuplicates(
				index,
				selectedControlDuplicates,
				arrayControl
			);
		});
	}

	createDuplicates(index, duplicates, arrayControl) {
		const dayName = arrayControl.at(index).get("dayName").value;
		duplicates.forEach((controls: any) => {
			const startTime = controls.controls.startTime.value;
			const endTime = controls.controls.endTime.value;
			this.addDuplicate(dayName, index, startTime, endTime);
		});
	}

	handleDomChangeStates() {
		this.overrideUpdated = false;
		this.dayUpdated = false;
		this.noticeUpdated = false;
		this.isFormDirty = false;
	}

	save() {
		dayjs.extend(timezone);
		dayjs.extend(utc);
		this.loading = true;
		this.handleDomChangeStates();
		const result = [];
		(this.availabilityFrom.controls.items.value as any).forEach((item) => {
			const startString = item.startTime;
			const endString = item.endTime;

			let startTime = item.checked ? startString : null;
			let endTime = item.checked ? endString : null;
			const day = this.days.findIndex((d) => d === item.dayName);

			result.push({
				type: this.lessonType,
				day,
				startTime,
				endTime
			});
		});

		this.availabilityService
			.updateNotice(this.userId, this.selectedNotice, this.lessonType)
			.pipe(
				flatMap(() =>
					this.availabilityService.addAvailability(
						this.userId,
						result
					)
				)
			)
			.subscribe(() => {
				this.loading = false;
				this.showSavedMessage();
			});
	}

	showSavedMessage() {
		this.isSavedMessage = true;
		clearTimeout(this.savedTimeout);
		this.savedTimeout = setTimeout(() => {
			this.isSavedMessage = false;
		}, 3000);
	}

	showSavedLimitMessage() {
		this.isSavedLimitMessage = true;
		clearTimeout(this.savedLimitTimeout);
		this.savedLimitTimeout = setTimeout(() => {
			this.isSavedLimitMessage = false;
		}, 3000);
	}

	onLimitChange(limit: number) {
		if (limit >= 14) {
			return this.availabilityService
				.updateLimit(this.userId, limit, this.lessonType)
				.subscribe(() => {
					this.showSavedLimitMessage();
				});
		}
	}
}

import { Component } from '@angular/core';

@Component({
  selector: 'app-second-camera-stream',
  templateUrl: './second-camera-stream.component.html',
  styleUrls: ['./second-camera-stream.component.scss']
})
export class SecondCameraStreamComponent {

}

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SetupComponent } from "./setup.component";
import { DeviceBrowserComponent } from "./device-browser/device-browser.component";
import { NetworkSpeedComponent } from "./network-speed/network-speed.component";
import { AudioSetupComponent } from "./audio-setup/audio-setup.component";
import { CameraSetupComponent } from "./camera-setup/camera-setup.component";
import { TranslateModule } from "@ngx-translate/core";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { LanguageSelectorModule } from "../language-selector/language-selector.module";
import { VolumeMeterModule } from 'src/app/lib-visual/components/volume-meter/volume-meter.module';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { VideoSettingsModule } from '../video-settings/video-settings.module';

@NgModule({
  declarations: [
    SetupComponent,
    DeviceBrowserComponent,
    NetworkSpeedComponent,
    AudioSetupComponent,
    CameraSetupComponent,
  ],
  imports: [CommonModule, TranslateModule, MatIconModule, MatProgressBarModule, LanguageSelectorModule, VolumeMeterModule,MatSelectModule,MatOptionModule, FormsModule,FormsModule,
    ReactiveFormsModule, VideoSettingsModule],
  exports: [SetupComponent],
})
export class SetupModule {}

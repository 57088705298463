import {Injectable, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {SELF_VIDEO} from '../data/local-constants';
import {CallStorage} from './storage/call-storage.service';
import {CallRoom, Room} from '../component/call-room';
import {StreamSettingsStorage} from './storage/rtc-storage.service';
import {EasyRtcService} from '../../../lib-rtc/services/easy-rtc.service';

// tslint:disable
@Injectable()
export class MainVideoDecorator implements Room, OnDestroy{
  private originalRoom: CallRoom = null;
  private subscribes:  Array<Subscription> = new Array<Subscription>();
  constructor(public callStorage: CallStorage, private vmStorage: StreamSettingsStorage) {}
  decorate(local: CallRoom) {
    this.originalRoom = local;
    this.initObservers();
    return this;
  }

  ngOnDestroy(): void {
    this.clearObservers();
  }

  createNewVideo = (id: string) => {
    return this.originalRoom.createNewVideo(id) as HTMLVideoElement;
  }

  getMainVideo = () => {
    return this.originalRoom.getMainVideo();
  }

  getVideoById = (id: string) => {
    return this.originalRoom.getVideoById(id);
  }
  
  removeChildById = (id: string) => {
    if (this.callStorage.mainVideo === id) {
      if (this.callStorage.prevVideo) {
        this.callStorage.updateMainVideo(this.callStorage.prevVideo);
      } else {
        this.callStorage.updateMainVideo(SELF_VIDEO);
      }
    }
    this.originalRoom.removeChildById(id);
  }
  updateById = (id: string, stream: MediaStream, isInit?: boolean) => {
    if (!this.originalRoom.getSelfVideo()) { return; }
    EasyRtcService.setVideoObjectSrc(this.originalRoom.getVideoById(id) , stream);
    if (id === SELF_VIDEO) {
      this.originalRoom.getSelfVideo().muted = true;
    }
    if (this.callStorage.mainVideo === id) {
      EasyRtcService.setVideoObjectSrc(this.originalRoom.getMainVideo() , stream);
      this.originalRoom.getMainVideo().muted = true;
    } else if (this.callStorage.prevVideo === id && isInit) {
      this.callStorage.updateMainVideo(id);
    }
  }
  private initObservers() {
    this.subscribes.push(this.callStorage.mainVideo$.subscribe(value => {
      const donorElement = this.getVideoById(value.currentVideo);
      if (donorElement && typeof donorElement.srcObject !== 'undefined') {
        EasyRtcService.setVideoObjectSrc(this.getMainVideo() , <MediaStream>donorElement.srcObject);
        this.getMainVideo().muted = true;
      }
    }));
    this.subscribes.push(this.vmStorage.selectedChanel$.subscribe((data) => {
      if (!this.originalRoom.getSelfVideo()) { return; }
      EasyRtcService.setVideoObjectSrc(this.originalRoom.getSelfVideo(), EasyRtcService.getLocalStream(data.current));
      this.originalRoom.getSelfVideo().muted = true;
      if (this.callStorage.mainVideo === SELF_VIDEO) {
        EasyRtcService.setVideoObjectSrc(this.originalRoom.getMainVideo(), EasyRtcService.getLocalStream(data.current));
        this.originalRoom.getMainVideo().muted = true;
      }
    }));
    this.subscribes.push(this.callStorage.usersStorage.onUsersUpdate$.subscribe(() => {
      const usersLeft = this.callStorage.usersStorage.users.filter(user => user.connected && user.rtcId != undefined);
      if(usersLeft.length==0) {
        this.callStorage.updateMainVideo(SELF_VIDEO);
      } else {
        const mainVideoUser = usersLeft.filter(user => user.rtcId === this.callStorage.mainVideo);
        if (mainVideoUser.length == 0 && this.callStorage.mainVideo !== SELF_VIDEO) {
          this.callStorage.updateMainVideo(usersLeft[0].rtcId);
        }
      }
    }))
  }
  private clearObservers(){
    this.subscribes.map((subscribe) => subscribe.unsubscribe())
  }
}

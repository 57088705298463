import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AuthenticationService } from "../authentication/authentication.service";
import { STRIPE_SESSION } from "src/app/constants/connection-constants";
import { logger } from "src/app/lib-core/logger";

@Injectable({
  providedIn: "root",
})
export class StripeService {
  constructor(private http: HttpClient, private auth: AuthenticationService) {}

  openPortalSession(id: string) {
    this.http
      .post(
        STRIPE_SESSION + "/" + id,
        {
          returnUrl: "https://sirius.video/",
        },
        {
          headers: this.auth.headers,
          withCredentials: true,
        }
      )
      .subscribe(
        (res) => {
          if (res["url"]) {
            window.open(res["url"], "_blank");
          }
        },
        (err) => {
          logger.log("error");
        }
      );
  }
}

import { Injectable } from "@angular/core";
import { logger } from "../../../lib-core/logger";
import { playDummySound } from "../../../helpers";
import { RoomStateStorage } from "./storage/RoomStateStorage";
import { ChatService } from "../../chat/services/chat.service";
import { PopupService } from "../../../services/popup/popup.service";
import { CallStorage } from "./storage/call-storage.service";
import { TranslateService } from "@ngx-translate/core";
import { StorageUtil } from "../../../utils/storage.util";
import { EasyRtcService } from "../../../lib-rtc/services/easy-rtc.service";
import { USER_KEY } from "../../../constants/localstorage-constants";
import { LoggingService } from "../../../services/logging/logging.service";
import { QualityRatingStorageUtil } from "../../../lm-quality-rating/utils/quality-rating-storage.util";
import { SessionStorageUtil } from "../../../lib-core/utils/session-storage.util";
import { Tariff, TariffUtil } from "../../../types/tariff-constants";
import { AuthenticationService } from "../../../services/authentication/authentication.service";
import { GA4Service } from "src/app/services/ga4.service";
import { getSubDomain } from "../../../helpers";
import { SpeakerTimeDetectorService } from "../../../services/speaker-time-detector.service";
import { BotService } from "../../../services/bot/bot.service";
import { PlatformDetectorService } from "../../../services/platform-detector/platform-detector.service";
import { SubRole } from "src/app/constants/subroles-constants";
import { RoomType } from "src/app/services/room.service";

@Injectable({
	providedIn: "root"
})
export class CallRoomService {
	audioEnter: HTMLAudioElement = new Audio("/assets/EnterSound.mp3");
	audioKnock: HTMLAudioElement = new Audio("/assets/Sirius_Bell_4.mp3");

	constructor(
		private roomState: RoomStateStorage,
		private chatService: ChatService,
		private popupService: PopupService,
		private callStorage: CallStorage,
		private translateService: TranslateService,
		private loggingService: LoggingService,
		private authenticationService: AuthenticationService,
		private speakerTimeDetectorService: SpeakerTimeDetectorService,
		private platformDetectorService: PlatformDetectorService
	) {}

	handleJoin(data) {
		/** In some browsers autoplay triggers the sound immediately as soon as the stream is available
		 * (like in pre-call settings after Sirius Now)
		 * In other browsers 'undefined' or false autoplay will give permissions error
		 * Which is why we are making sure this sound is played once someone actually joined the call and setting
		 * autoplay in joinHandler
		 */
		this.audioEnter.autoplay = true;
		// Showing controls for 3 seconds on 'join' (exactly after we closed settings popup and entered the call)
		sessionStorage.isLive = true;
		sessionStorage.reconnectJoin = true;
		this.roomState.isRecording = data.recording;
		if (this.roomState.isTeacher) {
			this.chatService.joinHandler(data);
		}
		//  Safari test for loop with reload: 2020-Nov-12

		this.audioEnter.play().catch((error) => {
			logger.log("audioEnter error ", error);
			// Try again one more time. It sometimes fails in Chrome despite autoplay being set previously
			// And in those cases delayed retry helps
			setTimeout(() => {
				this.audioEnter
					.play()
					.then(() => {
						logger.log("audioEnter retry success ");
					})
					.catch((retryError) => {
						console.error("audioEnter retry error ", retryError);
						/** If we refreshed the page and have not made any user interactions yet, we are going to need the workaround below
						 * to play notifications audio (e. g. like when someone entered the room) on iOS devices or in Safari on macOS
						 * That is because Apple has a policy not to autoplay any audio unless user interracted with the app first
						 * (like when pressing 'play')
						 * Programmatically invoking 'click()' on HTML elements does not work and this gives a lot of headache around the
						 * internet. The good news is, that we are going to need this only on Apple devices, only if the user refreshed
						 * the page inside the call, and there is a message to notify user that his action actually fixes notification problems.
						 */
						window.onclick = () => {
							playDummySound();
							window.onclick = null;
						};

						if (this.platformDetectorService.isSafari()) {
							this.popupService
								.openWarningPopup(
									this.translateService.instant(
										"popUp.allowSounds"
									)
								)
								.then(() => {
									this.audioEnter.play(); // This will give permission error if we don't play the sound initiated by user click before
								});
						}
					});
			}, 500);
		});
	}

	createNewVideo = (rtcId: string, isOldVariant?) => {
		if (!isOldVariant) {
			return null;
		}
		const parent = document.querySelector(".other-users");
		const before = parent.querySelector("div.participant");
		const user = document.createElement("user-call");
		user.id = rtcId;
		user.title = rtcId; // todo rework
		user.classList.add("participant");
		user.addEventListener("click", (event) => {
			this.callStorage.updateMainVideo(rtcId);
		});
		parent.insertBefore(user, before);
		return user.children[0] as HTMLVideoElement;
	};

	handleFinishCall() {
		sessionStorage.isLive = false;
		sessionStorage.reconnectView = false;
		sessionStorage.reconnectJoin = false;
		sessionStorage.reconnectTurn = false;
		sessionStorage.hidePassword = false;
		this.speakerTimeDetectorService.resetSpeaker();
		StorageUtil.writeRoomIdToSessionStorage("");
		EasyRtcService.hangupAll();
	}

	getSocketGoneParams(): { [key: string]: string } {
		return {
			roomId: this.roomState.roomId,
			userName: sessionStorage.getItem(USER_KEY),
			agent: this.loggingService.getAgent(),
			device: this.loggingService.getDevice(),
			camera: this.loggingService.getCamera(),
			mic: this.loggingService.getMicrophone()
		};
	}

	getRedirectPathAfterEndCall(
		isNotSubDomainAndFree,
		isJoinExpires,
		tariff: Tariff,
		roomtype: RoomType,
		subrole: SubRole
	): string[] {
		if (roomtype === RoomType.PERSONAL) {
			sessionStorage.bookingType = "PERSONAL";
		}
		if (roomtype === RoomType.EXTERNAL) {
			sessionStorage.bookingType = "EXTERNAL";
		}
		let redirectTo = [""];
		// if (subrole === SubRole.STUDENT_MATCHED || SubRole.TEACHER_MATCHED) {
		// 	redirectTo = ["platform", "lessons"];
		// } else
		if (roomtype === RoomType.LESSON) {
			QualityRatingStorageUtil.cleanRatingParamsStorage();
			SessionStorageUtil.writeRatingRoomAliasToSessionStorage(
				this.callStorage.roomId
			);
			redirectTo = ["feedback"];
		} else if (
			isNotSubDomainAndFree ||
			roomtype === RoomType.EXTERNAL ||
			roomtype === RoomType.PERSONAL
		) {
			QualityRatingStorageUtil.cleanRatingParamsStorage();
			// Save the room ID for the quality assessment page.
			SessionStorageUtil.writeRatingRoomAliasToSessionStorage(
				this.callStorage.roomId
			);
			SessionStorageUtil.writeRatingIsHiddenHigherPanelToSessionStorage(
				!isJoinExpires
			);

			redirectTo = ["feedback"];
		} else if (!!this.authenticationService.isLoggedIn$.value) {
			redirectTo = ["platform", "lessons"];
			const isGoToPricingPage = tariff === Tariff.FREE;
			if (isJoinExpires && isGoToPricingPage) {
				redirectTo = ["pricing"];
			}
		}
		return redirectTo;
	}

	async setOutputForAudios(deviceId: string) {
		if (!!(this.audioEnter as any).setSinkId) {
			try {
				// await (this.audioInterrupt as any).setSinkId(deviceId);
				await (this.audioEnter as any).setSinkId(deviceId);
				await (this.audioKnock as any).setSinkId(deviceId);
			} catch (err) {
				if (!BotService.isBot) {
					console.error(
						"audioEnter.setSinkId(",
						deviceId,
						");\nError: ",
						err
					);
				}
			}
		}
	}

	getJoinParams(): { [key: string]: string | boolean } {
		return {
			role: this.callStorage.role,
			slug: this.callStorage.roomId,
			name: localStorage.getItem(USER_KEY), // @todo: may be sessionStorage ? nobody set username in localstorage
			reconnectJoin: sessionStorage.getItem("reconnectJoin")
				? sessionStorage.getItem("reconnectJoin")
				: false
		};
	}
}

import { Component, OnInit } from '@angular/core';
import {OrganizationVO} from '../../../data/OrganizationVO';
import {Router} from '@angular/router';
import {OrganizationDataService} from '../../../services/organization-data.service';
import {PopupService} from '../../../../../services/popup/popup.service';
import {AuthenticationService} from '../../../../../services/authentication/authentication.service';

@Component({
  selector: 'app-organization-create',
  templateUrl: './organization-create.component.html',
  styleUrls: ['./organization-create.component.scss']
})
export class OrganizationCreateComponent implements OnInit {
  organization: OrganizationVO = new OrganizationVO();
  constructor(private router: Router,
              private dataService: OrganizationDataService,
              private popupService: PopupService,
              private authenticationService: AuthenticationService) { }

  ngOnInit() {
  }
  onCancel() {
    this.router.navigate(['/admin']);
  }
  createOrg(org: OrganizationVO) {
    this.dataService.add(org)
      .subscribe(newOrg => {
        this.router.navigate(['/admin']);
      }, error => {
        let errMsg = 'Error: Can\'t create organization';
        if (error.status === 400) {
          errMsg = 'Error: Name or Subdomain already exists';
        }
        this.popupService.openSnackBar(errMsg, 3000);
        console.error(error);
        if (error.status === 401 || error.status === 403) {
          this.authenticationService.logOut$().subscribe(res => {
            this.authenticationService.isLoggedIn$.next(false);
            this.router.navigate(['admin/login']);
          });
        }
      });
  }

}

import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-icon-round-letter',
  templateUrl: './letter.svg',
  styleUrls: ['./icon-round-letter.component.scss']
})
export class IconRoundLetterComponent implements OnInit {
  @Input() fillColor: string = '#15de96';
  @Input() textColor: string = '#ffffff';
  @Input() textValue: string = 'U';
  ngOnInit() {
  }

}

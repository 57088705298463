import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { UserActionsComponent } from './user-actions.component';
import { VolumeControlModule } from 'src/app/volume-control/volume-control.module';
import { ClickOutsideDirective } from './click-outside.directive';


@NgModule({
  declarations: [UserActionsComponent, ClickOutsideDirective],
    imports: [
        CommonModule,
        TranslateModule,
        VolumeControlModule,
    ],
  exports: [UserActionsComponent]
})
export class UserActionsModule { }

import { Component,Input,Output,EventEmitter } from '@angular/core';

@Component({
  selector: 'app-piano-octave',
  templateUrl: './piano-octave.component.html',
  styleUrls: ['./piano-octave.component.scss']
})
export class PianoOctaveComponent {
  @Input() octaveIndex = 0;
  @Output() readonly triggeredNote: EventEmitter<string> = new EventEmitter();
  @Output() readonly releasedNote: EventEmitter<string> = new EventEmitter();
  octaveNotes = [ "C", "C#", "D", "D#", "E", "F", "F#", "G", "G#", "A", "A#", "B"];

  getTriggeredNote(keyName){
    this.triggeredNote.emit(keyName);
  }

  getReleasedNote(keyName){
    this.releasedNote.emit(keyName);
  }

  getkeys():Array<string> {
    if (this.octaveIndex == 3){
    return [ "Q","2","W","3","E","R","5","T","6","Z","7","U"];
    }
    else if (this.octaveIndex == 4){
      return [ "Y","S","X","D","C","V","G","B","H","N","J","M"];
      }
    else {
      return [""];
    }
    }
  ngOnDestroy() {
    // console.log("DESTROYED Octave")
  }

}

@if (!isMobile) {
	<mat-tab-group #tabGroup [selectedIndex]="selectedIndex" animationDuration="0ms" mat-stretch-tabs="false" mat-align-tabs="start">
		<mat-tab label="{{ 'lessons-overview.hours-overview' | translate }}">
			<ng-template matTabContent>
				@if (generalOVerview && showLessonOverview) {
					<section class="flex gap-[1.5rem] mt-8">
						<div class="flex-1">
							<h1 class="font-bold text-lg">{{ generalOVerview.duration }} min</h1>
							<p class="font-light text-sm">{{ "lessons-overview.duration" | translate }}</p>
						</div>
						<div class="flex-1">
							<h1 class="font-bold text-lg">{{ generalOVerview.manualTakenLessons }}</h1>
							<p class="font-light text-sm">{{ "lessons-overview.units" | translate }}</p>
						</div>
						<div class="flex-1">
							<h1 class="font-bold text-lg">{{ generalOVerview.manualAvailableLessons }}</h1>
							<p class="font-light text-sm">{{ "lessons-overview.remaining" | translate }}</p>
						</div>
						<div class="flex-1">
							<h1 class="font-bold text-lg">{{ generalOVerview.renewalDate | date: "dd.MM.yyyy" }}</h1>
							<p class="font-light text-sm">{{ "lessons-overview.topup" | translate }}</p>
						</div>
					</section>
				}
				<section class="flex mt-8 justify-between">
					<div>
						<h1 class="font-semibold text-lg">{{ "lessons-overview.completed" | translate }}</h1>
					</div>

					<div>
						@if (isActiveStudent(studentData)) {
							@if (lessonPackageStatus) {
								<button class="blue-ui-button ng-star-inserted" (click)="createRegularSession()">
									{{ "lessons-overview.schedule" | translate }}
								</button>
							}
						} @else {
							<button class="blue-ui-button ng-star-inserted" (click)="resendEmail(studentData)">
								{{ "lessons-overview.email" | translate }}
							</button>
						}
					</div>
				</section>
				@if (sortedData.length > 0) {
					<app-table
						[columns]="tableColumns"
						[dataSource]="sortedData"
						[hasMenu]="true"
						[hasPaginator]="false"
						[totalItems]="totalLessons"
						[actions]="actions"
						(pageChange)="pageChange($event)"
						(sort)="onSort($event)"
					/>
				} @else {
					@if (isActiveStudent(studentData)) {
						<app-empty-list-container message="{{ 'lessons-overview.no-bookings' | translate }}" />
					} @else {
						<app-empty-list-container message="{{ 'lessons-overview.no-workspace' | translate }}" />
					}
				}
			</ng-template>
		</mat-tab>
		<mat-tab label="{{ 'lessons-overview.notes' | translate }}">
			<ng-template matTabContent>
				<app-notes-editor [teacherId]="userId" [studentId]="selectedStudentID" [isStudent]="false"></app-notes-editor>
			</ng-template>
		</mat-tab>
		<mat-tab label="{{ 'lessons-overview.recordings' | translate }}">
			<ng-template matTabContent>
				<div class="recordings-container">
					<app-recordings [studentId]="selectedStudentID" />
				</div>
			</ng-template>
		</mat-tab>
	</mat-tab-group>
} @else {
	@if (selectedOption === 1) {
		@if (generalOVerview && showLessonOverview) {
			<section class="flex flex-col">
				<h2 class="text-3xl font-bold">{{ "lessons-overview.overview" | translate }}</h2>
				<div class="grid grid-cols-2 gap-y-4 gap-x-8 mt-4">
					<div class="flex flex-col">
						<h1 class="font-bold text-2xl">{{ generalOVerview.duration }} min</h1>
						<p class="text-sm font-light">{{ "lessons-overview.duration" | translate }}</p>
					</div>
					<div class="flex flex-col">
						<h1 class="font-bold text-2xl">{{ generalOVerview.manualTakenLessons }}</h1>
						<p class="text-sm font-light">{{ "lessons-overview.units" | translate }}</p>
					</div>
					<div class="flex flex-col">
						<h1 class="font-bold text-2xl">{{ generalOVerview.manualAvailableLessons }}</h1>
						<p class="text-sm font-light">{{ "lessons-overview.remaining" | translate }}</p>
					</div>
					<div class="flex flex-col">
						<h1 class="font-bold text-2xl">{{ generalOVerview.renewalDate | date: "dd.MM.yyyy" }}</h1>
						<p class="text-sm font-light">{{ "lessons-overview.topup" | translate }}</p>
					</div>
				</div>
			</section>
		}
		<section class="flex mt-8 justify-between">
			<div>
				<h1 class="font-semibold text-lg">{{ "lessons-overview.completed" | translate }}</h1>
			</div>
		</section>
		@if (sortedData.length > 0) {
			<app-table
				[columns]="tableColumns"
				[dataSource]="sortedData"
				[hasMenu]="true"
				[hasPaginator]="false"
				[totalItems]="totalLessons"
				[actions]="actions"
				(pageChange)="pageChange($event)"
				(sort)="onSort($event)"
			/>
		} @else {
			<app-empty-list-container message="This student has no classes registered yet." />
		}
	}
	@if (selectedOption === 2) {
		<app-notes-editor [teacherId]="userId" [studentId]="selectedStudentID" [isStudent]="false"></app-notes-editor>
	}
	@if (selectedOption === 3) {
		<app-recordings [studentId]="selectedStudentID" />
	}
}

import {
	Directive,
	ElementRef,
	EventEmitter,
	HostListener,
	Output
} from "@angular/core";

@Directive({
	selector: "[appDragndrop]"
})
export class DragndropDirective {
	@Output() fileDropped = new EventEmitter<File>();
	@Output() fileDragging = new EventEmitter<boolean>();
	@HostListener("dragover", ["$event"])
	onDragOver(event) {
		event.preventDefault();
		event.stopPropagation();
		this.fileDragging.emit(true);
	}
	@HostListener("dragleave", ["$event"])
	onDragLeave(event) {
		event.preventDefault();
		event.stopPropagation();
		this.fileDragging.emit(false);
	}
	@HostListener("drop", ["$event"])
	onDrop(event) {
		event.preventDefault();
		event.stopPropagation();
		this.fileDragging.emit(false);
		const files = event.dataTransfer.files;
		if (files.length > 0) {
			this.fileDropped.emit(files);
		}
	}
}

import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReferralComponent } from "./referral.component";

@NgModule({
	declarations: [ReferralComponent],
	imports: [CommonModule],
	exports: [ReferralComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ReferralModule {}

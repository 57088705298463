import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-icon-user',
  templateUrl: './user-2.svg',
  styleUrls: ['./user-icon.component.scss']
})
export class UserIconComponent {
  fillColor = '#15de96';
  circleVisibility = 'hidden';
  @Input() set color(val: string) {
    if (val && val !== '') {
      this.fillColor = val;
    }
  }
  @Input() set isCircle(val: boolean) {
    this.circleVisibility = val ? 'visible' : 'hidden';
  }
}

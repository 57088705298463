import { Component } from "@angular/core";
import { NOTIFICATION_OPTIONS } from "../profile.constants";
import { ProfileService } from "../profile.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Profile } from "src/app/types/profile.types";
@Component({
	selector: "app-notifications-settings",
	templateUrl: "./notifications-settings.component.html",
	styleUrls: ["./notifications-settings.component.scss"]
})
export class NotificationsSettingsComponent {
	public notificationOptions = NOTIFICATION_OPTIONS;
	public notificationSettingsForm: FormGroup;
	public isSavedMessage: boolean;

	constructor(
		private fb: FormBuilder,
		private profileService: ProfileService
	) {}

	ngOnInit(): void {
		this.initForm();
		this.profileService.get().subscribe((profile: Profile) => {
			this.patchForm(profile);
		});
	}

	private initForm(): void {
		this.notificationSettingsForm = this.fb.group({
			trial: this.createSubForm(),
			single: this.createSubForm()
		});
	}

	private patchForm(profile: Profile) {
		const { notifications } = profile;
		if (notifications) {
			const { trial, single } = notifications.email;
			this.notificationSettingsForm.patchValue({
				trial,
				single
			});
		}
	}

	get isDirty(): boolean {
		return this.notificationSettingsForm.dirty;
	}

	public saveNotificationSettings() {
		const data = {
			notifications: {
				email: this.notificationSettingsForm.value
			}
		};
		this.profileService.save(data).subscribe(
			() => {
				this.notificationSettingsForm.markAsPristine();

				this.isSavedMessage = true;
				setTimeout(() => {
					this.isSavedMessage = false;
				}, 3000);
			},
			(err) => {
				console.log(err);
			}
		);
	}

	private createSubForm(): FormGroup<{}> {
		let controls = {};
		this.notificationOptions.forEach((option) => {
			controls[option] = [false];
		});
		return this.fb.group(controls);
	}

	public getTitle(formGroup, option): string {
		return "notifications-settings." + formGroup + "." + option;
	}
}

import { Component } from "@angular/core";
import { ProfileFormBaseComponent } from "../profile-form-base/profile-form-base.component";
import { FormBuilder } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthenticationService } from "src/app/services/authentication/authentication.service";
import { CountriesService } from "src/app/services/countries/countries.service";
import { ProfileService } from "../profile.service";
import { LanguageService } from "src/app/services/languages/language.service";
import { PopupService } from "src/app/services/popup/popup.service";

// to do: fix notifications so it doesnt call profile again

@Component({
	selector: "app-profile-teacher-matched",
	templateUrl: "./profile-teacher-matched.component.html",
	styleUrl: "./profile-teacher-matched.component.scss"
})
export class ProfileTeacherMatchedComponent extends ProfileFormBaseComponent {
	isShowPayoutMessage: boolean;
	formSection = 0;
	tabs = [
		"account",
		"about",
		"music",
		"description",
		"setup",
		"students",
		"payout",
		"notifications"
	];

	constructor(
		protected fb: FormBuilder,
		protected profileService: ProfileService,
		protected router: Router,
		protected authenticationService: AuthenticationService,
		protected countriesService: CountriesService,
		protected translate: TranslateService,
		protected languageService: LanguageService,
		protected popupService: PopupService,
		private route: ActivatedRoute
	) {
		super(
			fb,
			profileService,
			router,
			authenticationService,
			countriesService,
			translate,
			languageService,
			popupService
		);
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.form.removeControl("terms");
		this.about.removeControl("ageConfirmation");
		this.payoutDetails.disable();
		const tab = this.route.snapshot.paramMap.get("tab");
		if (!tab) {
			this.router.navigate(["platform/profile/account"]);
		}
		this.subscribeToRoute();
		this.initPhotoAutoSave();
	}

	moveToStep(i) {
		this.formSection = i;
	}

	// to do, forkjoin
	saveAboutAndPhoto() {
		this.saveGroup(this.about);
		this.savePhoto();
	}

	private subscribeToRoute(): void {
		this.route.url.subscribe((url) => {
			this.formSection = url[1] ? this.tabs.indexOf(url[1].path) : 0;
		});
	}
}

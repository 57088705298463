import { BehaviorSubject, Observable, throwError } from "rxjs";
import {
	catchError,
	filter,
	finalize,
	map,
	switchMap,
	take
} from "rxjs/operators";
import { of } from "rxjs";
import { Injectable } from "@angular/core";
import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest
} from "@angular/common/http";
import { Router } from "@angular/router";
import { AuthenticationService } from "./authentication/authentication.service";
import { C } from "@fullcalendar/core/internal-common";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	isRefreshingToken = false;
	tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
	constructor(private auth: AuthenticationService, private router: Router) {}

	private needToken(request) {
		return (
			!request.url.endsWith("/profile/authorization") &&
			!request.url.endsWith("/profile/token")
		);
	}

	intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			catchError((err) => {
				if (err instanceof HttpErrorResponse) {
					if (
						err.status === 401 &&
						!request.url.endsWith("/profile/token") &&
						!request.url.endsWith("/profile/authorization")
					) {
						return this.handle401Error(request, next);
					} else if (
						err.status === 500 &&
						request.url.endsWith("/profile/token")
					) {
						this.auth.clearTokens();
						window.location.href = "/login";
					}
				}
				return throwError(err);
			})
		);
	}

	handle401Error(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<any> {
		if (!this.isRefreshingToken) {
			this.isRefreshingToken = true;

			this.tokenSubject.next(null);
			return this.auth.updateToken().pipe(
				catchError((err) => {
					this.auth.clearTokens();
					this.router.navigateByUrl("/login");
					return throwError(err);
				}),
				switchMap((newToken) => {
					if (newToken) {
						this.tokenSubject.next(newToken);
						const reqWithNewToken = this.addToken(
							request,
							newToken
						);
						return next.handle(reqWithNewToken);
					}
					return throwError("No new token received");
				}),
				finalize(() => {
					this.isRefreshingToken = false;
				})
			);
		} else {
			// wait for the new token to be broadcasted
			return this.tokenSubject.asObservable().pipe(
				filter((token) => token != null),
				take(1), // first emitted token
				switchMap((token) => {
					// Retry request
					const reqWithToken = this.addToken(request, token);
					return next.handle(reqWithToken);
				})
			);
		}
	}

	private addToken(request, token?: string) {
		if (token) {
			return request.clone({
				setHeaders: {
					Authorization: token,
					VCP_HEADER_SECRET_KEY: "vcp_api"
				}
			});
		} else {
			return request;
		}
	}
}

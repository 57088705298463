import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {Api} from './api';

@Injectable({
  providedIn: 'root'
})
export class AuthAdminService {
  get token(): string {
    return this.tokenPrivate$.getValue();
  }
  set token(val: string) {
    if (this.tokenPrivate$.getValue() !== val) {
      this.tokenPrivate$.next(val);
    }
  }
  get token$(): Observable<string> {
    return this.tokenPrivate$.asObservable();
  }
  private refreshToken: string;
  private tokenPrivate$: BehaviorSubject<string> = new BehaviorSubject(null);
  constructor(private http: HttpClient) {
    this.loadTokens();
  }
  login(form: { email: string, password: string }): Observable<boolean> {
    return new Observable<boolean>(obs => {
      this.http.post(Api.url('/profile/authorization'),
        {email: form.email, password: form.password},
        {withCredentials: true, headers: {'Content-Type': 'application/json'}})
        .subscribe((data: { accessToken: string, refreshToken: string }) => {
          this.parseTokens(data)
            .subscribe(token => {
              obs.next(!!token);
            });
          obs.complete();
        }, error => {
          obs.error(error);
        });
    });
  }
  updateToken(): Observable<string> {
    return new Observable<string>(obs => {
      if (!this.refreshToken) {
        obs.next(null);
        obs.complete();
      } else {
        this.http.post(Api.url('/profile/token'),
          {token: this.refreshToken},
          {withCredentials: true, headers: {'Content-Type': 'application/json'}})
          .subscribe((data: { accessToken: string, refreshToken: string }) => {
            this.parseTokens(data)
              .subscribe(token => {
                obs.next(token);
                obs.complete();
              });
          }, error => {
            obs.error(error);
          });
      }});
  }
  clearUser() {
    this.token = null;
    this.refreshToken = null;
    localStorage.removeItem('refreshToken');
  }
  private loadTokens() {
    this.refreshToken = localStorage.getItem('refreshToken');
  }
  private parseTokens (response: {accessToken?: string, refreshToken?: string }): Observable<string> {
    return new Observable(obs => {
      if (response.accessToken && response.refreshToken) {
        this.token = response.accessToken;
        this.refreshToken = response.refreshToken;
        this.saveTokens();
        obs.next(this.token);
      } else {
        obs.next(null);
      }
      obs.complete();
    });
  }
  private saveTokens () {
    localStorage.setItem('refreshToken', this.refreshToken);
  }
}


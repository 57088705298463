import { Component, OnInit } from "@angular/core";
import {
	UntypedFormControl,
	UntypedFormGroup,
	Validators
} from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { getSubDomain } from "../helpers";
import { InitialAuthorizationService } from "src/app/lib-core/services/initial-authorization.service";
import { logger } from "src/app/lib-core/logger";
import { AuthenticationService } from "../services/authentication/authentication.service";
import { CustomThemeService } from "../services/custom-theme.service";
import { TEXT_NAME_MAX_LENGTH } from "../constants/generic.constants";
import { LanguageService } from "../services/languages/language.service";
import { Language } from "../models/language";
import { EMAIL_VALIDATION_REG } from "../lib-core/constants/constants";
import { BotService } from "../services/bot/bot.service";
import { environment } from "@env/environment";
import { Profile } from "../types/profile.types";
import { SubRole } from "../constants/subroles-constants";
import { TrimmedFormControl } from "../utils/trimmed-form-control";

@Component({
	selector: "app-login",
	templateUrl: "./login.component.html",
	styleUrls: ["./login.component.scss"]
})
export class LoginComponent implements OnInit {
	public isSchool: boolean;
	loginForm: UntypedFormGroup;
	showPass: boolean = false;
	isAuthError: boolean = false;
	authErrorStatus: number = 0;
	registrationNotFinishedEmail = "";

	agreementInfo: SafeHtml;
	isUserEmailFocused: boolean = false;
	isUserPasswordFocused: boolean = false;
	handleSubDomain = {
		subDomainNotAllowed: false,
		redirectTo: ""
	};
	assetsVersion = environment.assetsVersion;
	isStudentLead: boolean = false;
	rememberUser: boolean = true;

	constructor(
		translate: TranslateService,
		private router: Router,
		private authenticationService: AuthenticationService,
		private initialAuthorizationService: InitialAuthorizationService,
		private languageService: LanguageService,
		private translationService: TranslateService,
		private sanitizer: DomSanitizer,
		public customThemeService: CustomThemeService
	) {
		this.loginForm = new UntypedFormGroup({
			userEmail: new TrimmedFormControl("", [
				Validators.required,
				Validators.pattern(EMAIL_VALIDATION_REG), // Validators.email,
				Validators.maxLength(TEXT_NAME_MAX_LENGTH)
			]),
			userPassword: new UntypedFormControl("", [Validators.required])
			//   rememberUser: new UntypedFormControl(false, [Validators.required])
		});
		this.loginForm.get("userPassword").statusChanges.subscribe((status) => {
			if (status === "VALID") {
				this.isAuthError = false;
				this.handleSubDomain.subDomainNotAllowed = false;
			}
		});
		this.translationService
			.stream("login.agree-to-conditions")
			.subscribe((text: string) => {
				this.agreementInfo =
					this.sanitizer.bypassSecurityTrustHtml(text);
			});
	}

	setUserEmailFocus(value) {
		this.isUserEmailFocused = value;
	}

	setUserPasswordFocus(value) {
		this.isUserPasswordFocused = value;
	}

	ngOnInit() {
		this.isSchool = !!getSubDomain();
	}

	login(): Promise<void> {
		this.isAuthError = false;
		this.registrationNotFinishedEmail = "";
		this.handleSubDomain.subDomainNotAllowed = false;

		if (!this.loginForm.valid) {
			logger.log("login form is invalid ");
		} else {
			const { userEmail, userPassword, rememberUser } =
				this.loginForm.value;
			this.authenticationService.email = userEmail;
			(window as any).profitwell("start", { user_email: userEmail });
			/*this.authenticationService.authorize$(userEmail, userPassword, !!rememberUser)
        .subscribe(async(response) => {
          this.authenticationService.isLoggedIn$.next(true);
          this.authenticationService.resetProfile();
          const profile = await this.authenticationService.profile;
          const language: Language = this.languageService.getLanguageById(profile.language);
          await this.languageService.change(!!language ? language.iso : null);
          return this.router.navigate(['platform/start']);
        }, (error) => {
          if (error.error.description === 'User password not set!') {
            this.registrationNotFinishedEmail = userEmail;
          } else {
            this.isAuthError = true;
          }
        });
      */
			return this.authenticationService
				.userAuthorization(userEmail, userPassword, !!rememberUser)
				.then(async (res) => {
					try {
						const profile: Profile = await this
							.authenticationService.profile;
						if (
							profile?.subrole === SubRole.TEACHER_MATCHED ||
							profile?.subrole === SubRole.STUDENT_MATCHED
						) {
							this.router.navigate(["platform/lessons"]);
						} else if (profile?.subrole === SubRole.STUDENT_LEAD) {
							this.authenticationService
								.logOut$()
								.subscribe(() => {
									this.isStudentLead = true;
								});
						} else {
							this.router.navigate(["platform/start"]);
						}
					} catch (error) {
						this.authErrorStatus = error.status;
						this.isAuthError = true;
					}
				})
				.catch((error) => {
					if (error.error.subDomainNotAllowed) {
						this.handleSubDomain.subDomainNotAllowed =
							error.error.subDomainNotAllowed;
						this.handleSubDomain.redirectTo =
							error.error.redirectTo;
						return;
					}
					if (error.error.description === "User password not set!") {
						this.registrationNotFinishedEmail = userEmail;
					} else {
						this.authErrorStatus = error.status;
						this.isAuthError = true;
					}
				});
		}
	}

	togglePass() {
		this.showPass = !this.showPass;
	}

	resetPassword() {
		this.router.navigate(["/forgot-password"]);
	}

	onClickBack() {
		window.location.href = "https://www.sirius.video/";
	}
}

import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { ProfileService } from "../profile/profile.service";
import { Profile } from "../types/profile.types";

import { catchError, map } from "rxjs/operators";
import { of } from "rxjs";
import { AuthenticationService } from "../services/authentication/authentication.service";

export const startPageGuard: CanActivateFn = (route, state) => {
	const redirect = inject(Router).createUrlTree(["/platform/lessons"]);

	const profile = inject(AuthenticationService).profileInfo();

	if (profile) {
		if (profile.enablePersonalRoom) {
			return true;
		} else {
			return redirect;
		}
	} else {
		return inject(ProfileService)
			.get()
			.pipe(
				map((profile: Profile) => {
					if (profile.enablePersonalRoom) {
						return true;
					} else {
						return redirect;
					}
				}),
				catchError(() => {
					return of(redirect);
				})
			);
	}
};

<div class="bg-white w-full relative">
	<form class="filters-container align-middle flex sm:justify-end flex-wrap">
		<div class="mr-2">{{ "calendar.availabilities" | translate }}:</div>
		<div class="flex">
			<label for="showTrialAvailability" [innerHTML]="'lesson-statistics.trial' | translate"></label>
			<input
				class="mx-1 checkbox"
				id="showTrialAvailability"
				type="checkbox"
				name="trial"
				[checked]="availabilityType === 'trial'"
				(change)="onAvilabilityFilterChange($event)"
			/><label class="ml-1" for="showRegularAvailability">{{ "lesson-statistics.single" | translate }}</label>
			<input
				class="mx-1 checkbox"
				id="showRegularAvailability"
				type="checkbox"
				name="regular"
				[checked]="availabilityType === 'regular'"
				(change)="onAvilabilityFilterChange($event)"
			/>
		</div>
	</form>
	<full-calendar #calendar class="w-full" [options]="calendarOptions" [events]="eventsList">
		<ng-template #eventContent let-arg>
			<!-- <div
				*ngIf="isLesson(arg.event)"
				class="event-card-container"
				(mouseover)="openPopover(arg.event)"
				(mouseleave)="closePopover(arg.event)"
				[id]="arg.event.extendedProps.id"
			> -->
			<div *ngIf="isLesson(arg.event)" class="event-card-container" [id]="arg.event.extendedProps.id">
				<div class="event-card" [class.past]="isDisabled(arg.event)">
					<div class="name">{{ arg.event.title }}</div>
					<div class="time" [class.trial]="isTrialLesson(arg.event)">
						{{ arg.timeText }}
					</div>
				</div>
			</div>

			<div *ngIf="isOverride(arg.event)" class="override-card-container"></div>
		</ng-template>
	</full-calendar>
	<!-- <ng-container *ngFor="let event of lessonsList">
		<div
			class="popover"
			[class.show]="currentOpenPopover === event.extendedProps.id"
			[id]="event.extendedProps.id + '-popover'"
			(mouseover)="openPopover(event)"
			(mouseleave)="closePopover(event)"
		>
			<menu>
				<li
					(click)="onclickReschedule(event)"
					*ngIf="!isTrialLesson(event)"
				>
					{{ "calendar.reschedule" | translate }}
				</li>

				<li (click)="onClickStartLesson(event)">
					{{ "calendar.start" | translate }}
				</li>
			</menu>
		</div>
	</ng-container> -->
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { TunerComponent } from './tuner.component';
import {MatIconModule} from '@angular/material/icon';

@NgModule({
  declarations: [TunerComponent],
  imports: [
    CommonModule,
    TranslateModule,
    MatIconModule
  ],
  exports: [TunerComponent]
})
export class TunerModule { }

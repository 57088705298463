<mat-sidenav-container id="call-notes">
    <mat-sidenav #notesUI [opened]="displayNotes" [hidden]="!displayNotes" position="end" class="notes-desktop">
        <div id="notesUI">
            <h2 class="chat-title">
                {{ 'menu.notes' | translate }}
            </h2>
            <button class="close-chat" (click)="toggleNotes()"><mat-icon svgIcon="close"></mat-icon></button>
            <app-notes-editor class="text-sirius-black" [isMiniEditor]="true" [studentId]="studentId" [teacherId]="teacherId" [isStudent]="isStudent">
            </app-notes-editor>
        </div>
    </mat-sidenav>
</mat-sidenav-container>
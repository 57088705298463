import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { PlayerStateService } from '../../services/youtube/player-state.service';
import { VolumeControlService } from '../../volume-control/volume-control.service';

@Component({
  selector: 'video-player',
  template: `
    <youtube-player
      [videoId]="videoId"
      (ready)="onReady($event)"
      (stateChange)="onStateChange($event)"
      [playerVars]="playerConfig"
    >
    </youtube-player>
  `,
  styleUrls: ['./video-player.component.css']
})
export class VideoPlayerComponent implements OnInit {
  private apiLoaded = false;
  playerConfig = {
    controls: localStorage.getItem('hostPlayer') ? 1 : 0,
    autoplay: 1,
  };

  @Input() videoId: string;
  @Input() activePlayer: boolean;
  @Input() private user: any;
  @Output() readonly onPause: EventEmitter<any> = new EventEmitter();
  @Output() readonly onPlaying: EventEmitter<any> = new EventEmitter();

  playerEvent;
  isDocumentVisible = true;

  constructor(private playerService: PlayerStateService, private volumeControlService: VolumeControlService) { }
  @HostListener('document:visibilitychange', ['$event'])
  visibilitychange() {
    this.isDocumentVisible = !document.hidden;
  }


  ngOnInit(): void {
    if(!this.apiLoaded) {
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      document.body.appendChild(tag);
      this.apiLoaded = true;
    }
    this.playerService.playerState.subscribe(state => {
      if (this.isDocumentVisible) {
        if (state === 'paused') {
            const playerState = this.playerEvent?.target.playerInfo.playerState;
            if (playerState === 2 || playerState === 1 || playerState === -1) {
              this.onPause.emit(this.playerEvent?.target);
              this.playerEvent?.target?.pauseVideo();
            }
        }
        if (state === 'playing') {
            const playerState = this.playerEvent?.target.playerInfo.playerState;
            if (playerState === 2 || playerState === 1 || playerState === -1) {
              this.onPlaying.emit(this.playerEvent?.target);
              this.playerEvent?.target?.playVideo();
            }
        }
      }
    });
    this.playerService.volume$.subscribe((data: any) => {
      if (this.playerEvent?.target) {
        if (data.id === this.playerEvent?.target?.g?.id) {
          const vol = data.volume * 100;
          this.playerEvent.target.setVolume(vol);
        }
      }
    });
  }

  onReady(event): void {
    if (event) {
      this.playerEvent = event;
      this.playerService.playerEvent = this.playerEvent.target;
    }
    this.playerService.youtubeActive = true;
    if (!this.activePlayer) {
      event.target.setVolume(0);
      this.playerService.volume = { volume: 0, id: event.target?.g?.id };
    } else {
      const volume = this.volumeControlService.getVolume(this.user) * 100;
      event.target.setVolume(volume);
      this.playerService.volume = { volume, id: event.target?.g?.id };
    }
  }

  onStateChange(event) {
    switch (event.data) {
      case -1:
        // console.log('unstarted')
        break;
      case 0:
        // console.log('ended')
        break;
      case 1:
        // console.log('playing')
        if (this.isDocumentVisible) {
          this.playerService.playerState.next('playing');
        }
        break;
      case 2:
        // console.log('paused')
        if (this.isDocumentVisible) {
          this.playerService.playerState.next('paused');
        }
        break;
      case 3:
        // console.log('buffering')
        break;
      case 5:
        // console.log('video cued')
        break;
    }
  }

}

import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {PopupService} from '../../../../../services/popup/popup.service';
import {UserEntryVO} from '../../../data/UserEntryVO';
import {UsersDataService} from '../../../services/users-data.service';
import {AuthenticationService} from '../../../../../services/authentication/authentication.service';

@Component({
  selector: 'app-user-create',
  templateUrl: './user-create.component.html',
  styleUrls: ['./user-create.component.scss']
})
export class UserCreateComponent implements OnInit {
  user: UserEntryVO = new UserEntryVO();
  constructor(private router: Router,
              private dataService: UsersDataService,
              private popupService: PopupService,
              private activatedRoute: ActivatedRoute,
              private authenticationService: AuthenticationService) { }

  ngOnInit() {
  }
  onCancel() {
    this.router.navigate(['admin/users-list'], {queryParams: {organization: this.dataService.organizationID}});
  }
  createUser(user: UserEntryVO) {
    this.activatedRoute.queryParams
      .subscribe(queryParams => {
        if (queryParams.organization) {
          this.dataService.organizationID = queryParams.organization;
        }
        this.dataService.add(user, this.dataService.organizationID)
          .subscribe(newUser => {
            this.router.navigate(['admin/users-list'], {queryParams: {organization: this.dataService.organizationID}});
          }, error => {
            this.popupService.openSnackBar('Error Can\'t create user', 3000);
            console.error(error);
            if (error.status === 401 || error.status === 403) {
              this.authenticationService.logOut$().subscribe(res => {
                this.authenticationService.isLoggedIn$.next(false);
                this.router.navigate(['admin/login']);
              });
            }
          });
      });
  }

}

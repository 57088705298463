import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { MusicModalComponent } from "./modal/music-modal.component";
import { LanguageSelectorModule } from "../language-selector/language-selector.module";
import { MatSelectModule } from "@angular/material/select";

@NgModule({
	declarations: [MusicModalComponent],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		TranslateModule,
		LanguageSelectorModule,
        MatSelectModule
	],
	exports: [MusicModalComponent],
})
export class MusicDetailsSelectorModule {}

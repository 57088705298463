import {Observable} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {Api} from './api';
import {Injectable} from '@angular/core';
import {UserEntryVO} from '../data/UserEntryVO';
import {UserEntryParser} from '../data/user-entry-parser';
import {AuthenticationService} from '../../../services/authentication/authentication.service';
@Injectable()
export class UsersDataService {
  organizationID: string;

  constructor(private http: HttpClient,
              private authenticationService: AuthenticationService) {}
  get(organizationID?: string, page?: number): Observable<{page: number, length: number, items: UserEntryVO[]}> { // add type    
    return new Observable<any>(obs => {
      let url = '/users';
      if (organizationID) {
        url += '?organization=' + organizationID;
        if (page) {
          url += '&page=' + page;
        }
      } else if (page) {
        url += '?page=' + page;
      } else {
        url += '?page=1';
      }
      this.http.get(Api.url(url), {headers: this.authenticationService.headers, withCredentials: true})
        .subscribe((data: {page: number, pages: number, list: object[]}) => {
          const users = UserEntryParser.parse(data.list);
          obs.next({items: users, page: data.page, length: data.pages });
          obs.complete();
          }, error => {
          obs.error(error);
        });
    });
  }

  update(user: UserEntryVO, organizationID: string): Observable<UserEntryVO> {
    const url = organizationID ? `/users/${user.id}?organization=${organizationID}` : `/users/${user.id}`;
    const dataForUpdate = UserEntryParser.toServerUpdate(user);
    return new Observable<UserEntryVO>(obs => {
      this.http.put(Api.url(url), dataForUpdate, {headers: this.authenticationService.headers, withCredentials: true})
        .subscribe(data => {
          const updatedOrg = UserEntryParser.parse([data])[0];
          if (updatedOrg) {
            obs.next(updatedOrg);
            obs.complete();
          } else {
            obs.error(new Error('Can\'t update organization'));
          }
        }, error => {
          obs.error(error);
        });
    });
  }
  add (user: UserEntryVO, organizationID: string): Observable<UserEntryVO> {
    const dataForUpdate = UserEntryParser.toServerUpdate(user);
    const url = organizationID ? '/users?organization=' + organizationID : '/users';
    return new Observable<UserEntryVO>(obs => {
      this.http.post(Api.url(url), dataForUpdate,
        {headers: this.authenticationService.headers, withCredentials: true})
        .subscribe(res => {
          const newUser = UserEntryParser.parse([res])[0];
          if (newUser) {
            obs.next(newUser);
            obs.complete();
          } else {
            obs.error(new Error('Can\'t add user'));
          }
        }, error => {
          obs.error(error);
        });
    });
  }
  delete(email: string): Observable<UserEntryVO> {
    const url = `/users/${email}`;
    return new Observable<UserEntryVO>(obs => {
      this.http.delete(Api.url(url), {headers: this.authenticationService.headers, withCredentials: true})
        .subscribe(res => {
          const deletedUser = UserEntryParser.parse([res])[0];
          obs.next(deletedUser);
          obs.complete();
        }, error => {
          obs.error(error);
        });
    });
  }

  upgradeAccount(data: {email: string, subscription: string}): Observable<any> {
    const url = '/teachers/subscription';
    return new Observable<any>(obs => {
      this.http.post(Api.url(url), data, {headers: this.authenticationService.headers, withCredentials: true})
      .subscribe(res => {       
        if (res) {
          obs.next(res);
          obs.complete();
        } else {
          obs.error(new Error("Can't upgrade subscription"))
        }
      }, error => {
        obs.error(error)
      })
    })
  }
}

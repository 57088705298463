import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface NotifyPanel {
  roomFull: boolean;
  name: string;
}

@Component({
  selector: 'app-notification-panel',
  templateUrl: './notification-panel.component.html',
  styleUrls: ['./notification-panel.component.scss']
})
export class NotificationPanelComponent implements OnInit {
  @Input() notJoinedList: NotifyPanel[] = [];
  @Output() updateNotJoinedList = new EventEmitter<NotifyPanel[]>();

  constructor(
  ) { }

  ngOnInit(): void {
  }
  public removeItem(index: number) {
    this.notJoinedList.splice(index, 1);
    this.updateNotJoinedList.emit(this.notJoinedList);
  }
}

<div class="pricing-container">
	<app-platform-menu class="max-md:hidden is-tablet"></app-platform-menu>

	<div class="md:ml-[240px] min-[1560px]:ml-0 flex justify-center items-center flex-shrink flex-grow-0 is-tablet-cont">
		<div class="mx-2 md:mx-[40px] flex flex-col lg:grid auto-cols-fr auto-rows-auto gap-x-2 max-w-[1000px]">
			<!-- <div class="col-span-3">
					<div class="mb-10 text-5xl font-bold text-center">{{ "pricing.title" | translate }}</div>
				</div> -->

			<!-- tariff switch -->
			<div class="flex items-center justify-center col-span-3 mb-3 text-s xl:text-xs text-sirius-violet">
				{{ "pricing.button-pay-yearly" | translate }}
				<button (click)="toggleTariffPlan()" class="w-12 h-5 mx-3 rounded-full bg-sirius-violet">
					<div
						class="w-5 h-5 transition-all bg-white border-2 rounded-full border-sirius-violet"
						[ngClass]="isTariffPlanMonthly(tariffPlan) ? 'translate-x-8' : ''"
					></div>
				</button>
				{{ "pricing.button-pay-monthly" | translate }}
			</div>

			<!-- 'most popular' label -->
			<div class="col-span-1 col-start-2 py-1.5 mt-2 text-xs font-medium text-center text-white max-lg:hidden bg-sirius-violet">
				{{ "pricing.plan-pro.most-popular-label" | translate }}
			</div>

			<!-- tariffs -->
			<div class="flex flex-col h-full col-start-1 p-8 mb-4 text-xl bg-white lg:text-sm lg:p-5">
				<div class="text-2xl uppercase font-bold lg:text-lg !leading-none">{{ "pricing.plan-free.name" | translate }}</div>
				<div>{{ "pricing.plan-free.subheader-1" | translate }}</div>
				<div class="flex my-6 text-5xl font-bold xs:text-7xl lg:text-4xl">
					{{ "pricing.plan-free.price" | translate }}
				</div>
				<div class="mb-1 font-bold">{{ "pricing.plan-free.subheader-2" | translate }}</div>
				<ul class="font-normal list-none">
					<li class="flex my-2.5">
						<img class="mr-2" src="../../assets/icons/pricing/check.svg" />
						<span [innerHTML]="'pricing.plan-free.option-1' | translate"></span>
					</li>
					<li class="flex my-2.5">
						<img class="mr-2" src="../../assets/icons/pricing/check.svg" />
						<span [innerHTML]="'pricing.plan-free.option-2' | translate"></span>
					</li>
					<li class="flex my-2.5">
						<img class="mr-2" src="../../assets/icons/pricing/check.svg" />
						<span [innerHTML]="'pricing.plan-free.option-3' | translate"></span>
					</li>
				</ul>
				<div class="flex flex-col justify-end mt-3 align-middle grow">
					<button
						*ngIf="!isTariffFree(tariff)"
						class="default-ui-button lg:text-xs bg-white text-xl !rounded-full !border-2 !border-sirius-lilac text-sirius-lilac uppercase"
						[routerLink]="['/signup']"
						[disabled]="isTariffPro(tariff) || isTariffPlanDaily(tariffPlan)"
					>
						{{ "pricing.plan-free.button" | translate }}
					</button>
					<button
						*ngIf="isTariffFree(tariff)"
						class="default-ui-button lg:text-xs bg-btn-grey-disabled !border-2 !text-black !border-black !opacity-100 uppercase!rounded-full"
						disabled
					>
						{{ "pricing.button-current-plan" | translate }}
					</button>
				</div>
			</div>

			<div class="py-1 text-lg font-medium text-center text-white bg-sirius-violet lg:hidden">
				{{ "pricing.plan-pro.most-popular-label" | translate }}
			</div>
			<div>
				<div class="flex flex-col h-full p-8 mb-4 text-xl bg-white lg:text-sm lg:p-5">
					<div class="text-2xl uppercase font-bold !leading-none lg:text-lg">{{ "pricing.plan-pro.name" | translate }}</div>
					<div>{{ "pricing.plan-pro.subheader-1" | translate }}</div>
					<div class="flex my-6 text-5xl font-bold xs:text-7xl lg:text-4xl">
						<span class="float-left">
							{{
								(isTariffPlanYearly(tariffPlan) ? "pricing.plan-pro.price-yearly" : "pricing.plan-pro.price-monthly")
									| translate
							}} </span
						>&euro;
						<div
							class="text-xl lg:text-xs font-normal ml-2 !leading-tight"
							[innerHTML]="'pricing.plan-pro.price-label' | translate"
						></div>
					</div>
					<div class="mb-1 font-bold">{{ "pricing.plan-pro.subheader-2" | translate }}</div>
					<ul class="font-normal list-none">
						<li class="flex my-2.5">
							<img class="mr-2" src="../../assets/icons/pricing/check.svg" />
							<span [innerHTML]="'pricing.plan-pro.option-1' | translate"></span>
						</li>
						<li class="flex my-2.5">
							<img class="mr-2" src="../../assets/icons/pricing/check.svg" />
							<span [innerHTML]="'pricing.plan-pro.option-2' | translate"></span>
						</li>
						<li class="flex my-2.5">
							<img class="mr-2" src="../../assets/icons/pricing/check.svg" />
							<span [innerHTML]="'pricing.plan-pro.option-3' | translate"></span>
						</li>
						<li class="flex my-2.5">
							<img class="mr-2" src="../../assets/icons/pricing/check.svg" />
							<span [innerHTML]="'pricing.plan-pro.option-4' | translate"></span>
						</li>
					</ul>
					<div class="flex flex-col justify-end mt-3 align-middle grow">
						<button
							class="default-ui-button lg:text-xs !rounded-full !bg-sirius-lilac py-1 text-white uppercase"
							(click)="setTariffPro()"
							[disabled]="isTariffPro(tariff) || isTariffPlanDaily(tariffPlan) || isTariffInstitutions(tariff)"
						>
							{{
								(isTariffPro(tariff) || isTariffPlanDaily(tariffPlan)
									? "pricing.button-current-plan"
									: "pricing.plan-pro.button"
								) | translate
							}}
						</button>
					</div>
				</div>
			</div>

			<div
				class="flex flex-col h-full p-8 mb-4 text-xl bg-white lg:text-sm lg:p-5"
				*ngIf="'pricing.plan-institutions.href' | translate as hrefMailTo"
			>
				<div class="text-2xl uppercase font-bold !leading-none lg:text-lg">{{ "pricing.plan-institutions.name" | translate }}</div>
				<div>{{ "pricing.plan-institutions.subheader-1" | translate }}</div>
				<div class="flex my-6 text-5xl font-bold xs:text-7xl lg:text-4xl">
					{{ "pricing.plan-institutions.price" | translate }}
				</div>
				<div class="mb-1 font-bold">{{ "pricing.plan-institutions.subheader-2" | translate }}</div>
				<ul class="font-normal list-none">
					<li class="flex my-2.5">
						<img class="mr-2" src="../../assets/icons/pricing/check.svg" />
						<span [innerHTML]="'pricing.plan-institutions.option-1' | translate"></span>
					</li>
					<li class="flex my-2.5">
						<img class="mr-2" src="../../assets/icons/pricing/check.svg" />
						<span [innerHTML]="'pricing.plan-institutions.option-2' | translate"></span>
					</li>
					<li class="flex my-2.5">
						<img class="mr-2" src="../../assets/icons/pricing/check.svg" />
						<span [innerHTML]="'pricing.plan-institutions.option-3' | translate"></span>
					</li>
					<li class="flex my-2.5">
						<img class="mr-2" src="../../assets/icons/pricing/check.svg" />
						<span [innerHTML]="'pricing.plan-institutions.option-4' | translate"></span>
					</li>
				</ul>
				<div class="flex flex-col justify-end align-middle grow">
					<button
						class="default-ui-button lg:text-xs !bg-black !rounded-full text-white uppercase"
						(click)="setTariffInstitutions(hrefMailTo)"
					>
						{{ "pricing.plan-institutions.button" | translate }}
					</button>
				</div>
			</div>
		</div>
	</div>
</div>

import { Injectable } from "@angular/core";
import Intercom, {
	show,
	hide,
	shutdown,
	update
} from "@intercom/messenger-js-sdk";
import { AuthenticationService } from "../authentication/authentication.service";

@Injectable({
	providedIn: "root"
})
export class IntercomService {
	private APP_ID: string = "ptf0nhn5";
	constructor(private auth: AuthenticationService) {}

	async initialezeLoggedInUser() {
		const user = await this.auth.profile;
		if (user) {
			const intercomUser = {
				app_id: this.APP_ID,
				user_id: user.userId,
				name: `${user.firstName} ${user.lastName}`,
				email: user.email,
				phone: user.phoneNumber,
				avatar: {
					type: "avatar",
					image_url: user.photo
				},
				//Custom Properties
				sub_role: this.subRoleHandler(user.subrole),
				timezone: user.timezone,
				registration_link: user.registrationLink,
				// Teacher with ID = 3
				...(user.subrole === 3 && {
					teacher_status: user.teacherStatus,
					enable_personal_room: user.enablePersonalRoom,
					show_lesson_sheet: user.showLessonSheet,
					show_lesson_overview: user.showLessonOverview,
					instruments: user.instruments
						?.map((item) => item.instrument)
						.join(", ")
				}),
				// Students with ID = 6
				...(user.subrole === 6 && {
					checkout_url: user.checkoutUrl,
					instruments: user.instruments?.map(String).join(", "),
					music_genres: user.musicGenres?.map(String).join(", ")
				})
			};
			hide();
			return Intercom(intercomUser);
		}
	}

	async initialezeLoggedOutUser() {
		shutdown();
		Intercom({
			app_id: this.APP_ID
		});
	}

	hideIntercom(show: boolean) {
		return update({
			hide_default_launcher: show
		});
	}

	shutdownIntercom() {
		return shutdown();
	}

	subRoleHandler(subRole: number): string {
		const subRoles = {
			2: "Self-service Teacher",
			3: "SIRIUS Teacher",
			5: "Trial Student",
			6: "Paying Student",
			7: "External Student"
		};
		return subRoles[subRole];
	}
}

import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication/authentication.service';
import {LanguagesList} from '../models/language';
import {LanguageService} from '../services/languages/language.service';
import {ProfileService} from '../profile/profile.service';
import { logger } from 'src/app/lib-core/logger';
import {TEXT_NAME_MAX_LENGTH} from '../constants/generic.constants';
import { EMAIL_VALIDATION_REG } from '../lib-core/constants/constants';
import { getSubDomain } from '../helpers';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  public isSchool: boolean;
  forgotPassForm: UntypedFormGroup;
  isMailError: boolean;

  constructor(private router: Router,
              private authenticationService: AuthenticationService,
              private languageService: LanguageService,
              private profileService: ProfileService) {
    this.forgotPassForm = new UntypedFormGroup({
      userEmail: new UntypedFormControl('', [
        Validators.required,
        Validators.pattern(EMAIL_VALIDATION_REG), // Validators.email
        Validators.maxLength(TEXT_NAME_MAX_LENGTH)
      ])
    });
  }

  ngOnInit() {
    this.isSchool = !!getSubDomain();
  }

  recover(): void {
    if (!this.forgotPassForm.valid) {
      logger.log('forgot pass form is invalid ');
    } else {
      logger.log('forgot pass form is valid ');
      this.authenticationService.email = this.forgotPassForm.value.userEmail;
      localStorage.email = this.authenticationService.email;

      logger.log('forgot form is valid ');
      this.authenticationService.email = this.forgotPassForm.value.userEmail;
      this.profileService.getLanguages().subscribe((languages: LanguagesList) => {
        this.languageService.languages = languages.list;
        const selectedLanguage = this.languageService.languages.find(lang => lang.iso === localStorage.language);
        if (selectedLanguage) {
          this.languageService.selectedLangId = selectedLanguage._id;
          this.languageService.selectedLocale = selectedLanguage.iso;
        } else {
          this.languageService.selectedLangId = this.languageService.languages[0]._id;
          this.languageService.selectedLocale = this.languageService.languages[0].iso;
        }
        this.authenticationService.recoverEmail$().subscribe((response) => {
          if (response._event === 'error') {
            logger.log('Auth error: ', response);
            this.isMailError = true;
          } else {
            this.isMailError = false;
            localStorage.email = this.authenticationService.email;
            this.router.navigate(['recovery']);
          }
        }, (error) => {
          console.error('Mail error: ', error);
          this.isMailError = true;
        });
      });
    }
  }

}

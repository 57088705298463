
export enum Tariff {
  // TRIAL = 'trial',
  FREE = 'free',
  PRO_YEARLY = 'year',
  PRO_MONTHLY = 'month',
  INSTITUTIONS = 'unlimited',
  DAY = 'day'
}

export class TariffUtil {

  public static create(value: string): Tariff {
    let result: Tariff = Tariff.FREE;
    switch (value) {
      case Tariff.FREE.valueOf(): result = Tariff.FREE; break;
      case Tariff.PRO_YEARLY.valueOf(): result = Tariff.PRO_YEARLY; break;
      case Tariff.PRO_MONTHLY.valueOf(): result = Tariff.PRO_MONTHLY; break;
      case Tariff.INSTITUTIONS.valueOf(): result = Tariff.INSTITUTIONS; break;
      case Tariff.DAY.valueOf(): result = Tariff.DAY; break;
    }
    return result;
  }

  public static isTariffPro(tariff: Tariff): boolean {
    return [Tariff.PRO_YEARLY, Tariff.PRO_MONTHLY].includes(tariff);
  }

  public static getLabel(tariff: Tariff): string {
    let result;
    switch (tariff) {
      // case Tariff.TRIAL:
      //   result = 'pricing.plan-trial.name-label';
      //   break;
      case Tariff.FREE:
        result = 'pricing.plan-free.name-label';
        break;
      case Tariff.PRO_MONTHLY:
        result = 'pricing.plan-pro.monthly-name-label';
        break;
      case Tariff.PRO_YEARLY:
        result = 'pricing.plan-pro.yearly-name-label';
        break;
      case Tariff.INSTITUTIONS:
        result = 'pricing.plan-institutions.name';
        break;
    }
    return result;
  }

}

import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import { DISALLOW_TRAILING_SPACES } from '../../../../../constants/generic.constants';
import {UserEntryVO} from '../../../data/UserEntryVO';
import {Timezone} from '../../../../../models/timezone';
import {ProfileService} from '../../../../../profile/profile.service';
import {Language, LanguagesList} from '../../../../../models/language';
import {LanguageService} from '../../../../../services/languages/language.service';

@Component({
  selector: 'app-user-edit-form',
  templateUrl: './user-edit-form.component.html',
  styleUrls: ['./user-edit-form.component.scss']
})
export class UserEditFormComponent implements OnInit {

  @Input() isEditForm: boolean = false;
  @Input() userEntry: UserEntryVO;
  @ViewChild('userForm') userForm: NgForm;
  @Output() closing = new EventEmitter<boolean>();
  @Output() submitForm = new EventEmitter<UserEntryVO>();
  logo: string;
  headerLogo: string;
  backgroundImage: string;
  timezones: Timezone[];
  languages: Language[];
  disallowTrailingSpaces = DISALLOW_TRAILING_SPACES;

  constructor(private profileService: ProfileService,
              private languageService: LanguageService) { }

  ngOnInit(): void {
    setTimeout(this.init.bind(this));
  }
  init() {
    const formValue: any = {};
    formValue.email = this.userEntry.email;
    formValue.firstName = this.userEntry.firstName;
    formValue.language = this.userEntry.language;
    formValue.lastName = this.userEntry.lastName;
    this.userForm.form.patchValue(formValue);
    this.profileService.getTimezones().subscribe((timezones: Timezone[]) => {
      this.timezones = timezones;

      if (this.userEntry.timeZone) {
        // Set user time zone
        const userTimezone = timezones.find(
          (timezone: Timezone) => timezone.value.includes(this.userEntry.timeZone) ||
            timezone.text.includes(this.userEntry.timeZone));
        if (userTimezone) {
          this.userForm.form.patchValue({ timeZone: userTimezone.value });
        }
      } else {
        // Set default time zone
        const currentUtc = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const currentTimezone = timezones.find((timezone: Timezone) => timezone.utc.includes(currentUtc));
        if (currentTimezone) {
          this.userForm.form.patchValue({ timeZone: currentTimezone.value });
        }
      }
    });
    this.profileService.getLanguages().subscribe((languages: LanguagesList) => {
      this.languageService.languages = this.languages = languages.list;
      const currentLanguage: any = languages.list.find((language: Language) => language._id === this.userEntry.language);
      if (currentLanguage) {
        this.userForm.form.patchValue({ language: currentLanguage._id });
      }
    }, (error) => {
      console.error('Error getting languages: ', error);
    });
  }
  onSubmit() {
    this.submitForm.emit(this.createAnswer());
  }
  onClose() {
    this.closing.emit(false);
  }
  private createAnswer(): UserEntryVO {
    const userForUpdate = Object.assign(this.userEntry, this.userForm.form.value);
    if (this.logo) {
      userForUpdate.logo  = this.logo;
    }
    if (this.backgroundImage) {
      userForUpdate.backgroundImage = this.backgroundImage;
    }
    if (this.headerLogo) {
      userForUpdate.headerLogo = this.headerLogo;
    }
    return userForUpdate;
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../services/authentication/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { logger } from 'src/app/lib-core/logger';

const PASS_VALIDATION_PATTERN = /(?=.*[\d\W_])/;

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  showPass: boolean = false;
  showConfirmPass: boolean = false;
  resetPassForm: UntypedFormGroup;
  isAuthError: boolean = false;
  isCodeError: boolean = false;
  isAlreadyUsed: boolean = false;
  // Validators feedback values
  hasEnoughPassLength: boolean;
  hasNumberOrSpecial: boolean;
  hasBothCases: boolean;
  isNotEmpty: boolean;

  constructor(private authenticationService: AuthenticationService,
              private router: Router,
              private route: ActivatedRoute) {
    this.resetPassForm = new UntypedFormGroup({
      userPassword: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern(PASS_VALIDATION_PATTERN),
      ]),
      confirmPassword: new UntypedFormControl('', [Validators.required]),
      // SIRIUS-634 Hide security code in sign up + password reset process
      // securityCode: new FormControl('', [
      //   Validators.required,
      //   Validators.minLength(CODE_MAX_LENGTH),
      //   Validators.maxLength(CODE_MAX_LENGTH)
      // ])
    });
  }

  ngOnInit() {
    const key = this.route.snapshot.paramMap.get('key');
    if (!key || key === '') {
      logger.log('key ', key);
    }
    this.authenticationService.checkRecovery$(key).subscribe((response) => {
        this.isAlreadyUsed = !response.status;
      },
      (error) => {
        console.error('recovery check error ', error);
      });
  }

  resetPassword(): void {
    if (!this.resetPassForm.valid) {
      logger.log('reset form is invalid ');
    } else {
      logger.log('reset form is valid ');
      // ToDo: Rework those ASAP
      this.authenticationService.firstName = this.resetPassForm.value.firstName;
      this.authenticationService.lastName = this.resetPassForm.value.lastName;
      // SIRIUS-754 Modify Sign up detail page
      // this.authenticationService.timeZone = 2; // ToDo: Rework this properly
      const data = {
        password: this.resetPassForm.value.userPassword,
        // SIRIUS-634 Hide security code in sign up + password reset process
        // key: parseInt(this.resetPassForm.value.securityCode, 10)
      };
      this.authenticationService.resetPass$(this.route.snapshot.paramMap.get('key'), data).subscribe((response) => {
        if (response._event === 'error') {
          logger.log('Auth error: ', response);
          this.isAuthError = true;
        } else {
          this.router.navigate(['login']);
        }
      }, (error) => {
        console.error('Auth error: ', error);
        if (error.status === 404) {
          this.isCodeError = true;
          this.isAuthError = false;
        } else if (error.status === 400) {
          this.isCodeError = false;
          this.isAuthError = true;
        }
      });
    }
  }

  onPasswordFocus(): void {
    // To reset it after the user starts entering the password once more
    this.isAuthError = false;
  }

  onCodeFocus(): void {
    // To reset it after the user starts entering the code once more
    this.isCodeError = false;
  }

  togglePass() {
    this.showPass = !this.showPass;
  }

  toggleConfirmPass() {
    this.showConfirmPass = !this.showConfirmPass;
  }

  formUpdate(): void {
    const hasErrors = this.resetPassForm.controls.userPassword.errors;
    this.isNotEmpty = !hasErrors || hasErrors && !this.resetPassForm.controls.userPassword.errors.required;
    this.hasEnoughPassLength = !hasErrors || hasErrors && this.resetPassForm.controls.userPassword.errors
      && !this.resetPassForm.controls.userPassword.errors.minlength;
    this.hasNumberOrSpecial = !hasErrors || hasErrors && this.resetPassForm.controls.userPassword.errors
      && !this.resetPassForm.controls.userPassword.errors.pattern;
    this.hasBothCases = !hasErrors || hasErrors && this.resetPassForm.controls.userPassword.errors
      && !this.resetPassForm.controls.userPassword.errors.passwordHasBothCases;
  }

  passwordHasBothCases = (control: AbstractControl) => {
    if (!control) {
      return null;
    }
    return control && control.value &&
      control.value.toLowerCase() !== control.value && control.value.toUpperCase() !== control.value ? null
        : { passwordHasBothCases: true };
  }
}

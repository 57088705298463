import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-mail-sent',
  templateUrl: './mail-sent.component.html',
  styleUrls: ['./mail-sent.component.scss']
})
export class MailSentComponent implements OnInit {

  email: string;

  constructor(private router: Router) { }

  ngOnInit() {
    if (!localStorage.email || localStorage.email === '') {
      this.router.navigate(['signup']);
    } else {
      this.email = localStorage.email;
    }
  }

}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {ConnectionInfoModel} from '../../models/connection-info';

@Injectable({
  providedIn: 'root'
})

export class ViewMediaService {  
  // public techData: ConnectionInfoModel | any = {};
  public prevMediaStream$: BehaviorSubject<MediaStream | null> = new BehaviorSubject<MediaStream | null>(null);
  // techData$ = new BehaviorSubject<ConnectionInfoModel>(this.techData);
  // currentData = this.techData$.asObservable();
}

import { Component, Input } from "@angular/core";

@Component({
  selector: "app-emoji-icon",
  templateUrl: "./emoji-icon.component.html",
  styleUrls: ["./emoji-icon.component.scss"],
})
export class EmojiIconComponent {
  @Input() emoji = "";
}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CookiesService {

  constructor() { }

  set(name: string, value: string, expireTime?: number): void {
    let expireDate = '';

    if (expireTime) {
      const date = new Date();
      date.setTime(date.getTime() + expireTime);
      expireDate = '; expires=' + date.toUTCString();
    }

    document.cookie = `${encodeURIComponent(name)} = ${encodeURIComponent(value)} + ${expireDate}; path=/`;
  }

  get(name: string): string {
    const value = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
    return value ? value[2] : null;
  }

  delete(name: string): void {
    this.set(name, '', -1);
  }
}

<div class="form-container">
    <h1>Upgrade account</h1>
    <form #upgradeForm='ngForm'>
        <div>
            <span>Email address</span>
            <input type="email" name="email" ngModel required>
        </div>
        <div>
            <span>Subscription Plan</span>
            <select name="subscription" ngModel required>
                <option value="MONTH">Month</option>
                <option value="YEAR">Year</option>
            </select>
        </div>
        <div class="controls">
            <p [class]="status">{{userMessage}}</p>
            <button class="blue-ui-button" (click)="onSubmit()" type="submit">Upgrade</button>
        </div>
    </form>
</div>
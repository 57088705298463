<form class="notice-container" [formGroup]="noticeFrom" novalidate>
	<div class="title-desc-wrapper">
		<div class="notice-title">
			{{'availability.notice-title' | translate}}
		</div>
		<div class="notice-description">
			{{('availability.notice-description-' + lessonType) | translate}}
		</div>
	</div>
	<input numbersOnly #el formControlName="notice" [ngClass]="{'error': noticeFrom.controls.notice.invalid}" [maxLength]="3" autocapitalize="none" autocomplete="off" autocorrect="off" spellcheck="false" tabindex="0" type="text" aria-autocomplete="list" aria-expanded="false" aria-haspopup="true" role="combobox">
	<span>{{'availability.hours' | translate}}</span>
</form>
<div class="video-controls">
    <div class="title-row">
        <mat-icon class="text-sirius-violet mr-1">videocam</mat-icon>
        <div class="font-bold">{{ 'call-controls.main-camera' | translate }}</div>
    </div>

    <form [formGroup]="formGroup" novalidate>
        <div class="input-device" formGroupName="video">
            <mat-radio-group class="vertical-radio-group" formControlName="inputDeviceId">
                <mat-radio-button *ngFor="let inputDevice of videoInputDevices, index as i;"
                    [value]="inputDevice.deviceId" class="radio-button"
                    value="default">{{inputDevice.label}}</mat-radio-button>
            </mat-radio-group>
        </div>

        <div class="title-row">
            <mat-icon class="text-sirius-violet mr-1">videocam</mat-icon>
            <div class="font-bold">{{ 'call-controls.second-camera' | translate }}</div>
        </div>

        <div class="input-device" formGroupName="secondCamera">
            <mat-radio-group class="vertical-radio-group" formControlName="secondCamera">
                <mat-radio-button *ngFor="let inputDevice of videoInputDevices, index as i;"
                    [value]="inputDevice.deviceId" class="radio-button" disableRipple=true
                    value="default">{{inputDevice.label}}</mat-radio-button>
                <mat-radio-button [value]="'none'" class="radio-button" selected>

                    {{ 'call-controls.2nd-cam-off' | translate }}

                </mat-radio-button>
            </mat-radio-group>
        </div>

        <hr />

        <div class="title-row">
            <a class="text-sirius-black flex w-full items-center" (click)="clickToggleCamera()">
                <span style="cursor: pointer;" *ngIf="isCamera">{{ 'call-controls.cam-off' | translate }}</span>
                <span class="text-red-500" style="cursor: pointer;" *ngIf="!isCamera">{{ 'call-controls.cam-on' |
                    translate }}</span>
            </a>
        </div>

    </form>

</div>
import {
	Component,
	EventEmitter,
	HostBinding,
	Input,
	OnInit,
	Output,
} from "@angular/core";
import { EmojiService } from "../../emoji.service";
import { GA4Service } from "src/app/services/ga4.service";

@Component({
	selector: "app-emoji-picker",
	templateUrl: "./emoji-picker.component.html",
	styleUrls: ["./emoji-picker.component.scss"],
})
export class EmojiPickerComponent implements OnInit {
	@Input() public isMobile = false;
	@Input() public isHandOn = false;

	@Output() emojiSelected: EventEmitter<string> = new EventEmitter();
	@Output() readonly clickClose: EventEmitter<void> = new EventEmitter();
	@Output() readonly toggleHandOn: EventEmitter<boolean> = new EventEmitter();

	constructor(private emojiService: EmojiService, private ga4: GA4Service) {}

	get emojis() {
		return this.emojiService.emojis;
	}

	onSelectEmoji(emoji: string): void {
		this.emojiSelected.emit(emoji);
		this.clickClose.emit();
	}

	doToggleHandOn(isHandOn: boolean): void {
		this.toggleHandOn.emit(isHandOn);
		this.ga4.play_sound();
		this.clickClose.emit();
	}

	public close(): void {
		this.clickClose.emit();
	}

	ngOnInit(): void {}
}

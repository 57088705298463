<div class="info-form">
  <div><h3 *ngIf="haveStaticName" [innerHTML]="'lobby-info.welcome-message' | translate: {username: username}"></h3></div>
  <div><h2>
   {{roomName}}
  </h2></div>
  <div><h3 *ngIf="!isLive"><span [innerHTML]="'lobby-info.conference-starts' | translate: {startTimeDisplayed: startTimeDisplayed}"></span></h3></div>
  <div class="time-div" *ngIf="!isLive">
    <app-icon-timer-item *ngIf="diffDays>0" [topText]="diffHrs.toString()" [bottomText]="'lobby-info.timer-days' | translate"></app-icon-timer-item>
    <h1 *ngIf="diffDays>0">:</h1>
    <app-icon-timer-item *ngIf="diffHrs>0" [topText]="diffHrs.toString()" [bottomText]="'lobby-info.timer-hours' | translate"></app-icon-timer-item>
    <h1 *ngIf="diffHrs>0">:</h1>
    <app-icon-timer-item [topText]="diffMins.toString()" [bottomText]="'lobby-info.timer-minutes' | translate"></app-icon-timer-item>
    <h1>:</h1>
    <app-icon-timer-item [topText]="diffSec.toString()" [bottomText]="'lobby-info.timer-seconds' | translate"></app-icon-timer-item>
  </div>
  <div *ngIf="isLive" class="time-div">
    <h1><span>{{ 'lobby-info.live-now' | translate }}</span></h1>
  </div>
  <input type="text" matInput *ngIf="!haveStaticName"
         [(ngModel)]="username"
         maxlength="60"
         (keydown.enter)="onEnterDown($event)"
         [placeholder]="'lobby-info.username-placeholder' | translate">
  <div>
    <button [disabled]="!isLive || username.length < 4 || !canSubmit" (click)="onSubmit()">{{ 'buttons.join' | translate }}</button>
  </div>

  <h4><span>{{ 'lobby-info.test-call' | translate }}</span></h4>
</div>

<button [attr.aria-label]="'aria-label.show-speed-test' | translate" (mouseenter)="togglePopup(true)" (click)="togglePopup(true)" (mouseleave)="togglePopup(false)"
  class="flex items-center h-full transition-all hover:opacity-80">
  <img [class.animate-pulse]="isTesting" [src]="icon" class="w-full h-full mb-px" alt="speed test"/>
</button>

<div (mouseenter)="togglePopup(true)" (mouseleave)="togglePopup(false)" *ngIf="isPopupOpen"
  [style.top]="headerHeight + 'px'"
  class="absolute right-0 p-2 pt-1 text-sm text-right text-white xl:text-xs bg-btn-grey whitespace-nowrap">

  <div *ngIf="isError">{{'error'}}</div>

  <ng-container *ngIf="!isError">

    <div class="mb-1" *ngIf="!lastChecked && !isTesting">{{ 'speed-test.perform-speedtest' | translate }}</div>

    <ng-container *ngIf="isTesting">
      <div>{{currentMbps}} Mbps</div>
      <div class="mb-1" [innerHTML]="'speed-test.testing' | translate"></div>
    </ng-container>

    <ng-container *ngIf="!isTesting && latestResult">
      <div [attr.aria-label]="latestSpeed + ' Mbps'" >{{latestSpeed}} Mbps</div>
      <div>
        {{'speed-test.' + connectionStatus | translate}} {{'speed-test.connection' | translate}}
      </div>
      <div class="mb-1">
        {{'speed-test.last-checked' | translate}}: {{lastChecked}}
      </div>
    </ng-container>

  </ng-container>

  <div>
    <button (click)="toggleTest()"
      [attr.aria-label]="(isTesting ? 'aria-label.cancel-speed-test' : 'aria-label.start-speed-test') | translate" 
      [disabled]="isError" 
      [class.disabled]="isError" 
      [class.bg-pink-50]="isTesting"
      class="px-2 py-1 leading-4 text-black align-middle rounded-md bg-sirius-bg hover:bg-pink-50">
      {{(isTesting ? 'speed-test.cancel' : lastChecked ? 'speed-test.recheck' : 'speed-test.check-connection') |
      translate}}
    </button>
  </div>

</div>
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PracticeRoomComponent } from './practice-room/practice-room.component';
import { TranslateModule } from "@ngx-translate/core";
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { TunerModule } from '../lib-tuner/tuner/tuner.module';
import {RouterModule} from '@angular/router';
import { MetronomeModule } from '../lib-metronome/components/metronome/metronome.module';
import {PianoModule} from '../piano/piano.module'


@NgModule({
  declarations: [
    PracticeRoomComponent
  ],
  imports: [
    RouterModule,
    TunerModule,
    CommonModule,
    MetronomeModule,
    MatIconModule,
    MatButtonModule,
    TranslateModule,
    PianoModule
  ]
})
export class PracticeRoomModule { }

import { Injectable } from "@angular/core";
import {
	ActivatedRouteSnapshot,
	Router,
	RouterStateSnapshot,
	UrlTree
} from "@angular/router";
import { Observable } from "rxjs";
import { AuthenticationService } from "../services/authentication/authentication.service";
import { SubRole } from "../constants/subroles-constants";
import { ProfileService } from "../profile/profile.service";
import { TeacherStatus } from "../types/profile.types";

@Injectable({
	providedIn: "root"
})
export class NotOnboardedGuard {
	constructor(private router: Router, private profile: ProfileService) {}
	private subrole;
	private teacherStatus;

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	):
		| Observable<boolean | UrlTree>
		| Promise<boolean | UrlTree>
		| boolean
		| UrlTree {
		return this.checkWorkloadAndNavigate();
	}

	async checkWorkloadAndNavigate(): Promise<boolean | UrlTree> {
		try {
			const profile = await this.profile.get().toPromise();

			if (profile) {
				this.subrole = profile.subrole;
				this.teacherStatus = profile.teacherStatus;
			}

			if (
				this.subrole === SubRole.TEACHER_MATCHED &&
				(this.teacherStatus === TeacherStatus.SIGN_UP ||
					this.teacherStatus === TeacherStatus.REVIEW ||
					this.teacherStatus === TeacherStatus.REJECTED)
			) {
				return this.router.parseUrl("/teacher-onboarding");
			} else {
				return true;
			}
		} catch (error) {
			return this.router.parseUrl("/");
		}
	}
}

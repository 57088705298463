import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {OrganizationVO} from '../../../data/OrganizationVO';
import {NgForm} from '@angular/forms';

@Component({
  selector: 'app-organization-edit-form',
  templateUrl: './organization-edit-form.component.html',
  styleUrls: ['./organization-edit-form.component.scss']
})
export class OrganizationEditFormComponent implements OnInit {

  @Input() isEditForm: boolean = false;
  @Input() organization: OrganizationVO;
  @ViewChild('orgForm') orgForm: NgForm;
  @ViewChild('logoImage') logoImage: NgForm;
  @Output() closing = new EventEmitter<boolean>();
  @Output() submitForm = new EventEmitter<OrganizationVO>();
  logo: string;
  watermark: string;
  background: string;
  attachments: any;
  constructor() { }

  ngOnInit(): void {
    setTimeout(this.init.bind(this));
  }
  init() {
    const formValue: any = {};
    formValue.name = this.organization.name || '';
    formValue.subdomain = this.organization.subdomain || '';
    formValue.logo = this.organization.logo || '';
    formValue.watermark = this.organization.watermark || '';
    formValue.background = this.organization.backgroundImage || '';
    if (!this.isEditForm) {
      formValue.name = '';
      formValue.subdomain = '';
      formValue.logo = '';
      formValue.watermark = '';
      formValue.background = '';
    }
    this.orgForm.form.patchValue(formValue);
  }
  fileShare(type, element) {
    if (!this.attachments) {
      this.attachments = {};
    }
    this.attachments[type] = {};
    this.attachments[type].data = element.currentTarget.files[0];
    this.attachments[type].name = element.currentTarget.files[0].name;

    if (element.currentTarget.files && element.currentTarget.files[0]) {
      if (!element.currentTarget.files[0].type.includes('png') &&
          !element.currentTarget.files[0].type.includes('jpg') &&
          !element.currentTarget.files[0].type.includes('jpeg')) {
        alert('Please upload images and use png/jpg/jpeg file formats.');
      } else {
        const reader = new FileReader();
        reader.onload = (loadEvent) => {
          this[type] = (loadEvent.target as any).result;
        };
        reader.readAsDataURL(element.currentTarget.files[0]); // convert to base64 string and render as a preview
      }
    }
  }
  onSubmit() {
    this.submitForm.emit(this.createAnswer());
  }
  onClose() {
    this.closing.emit(false);
    this.logo = '';
    this.watermark = '';
    this.background = '';
  }
  private createAnswer(): OrganizationVO {
    const orgForUpdate = Object.assign(this.organization, this.orgForm.form.value);
    if (this.logo) {
      orgForUpdate.logo  = this.logo;
    }
    if (this.background) {
      orgForUpdate.backgroundImage = this.background;
    }
    if (this.watermark) {
      orgForUpdate.headerLogo = this.watermark;
    }
    // If user uploaded new logos
    orgForUpdate.attachments = this.attachments;
    return orgForUpdate;
  }
  // private validate(): string { // return error string or '';
  //   const errorString = '';
  // }

}

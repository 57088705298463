import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { ADAPTATION, AdaptattionUtil } from '../../lib-visual/components/video-stream-display/video-stream-display.constants';
import { PlatformDetectorService } from '../../services/platform-detector/platform-detector.service';

@Component({
  selector: 'app-waiting-room-video',
  exportAs: 'WaitingRoomVideo',
  templateUrl: './waiting-room-video.component.html',
  styleUrls: ['./waiting-room-video.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WaitingRoomVideoComponent implements OnInit {
  @Input()
  public isMirrored = true;
  @Input()
  public debugName = '';
  @Input()
  srcObject: MediaStream | null = null;

  @ViewChild('video', { static: true })
  public videoElementRef: ElementRef<HTMLVideoElement> | null = null;

  @Output()
  readonly changePlaying: EventEmitter<boolean> = new EventEmitter();

  @HostBinding('class.host-width-100')
  get isClassHostWidth100(): boolean { return this.isHostWidth100; }
  @HostBinding('class.host-height-100')
  get isClassHostHeight100(): boolean { return this.isHostHeight100; }

  public isVideoWidth100 = false;
  public isVideoHeight100 = false;
  public isFullCover = false;
  private isHostWidth100 = false;
  private isHostHeight100 = false;

  public isPhone: boolean;
  public isIPad: boolean;

  constructor(private platformDetectorService: PlatformDetectorService) {
  }

  ngOnInit(): void {
    this.isPhone = this.platformDetectorService.isIphone();
    this.isIPad = this.platformDetectorService.isIPad();
    this.settingByAdaptationMode(AdaptattionUtil.create('fit-video-height'));
  }

  private settingByAdaptationMode(adaptationMode: ADAPTATION): void {
    if (adaptationMode === ADAPTATION.FIT_VIDEO_HEIGHT) {
      this.isHostWidth100 = false;
      this.isHostHeight100 = true;
      this.isVideoWidth100 = false;
      this.isVideoHeight100 = true;
      this.isFullCover = false;
    }
  }

}

import { BrowserModule } from "@angular/platform-browser";
import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatTabsModule } from "@angular/material/tabs";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import {
	TranslateLoader,
	TranslateModule,
	TranslateService
} from "@ngx-translate/core";
import { Router } from "@angular/router";
import * as Sentry from "@sentry/angular";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { PopupService } from "./services/popup/popup.service";
import { LoggingService } from "./services/logging/logging.service";
import { ErrorHandlerService } from "./services/error-handler/error-handler.service";
import { ConnectionService } from "./services/internet-connection/connection.service";
import {
	HttpBackend,
	HttpClient,
	HTTP_INTERCEPTORS,
	provideHttpClient,
	withInterceptorsFromDi
} from "@angular/common/http";
import { RoleService } from "./services/role.service";
import { RoomAuthService } from "./services/ws/room-auth.service";
import { RoomLinkAuth } from "./components/call-room/guard/room-link.auth";
import { RoomStateAuth } from "./components/call-room/guard/room-state.auth";
import { UserIconModule } from "./user-icon/user-icon.module";
import { AuthGuard } from "./components/call-room/guard/auth";
import { LoggedInGuard } from "./components/call-room/guard/loggedInGuard";
import { IsNotAuthorizedGuard } from "./components/admin/guard/is-no-autorized.guard";
import { IsAuthorizedGuard } from "./components/admin/guard/is-authorized.guard";
import { OrganizationDataService } from "./components/admin/services/organization-data.service";
import { IsNotSchoolGuard } from "./components/guard/is-not-school.guard";
import { ViewMediaModule } from "./components/view-media/view-media.module";
import { EventSocketService } from "./services/ws/event-socket.service";
import { UsersService } from "./services/users.service";
import { ProfileService } from "./profile/profile.service";
import { LanguageService } from "./services/languages/language.service";
import { Language } from "./models/language";
import { UserActionsModule } from "./components/user-actions/user-actions.module";
import { DisplayGridParticipantsModule } from "./lib-widgets/components/display-grid-participants/display-grid-participants.module";
import { MetronomeModule } from "./lib-metronome/components/metronome/metronome.module";
import { TunerModule } from "./lib-tuner/tuner/tuner.module";
import { PianoModule } from "./piano/piano.module";
import { AuthInterceptor } from "./services/auth.interceptor";
import { logger } from "src/app/lib-core/logger";
import { SettingPopupModule } from "./lib-setting/components/setting-popup/setting-popup.module";
import { SettingPopupWithoutVideoModule } from "./lib-setting/components/setting-popup-without-video/setting-popup-without-video.module";
import { VideoSettingsModule } from "./video-settings/video-settings.module";
import { LoadingModule } from "./lib-visual/components/loading/loading.module";
import { VolumeMeterModule } from "./lib-visual/components/volume-meter/volume-meter.module";
import { AuthenticationService } from "./services/authentication/authentication.service";
import { CheckRegistrationResolver } from "./services/authentication/check-registration.resolver";
import { LibPipeModule } from "./lib-pipe/lib-pipe.module";
import { LibCoreModule } from "./lib-core/lib-core.module";
import { RoomAliasGuard } from "./lib-core/guards/room-alias.guard";
import { InitialAuthorizationService } from "./lib-core/services/initial-authorization.service";
import { ClipboardService } from "./services/clipboard.service";
import { SharedModule } from "@shared/shared.module";
import { RedirectGuard } from "./lib-core/guards/redirect.guard";
import { EmojiModule } from "./emoji/emoji.module";
// import { TranslateCacheModule, TranslateCacheService, TranslateCacheSettings } from 'ngx-translate-cache';
import { SpeakerTimeDetectorService } from "./services/speaker-time-detector.service";
import { VolumeControlModule } from "./volume-control/volume-control.module";
import { EchoDetectorService } from "./services/echo-detector.service";
import { SetupModule } from "./setup/setup.module";
import { SubRoleGuard } from "./components/guard/subrole.guard";
import { PracticeRoomModule } from "./praticeroom/practiceroom.module";
// import { LowerCaseUrlSerializer } from './utils/url.util';
import { ReferralModule } from "./referral/referral.module";
import { ProfilePageComponent } from "./profile/profile-page/profile-page.component";
import { DragDropModule } from "@angular/cdk/drag-drop";

export function onInit(
	profileService: ProfileService,
	languageService: LanguageService,
	auth: AuthenticationService,
	initialAuthorizationService: InitialAuthorizationService
): () => Promise<void> {
	return () => {
		return profileService
			.getLanguages()
			.toPromise()
			.then((data: any) => {
				const languages: Language[] =
					!!data && !!data.list ? (data.list as Language[]) : null;
				if (!!languages) {
					languageService.setLanguages(languages);
				} else {
					logger.log(
						"appOninit() profileService.getLanguages()",
						data
					);
				}
				let hasInitialAuthorization = false;
				return auth
					.checkLoggedIn$()
					.toPromise()
					.then((response) => {
						hasInitialAuthorization = !!response;
						return response;
					})
					.finally(() => {
						initialAuthorizationService.setHasInitialAuthorization(
							hasInitialAuthorization
						);
					});
				// return null;
			});
	};
}

// export function TranslateCacheFactory(translateService, translateCacheSettings) {
//   return new TranslateCacheService(translateService, translateCacheSettings);
// }

@NgModule({
	bootstrap: [AppComponent],
	imports: [
		SharedModule,
		BrowserModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		MatInputModule,
		FormsModule,
		ReactiveFormsModule,
		MatSelectModule,
		MatCheckboxModule,
		MatButtonModule,
		MatCardModule,
		MatDividerModule,
		MatListModule,
		MatIconModule,
		MatAutocompleteModule,
		MatSidenavModule,
		MatDialogModule,
		MatProgressBarModule,
		MatSnackBarModule,
		ViewMediaModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpBackend]
			}
		}),
		// TranslateCacheModule.forRoot({
		//     cacheService: {
		//         provide: TranslateCacheService,
		//         useFactory: TranslateCacheFactory,
		//         deps: [TranslateService, TranslateCacheSettings]
		//     }
		// }),
		MatTabsModule,
		UserActionsModule,
		UserIconModule,
		DisplayGridParticipantsModule,
		MetronomeModule,
		TunerModule,
		PianoModule,
		SettingPopupModule,
		SettingPopupWithoutVideoModule,
		VideoSettingsModule,
		LoadingModule,
		VolumeMeterModule,
		LibPipeModule,
		LibCoreModule,
		EmojiModule,
		VolumeControlModule,
		SetupModule,
		PracticeRoomModule,
		ReferralModule,
		DragDropModule
	],
	providers: [
		PopupService,
		LoggingService,
		ErrorHandlerService,
		ConnectionService,
		RoleService,
		RoomStateAuth,
		RoomLinkAuth,
		RoomAuthService,
		AuthGuard,
		RedirectGuard,
		LoggedInGuard,
		IsNotAuthorizedGuard,
		IsAuthorizedGuard,
		ProfileService,
		SubRoleGuard,
		LanguageService,
		SpeakerTimeDetectorService,
		EchoDetectorService,
		{
			provide: ErrorHandler,
			useValue: Sentry.createErrorHandler({
				showDialog: false
			})
		},
		{
			provide: Sentry.TraceService,
			deps: [Router]
			// useValue: undefined
		},
		{
			provide: APP_INITIALIZER,
			useFactory: () => () => {},
			deps: [Sentry.TraceService],
			multi: true
		},
		{
			provide: APP_INITIALIZER,
			useFactory: onInit,
			multi: true,
			deps: [
				ProfileService,
				LanguageService,
				AuthenticationService,
				InitialAuthorizationService
			]
		},
		{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
		// {
		//     provide: UrlSerializer,
		//     useClass: LowerCaseUrlSerializer
		// },
		OrganizationDataService,
		EventSocketService,
		UsersService,
		ClipboardService,
		IsNotSchoolGuard,
		CheckRegistrationResolver,
		RoomAliasGuard,
		provideHttpClient(withInterceptorsFromDi())
	]
})
export class AppModule {}

export function HttpLoaderFactory(handler: HttpBackend) {
	const http = new HttpClient(handler);
	return new TranslateHttpLoader(
		http,
		"/assets/i18n/",
		".json?cb=" + new Date().getTime()
	);
}

import { animate, style, transition, trigger } from "@angular/animations";
export const MenuSlide = trigger("MenuSlide", [
  transition(":enter", [
    style({ transform: "translateY(100%)" }),
    animate(100),
  ]),
  transition(":leave", [
    animate(100, style({ transform: "translateY(100%)" })),
  ]),
]);

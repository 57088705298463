<div class="registration-outer">
  <h1>{{ 'reset-password.title' | translate }}</h1>
  <form (ngSubmit)="resetPassword()" [formGroup]="resetPassForm" (change)="formUpdate()" (keyup)="formUpdate()">
    <div class="registration-row">
      <label class="registration-label" for="userPassword">{{ 'reset-password.password-label' | translate }}</label>
      <div style="position: relative">
        <div class="password-field-outer">
          <input id="userPassword"
                 class="password"
                 [placeholder]="'reset-password.password-placeholder' | translate"
                 (focus)="onPasswordFocus()"
                 formControlName="userPassword"
                 type="{{showPass ? 'text' : 'password'}}">
          <div class="toggle-password-visibility" [ngClass]="{'show': showPass, 'hide': !showPass}" (click)="togglePass()"></div>
        </div>
        <app-password-requirements
          [hasEnoughPassLength]="hasEnoughPassLength"
          [hasNumberOrSpecial]="hasNumberOrSpecial"
          [hasBothCases]="hasBothCases"
          [isNotEmpty]="isNotEmpty">
        </app-password-requirements>
      </div>
      <div *ngIf="isAuthError || resetPassForm.controls['userPassword'].invalid && resetPassForm.controls['userPassword'].dirty"
          class="registration-error-message">
        {{ 'reset-password.password-error' | translate }}
      </div>
    </div>

    <div class="registration-row">
      <div class="password-field-outer">
        <input id="confirmPassword"
               class="password"
               [placeholder]="'reset-password.confirm-password-placeholder' | translate"
               formControlName="confirmPassword"
               type="{{showConfirmPass ? 'text' : 'password'}}">
        <div class="toggle-password-visibility" [ngClass]="{'show': showConfirmPass, 'hide': !showConfirmPass}" (click)="toggleConfirmPass()"></div>
      </div>
      <div *ngIf="resetPassForm.value.userPassword !== resetPassForm.value.confirmPassword" class="registration-error-message">
        {{ 'reset-password.confirm-password-error' | translate }}
      </div>
    </div>

    <!-- SIRIUS-634 -->
    <!-- <div class="registration-row">
      <input type="text"
             [placeholder]="'reset-password.security-code-placeholder' | translate"
             (focus)="onCodeFocus()"
             formControlName="securityCode"
             maxlength="4">
      <div *ngIf="resetPassForm.controls['securityCode'].invalid && resetPassForm.controls['securityCode'].dirty" class="registration-error-message">
        {{ 'reset-password.security-code-error' | translate }}
      </div>
      <div *ngIf="isCodeError" class="registration-error-message">
        {{ 'reset-password.auth-error' | translate }}
      </div>
      <div *ngIf="isAlreadyUsed" class="registration-error-message"
           [innerHTML]="'reset-password.already-used' | translate | safeHtml" appRouteTransformer>
      </div>
    </div> -->

    <div class="registration-row">
      <button class="default-ui-button blue-ui-button large"
              type="submit"
              [disabled]="isAlreadyUsed || !resetPassForm.valid || resetPassForm.value.userPassword !== resetPassForm.value.confirmPassword">
        {{ 'buttons.continue' | translate }}
      </button>
    </div>
  </form>
</div>

<menu class="students-menu">
	<!-- <div class="flex justify-center">
        <button *ngIf="allowExternalStudents" class="btn mb-2 sm:ml-0 blue-ui-button" (click)="inviteExternalStudent()">
            {{"lessons.addStudent" | translate}}
        </button>
    </div> -->
	<div *ngIf="allowExternalStudents" class="ml-2 mt-2">
		<mat-icon matTooltip="{{ 'lessons.addStudent' | translate }}" class="cursor-pointer" (click)="inviteExternalStudent()"
			>person_add</mat-icon
		>
	</div>

	@for (student of studentsList; track student.id; let i = $index) {
	<li>
		<a
			[ngClass]="{ 'is-active': selectedStudentId === student.id }"
			(click)="selectStudent(student); isMobile && goToSubMenu(student)"
			style="cursor: pointer"
		>
			{{ student.name ? student.name : student.email }}
			<span [ngClass]="student.activationStatus === 'activated' ? student.lessonPackage.status : 'pending'">
				{{ studentStatuses[student.id] | translate }}
			</span>
		</a>
	</li>
	}
	<div class="pagination">
		<span class="count" title="Not all the students have their workspace activated."> {{ studentsList.length }} of {{ count }}</span>
		<div class="arrows">
			<button mat-button (click)="handlePreviousPage()" [disabled]="page <= 1">
				<mat-icon aria-hidden="false" aria-label="back button" fontIcon="chevron_left"></mat-icon>
			</button>
			<button mat-button (click)="handleNextPage()" [disabled]="page >= pages">
				<mat-icon aria-hidden="false" aria-label="next button" fontIcon="chevron_right"></mat-icon>
			</button>
		</div>
	</div>
</menu>

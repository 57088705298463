import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-icon-timer-item',
  templateUrl: './circle.svg',
  styleUrls: ['./icon-timer-item.component.scss']
})
export class IconTimerItemComponent implements OnInit {
  topTextDisplay: string = '00';
  @Input() bottomText: string = 'HOURS';
  @Input() set topText(value: string) {
    value = value.toUpperCase();
    if (value.length === 0) {
    } else if (value.length === 1) {
      this.topTextDisplay = '0' + value;
    } else if (value.length === 2) {
      this.topTextDisplay = value;
    } else {
      this.topTextDisplay = value.substring(0, 2);
    }
  }

  constructor() { }

  ngOnInit() {
  }

}

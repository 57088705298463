import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
	providedIn: "root"
})
export class CallSiriusCloudService {
	public displayCloud$: BehaviorSubject<boolean> =
		new BehaviorSubject<boolean>(false);

	getDisplayCloud$(): BehaviorSubject<boolean> {
		return this.displayCloud$;
	}
}

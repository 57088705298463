import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GetProfileDtoResolver } from './resolvers/get-profile-dto.resolver';
import { InitialAuthorizationService } from './services/initial-authorization.service';


@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    InitialAuthorizationService,
    GetProfileDtoResolver,
  ]
})
export class LibCoreModule { }

<div class="main-info" *ngIf="!isDelete">
  <p>{{userEntry.firstName}}</p>
  <p>{{userEntry.lastName}}</p>
  <p>{{userEntry.email}}</p>
  <div>
    <button class="blue-ui-button"
            (click)="onEditorClick()">Edit</button>
    <button class="orange-ui-button"
            (click)="onDelete()" >Delete</button>
  </div>
</div>
<app-user-edit-form *ngIf="isEditor && !isDelete" [userEntry]="userEntry"
                            isEditForm="true"
                            (closing)="onClose()"
                            (submitForm)="onUserEdited($event)">
</app-user-edit-form>


import { Component, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { UsersDataService } from "../../../services/users-data.service";

@Component({
  selector: "app-account-upgrade-form",
  templateUrl: "./account-upgrade-form.component.html",
  styleUrls: ["./account-upgrade-form.component.scss"],
})
export class AccountUpgradeFormComponent {
  @ViewChild("upgradeForm") upgradeForm: NgForm;
  userMessage: string;
  status: "success" | "error";

  constructor(private dataService: UsersDataService) {}

  onSubmit() {
    this.dataService.upgradeAccount(this.upgradeForm.value).subscribe(
      (res) => {
        if (res) {
          this.status = "success";
          this.userMessage = "User successfully upgraded!";
          this.upgradeForm.resetForm();
        }
      },
      (error) => {
        this.status = "error";
        if (error.status === 404) {
          this.userMessage = "User does not exist in database";
        } else {
          this.userMessage = "Can't upgrade subscription";
        }
      }
    );
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-duplicate-select-list',
  templateUrl: './duplicate-select-list.component.html',
  styleUrls: ['./duplicate-select-list.component.scss']
})
export class DuplicateSelectListComponent {
	@Output() readonly close: EventEmitter<void> = new EventEmitter();
	@Output() readonly apply: EventEmitter<void> = new EventEmitter();
	@Input() days: string[];
	@Input() selectedDay: string;
	selectListForm: FormGroup;

	constructor(private fb: FormBuilder) {
		this.selectListForm = this.fb.group({
			checkArray: this.fb.array([], [Validators.required]),
		});
	}
	onCheckboxChange(e: any) {
		const checkArray: FormArray = this.selectListForm.get('checkArray') as FormArray;
		if (e.target.checked) {
			checkArray.push(new FormControl(e.target.value));
		} else {
			let i: number = 0;
			checkArray.controls.forEach((item: any) => {
				if (item.value == e.target.value) {
					checkArray.removeAt(i);
					return;
				}
				i++;
			});
		}
	}

	submit() {
		this.apply.emit(this.selectListForm.value.checkArray);
	}

}

import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { PlatformDetectorService } from "../services/platform-detector/platform-detector.service";
import { InitialAuthorizationService } from "src/app/lib-core/services/initial-authorization.service";
import { AuthenticationService } from "../services/authentication/authentication.service";
import { ColorScheme } from "../types/color-scheme.type";
import { CustomThemeService } from "../services/custom-theme.service";
import { getSubDomain } from "../helpers";
import { environment } from "src/environments/environment";
import { Profile } from "../types/profile.types";
import { ProfileService } from "../profile/profile.service";
import { Tariff } from "../types/tariff-constants";
import { Resources } from "../services/access-control/resources.constants";
import { SubRole } from "../constants/subroles-constants";

@Component({
	selector: "app-header",
	templateUrl: "./header.component.html",
	styleUrls: [
		"./header.component.scss",
		"./header.component.tablet.scss",
		"./header.component.mobile.scss"
	]
})
export class HeaderComponent implements OnInit {
	public profile: Profile;
	public userMenuVisible = false;
	public isSchool: boolean;
	public enableRecordings = environment.enableRecordings;
	isBetaInfoDisplayed: boolean = false;
	lessonOverviewUrl = "";
	public tariff: Tariff = Tariff.FREE;
	public subrole: SubRole;
	public Resources = Resources;
	public isUserLogged: boolean;
	public showLessonOverview = false;
	isPersonalRoom: boolean;

	private isBetaTester: boolean = false;

	@Input() menuStyle: ColorScheme;
	@Input() isAdmin: boolean = false;

	get isMatchedTeacher() {
		return this.subrole === SubRole.TEACHER_MATCHED;
	}

	constructor(
		private profileService: ProfileService,
		private router: Router,
		public authenticationService: AuthenticationService,
		private platformDetectorService: PlatformDetectorService,
		public customThemeService: CustomThemeService
	) {}

	async ngOnInit() {
		this.authenticationService.checkLoggedIn$().subscribe(
			(logged) => {
				this.authenticationService.isLoggedIn$.next(logged);
				this.isUserLogged = true;
			},
			(e) => {
				this.authenticationService.isLoggedIn$.next(false);
				this.isUserLogged = false;
			}
		);
		const profile = await this.authenticationService.profile;
		this.lessonOverviewUrl = profile?.lessonOverview;
		this.isSchool = !!getSubDomain();
		this.tariff = profile?.subscription?.type || Tariff.FREE;
		this.subrole = profile?.subrole;
		this.isBetaTester = profile?.betaTesting;
		this.isPersonalRoom = profile?.enablePersonalRoom;
		this.showLessonOverview = profile?.showLessonSheet;
	}

	// getCalComLink(): string {
	// 	const access = localStorage.getItem("accessToken");
	// 	const refresh = localStorage.getItem("refreshToken");
	// 	return `${environment.calcomUrl}/slogin?access=${access}&refresh=${refresh}`;
	// }

	logoClick(): void {
		const route =
			this.isAdmin === true
				? "/admin"
				: this.authenticationService.isLoggedIn$.value
				? "/platform/start"
				: this.isSchool
				? "/login"
				: "/";

		this.router.navigate([route]);
	}

	signUpClick(): void {
		this.router.navigate(["signup"]);
	}

	loginClick(): void {
		if (this.isAdmin) {
			this.router.navigate(["admin/login"]);
		} else {
			this.router.navigate(["login"]);
		}
	}

	showUserMenu(): void {
		this.userMenuVisible = true;
	}

	// TODO: prepare function for instant hidding of menu
	hideUserMenu(): void {
		this.userMenuVisible = false;
	}

	showUserMenuDesktop(): void {
		if (this.platformDetectorService.isDesktop()) {
			this.showUserMenu();
		}
	}

	hideUserMenuDesktop(): void {
		if (this.platformDetectorService.isDesktop()) {
			this.hideUserMenu();
		}
	}

	toggleUserMenuMobile(): void {
		if (!this.platformDetectorService.isDesktop()) {
			// For touch devices
			this.userMenuVisible = !this.userMenuVisible;
		}
	}

	logOut(): void {
		this.authenticationService.logOut$().subscribe((res) => {
			if (this.isAdmin) {
				this.router.navigate(["admin/login"]);
			} else {
				if ("/" === environment.logoutUrl) {
					this.router.navigate([environment.logoutUrl]);
				} else {
					window.location.href = environment.logoutUrl;
				}
			}
			this.hideUserMenu();
		});
	}
	public setTariffPro(): void {
		this.profileService
			.getPuddleOverrideUrl(this.profile.email)
			.subscribe((data) => {
				if (data && data.response) {
					// @ts-ignore
					Paddle.Checkout.open({
						override: data.response.url,
						passthrough: { user_id: this.profile.userId }
					});
				}
			});
	}
	showLessonManagement(): boolean {
		return this.isBetaTester;
	}
}

<img class="sirius-logo" src="/assets/icons/sirius/Sirius.png" alt="Sirius" />
<img class="sirius-music-gfx" src="/assets/icons/sirius/redesign/sirius-music.png" alt="Sirius" />
<img class="sirius-jupiter-gfx" src="/assets/icons/sirius/redesign/sirius-planet.png" alt="Sirius" />
<img class="sirius-mars-gfx" src="/assets/icons/sirius/redesign/sirius-planeten-01.png" alt="Sirius" />
<img class="sirius-blueplanet-gfx" src="/assets/icons/sirius/redesign/sirius-planeten-02.png" alt="Sirius" />
<img class="sirius-bluebigplanet-gfx" src="/assets/icons/sirius/redesign/sirius-planeten-04.png" alt="Sirius" />

<app-language-selector />
<div class="registration-outer">
	<h1 class="mail-sent">{{ "mail-sent.title" | translate }}</h1>
	<p class="mail-sent">
		{{ "mail-sent.email-sent-to" | translate : { email: email } }}<br />
		{{ "mail-sent.click-confirmation-link" | translate }}
	</p>
	<p class="mail-sent" [innerHTML]="'mail-sent.sign-up-again' | translate | safeHtml" appRouteTransformer></p>
</div>

@if (displayMaterial) {
	<div class="mat-container">
		@if (isTeacher) {
			<input type="file" #fileInput style="display: none" (change)="uploadFile($event)" multiple />
			<button class="default-ui-button input table-button_upload-material" (click)="fileInput.click()">
				<mat-icon fontIcon="add"></mat-icon>
				{{ "material.upload-button" | translate }}
			</button>
			<hr />
		}
		@if (count > 0) {
			<table class="mobile" mat-table [dataSource]="filesData" matSort>
				<!-- Filename & Info Column -->
				<ng-container matColumnDef="name">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>
						{{ "material.table-title" | translate }}
					</th>
					<td class="mobile__file-info" mat-cell *matCellDef="let list">
						<a [href]="list?.url" target="_blank" rel="noreferrer" [download]="list?.name">
							<p class="file-name">{{ list?.name }}</p>
							<p class="file-info">{{ list?.extension }} | {{ list?.created | date: "dd.MM.yyyy - H:mm" }}</p>
						</a>
					</td>
				</ng-container>

				<!-- menu Column -->
				<ng-container matColumnDef="menu">
					<th mat-header-cell *matHeaderCellDef></th>
					<td class="mobile__dropdown-button" mat-cell *matCellDef="let item">
						<button mat-button [matMenuTriggerFor]="contextMenu" [matMenuTriggerData]="{ item: item }">
							<mat-icon class="menu__expand-button">expand_more</mat-icon>
						</button>
					</td>
				</ng-container>

				<tr mat-row *matRowDef="let row; columns: displayedMobileColumns"></tr>
			</table>
			<mat-menu #contextMenu="matMenu">
				<ng-template matMenuContent let-list="item">
					@if (isTeacher) {
						<button mat-menu-item (click)="shareFile(list)">
							<mat-icon fontIcon="share"></mat-icon>
							{{ "material.menu.option1" | translate }}
						</button>
						<button mat-menu-item (click)="renameFile(list)">
							<mat-icon fontIcon="edit"></mat-icon>
							{{ "material.menu.option2" | translate }}
						</button>
						<button mat-menu-item (click)="deleteFile(list)">
							<mat-icon fontIcon="delete"></mat-icon>
							{{ "material.menu.option3" | translate }}
						</button>
					}
					<button mat-menu-item (click)="downloadFile(list.url, list.name)">
						<mat-icon fontIcon="download"></mat-icon>
						{{ "material.menu.option4" | translate }}
					</button>
				</ng-template>
			</mat-menu>
			<mat-paginator
				#paginator
				[ngStyle]="{ display: count > 10 ? 'block' : 'none' }"
				[hidePageSize]="true"
				[pageSize]="10"
				[length]="count"
				(page)="pageChanged($event)"
				showFirstLastButtons
				aria-label="Select page of recordings elements"
			/>
		} @else {
			<app-empty-list-container message="{{ 'material.empty-list-message' | translate }}" />
		}
	</div>
}

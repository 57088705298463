import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { InitialAuthorizationService } from 'src/app/lib-core/services/initial-authorization.service';
import { SessionStorageUtil } from 'src/app/lib-core/utils/session-storage.util';


@Injectable({
  providedIn: 'root'
})
export class RoomAliasGuard  {

  constructor(
    private router: Router,
    private initialAuthorizationService: InitialAuthorizationService,
  ) {
  }

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const isRoomAlias = this.isExistRatingRoomAlias();
    if (!!isRoomAlias) {
      return Promise.resolve(true);
    } else {
      const redirectTo = this.getRedirectTo();
      const urlTreeLogin: UrlTree = this.router.parseUrl(redirectTo);
      return Promise.resolve(urlTreeLogin);
    }
  }

  public canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    const isRoomAlias = this.isExistRatingRoomAlias();
    if (!!isRoomAlias) {
      return Promise.resolve(true);
    } else {
      const redirectTo = this.getRedirectTo();
      this.router.navigate([redirectTo]);
      return Promise.resolve(false);
    }
  }

  // ** Private API **

  private isExistRatingRoomAlias(): boolean {
    const roomAlias = SessionStorageUtil.readRatingRoomAliasFromSessionStorage();
    return (!!roomAlias);
  }

  private getRedirectTo(): string {
    const hasInitialAuthorization = this.initialAuthorizationService.getHasInitialAuthorization();
    return (hasInitialAuthorization ? 'platform/start' : '/');
  }
}

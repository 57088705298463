<div
	class="main-container w-full h-full bg-indigo flex justify-center items-center text-white max-w-full"
>
	<div class="m-auto max-w-full max-h-full overflow-hidden">
		<div *ngIf="name" class="font-bold max-w-full name">
			<span>{{ "student-info.waiting-for" | translate }}</span
			>&nbsp;<span>{{ name }}</span>
		</div>
		<div
			class="grid grid-cols-[auto,auto] details-grid max-w-full max-h-full overflow-hidden"
		>
			<ng-container *ngIf="instruments">
				<div class="font-bold mr-[2px] instrument">
					{{ "student-info.instrument" | translate }}
				</div>
				<div class="instrument">
					{{ instruments }}
				</div>
			</ng-container>
			<ng-container *ngIf="genre">
				<div class="font-bold mr-[2px]">
					{{ "student-info.genre" | translate }}
				</div>
				<div>{{ genre }}</div>
			</ng-container>
			<ng-container *ngIf="ageGroup">
				<div class="font-bold mr-[2px]">
					{{ "student-info.age-group" | translate }}
				</div>
				<div>{{ ageGroup }}</div>
			</ng-container>
			<ng-container *ngIf="skillLevel">
				<div class="font-bold mr-[2px]">
					{{ "student-info.skill-level" | translate }}
				</div>
				<div>{{ "skill-level." + skillLevel | translate }}</div>
			</ng-container>
			<ng-container *ngIf="favouriteSong">
				<div class="font-bold mr-[2px]">
					{{ "student-info.favourite-song" | translate }}
				</div>
				<div>{{ favouriteSong }}</div>
			</ng-container>
			<ng-container *ngIf="notesForTeacher">
				<div class="font-bold mr-[2px]">
					{{ "student-info.notes-for-you" | translate }}
				</div>
				<div class="notes">
					{{ notesForTeacher }}
				</div>
			</ng-container>
		</div>
	</div>
</div>

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import {AuthenticationService} from '../../../services/authentication/authentication.service';

@Injectable()
export class IsAuthorizedGuard  {
  constructor(private auth: AuthenticationService,
              private router: Router) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return new Observable<boolean | UrlTree>(obs => {
      this.auth.checkLoggedIn$()
        .toPromise().then(isAuth => {
          if (isAuth) {
            obs.next(true);
          } else {
            obs.next(this.getRedirectToAuth());
          }
          obs.complete();
        }, error => {
          console.error('updateToken isAuth ERROR getRedirectToAuth ', error);
          obs.next(this.getRedirectToAuth());
          obs.complete();
        });
    });
  }
  private getRedirectToAuth(): UrlTree {
    const url = 'admin/login';
    return this.router.parseUrl(url);
  }
}

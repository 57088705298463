import { Component, NgZone, EventEmitter, Output,ViewEncapsulation } from '@angular/core';
import { MediaProperiesUtil } from 'src/app/lib-setting/components/setting-popup/setting-popup.interface';
import { MediaDevicesService } from 'src/app/lib-media-devices/services/media-devices.service';
import { PlatformDetectorService } from "src/app/services/platform-detector/platform-detector.service";
import { UntypedFormControl, UntypedFormGroup, Validators, FormGroup } from '@angular/forms';
import { from, Observable, ObservedValueOf} from 'rxjs';

const CN_AUDIO_CONTEXT = window.AudioContext // For Safari

@Component({
  selector: 'app-camera-setup',
  templateUrl: './camera-setup.component.html',
  styleUrls: ["./camera-setup.component.scss"],
  encapsulation: ViewEncapsulation.None
})

export class CameraSetupComponent {
  @Output() complete = new EventEmitter();
  private audioContext: AudioContext;
  preCallSettingsForm: UntypedFormGroup;
  currentVolume: number = 0;
  videoDeviceList: Array<MediaDeviceInfo> = new Array<MediaDeviceInfo>();
  audioDeviceList: Array<MediaDeviceInfo> = new Array<MediaDeviceInfo>();
  outputDevicesList: Array<MediaDeviceInfo> = new Array<MediaDeviceInfo>();
  isMediaError = false;
  micIsWorking = false;
  videoIsWorking = false;
  isAudioOutputWorking = false;
  public isFirefox: boolean;
  public stream;
  public audioSettings = "audioInput"

  public showOutputSettings() {
    this.audioSettings = "audioOutput"
  }

  onCompleted(): void {
    this.complete.emit();
  }

  public controls = {
    videoInputDeviceId: new UntypedFormControl(null, [Validators.required]),   // videoInputDevices
    audioInputDeviceId: new UntypedFormControl(null, [Validators.required]),   // audioInputDevices
    audioOutputDeviceId: new UntypedFormControl(null, [Validators.required]),  // audioOutputDevices
  };

  constructor(private ngZone: NgZone, private mediaDevicesService: MediaDevicesService, private platformDetectorService:PlatformDetectorService){
    this.preCallSettingsForm = new UntypedFormGroup({
      ...this.controls
    });
  }

  async ngOnInit() {
    var constraints = { audio: true, video: true};
    this.updateDeviceList();
    try {
    this.stream = await navigator.mediaDevices.getUserMedia(constraints)
    }
    catch(e){
      this.isMediaError = true;
    }
    this.initAudioAnalyser(this.stream);
    this.isFirefox = this.platformDetectorService.isFirefox();
    const mediaProperies = MediaProperiesUtil.readMediaProperiesFromStorage();
    console.log(mediaProperies);
    const audioInpDevInfo = this.mediaDevicesService.findMediaDeviceInfo(this.audioDeviceList, mediaProperies.audioInpDev);
    console.log(audioInpDevInfo.deviceId);

    if (this.audioDeviceList.length > 0) {
      const audioInpDevInfo = this.mediaDevicesService.findMediaDeviceInfo(this.audioDeviceList, mediaProperies.audioInpDev);
      this.controls.audioInputDeviceId.setValue((!!audioInpDevInfo ? audioInpDevInfo.deviceId : null), { emitEvent: false });
      console.log("done");
      console.log(this.controls.audioInputDeviceId.value);
    } else {
      this.controls.audioInputDeviceId.disable({ emitEvent: false });
    }

    if (this.outputDevicesList.length > 0) {
      const audioOutDevInfo = this.mediaDevicesService.findMediaDeviceInfo(this.outputDevicesList, mediaProperies.audioOutDev);
      this.controls.audioOutputDeviceId.setValue(audioOutDevInfo.deviceId, { emitEvent: false });
    } else {
      this.controls.audioOutputDeviceId.disable({ emitEvent: false });
    }

    if (this.videoDeviceList.length > 0) {
      const videoDeviceInfo = this.mediaDevicesService.findMediaDeviceInfo(this.videoDeviceList, mediaProperies.videoInpDev);
      this.controls.videoInputDeviceId.setValue(videoDeviceInfo.deviceId, { emitEvent: false });
    } else {
      this.controls.videoInputDeviceId.disable({ emitEvent: false });
    }

  }

  private updateDeviceList(): Observable<ObservedValueOf<Promise<MediaDeviceInfo[]>>> {
    const deviceIds = from(this.mediaDevicesService.enumerateDevices(true));
    deviceIds.subscribe(
       (ids: MediaDeviceInfo[]) => {
         this.videoDeviceList = this.mediaDevicesService.getDeviceVideoInput(ids);
         this.audioDeviceList = this.mediaDevicesService.getDeviceAudioInput(ids);
         this.outputDevicesList = this.mediaDevicesService.getDeviceAudioOutput(ids);
       }
     );
    return deviceIds;
   }

  private initAudioAnalyser(stream: MediaStream): void {
    this.audioContext = new CN_AUDIO_CONTEXT();
    const analyser = this.audioContext.createAnalyser();
    const microphone = this.audioContext.createMediaStreamSource(stream);
    const javascriptNode = this.audioContext.createScriptProcessor(2048, 1, 1);

    analyser.smoothingTimeConstant = 0.8;
    analyser.fftSize = 1024;

    microphone.connect(analyser);
    analyser.connect(javascriptNode);
    javascriptNode.connect(this.audioContext.destination);

    javascriptNode.addEventListener('audioprocess', () => {
      const array = new Uint8Array(analyser.frequencyBinCount);
      analyser.getByteFrequencyData(array);
      const values = array.reduce((sum, current) => {
        return sum + current;
      }, 0);

      this.ngZone.run(() => {
        this.currentVolume = values / array.length;
      });
    });
  }

  public async testAudio() {
    const audioEnter = new Audio('/assets/EnterSound.mp3');
    await (audioEnter as any).setSinkId(this.controls.audioOutputDeviceId.value);
    await audioEnter.play();
  }

  private updateAnalyser = async(stream: MediaStream) => {
    // Below is the code for volume meter
    if (this.audioContext) {
      this.audioContext.close().then(() => {
        this.initAudioAnalyser(stream);
      });
    } else {
      this.initAudioAnalyser(stream);
    }
  }

  private getInfo(deviceList: MediaDeviceInfo[]): string {
    let result = '';
    for (const device of (deviceList || [])) {
      result += `- "${device.label}" deviceId=${device.deviceId.substr(0, 10)}\n`;
    }
    return result;
  }

  public async doChangeVideoInputDevice(): Promise<void> {
    this.writeMediaProperies();
    await this.changeMediaSource();

  }


  // public async testAudio() {
  //   const audioEnter = new Audio('/assets/EnterSound.mp3');
  //   await (audioEnter as any).setSinkId(this.controls.audioOutputDeviceId.value);
  //   await audioEnter.play();
  // }

  public async doChangeAudioInputDevice(): Promise<void> {
    console.log("triggered")
    this.writeMediaProperies();
    await this.changeMediaSource();
  }

  public async updateAudioSettings(): Promise<void> {
    this.writeMediaProperies();
    await this.changeMediaSource();
    // this.settingsStorage.clearCurrentStream();
    // if (!BotService.isBot) {
    //   await this.initMediaSource();
    // }
  }

  private writeMediaProperies(): void {
    const videoInputDevice = this.controls.videoInputDeviceId.value;
    const audioInputDevice = this.controls.audioInputDeviceId.value;
    const audioOutputDevice = this.controls.audioOutputDeviceId.value;

    MediaProperiesUtil.writeVideoInputDeviceToStorage(videoInputDevice);
    MediaProperiesUtil.writeAudioInputDeviceToStorage(audioInputDevice);
    MediaProperiesUtil.writeAudioOutputDeviceToStorage(audioOutputDevice);
  }

  private async changeMediaSource(): Promise<void> {

    if (this.stream) {
      this.stream.getTracks().forEach((track) => {
        track.stop();
        this.stream.removeTrack(track);
      });
    }

    this.stream = null;
    this.isMediaError = false;
    this.stream = await navigator.mediaDevices.getUserMedia({ video: { deviceId: this.controls.videoInputDeviceId.value } })

  }

  async ngOnDestroy() {
    if (this.stream) {
      this.stream.getTracks().forEach((track) => {
        track.stop();
        this.stream.removeTrack(track);
      });
    }
    console.log("destroyed")

  }


}

import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class MirroringService {
  public mirroring = {};

  setValue(user: any, value: boolean) {
    this.mirroring[user.id] = value;
  }

  constructor() {}
}

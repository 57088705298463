import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import {} from "@angular/core";
import { ModulesForMaterialComponent } from "../../material.modules";

@Component({
	selector: "app-material-mobile-table",
	standalone: true,
	imports: [ModulesForMaterialComponent],
	templateUrl: "./material-mobile-table.component.html",
	styleUrl: "../../material.component.scss"
})
export class MaterialMobileTableComponent {
	@Input() displayMaterial: boolean;
	@Input() isTeacher: boolean;
	@Input() count: number;
	@Input() filesData;
	@Output() uploadFileEvent = new EventEmitter<any>();
	@Output() shareEvent = new EventEmitter<any>();
	@Output() renameEvent = new EventEmitter<any>();
	@Output() deleteEvent = new EventEmitter<any>();
	@Output() pageChangeEvent = new EventEmitter<any>();
	displayedMobileColumns: string[] = ["name", "menu"];

	uploadFile(file) {
		this.uploadFileEvent.emit(file);
	}

	shareFile(value) {
		this.shareEvent.emit(value);
	}

	renameFile(value) {
		this.renameEvent.emit(value);
	}

	deleteFile(value) {
		this.deleteEvent.emit(value);
	}
	pageChanged(event) {
		this.pageChangeEvent.emit(event);
	}

	downloadFile(url, fileName) {
		const anchor = document.createElement("a");
		anchor.href = url;
		anchor.target = "_blank";
		anchor.download = fileName || "download";
		document.body.appendChild(anchor);
		anchor.click();
		document.body.removeChild(anchor);
	}
}

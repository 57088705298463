
export class SoundPlayerUtil {

  public static async fetch(audioFileName: string): Promise<ArrayBuffer> {
    return await fetch(audioFileName).then((resp) => resp.ok && resp.arrayBuffer());
  }

  public static createBufferSource(audioContext: AudioContext, arrayBuffer: ArrayBuffer): Promise<AudioBuffer> {
    const promise = new Promise<AudioBuffer>((resolve, reject) => {
      audioContext.decodeAudioData(arrayBuffer,
        (buffer) => {
          return resolve(buffer);
        },
        (error) => {
          console.error('Error with decoding audio data ' + error);
          return reject(error);
        }
      );
    });
    return promise;
  }

  public static async fetchAndCreateBufferSource(audioContext: AudioContext, audioFileName: string): Promise<AudioBuffer> {
    const arrayBuffer: ArrayBuffer = await SoundPlayerUtil.fetch(audioFileName);
    return await SoundPlayerUtil.createBufferSource(audioContext, arrayBuffer);
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { VideoStreamDisplayModule } from 'src/app/lib-visual/components/video-stream-display/video-stream-display.module';
import { LoadingModule } from 'src/app/lib-visual/components/loading/loading.module';
import { VolumeMeterModule } from 'src/app/lib-visual/components/volume-meter/volume-meter.module';
import { VideoSettingsComponent } from './video-settings.component';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { PreCallSettingsComponent } from './pre-call-settings/pre-call-settings.component';
import { PreCallInfoComponent } from './pre-call-info/pre-call-info.component';
import { RequestPermissionComponent } from './request-permission/request-permission.component';
import { WaitingRoomVideoComponent } from './waiting-room-video/waiting-room-video.component';


@NgModule({
  declarations: [
    VideoSettingsComponent,
    PreCallSettingsComponent,
    PreCallInfoComponent,
    RequestPermissionComponent,
    WaitingRoomVideoComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    // MatCardModule,
    // MatDialogModule,
    // MatIconModule,
    MatSelectModule,
    // MatTabsModule,
    TranslateModule,
    VideoStreamDisplayModule,
    LoadingModule,
    VolumeMeterModule,
    MatIconModule,
    RouterModule,
  ],
  exports: [VideoSettingsComponent,WaitingRoomVideoComponent]
})
export class VideoSettingsModule { }

import { CommonModule } from "@angular/common";
import {
	Component,
	HostListener,
	Input,
	ViewChild,
	ViewEncapsulation
} from "@angular/core";
import { ChatService } from "../chat/services/chat.service";
import { MatSidenav } from "@angular/material/sidenav";
import { MatSidenavModule } from "@angular/material/sidenav";
import { TalkServiceService } from "src/app/talkjs-chat/services/talk-service.service";
import { ProfileService } from "src/app/profile/profile.service";
import { ENCODED_LANGUAGES } from "src/app/constants/encodedLanguages-contants";
import { RoomAuthService } from "src/app/services/ws/room-auth.service";
import { RoomStateStorage } from "../call-room/service/storage/RoomStateStorage";
import { MatIconModule } from "@angular/material/icon";
import { PlatformDetectorService } from "src/app/services/platform-detector/platform-detector.service";

@Component({
	selector: "app-talkjs-chat",
	standalone: true,
	imports: [MatSidenavModule, CommonModule, MatIconModule],
	templateUrl: "./talkjs-chat.component.html",
	styleUrl: "./talkjs-chat.component.scss"
})
export class TalkjsChatComponent {
	@Input() displayChat: boolean;
	@Input() isTeacher: boolean;
	@Input() studentId: string;
	@ViewChild("chatUI") chatUI: MatSidenav;

	private teacherId: string;
	viewportHeight: number;
	visualViewport: any;
	isMobile: boolean;

	constructor(
		private chatService: ChatService,
		private talkService: TalkServiceService,
		private profileService: ProfileService,
		private roomService: RoomAuthService,
		public roomState: RoomStateStorage,
		private platformDetectorService: PlatformDetectorService
	) {
		this.isMobile = this.platformDetectorService.isMobile();
	}

	ngOnInit(): void {
		this.init();
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		const roomKey = urlParams.get("roomKey");

		this.roomService.getRoomInfo(roomKey).subscribe((roomData) => {
			this.teacherId = roomData.userId;
		});

		this.isTeacher = this.roomState.isTeacher ? true : false;
		this.initTalkjsChat();
		this.adjustHeight();

		if (window.visualViewport) {
			this.visualViewport = window.visualViewport;
			this.visualViewport.addEventListener(
				"resize",
				this.onViewportResize
			);
		} else {
			window.addEventListener("resize", this.onWindowResize);
		}
	}

	init() {
		this.chatService.getDisplayChat$().next(this.displayChat);
	}

	onViewportResize = () => {
		this.adjustHeight();
	};

	onWindowResize = () => {
		this.adjustHeight();
	};

	@HostListener("window:resize")
	onResize() {
		this.adjustHeight();
	}
	private adjustHeight() {
		const navbarHeight = 56;
		if (this.isMobile) {
			if (this.visualViewport) {
				this.viewportHeight = this.visualViewport.height - navbarHeight;
			} else {
				this.viewportHeight = window.innerHeight - navbarHeight;
			}
		} else {
			this.viewportHeight = window.innerHeight;
		}

		window.scrollTo(0, 8);
	}

	toggleChat(): void {
		if (this.displayChat) {
			this.chatUI.open();
		} else {
			this.chatUI.close();
		}
		this.displayChat = !this.displayChat;
		this.chatService.getDisplayChat$().next(this.displayChat);
	}

	async initTalkjsChat() {
		this.profileService.get().subscribe((profile) => {
			const user = {
				id: profile.userId,
				username: `${profile.firstName} ${profile.lastName}`,
				email: profile.email,
				photoUrl: profile.photo || null,
				locale: this.validateLnaguage(profile.languageId)
			};
			this.loadChatBox(user);
		});
	}

	async loadChatBox(user) {
		await this.talkService.intializeUser(user);

		const chatBox = await this.talkService.createChatBox(
			this.isTeacher ? this.studentId : this.teacherId,
			this.isTeacher
		);

		chatBox.mount(document.getElementById("talkjs-container"));
	}

	validateLnaguage(encodedLanguageId: string): string {
		return ENCODED_LANGUAGES[encodedLanguageId];
	}
}

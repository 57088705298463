<div class="container">
	<a [routerLink]="['/platform/start']"><img class="sirius-logo grid-item" src="/assets/icons/sirius/Sirius.png" alt="Sirius" /></a>

	<div class="grid-item features flex mt-0 mb-0 md:mt-16 md:mb-0 justify-center flex-col md:flex-row items-center">
		<ng-container *ngIf="!isTuner && !isMetronome && !isPiano">
			<p>No feature activated</p>
		</ng-container>
		<div
			*ngIf="isTuner"
			[ngClass]="['tuner-box', 'grid-item', 'md:mr-3', 'mb-3', 'md:mb-0', isMobile ? 'mobile' : '', isPortrait ? 'is-portrait' : '']"
			[hidden]="isMobile"
		>
			<app-tuner
				[isMobile]="isMobile"
				[isPortrait]="isPortrait"
				[isBrowserSafari]="isBrowserSafari"
				(clickClose)="tunerClose()"
				(changeIsPortrait)="changeIsPortrait()"
			></app-tuner>
		</div>
		<div
			*ngIf="isMetronome"
			[ngClass]="['metronome-box', isMobile ? 'mobile' : '', isPortrait && isTuner ? 'is-portrait-and-tuner' : '']"
		>
			<app-metronome [isMobile]="isMobile" (clickClose)="metronomeClose()"></app-metronome>
		</div>
	</div>

	<div *ngIf="isPiano" class="piano-width" [ngClass]="[isMobile ? 'mobile' : '']">
		<app-piano />
	</div>

	<div class="controls-bar flex justify-between self-end">
		<div class="flex grid-item">
			<div class="flex flex-col items-center mr-3">
				<button
					(click)="doToggleTuner(!isTuner)"
					color="primary"
					mat-mini-fab
					[ngClass]="['control-desktop mat-mini-fab', isTuner ? 'toggle-active' : '']"
				>
					<mat-icon [svgIcon]="'tuner'"></mat-icon>
				</button>
				<p class="small-font">{{ "call-controls.tuner" | translate }}</p>
			</div>
			<div class="flex flex-col items-center mr-3">
				<button
					(click)="doToggleMetronome(!isMetronome)"
					color="primary"
					mat-mini-fab
					[ngClass]="['control-desktop mat-mini-fab', isMetronome ? 'toggle-active' : '']"
				>
					<mat-icon [svgIcon]="'metronome'"></mat-icon>
				</button>
				<p class="small-font">{{ "call-controls.metronome" | translate }}</p>
			</div>
			<div *ngIf="!isMobileWidth()" class="flex flex-col items-center mr-3">
				<button
					mat-mini-fab
					color="primary"
					[ngClass]="['control-desktop mat-mini-fab', isPiano ? 'toggle-active' : '']"
					(click)="doTogglePiano(!isPiano)"
				>
					<mat-icon svgIcon="piano"></mat-icon>
				</button>
				<p class="small-font">{{ "call-controls.piano" | translate }}</p>
			</div>
		</div>
		<div>
			<div class="flex flex-col items-center end-btn grid-item">
				<button mat-mini-fab [routerLink]="['/platform/start']" [ngClass]="['control-desktop mat-mini-fab']">
					<mat-icon color="white" class="leave" svgIcon="leave"></mat-icon>
				</button>
				<p class="small-font">Leave Practice Room</p>
			</div>
		</div>
	</div>
</div>

import { Component } from "@angular/core";
import Talk from "talkjs";
import { TalkServiceService } from "./services/talk-service.service";
import { AuthenticationService } from "../services/authentication/authentication.service";
import { ENCODED_LANGUAGES } from "../constants/encodedLanguages-contants";

export interface TalkJsUser {
	id: PropertyKey;
	username: string;
	email: string | null;
	photoUrl: string | null;
	locale: string;
}

@Component({
	selector: "app-talkjs-chat",
	standalone: true,
	imports: [],
	templateUrl: "./talkjs-chat.component.html",
	styleUrl: "./talkjs-chat.component.scss"
})
export class TalkjsChatComponent {
	private chatbox: Talk.Chatbox;
	user: TalkJsUser;

	constructor(
		private talkService: TalkServiceService,
		private authenticationService: AuthenticationService
	) {}

	ngOnInit() {
		this.authenticationService.profile.then((userData) => {
			this.user = {
				id: userData.userId,
				username: `${userData.firstName} ${userData.lastName}`,
				email: userData.email,
				photoUrl: userData.photo || null,
				locale: this.validateLnaguage(userData.languageId)
			};
			this.loadChatbox(this.user);
		});
	}

	async loadChatbox(user) {
		await this.talkService.intializeUser(user);

		const inbox = await this.talkService.createInbox();

		inbox.mount(document.getElementById("talkjs-container"));
	}

	ngOnDestroy() {
		if (this.chatbox) {
			this.chatbox.destroy();
		}
	}

	validateLnaguage(encodedLanguageId: string): string {
		return ENCODED_LANGUAGES[encodedLanguageId];
	}
}

<div class="control-icon-panel">
  <app-icon-cross [userId]="user.id"></app-icon-cross>
  <div class="indicator"
       [class.selected]="!user.isMicro"
       (click)="muteUser()">
    <p class="icon-background"  id="micro-off" [hidden]="user.isMicro"></p>
    <p class="icon-background"  id="micro-on" [hidden]="!user.isMicro"></p>
  </div>
  <div class="indicator"
       [class.selected]="!user.isCamera"
       (click)="hideUser()">
    <p class="icon-background"  id="camera-off" [hidden]="user.isCamera"></p>
    <p class="icon-background"  id="camera-on" [hidden]="!user.isCamera"></p>
  </div>
</div>

import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {AuthenticationService} from '../../../services/authentication/authentication.service';

@Injectable()
export class IsNotAuthorizedGuard  {
  constructor(private auth: AuthenticationService,
              private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return new Observable(obs => {
      this.auth.checkLoggedIn$()
        .toPromise().then((data) => {
          if (data) {
            obs.next(this.routeTo);
          } else {
            obs.next(true);
          }
          obs.complete();
        }, (error) => {
          obs.error(error);
        });
    });
  }
  private get routeTo() {
    return this.router.parseUrl('/admin');
  }
}

export class UserEntryVO {
  constructor() {}
  id: string;
  email: string;
  firstName: string;
  language: string;
  lastName: string;
  timeZone: string;
  password: string;
}

import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	HostListener,
	OnDestroy,
	OnInit
} from "@angular/core";
import { PlatformService } from "../platform/platform.service";
import { Router } from "@angular/router";
import {
	UntypedFormControl,
	UntypedFormGroup,
	Validators
} from "@angular/forms";
import {
	ERROR,
	ROOM,
	VIEW,
	WebSocketService
} from "../services/ws/web-socket.service";
import { AuthenticationService } from "../services/authentication/authentication.service";
import { GA4Service } from "../services/ga4.service";
import { PopupService } from "../services/popup/popup.service";
import { TEXT_NAME_MAX_LENGTH } from "../constants/generic.constants";
import { PersonalRoomVO } from "../services/data/PersonalRoomVO";
import { TranslateService } from "@ngx-translate/core";
import { ButtonType } from "../components/call-room/data/ButtonType";
import { Tariff } from "../types/tariff-constants";
import { Profile } from "../types/profile.types";
import { getSubDomain } from "../helpers";
import { catchError } from "rxjs/operators";
import { of } from "rxjs";
import { logger } from "src/app/lib-core/logger";
import { CallStorage } from "../components/call-room/service/storage/call-storage.service";
import { ClipboardService } from "../services/clipboard.service";
import { VideoSettingsService } from "../video-settings/service/video-settings.service";
import { VENDOR_ID } from "../constants/connection-constants";
import { ProfileService } from "../profile/profile.service";
import { RoomStateStorage } from "../components/call-room/service/storage/RoomStateStorage";
import { environment } from "@env/environment";
import { IntercomService } from "../services/intercom/intercom.service";
import { USER_KEY } from "../constants/localstorage-constants";
// import { HelpScoutService } from "../services/help-scout-service.service";

const NAME_VALIDATION_PATTERN = /^[A-Za-z0-9\s\-]*$/;

declare function typeformGroupRooms(): void;

@Component({
	selector: "app-start",
	templateUrl: "./start.component.html",
	styleUrls: [
		"./start.component.scss",
		"./start.component.tablet.scss",
		"./start.component.mobile.scss"
	]
})
export class StartComponent implements OnInit, OnDestroy {
	roomAccessError: boolean = false;
	linkLengthError: boolean = false;
	isBetaInfoDisplayed: boolean = false;
	enterRoomForm: UntypedFormGroup;
	personalRoomForm: UntypedFormGroup;
	personalRoomData: PersonalRoomVO;
	isPersonalRoomError: boolean = false;
	profile: Profile;
	username: string = "";
	tariff: Tariff;
	isMusicModal = false;

	constructor(
		public platformService: PlatformService,
		private webSocketService: WebSocketService,
		private router: Router,
		private ga4: GA4Service,
		private popupService: PopupService,
		private translateService: TranslateService,
		private cd: ChangeDetectorRef,
		public clipboardService: ClipboardService,
		private authenticationService: AuthenticationService,
		private callStorage: CallStorage,
		private videoSettingsService: VideoSettingsService,
		private profileService: ProfileService,
		private roomState: RoomStateStorage,
		private intercom: IntercomService
		// private helpScoutService: HelpScoutService
	) {
		typeformGroupRooms();

		this.enterRoomForm = new UntypedFormGroup({
			roomLink: new UntypedFormControl("", [
				Validators.required,
				Validators.minLength(1)
			])
		});
		this.personalRoomForm = new UntypedFormGroup({
			roomName: new UntypedFormControl("", [
				Validators.required,
				Validators.minLength(1),
				Validators.maxLength(TEXT_NAME_MAX_LENGTH),
				Validators.pattern(NAME_VALIDATION_PATTERN)
			]),
			roomPassword: new UntypedFormControl("", [
				Validators.required,
				Validators.minLength(4),
				Validators.maxLength(TEXT_NAME_MAX_LENGTH)
			])
		});
		logger.log("&StartComponent(); webSocketService.subscribe(ROOM)");
		this.webSocketService.subscribe(ROOM, this.onSocketEvent);
	}

	private cleanRoomLeftoverData(): void {
		sessionStorage.removeItem("isLive");
		sessionStorage.removeItem("reconnectView");
		sessionStorage.removeItem("reconnectJoin");
		sessionStorage.removeItem("reconnectTurn");
		sessionStorage.removeItem("roomPassword");
		sessionStorage.removeItem("roomUrl");
		sessionStorage.removeItem("hidePassword");
	}

	async ngOnInit() {
		localStorage.removeItem("subrole");
		this.intercom.hideIntercom(false);
		this.profile = await this.authenticationService.profile;
		this.username = this.profile.firstName;

		this.cleanRoomLeftoverData();

		// await this.helpScoutService.showBeaconWithId();

		if (this.platformService.hasEnteredRoom) {
			sessionStorage.removeItem("isLive");
			this.platformService.hasEnteredRoom = false;
		}

		this.isBetaInfoDisplayed =
			!getSubDomain() && this.profile.subscription.type === Tariff.FREE;
		this.getPersonalRoom();
		// @ts-ignore
		Paddle.Setup({
			vendor: VENDOR_ID,
			eventCallback: async (data) => {
				if (data.event === "Checkout.Complete") {
					if (!!this.ga4.hasConsent) {
						if (
							data.eventData.product.name ==
							`PRO (jährlich abgerechnet / yearly billed)`
						) {
							this.ga4.yearlyAccount();
						} else {
							this.ga4.monthlyAccount();
						}
					}
				}
				if (
					data.event === "Checkout.Close" &&
					data.eventData.checkout.completed
				) {
					this.authenticationService.resetProfile();
					this.isBetaInfoDisplayed = false;
					this.profile = await this.authenticationService.profile;
				}
			}
		});
	}
	@HostListener("window:unload", ["$event"])
	unloadHandler(event) {
		this.ngOnDestroy();
	}

	/**
	 * Name requirements are:
	 * When entering a uppercase letter it should automatically convert it to a lowercase letter.
	 * When entering a space it should automatically convert it to "-"
	 */

	updateName(): void {
		this.personalRoomForm.patchValue({
			roomName: this.personalRoomForm.value.roomName
				.replaceAll(" ", "-")
				.toLowerCase()
		});
	}

	// #private showTariffExpiredPopup(countDaysTariffFree: number): void {
	// #  this.popupService.openExpirationFreePlanPopup(countDaysTariffFree)
	// #    .then((response: boolean) => {
	// #      if (response) {
	// #        this.router.navigate(['/platform/subscription']);
	// #      }
	// #    });
	// #}

	/**
	 * Password requirements are:
	 * When entering a space it should automatically convert it to "-"
	 */

	updatePassword(): void {
		this.personalRoomForm.patchValue({
			roomPassword: this.personalRoomForm.value.roomPassword.replaceAll(
				/[`@#$%^&+={}|\-\[\];:\'\"<>.,\s\/\\\?-_]/g,
				""
			)
		});
	}

	//   async showBeacon(): Promise<void> {

	//     const subrole = await this.authenticationService.getSubrole();
	//     const lang = this.translateService.currentLang;
	//     if (lang === "deu") {
	//       helpdeskBeaconGerman();
	//     } else {
	//       helpdeskBeaconEnglish();
	//     }

	//     if (!getSubDomain()) {
	//       Beacon('open');
	//       Beacon('identify', {
	//         name: this.profile.firstname + ' ' + this.profile.lastname,
	//         email: this.profile.email,
	//         language: this.translateService.currentLang,
	//         subscription: this.profile.subscription.type,
	//         subrole: subrole
	//       });
	//       Beacon('close');
	//     }
	//   }

	closeBetaInfo(): void {
		this.isBetaInfoDisplayed = false;
	}

	ngOnDestroy() {
		// this.helpScoutService.destroyBeacon();

		this.videoSettingsService.fromStartPage = true;
		this.videoSettingsService.isUserNameEntered = false;
		try {
			this.webSocketService.unSubscribe(ROOM, this.onSocketEvent);
		} catch (error) {
			console.error("Error unsubscribing: ", error);
		}
		this.roomAccessError = false;
	}

	enterRoom(): void {
		const roomArray = this.enterRoomForm.value.roomLink.split("/");
		const roomLink = roomArray[roomArray.length - 1].trim();
		// #const personalRoomLink = (this.personalRoomData && this.personalRoomData.link) ?
		// #  this.personalRoomData.link.substring(this.personalRoomData.link.lastIndexOf('/') + 1) :
		// #  null;
		// #// User with expired tariff tries to enter his personal room
		// #if (
		// #  this.profile.subscription.type !== Tariff.INSTITUTIONS &&
		// #  this.profile.subscription.expired &&
		// #  roomLink === personalRoomLink
		// #) {
		// #  const countDaysTariffFree: number = this.profile.subscription.left;
		// #  this.showTariffExpiredPopup(countDaysTariffFree);
		// #} else {
		sessionStorage.removeItem("roomPassword");
		const roomId = roomLink.split("?")[0];
		const roomPassword = roomLink.split("?password=")[1];
		this.router.navigate([roomId], {
			queryParams: { password: roomPassword }
		});
		// #}
	}

	private onSocketEvent = (data) => {
		switch (data[0]._event) {
			case VIEW:
				logger.log("&Start.event() VIEW", data[0]);
				this.roomAccessError = false;
				this.webSocketService.unSubscribe(ROOM, this.onSocketEvent);
				break;
			case ERROR:
				logger.log("&Start.event() ERROR", data[0]);
				this.roomAccessError = true;
				break;
			// default:
			//   logger.log('&Start.event() default event', data[0]._event);
			//   break;
		}
	};

	resetValues(): void {
		this.linkLengthError = false;
		if (this.roomAccessError) {
			this.enterRoomForm.reset();
			this.roomAccessError = false;
		}
	}

	resetPersonalRoomValues(): void {
		if (this.isPersonalRoomError) {
			this.enterRoomForm.reset();
			this.isPersonalRoomError = false;
		}
	}

	checkLinkLength(): void {
		this.linkLengthError =
			!!this.enterRoomForm.value.roomLink &&
			this.enterRoomForm.value.roomLink.length > 0 &&
			this.enterRoomForm.value.roomLink.length < 1;
	}

	siriusNow(): void {
		this.platformService
			.checkLoggedIn$()
			.toPromise()
			.then((loggedIn) => {
				if (!!loggedIn) {
					this.platformService.siriusNow().then(async (roomUrl) => {
						this.platformService.triggerLoader(3000); // Longer load because after Sirius Now we are redirected to Settings
						await this.router.navigate([roomUrl]);
					});
				} else {
					this.router.navigate(["/login"]);
				}
			});
	}
	siriusLater(): void {
		this.platformService.siriusFuture().then((data) => {
			this.popupService.openRoomInfoDialog(data);
		});
	}
	siriusPersonal(): void {
		if (!this.personalRoomForm.valid) {
			logger.log("personal room form is invalid ");
		} else {
			logger.log("personal room form is valid ");
			// #if (
			// #  this.profile.subscription.type !== Tariff.INSTITUTIONS &&
			// #  this.profile.subscription.expired
			// #) {
			// #  const countDaysTariffFree: number = this.profile.subscription.left;
			// #  this.showTariffExpiredPopup(countDaysTariffFree);
			// #} else {
			const { roomName, roomPassword } = this.personalRoomForm.value;

			this.platformService
				.addPersonalRoom$(roomName, roomPassword)
				.subscribe(
					(response: any) => {
						// @todo: remove duplication of code
						this.personalRoomData = new PersonalRoomVO();
						this.personalRoomData.id = response._id;
						this.personalRoomData.link = response.target.replace(
							/^(https?|ftp):\/\//,
							""
						);
						this.personalRoomData.password = response.password;
						this.isPersonalRoomError = false;
						this.personalRoomForm.reset();

						localStorage.setItem(
							"personalRoom",
							JSON.stringify(this.personalRoomData)
						);
					},
					(error) => {
						console.error("addPersonalRoom$ error: ", error);
						this.isPersonalRoomError = true;
					}
				);
			// #}
		}
	}

	copyLink(): void {
		this.clipboardService.copyLink(
			this.personalRoomData.link,
			this.personalRoomData.password
		);
	}

	getPersonalRoom(): void {
		this.platformService
			.getPersonalRoom$()
			.pipe(catchError((error) => of(console.error(error))))
			.subscribe((data: any) => {
				if (data) {
					this.personalRoomData = new PersonalRoomVO();
					this.personalRoomData.id = data.id;
					this.personalRoomData.link = data.target.replace(
						/^(https?|ftp):\/\//,
						""
					);
					this.personalRoomData.password = data.password;
					localStorage.setItem(
						"personalRoom",
						JSON.stringify(this.personalRoomData)
					);
					localStorage.setItem("bgColor", data.styles);
				} else {
					this.personalRoomData = null;
				}
				this.cd.detectChanges();
			});
	}

	startPersonalRoom(): void {
		// #if (
		// #  this.profile.subscription.type !== Tariff.INSTITUTIONS &&
		// #  this.profile.subscription.expired
		// #) {
		// #  const countDaysTariffFree: number = this.profile.subscription.left;
		// #  this.showTariffExpiredPopup(countDaysTariffFree);
		// #} else {
		sessionStorage.roomUrl = "https://" + this.personalRoomData.link;
		sessionStorage.roomPassword = this.personalRoomData.password;
		const roomLink = this.personalRoomData.link.split("?")[0].split("/")[1];
		sessionStorage.setItem(USER_KEY, this.username);

		this.router.navigate([roomLink]).then(() => {
			this.platformService.triggerLoader();
			if (this.roomState.hasFinishedCall$.getValue()) {
				window.location.reload();
			}
		});
		// #}
	}

	deletePersonalRoom(): void {
		this.popupService.openCustomMessagePopup(
			"start.personal-confirmation-title",
			[
				{
					type: ButtonType.OK,
					text: "buttons.room-edit",
					callBack: () => {
						this.platformService
							.deletePersonalRoom$(this.personalRoomData.id)
							.subscribe(
								(response) => {
									localStorage.removeItem("personalRoom");
									this.getPersonalRoom();
								},
								(error) => {
									console.error("Deleting error. ", error);
								}
							);
					}
				},
				{
					type: ButtonType.CANCEL,
					text: "buttons.cancel"
				}
			]
		);
	}

	toggleTitle(title, instruction) {
		if (!Array.from(title.firstChild.classList).includes("down")) {
			title.firstChild.classList.add("down");
			instruction.style.display = "block";
		} else {
			title.firstChild.classList.remove("down");
			instruction.style.display = "none";
		}
	}

	public setTariffPro(): void {
		this.profileService
			.getPuddleOverrideUrl(this.profile.email)
			.subscribe((data) => {
				if (data && data.response) {
					// @ts-ignore
					Paddle.Checkout.open({
						override: data.response.url,
						passthrough: { user_id: this.profile.userId }
					});
				}
			});
	}
	showLessonManagement(): boolean {
		const value = localStorage.getItem("show-lesson-management");
		return value === "true";
	}

	showAvailabilityCalendar() {
		this.popupService.openAvailabilityCalendar();
	}
}

import { Component, EventEmitter, Input, Output } from "@angular/core";
import { logger } from "src/app/lib-core/logger";
import { PlatformDetectorService } from "src/app/services/platform-detector/platform-detector.service";
import { log } from "tone/build/esm/core/util/Debug";

@Component({
  selector: "app-device-browser",
  templateUrl: "./device-browser.component.html",
})
export class DeviceBrowserComponent {
  @Output() complete = new EventEmitter();
  @Input() deviceInfo;

  onCompleted(): void {
    this.complete.emit();
  }

  get isDesktop(): boolean {
    return this.deviceType === "desktop";
  }

  get isChrome(): boolean {
    return this.browser === "Chrome";
  }

  get deviceType(): string {
    return this.deviceInfo.deviceType;
  }

  get browser(): string {
    return this.deviceInfo.browser;
  }

  get os(): string {
    return this.deviceInfo.os;
  }

  get googleDownloadLink(): string {
    if (
      this.os === "android" &&
      (this.deviceType === "mobile" || this.deviceType === "tablet")
    ) {
      return "https://play.google.com/store/apps/details?id=com.android.chrome&hl=de";
    } else {
      return "https://www.google.com/chrome/";
    }
  }

  get badBrowserMessage(): string {
    if (this.os === "Android") {
      return "setup.device-browser-tab.bad-browser-message-android";
    } else if (this.os === "iOS") {
      return "setup.device-browser-tab.bad-browser-message-ios";
    } else {
      return "setup.device-browser-tab.bad-browser-message-desktop";
    }
  }
  get goodBrowserMessage(): string {
    if (this.os === "Android") {
      return "setup.device-browser-tab.good-browser-message-android";
    } else if (this.os === "iOS") {
      return "setup.device-browser-tab.good-browser-message-ios";
    } else {
      return "setup.device-browser-tab.good-browser-message-desktop";
    }
  }

  get isGoodBrowser(): boolean {
    if (this.deviceType === "desktop") {
      return this.browser === "Chrome";
    } else if (this.os === "Android") {
      return this.browser === "Chrome";
    } else if (this.os === "iOS") {
      return this.browser === "Safari";
    } else {
      return false;
    }
  }
}

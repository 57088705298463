 import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  TemplateRef,
  ViewContainerRef,
} from "@angular/core";
import { AuthenticationService } from "../services/authentication/authentication.service";
import { SUBROLES } from "../constants/subroles-constants";

@Directive({
  selector: "[showForSubroles]",
})
export class ShowForSubrolesDirective {
  @Input() showForSubroles: string[];
  constructor(
    private readonly authenticationService: AuthenticationService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {}

  async ngOnInit() {
    const { subrole } = await this.authenticationService.profile;
    if (this.showForSubroles.includes(SUBROLES[subrole])) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}

@Directive({
  selector: "[hideForSubroles]",
})
export class HideForSubrolesDirective {
  @Input() hideForSubroles: string[];
  constructor(
    private readonly authenticationService: AuthenticationService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {}

  async ngOnInit() {
    const { subrole } = await this.authenticationService.profile;
    if (this.hideForSubroles.includes(SUBROLES[subrole])) {
      this.viewContainer.clear();
    } else {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }
}

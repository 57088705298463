<button class="tn-btn-close tuner-close-button" (click)="close()" (touchstart)="close()">
	<mat-icon>close</mat-icon>
</button>

<div class="tn-separator"></div>
<div [ngClass]="['tn-meter', isMobile ? 'is-mobile' : '']">
	<div class="tn-meter-dot"></div>
	<div #materPointer class="tn-meter-pointer"></div>
	<ng-container *ngFor="let meterScale of meterScaleList; trackBy: trackByMeterScale">
		<div
			[ngClass]="[
				'tn-meter-scale',
				isMeterScaleStrong(meterScale) ? 'tn-meter-scale-strong' : '',
				'tn-meter-scale-rotate' + meterScale
			]"
		></div>
	</ng-container>
</div>

<div class="flex justify-between items-center mt-3 px-2">
	<span class="flex align-middle flex-1 text-xs">440hz</span> <span class="text-3xl font-bold flex-1 text-center">{{ noteName }}</span>
	<span class="text-right flex-1 text-xs">{{ "tuner.octave" | translate }}: {{ octave }}</span>
</div>

<!-- <div class="tn-notes">
  <div [ngClass]="['tn-notes-list', (isMobile ? 'is-mobile' : '')]" #notesList>
    <ng-container *ngFor="let noteData of noteDataList; trackBy: trackByNoteData">
      <div [ngClass]="['tn-note', (noteData == noteDataActive ? 'active' : '')]" [attr.data-name]="noteData.name"
        [attr.data-value]="noteData.value" [attr.data-octave]="noteData.octave"
        [attr.data-frequency]="noteData.frequency">
        <div [ngClass]="['tn-note-name', (isMobile ? 'is-mobile' : '')]">{{ noteData.name[0] }}</div>
        <span class="tn-note-sharp up">{{ noteData.name[1] || '' }}</span>
        <span class="tn-note-sharp down">{{ noteData.octave }}</span>
      </div>
    </ng-container>
  </div>
  <ng-container *ngFor="let noteData of noteDataList; trackBy: trackByNoteData">
    <div>
    </div>
  </ng-container>

  <div [ngClass]="['tn-frequency-and-a4-value', (isMobile ? 'is-mobile' : '')]">
    <div [ngClass]="['tn-set-a4-value', (isMobile ? 'is-mobile' : '')]">
      <div [ngClass]="['tn-a4-value-text', (isMobile ? 'is-mobile' : '')]">
        <span>A<sub>4</sub>=</span>&nbsp;<span class="tn-red">{{ a4Value }}</span>&nbsp;<span>{{ 'tuner.hertz' |
          translate }}</span>
      </div>
      <button (click)="changeA4Value(a4Value - 1, true)">
        <div class="tn-icon tn-a4-value-dec"></div>
      </button>
      <button (click)="changeA4Value(a4Value + 1, true)">
        <div class="tn-icon tn-a4-value-inc"></div>
      </button>
    </div>
    <div [ngClass]="['tn-frequency', (isMobile ? 'is-mobile' : '')]">
      {{ frequencyValue }}<span class="tn-fn80">{{ 'tuner.hertz' | translate }}</span>
    </div>
  </div>
</div> -->

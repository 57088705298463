import {Component, HostListener, Input} from '@angular/core';
import {ButtonType} from '../components/call-room/data/ButtonType';
import {AFFECT, KICK, WebSocketService} from '../services/ws/web-socket.service';
import {TranslateService} from '@ngx-translate/core';
import {PopupService} from '../services/popup/popup.service';
import {SELF_VIDEO} from '../components/call-room/data/local-constants';
import {UserVO} from '../services/data/UserVO';
import {CallStorage} from '../components/call-room/service/storage/call-storage.service';

@Component({
  selector: 'app-icon-cross',
  templateUrl: './close.svg',
  styleUrls: ['./cross-icon.component.scss']
})
export class CrossIconComponent {
  @Input() fillColor: string = '#420075';
  @Input() textColor: string = '#ffffff';
  @Input() set userId(id: string) {
    if (id !== SELF_VIDEO) {
      this.user = this.callStorage.usersStorage.getUser(id);
    }
  }
  private user: UserVO = new UserVO();

  constructor(private translateService: TranslateService,
              private popupService: PopupService,
              private webSocketService: WebSocketService,
              private callStorage: CallStorage) {}

  @HostListener('click')
  kickUser() {
    this.translateService.get(['popUp.remove', 'popUp.fromCall', 'popUp.cancel', 'popUp.remove']).subscribe(data => {
      const message = `${data['popUp.remove']} ${this.user.name} ${data['popUp.fromCall']}`;
      this.popupService.openCustomMessagePopup(message,
        [ {
          type: ButtonType.OK,
          text: data['popUp.cancel'],
        }, {
          type: ButtonType.CANCEL,
          text: data['popUp.remove'],
          callBack: () => {
            this.webSocketService.dispatch(AFFECT, {option: KICK, target: this.user.id});
          },
        }]);
    });
  }
}

import { Component,Input, Output,EventEmitter, HostListener} from '@angular/core';
import { PianoService } from '../service/piano.service';
import { EventSocketService } from '../../services/ws/event-socket.service';
import { CallStorage } from '../../components/call-room/service/storage/call-storage.service';

@Component({
  selector: 'app-piano-key',
  templateUrl: './piano-key.component.html',
  styleUrls: ['./piano-key.component.scss']
})

export class PianoKeyComponent {
  constructor(private pianoService: PianoService, private eventSocketService: EventSocketService, private callStorage:CallStorage) { }
  get sharpKey() { return !this.note || this.note.endsWith("#"); }
  get keyName(): string { return `${this.note}${this.index}` };

  @Input() note: string;
  @Input() index: number;
  @Input() key: string;

  @Output() readonly triggeredNote: EventEmitter<string> = new EventEmitter();
  @Output() readonly releasedNote: EventEmitter<string> = new EventEmitter();

  public isActive: boolean = false;
  public isActiveWS: boolean = false;
  noteSound = null;
  public samplerIsLoaded: boolean = false;


  ngOnInit() {
    // We register this keys using the piano service so we can play it using the keyboard
    this.pianoService.registerPianoKey(this);
  }

  ngOnDestroy() {
    // console.log("DESTROYED Key")
    // this.sampler.dispose()
  }
  // Load the sounds async after the view has been rendered
  ngAfterViewChecked() {}

  fired = false;
  firedws = false;

  onPianoKeyDown(event: Event) {
    if(!this.fired) {
      this.triggeredNote.emit(this.keyName);
      if (event instanceof MouseEvent) {
      this.pianoService.mouseDownWs(this.note,this.index);
      }
      this.isActive = true;
      this.fired = true;
    }
  }

  onPianoKeyUp(event: Event) {
    this.releasedNote.emit(this.keyName);
    if (event instanceof MouseEvent) {
    this.pianoService.mouseUpWs(this.note,this.index);
    }
    this.fired = false;
    this.isActive = false;
  }

  onPianoKeyDownMidi() {
    if(!this.fired) {
    this.triggeredNote.emit(this.keyName);
    this.isActive = true;
    this.fired = true;
    }
  }

  onPianoKeyUpMidi() {
    this.fired = false;
    this.isActive = false;
    this.releasedNote.emit(this.keyName);
  }

  onPianoKeyDownWS() {
    if(!this.firedws) {
    this.triggeredNote.emit(this.keyName);
    this.isActiveWS = true;
    this.firedws = true;
    }
  }
    
  onPianoKeyDownUPWS() {
    this.releasedNote.emit(this.keyName);
    this.isActiveWS = false;
    this.firedws = false;
  }

  @HostListener('document:mouseup', ['$event'])
  onMouseUp(event: MouseEvent) {
    this.pianoService.mouseUpWs(this.note,this.index);
    this.fired = false;
    this.isActive = false;
    this.releasedNote.emit(this.keyName);
  }

}

// Every Key knows it note, so you choose the note in array to triggerd it from outside
import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {CONTACT_URL} from '../../../../constants/generic.constants';

@Component({
  selector: 'app-dealer-redirect',
  templateUrl: './dealer-redirect.component.html',
  styleUrls: ['./dealer-redirect.component.scss']
})
export class DealerRedirectComponent implements OnInit {
  redirectLink = CONTACT_URL;
  constructor(@Inject(MAT_DIALOG_DATA) redirectLink: string,
              public translate: TranslateService) {
    this.redirectLink = redirectLink;
  }

  ngOnInit() {
  }

  redirect(): void {
    window.location.href = this.redirectLink;
  }

}

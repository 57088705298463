import { HttpClient } from "@angular/common/http";
import { Component, EventEmitter, Output } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
// import { logger } from "src/app/lib-core/logger";
import { SpeedTestComponent } from "src/app/speed-test/speed-test.component";
import { GA4Service } from 'src/app/services/ga4.service';

@Component({
  selector: "app-network-speed",
  templateUrl: "./network-speed.component.html",
})
export class NetworkSpeedComponent extends SpeedTestComponent {
  @Output() complete = new EventEmitter();

  constructor(
    protected translateService: TranslateService,
    protected http: HttpClient,
    protected ga4: GA4Service
  ) {
    super(translateService, http,ga4);
    this.latestResult = null;
  }

  get testProgressPercentage(): number {
    const elapsedSeconds = +((performance.now() - this.testStartTime) / 1000);
    const timeElapsedPercentage = Math.ceil(
      (elapsedSeconds / this.uploadTimeoutSeconds || 0) * 100
    );
    const uploadedBytesPercentage = Math.ceil(
      (+this.testBytesUploaded / this.uploadTotalBytes || 0) * 100
    );
    const progressPercentage = Math.max(
      uploadedBytesPercentage,
      timeElapsedPercentage
    );
    return uploadedBytesPercentage ? progressPercentage : 0;
  }

  get connectionQuality(): string {
    return this.formatSpeed(this.latestResult.speed);
  }

  onCompleted(): void {
    this.complete.emit();
  }
}

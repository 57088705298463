import {
	AfterViewInit,
	Component,
	ElementRef,
	Input,
	OnInit,
	ViewChild
} from "@angular/core";
import { MatSidenav } from "@angular/material/sidenav";
import { ChatService } from "./services/chat.service";
import { PlatformDetectorService } from "../../services/platform-detector/platform-detector.service";
import { PopupService } from "../../services/popup/popup.service";
import { logger } from "src/app/lib-core/logger";
import { SEND, WebSocketService } from "../../services/ws/web-socket.service";
import { TranslateService } from "@ngx-translate/core";
import { getFileTypeByExtension } from "../../helpers/getFileTypeByExtension";

@Component({
	selector: "app-chat",
	templateUrl: "./chat.component.html",
	styleUrls: ["./chat.component.scss"]
})
export class ChatComponent implements OnInit, AfterViewInit {
	@Input() userName: string;
	@Input() displayChat: boolean;
	@ViewChild("chatBody") chatBody: ElementRef;
	@ViewChild("chatUI") chatUI: MatSidenav;
	@ViewChild("uploadInput", { static: true }) uploadInput: ElementRef;
	@ViewChild("uploadButton", { static: true }) uploadButton: ElementRef;
	isLoading: boolean = false;

	constructor(
		private chatService: ChatService,
		protected platformDetectorService: PlatformDetectorService,
		private popupService: PopupService,
		private translateService: TranslateService,
		protected websocket: WebSocketService
	) {}

	currentMessage: string = "";
	chatMessages: any[] = [];
	isStandaloneChat: boolean = false;
	fileSizeWarning: string;
	// Device detection
	isDesktop: boolean = true;
	isMobile: boolean = false;
	isTablet: boolean = false;

	protected fileHandler() {
		const fileElement: any = document.getElementById("upload-input");
		this.parseFiles(fileElement.files);
		fileElement.value = "";
	}
	getFile($event) {
		this.parseFiles($event);
		logger.log($event);
	}
	private parseFiles(input) {
		const files = [];
		this.isLoading = true;
		for (const file of input) {
			let fileType = file.type;
			if (!fileType) {
				const extension = file.name.split(".").pop();
				fileType = getFileTypeByExtension(extension);
			}
			files.push({
				body: file,
				userFileName: file.name,
				fileSize: file.size,
				fileType: fileType,
				context: "chat"
			});
		}

		const filesSize = files.reduce((a, b) => +a + +b.fileSize, 0);

		if (filesSize > 50000000) {
			this.isLoading = false;
			this.popupService.openWarningPopup(this.fileSizeWarning).then();
			return;
		}

		this.websocket.dispatch(SEND, { files });
	}

	ngOnInit() {
		this.isMobile = this.platformDetectorService.isMobile();
		this.isTablet = this.platformDetectorService.isTablet();
		this.isDesktop = this.platformDetectorService.isDesktop();
		this.translateService
			.stream("chat.fileSizeWarning")
			.subscribe((text: string) => {
				this.fileSizeWarning = text;
			});
		this.chatMessages = this.chatService.chatMessages; // in case if messages were received by service before this component inited;
		this.chatService.chatMessages$().subscribe((messages: any) => {
			this.chatMessages = messages;
			if (
				this.chatMessages.length > 0 &&
				this.chatMessages[this.chatMessages.length - 1].type !== "text"
			) {
				this.isLoading = false;
			}
		});
		this.chatService.chatScroll$().subscribe((down) => {
			if (down) {
				this.chatScrollToBottom();
			} else {
				this.chatBody.nativeElement.scrollTop = 1;
			}
		});
		this.chatService.getDisplayChat$().next(this.displayChat);
		this.uploadInput.nativeElement.addEventListener(
			"change",
			this.fileHandler.bind(this),
			false
		);
		// if (!window.sessionStorage.getItem('accessToken')) { // try to load history if student. teacher will request messages from server
		//   this.chatService.loadMessagesFromHistory();
		// }
	}

	ngAfterViewInit() {
		this.chatUI.openedStart.subscribe(() => {
			this.chatScrollToBottom();
		});
	}

	toggleChat(): void {
		if (this.displayChat) {
			this.chatUI.open();
		} else {
			this.chatUI.close();
		}
		this.displayChat = !this.displayChat;
		this.chatService.getDisplayChat$().next(this.displayChat);
	}

	// ToDo: Find out how we can hook it onto the browser event instead of timeout
	private chatScrollToBottom(): void {
		setTimeout(() => {
			if (this.chatBody) {
				this.chatBody.nativeElement.scrollTop =
					this.chatBody.nativeElement.scrollHeight;
			}
		}, 300);
	}

	chatScrollHandler() {
		if (this.chatBody && this.chatBody.nativeElement.scrollTop === 0) {
			if (
				this.chatService.getCurrentPage() <
				this.chatService.getMaxPages()
			) {
				this.chatService.requestNextChatPage();
			}
		}
	}

	sendMessage() {
		this.currentMessage = this.currentMessage.trim();
		if (this.currentMessage.length > 0) {
			if (this.currentMessage.includes("base64")) {
				// Display popup here to warn customer that we don't support base64 yet
				this.popupService.openWarningPopup(
					"We could not send a picture, because currently " +
						"'base64' format is not supported. \nThis functionality is in development."
				); // TODO: translate
				// Remove long base64 link
				// Regexp for different combinations of ' ' and '\n' with at least one space and 0 or more '\n'
				const regexpSeparators = /\s*\n*\s+\n*/;
				this.currentMessage = this.currentMessage
					.split(regexpSeparators)
					.filter((item) => !item.includes("base64"))
					.join(" ");
			}

			let linkStartIndex = this.currentMessage.indexOf("http");
			linkStartIndex =
				linkStartIndex > -1
					? linkStartIndex
					: this.currentMessage.indexOf("www");

			if (linkStartIndex >= 0) {
				let linkEndIndex = this.currentMessage.indexOf(
					" ",
					linkStartIndex
				);
				linkEndIndex =
					linkEndIndex > -1
						? linkEndIndex
						: this.currentMessage.length;

				const link = this.currentMessage.substring(
					linkStartIndex,
					linkEndIndex
				);

				if (link.length > 1000) {
					this.popupService.openWarningPopup(
						"Sorry, but one or more links you have sent were not rendered correctly. \nPlease send the link in a separate message and, if necessary, use link compression services."
					); // TODO: translate
				} else {
					const preLinkMessage = this.currentMessage
						.substring(0, linkStartIndex)
						.trim();
					if (preLinkMessage.length > 0) {
						this.chatService.sendSocketMessage(preLinkMessage);
					}

					this.chatService.sendSocketMessage(link);

					const postLinkMessage = this.currentMessage
						.substring(linkEndIndex)
						.trim();
					if (postLinkMessage.length > 0) {
						this.currentMessage = postLinkMessage;
						this.sendMessage();
					}
				}
			} else {
				this.chatService.sendSocketMessage(this.currentMessage);
			}

			this.currentMessage = "";
		}
	}

	onFocus(): void {
		this.chatService.chatInputStatus = true;
	}

	onBlur(): void {
		this.chatService.chatInputStatus = false;
	}

	processKeyPress(event): void {
		// logger.log('processKeyPress', event); // Uncomment for debug
		if (event.keyCode === 13 && event.shiftKey) {
			return;
		} else if (event.keyCode === 13) {
			event.preventDefault();
			this.sendMessage();
		}
	}

	clearMessages(): void {
		this.chatService.openDeleteChatPopup();
	}
}

import { Injectable } from '@angular/core';


@Injectable()
export class EchoDetectorService {
  analyserNode: AnalyserNode;
  dataArray: Uint8Array;
  bufferSize;
  echoDetected = false;
  threshold;
  filter: AdaptiveFilter;
  sampleRate;

  constructor() {}

  registerAdaptiveFilter(stream: MediaStream) {
    const audioContext = new AudioContext();
    const source = audioContext.createMediaStreamSource(stream);
    this.analyserNode = audioContext.createAnalyser();
    this.bufferSize = 1024;
    const decay = 0.5; // Decay coefficient
    this.threshold = 0.1; // Echo detection threshold
    this.sampleRate = audioContext.sampleRate;
    this.filter = new AdaptiveFilter(this.bufferSize, decay);
    this.echoDetected = false;
    source.connect(this.analyserNode);
  }

  detectEcho() {
    const input = new Float32Array(this.bufferSize);
    this.analyserNode.getFloatTimeDomainData(input);

    const output = this.filter.filter(input);

    const correlation = this.calculateCorrelation(output, input);
    console.log(correlation, 'correlation');
    if (correlation > this.threshold) {
      console.log('%c Echo detected!', 'background: orange; color: black');
      this.echoDetected = true;
    } else {
      this.echoDetected = false;
    }
  }
  calculateCorrelation(signal1: Float32Array, signal2: Float32Array): number {
    if (signal1.length !== signal2.length) {
      throw new Error('The lengths of the signals must be equal.');
    }

    const n = signal1.length;
    let sumXY = 0;
    let sumX = 0;
    let sumY = 0;
    let sumXSquare = 0;
    let sumYSquare = 0;
    for (let i = 0; i < n; i++) {
      const x = signal1[i];
      const y = signal2[i];

      if (isNaN(x) || isNaN(y)) {
        throw new Error('Signal contains invalid values.');
      }

      sumXY += x * y;
      sumX += x;
      sumY += y;
      sumXSquare += x * x;
      sumYSquare += y * y;
    }

    const numerator = n * sumXY - sumX * sumY;
    const denominator = Math.sqrt((n * sumXSquare - sumX * sumX) * (n * sumYSquare - sumY * sumY));

    if (denominator === 0 || isNaN(denominator)) {
      return 0;
    }

    return numerator / denominator;
  }

}

class AdaptiveFilter {
  private bufferSize: number;
  private decay: number;
  private buffer: Float32Array;
  private weights: Float32Array;

  constructor(bufferSize: number, decay: number) {
    this.bufferSize = bufferSize;
    this.decay = decay;
    this.buffer = new Float32Array(bufferSize);
    this.weights = new Float32Array(bufferSize);
    this.reset();
  }

  reset() {
    this.buffer.fill(0);
    this.weights.fill(0);
  }

  filter(input: Float32Array): Float32Array {
    const output = new Float32Array(this.bufferSize);
    const error = new Float32Array(this.bufferSize);

    for (let i = 0; i < this.bufferSize; i++) {
      output[i] = this.weights[i] * this.buffer[i];
      error[i] = input[i] - output[i];

      if (!Number.isNaN(error[i]) && Number.isFinite(error[i])) {
        this.weights[i] += this.decay * error[i] * this.buffer[i];
      }
    }

    this.buffer.copyWithin(0, 1);
    this.buffer[this.bufferSize - 1] = input[this.bufferSize - 1];

    return output;
  }
}




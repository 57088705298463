<form autocomplete="off" [formGroup]="notificationSettingsForm">
	<!-- <h1>{{ "notifications-settings.title" | translate }}</h1> -->

	<ul>
		<li class="list-none flex justify-between py-2.5">
			<div class="font-bold">
				{{ "notifications-settings.notify-me" | translate }}
			</div>
			<div class="w-20 flex justify-center">Email</div>
		</li>
		<ng-container formGroupName="trial">
			<li *ngFor="let option of notificationOptions" class="list-none flex justify-between py-2.5 border-t">
				<div class="option-title" [innerHTML]="
						getTitle('trial', option) | translate | safeHtml
					"></div>
				<div class="w-20 flex justify-center">
					<input [formControlName]="option" class="checkbox" type="checkbox" />
				</div>
			</li>
		</ng-container>
		<ng-container formGroupName="single">
			<ng-container *ngFor="let option of notificationOptions">
				<li *ngIf="option !== 'cancellation'" class="list-none flex justify-between py-2.5 border-t">
					<div class="option-title" [innerHTML]="
							getTitle('single', option) | translate | safeHtml
						"></div>
					<div class="w-20 flex justify-center">
						<input [formControlName]="option" class="checkbox" type="checkbox" />
					</div>
				</li>
			</ng-container>
		</ng-container>
	</ul>

	<div class="flex justify-end w-full items-center mt-8">
		<span *ngIf="isSavedMessage" class="text-sirius-lilac mr-2">
			{{ "buttons.saved" | translate }}
		</span>
		<button [disabled]="!isDirty" class="s-mat-button text-center" mat-flat-button color="accent" (click)="saveNotificationSettings()">
			{{ "buttons.save-changes" | translate }}
		</button>

	</div>
</form>
import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostBinding,
  HostListener,
} from "@angular/core";
import { PlatformDetectorService } from "src/app/services/platform-detector/platform-detector.service";

@Directive({
  selector: "[mainPanel]",
})
export class MainPanelDirective implements AfterViewInit {
  private observer: ResizeObserver;
  private height: number;
  private width: number;

  @HostListener("window:resize") changeMargin() {
    this.positionPanel();
  }

  constructor(
    private elementRef: ElementRef,
    private platformService: PlatformDetectorService
  ) {}

  ngAfterViewInit(): void {
    this.observer = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        this.width = entry.contentRect.width;
        this.height = entry.contentRect.height;
        this.positionPanel();
      });
    });
    this.observer.observe(this.elementRef.nativeElement);
  }

  positionPanel() {
    let marginLeft = "0px";
    const marginTop = this.platformService.isMobile() ? "24px" : "32px";
    this.elementRef.nativeElement.style.setProperty("margin-top", marginTop);
    const containerWidth =
		document.getElementById("video-component")?.clientWidth - 1;
    const innerWidth = containerWidth
		? Math.min(containerWidth, window.innerWidth)
		: window.innerWidth;
    if (innerWidth > 768) {
      if (innerWidth < this.width + 600) {
        marginLeft = "0px";
      } else if (
        // innerWidth > this.width + 600 
        // ||
        this.height > this.width
      ) {
        marginLeft = "276px"; // 256px + 20
      }
    }

    this.elementRef.nativeElement.style.setProperty("margin-left", marginLeft);
    this.elementRef.nativeElement.style.setProperty(
      "font-size",
      this.elementRef.nativeElement.clientHeight * 0.25 + "px"
    );
  }
}

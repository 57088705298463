import { Injectable } from '@angular/core';
import {PopupService} from '../popup/popup.service';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {

  constructor(private popupService: PopupService,
              private translateService: TranslateService) { }

  public processServerError(data: any, callBack?: any): void {
    // In case we need specific callbacks passed from other parts of code
    if (callBack) { // Probably will be refactored in the future
      callBack();
    }
    switch (data.status) {
      case 403:
        throw new Error(data);
      case 404:
        break;
      default:
        console.warn('Unhandled error event ', data);
        // ToDo: Uncomment it after demos and figure out why we get this when entering the room
        // this.popupService.openWarningPopup('Unhandled error event ' + data.status + ' ' + data.message);
        break;
    }
  }
}

import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-restore-mail-sent',
  templateUrl: './restore-mail-sent.component.html',
  styleUrls: ['./restore-mail-sent.component.scss']
})
export class RestoreMailSentComponent implements OnInit {

  email: string;

  constructor(private router: Router) { }

  ngOnInit() {
    if (!localStorage.email || localStorage.email === '') {
      this.router.navigate(['forgot-password']);
    } else {
      this.email = localStorage.email;
      localStorage.email = '';
    }
  }

}

import { AfterViewInit, Directive, ElementRef, OnDestroy } from "@angular/core";
import { PlatformDetectorService } from "src/app/services/platform-detector/platform-detector.service";
import { VideoLayoutService } from "src/app/services/video-layout/video-layout.service";

@Directive({
	selector: "[appVideoLayouts]",
})
export class VideoLayoutsDirective implements AfterViewInit, OnDestroy {
	private observer: MutationObserver;
	private resizeObserver: ResizeObserver;
	private timeOut;

	private updateView = () => {
		// if (this.timeOut) {
			// clearTimeout(this.timeOut);
		// }
		// this.timeOut = setTimeout(() => {
			this.videoLayoutService.calculateLayout(true);
		// });
	};

	constructor(
		private elRef: ElementRef,
		private platformDetector: PlatformDetectorService,
		private videoLayoutService: VideoLayoutService
	) {}

	ngAfterViewInit() {
		const videoComponent = document.getElementById("video-component");

		this.resizeObserver = new ResizeObserver((entries) => {
			entries.forEach(() => {
				this.updateView();
			});
		});

		this.resizeObserver.observe(videoComponent);

		this.observer = new MutationObserver((mutations) => {
			mutations.forEach((mutation) => {
				if (
					mutation &&
					(mutation.type === "childList" ||
						mutation.type === "attributes")
				) {

					this.updateView();
				}
			});
		});
		const config = { attributes: true, childList: true };

		this.observer.observe(this.elRef.nativeElement, config);
	}

	ngOnDestroy() {
		this.observer.disconnect();
		this.resizeObserver.disconnect();
	}
}

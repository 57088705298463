import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { defer, from, Observable, ObservedValueOf, of, Subscription, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { catchError, delay, map, retryWhen, switchMap } from 'rxjs/operators';
import { MediaDevicesService } from 'src/app/lib-media-devices/services/media-devices.service';
import { DEBUG_VALUE_22, LocationUtil, PRM_DEBUG } from 'src/app/lib-core/utils/location.util';
import { MediaProperiesUtil } from 'src/app/lib-setting/components/setting-popup/setting-popup.interface';
import { LocalStorageUtil } from 'src/app/lib-core/utils/local-storage.util';
import { CaptureMediaService } from '../../services/capture-media.service';
import { MediaSettingsStorageService } from '../../components/call-room/service/storage/media-settings-storage.service';
import { RoomStateStorage } from '../../components/call-room/service/storage/RoomStateStorage';
import { StreamSettingsStorage } from '../../components/call-room/service/storage/rtc-storage.service';
import { CallStorage } from '../../components/call-room/service/storage/call-storage.service';
import { PopupService } from '../../services/popup/popup.service';
import { RoomAuthService } from '../../services/ws/room-auth.service';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { StorageUtil } from '../../utils/storage.util';
import { RtcStreamViewModel } from '../../components/call-room/service/viev-model/rtc-stream-view-model';
import { VideoSettingsService } from '../service/video-settings.service';
import { PlatformDetectorService } from '../../services/platform-detector/platform-detector.service';
import { EasyRtcService } from '../../lib-rtc/services/easy-rtc.service';
import { EventSocketService } from '../../services/ws/event-socket.service';
import { USER_KEY } from '../../constants/localstorage-constants';
import { SpeakerTimeDetectorService } from '../../services/speaker-time-detector.service';
import { BotService } from '../../services/bot/bot.service';
import { RecordingService } from '../../services/recording/recording.service';
import { ensureGlobalObject } from '../../services/utils';
import { RoomService } from 'src/app/services/room.service';

@Component({
  selector: 'app-pre-call-settings',
  templateUrl: './pre-call-settings.component.html',
})
// todo remove easyrtc (add analog, i use only capture part)
// todo add settingsStorage storageManager and settingsVm
// todo end it

export class PreCallSettingsComponent implements OnInit, OnDestroy {
  isSettingsDisplay: boolean;
  isLoading: boolean = true;
  @Input() set displaySettings(val: boolean) {
    this.isSettingsDisplay = val;
  }
  @Input() videoSettingsCanBeClosed = false;
  @Output() isInitMedia = new EventEmitter<boolean>();
  @Output() displayChanged = new EventEmitter<boolean>();
  @Output() entryAllowed = new EventEmitter<boolean>();
  @Output() displayPermission = new EventEmitter<boolean>();
  @ViewChild('output')
  public outputSelectRef: ElementRef<HTMLSelectElement>;
  public isBrowserSafari = false;
  public hideOnIosSafari = false;
  public isFirefox: boolean;

  vDeviceId: string;
  aDeviceId: string;
  outputDeviceId: string;
  videoDeviceList: Array<MediaDeviceInfo> = new Array<MediaDeviceInfo>();
  audioDeviceList: Array<MediaDeviceInfo> = new Array<MediaDeviceInfo>();
  outputDevicesList: Array<MediaDeviceInfo> = new Array<MediaDeviceInfo>();
  isMediaError = false;
  currentVolume: number = 0;
  private audioContext: AudioContext;
  private subscribes: Array<Subscription> = new Array<any>();
  private isDebug = false;
  public roomLink = location.host + location.pathname;

  public isPhone: boolean;
  public isIPad: boolean;
  public localStream: MediaStream | null = null;
  public javascriptNode: ScriptProcessorNode;
  public analyser:AnalyserNode;
  public initMediaStreamCallCount = 0;

  preCallSettingsForm: UntypedFormGroup;
  public controls = {
    videoInputDeviceId: new UntypedFormControl(null, [Validators.required]),   // videoInputDevices
    audioInputDeviceId: new UntypedFormControl(null, [Validators.required]),   // audioInputDevices
    audioOutputDeviceId: new UntypedFormControl(null, [Validators.required]),  // audioOutputDevices
  };

  public connectedUsers;
  public userLimit;

  constructor(
    private captureMediaService: CaptureMediaService,
    private changeDetectorRef: ChangeDetectorRef,
    private settingsStorage: StreamSettingsStorage,
    public roomState: RoomStateStorage,
    public msService: MediaSettingsStorageService,
    public callStorage: CallStorage,
    private ngZone: NgZone,
    private authenticationService: AuthenticationService,
    private popupService: PopupService,
    private roomAuthService: RoomAuthService,
    private platformDetectorService: PlatformDetectorService,
    private mediaDevicesService: MediaDevicesService,
    private rtcStreamViewModel: RtcStreamViewModel,
    private router: Router,
    private videoSettingsService: VideoSettingsService,
    private eventSocketService: EventSocketService,
    private speakerTimeDetectorService: SpeakerTimeDetectorService,
    private recordingService: RecordingService,
    private roomService: RoomService,
  ) {
    this.isDebug = (LocationUtil.findGetParameter(PRM_DEBUG) === DEBUG_VALUE_22);

    this.preCallSettingsForm = new UntypedFormGroup({
      ...this.controls
    });
    this.prepareMediaProperies();
  }

  async ngOnInit() {
    ensureGlobalObject('APP.conference').membersCount = 1;
    this.isIPad = this.platformDetectorService.isIPad();
    this.isFirefox = this.platformDetectorService.isFirefox();
    this.isBrowserSafari = this.platformDetectorService.isBrowserSafari();
    this.isPhone = this.platformDetectorService.isIphone();
    this.videoSettingsService.checkPermissions().subscribe((data) => {
      if (typeof data === 'object' && data !== null) {
        const { cam, mic } = data;
        const hasPermission = (cam && mic) || (mic && !cam);
        if (!hasPermission && !!window.sessionStorage.getItem('userName')) {
          this.displayPermission.emit(true);
        }
      } else {
        if (!data && !!window.sessionStorage.getItem('userName')) {
          this.displayPermission.emit(true);
        }
      }
    });
    this.addObservers();

    const deviceList: Observable<ObservedValueOf<Promise<MediaDeviceInfo[]>>> = this.updateDeviceList();
    deviceList.pipe(switchMap((list) => {
      this.msService.settingDevices(list);
      this.getAlert();
      return of(this.videoSettingsService.checkPermissions);
    })).subscribe((permission) => {
      if (permission) {
        this.init();
        BotService.componentComplete$.next('preCallSettings');
      }
    });
    BotService.recordingBotEvent$.subscribe((event: any) => {
      if (event.type === 'closeSettingsAndEnter') {
        this.closeSettingsAndEnter();
      }
    });
  }

  async ngOnDestroy(): Promise<void> {
    this.subscribes.map((subscribe) => {
      subscribe.unsubscribe();
    });
    this.videoSettingsService.unsubscribe();
  }

  private getInfo(deviceList: MediaDeviceInfo[]): string {
    let result = '';
    for (const device of (deviceList || [])) {
      result += `- "${device.label}" deviceId=${device.deviceId.substr(0, 10)}\n`;
    }
    return result;
  }

  public async doChangeVideoInputDevice(): Promise<void> {
    await this.update();
  }

  public async doChangeAudioOutputDevice(audioOutputDeviceId: string): Promise<void> {
    this.mediaDevicesService.audioOutputDeviceChange$.next(audioOutputDeviceId);
    await this.update();
  }

  public async testAudio() {
    const audioEnter = new Audio('/assets/EnterSound.mp3');
    await (audioEnter as any).setSinkId(this.controls.audioOutputDeviceId.value);
    await audioEnter.play();
  }

  public async doChangeAudioInputDevice(): Promise<void> {
    await this.update();
  }

  private init = async() => {
    const mediaProperies = MediaProperiesUtil.readMediaProperiesFromStorage();
    MediaProperiesUtil.writeAudioGainControlStorage(mediaProperies.audioAutoGainControl);

    const isSafari = this.platformDetectorService.isSafari();
    if (isSafari) {
        mediaProperies.audioEchoCancellation = true;
        MediaProperiesUtil.writeAudioEchoCancellation(true);
      }

    if (this.audioDeviceList.length > 0) {
      const audioInpDevInfo = this.mediaDevicesService.findMediaDeviceInfo(this.audioDeviceList, mediaProperies.audioInpDev);
      this.controls.audioInputDeviceId.setValue((!!audioInpDevInfo ? audioInpDevInfo.deviceId : null), { emitEvent: false });
    } else {
      this.controls.audioInputDeviceId.disable({ emitEvent: false });
    }

    if (this.outputDevicesList.length > 0) {
      const audioOutDevInfo = this.mediaDevicesService.findMediaDeviceInfo(this.outputDevicesList, mediaProperies.audioOutDev);
      this.controls.audioOutputDeviceId.setValue(audioOutDevInfo.deviceId, { emitEvent: false });
    } else {
      this.controls.audioOutputDeviceId.disable({ emitEvent: false });
    }

    if (this.videoDeviceList.length > 0) {
      const videoDeviceInfo = this.mediaDevicesService.findMediaDeviceInfo(this.videoDeviceList, mediaProperies.videoInpDev);
      this.controls.videoInputDeviceId.setValue(videoDeviceInfo.deviceId, { emitEvent: false });
    } else {
      this.controls.videoInputDeviceId.disable({ emitEvent: false });
    }
    const roomUserOption = JSON.parse(sessionStorage.getItem('room_user_options'));
    if (roomUserOption) {
      const {isCam, isMicro} = roomUserOption;
      this.roomState.isMicroOn = isMicro;
      this.roomState.isCamOn = isCam;
    }
    await this.update();
  }

  private async update(): Promise<void> {
    this.writeMediaProperies();
    this.settingsStorage.clearCurrentStream();
    if (!BotService.isBot) {
      await this.initMediaSource();
    }
  }

  private async initMediaSource(stopLoading?: boolean): Promise<void> {
    if (!stopLoading) {
      this.roomState.isLoading = true;
    }
    await this.settingsStorage.closeAllLocalStreamsAndPause();
    if (this.localStream) {
      this.localStream.getTracks().forEach((track) => {
        track.stop();
        this.localStream.removeTrack(track);
      });
    }
    this.localStream = null;
    this.isMediaError = false;
    if (this.mediaDevicesService.hasGetUserMediaEnable()) {
      const isCamEnabled = VideoSettingsService.mediaPermissions.value.camCanBeCalled;
      defer(() => from(this.mediaDevicesService.getUserMedia({
        video: BotService.isBot || !isCamEnabled ? false : {deviceId: this.controls.videoInputDeviceId.value},
        audio: {deviceId: this.controls.audioInputDeviceId.value}
      }))).pipe(
        catchError((err) => {
          this.initMediaStreamCallCount ++;
          this.isMediaError = true;
          this.roomState.isLoading = false;
          if (this.isBrowserSafari && this.platformDetectorService.getSafariVersion() >= 16 && this.initMediaStreamCallCount < 2) {
            this.videoSettingsService.checkPermissions().subscribe(async () => await this.initMediaSource());
          }
          return throwError(err);
        })).subscribe(async(stream) => {
          this.localStream = stream;
          await this.updateAnalyser(stream);
          this.updateDeviceList();
          this.roomState.isLoading = false;
        },
      error => {this.isMediaError = true;}
      );
    }
  }



public initAudioAnalyser(stream: MediaStream): void {

    (window as any).AudioContext = ((window as any).AudioContext || (window as any).webkitAudioContext);
    this.audioContext = new ((window as any).AudioContext)();
    this.analyser = this.audioContext.createAnalyser();
    const microphone = this.audioContext.createMediaStreamSource(stream);
    this.javascriptNode = this.audioContext.createScriptProcessor(2048, 1, 1);

    this.analyser.smoothingTimeConstant = 0.8;
    this.analyser.fftSize = 1024;

    microphone.connect(this.analyser);
    this.analyser.connect(this.javascriptNode);
    this.javascriptNode.connect(this.audioContext.destination);
    this.javascriptNode.addEventListener('audioprocess', () => {
      const array = new Uint8Array(this.analyser.frequencyBinCount);
      this.analyser.getByteFrequencyData(array);
      const values = array.reduce((sum, current) => {
        return sum + current;
      }, 0);

      this.ngZone.run(() => {
        this.currentVolume = values / array.length;
      });
    });
    // const isSafari = this.platformDetectorService.isSafari();
    // if (isSafari) {
    // this.javascriptNode.disconnect();
    // }
  }

  private updateAnalyser = async(stream: MediaStream) => {
    // Below is the code for volume meter
    if (this.audioContext) {
      this.audioContext.close().then(() => {
        this.initAudioAnalyser(stream);
      });
    } else {
      this.initAudioAnalyser(stream);
    }
  }

  private writeMediaProperies(): void {
    const videoInputDevice = this.controls.videoInputDeviceId.value;
    const audioInputDevice = this.controls.audioInputDeviceId.value;
    const audioOutputDevice = this.controls.audioOutputDeviceId.value;
    MediaProperiesUtil.writeVideoInputDeviceToStorage(videoInputDevice);
    MediaProperiesUtil.writeAudioInputDeviceToStorage(audioInputDevice);
    MediaProperiesUtil.writeAudioOutputDeviceToStorage(audioOutputDevice);
  }

  private addObservers() {
    this.subscribes.push(this.settingsStorage.selectedMediaId$.subscribe(this.updateSelectedId));
    this.subscribes.push(this.roomState.isLoading$
      .subscribe(data => {
        this.isLoading = data;
        this.isInitMedia.emit(data);
      }));
    this.mediaDevicesService.onDeviceChange(this.updateDeviceList());
  }

  private updateSelectedId = (mediaId: string) => {
    this.vDeviceId = mediaId;
  }

  public isSafariOnIos() {
    return (this.isPhone || this.isIPad) && this.isBrowserSafari;
  }

  public isSafariDesktopBrowser() {
    return this.platformDetectorService.isSafari();
  }

  private defaultParams(): any {
    return { slug: this.callStorage.roomId };
  }

  private setOutputDevice() {
    let selectedDevice = null;
    if (this.outputSelectRef) {
      selectedDevice = this.outputDevicesList[this.outputSelectRef.nativeElement.selectedIndex].deviceId;
    }
    if (selectedDevice) {
      this.captureMediaService.outputConfig.deviceId = selectedDevice;
    }
  }

  async closeSettingsAndEnter(): Promise<void> {
    let permission
    if (this.platformDetectorService.getSafariVersion() >= 16) {
      permission = VideoSettingsService.mediaPermissions.value;
    } else {
      permission = await this.videoSettingsService.checkPermissions('enter').toPromise();
    }
    const { cam, mic } = permission;
    const hasPermission = (cam && mic) || (mic && !cam);
    if (!hasPermission) {
      return this.displayPermission.emit(true);
    }
    if (!VideoSettingsService.mediaPermissions.value.cam) {
      this.roomState.isCamOnImSure = false;
      StorageUtil.writeMediaPrmsToSessionStorage({ isCam: false });
    }

    if (this.localStream && !BotService.isBot) {
       this.localStream.getTracks().forEach((track) => {
         track.stop();
         this.localStream = null;
       });
    }

    this.roomState.isLoading = true;
    const mediaProperties = MediaProperiesUtil.readMediaProperiesFromStorage();
    const constraints = MediaProperiesUtil.createConstraints(mediaProperties);

    defer(() => from(EasyRtcService.initMediaSourceByBrowserSupport(constraints))).pipe(
      catchError((err) => {
        return throwError(err);
      }),
      retryWhen((error) => {
        let retries = 0;
        return error.pipe(
          delay(1000),
          map(err => {
            if (retries++ === 2) {
              throw err;
            }
            return err;
          })
        );
      })).subscribe({
      next: async(mediaSt: any) => {
        if (mediaSt != null) {
          if (!BotService.isBot) {
            await this.updateAnalyser(mediaSt); // this.mediaStream = stream;
          }
          this.settingsStorage.setCurrentStream(mediaSt);
          this.videoSettingsService.fromStartPage = false;
          if (!this.isBrowserSafari) {
            this.setOutputDevice();
          }
          if (!BotService.isBot) {
            this.speakerTimeDetectorService.resetSpeaker();
          }
          this.joinToRoom();
        }
      },
      error: (err: any) => {
        console.error('EasyrtcFunc.initMediaSource(', constraints, ')'
          + '\nvideoDeviceList=', this.videoDeviceList, ''
          + '\naudioDeviceList=', this.audioDeviceList, ''
          + '\noutputDevicesList=', this.outputDevicesList, ''
          + '\nError: ', err);
        this.isMediaError = true;
        if (!this.platformDetectorService.isSupportedSafariVersion()) {
          this.router.navigate(['/unsupported-browser']);
        }
      }});
  }

  async joinToRoom() {
    const isApprove = this.authenticationService.isApprover();
    const canJoin = this.authenticationService.canJoin();
    if (canJoin && isApprove) {
      this.eventSocketService.notify(this.callStorage.roomId, {roomFull: false});
    }

    if (canJoin || isApprove) {
      sessionStorage.isLive = true;
    } else {
      this.roomState.isLoading = true;
    }

    // Check if room is full (before creating request) and it will be checked once again after approving request
    try {
      if (!isApprove) {
		let result;
        const connectedUsers = this.roomService.connectedUsersSubject.getValue();
        const userLimit = this.roomService.userLimitSubject.getValue();
        result = connectedUsers >= userLimit;
        if (result) {
          const userName = sessionStorage.getItem(USER_KEY)
          this.eventSocketService.notify(this.callStorage.roomId, {roomFull: true, name: userName});
          this.popupService.openRoomFullDialog();
          this.roomState.isLoading = false;
          return;
        }
      }
    } catch (e) {
      console.error(e);
      this.roomState.isLoading = false;
      return;
    }

    // TODO sessionStorage.setItem(USER_KEY, this.enterCallForm.value.userName);
    this.displaySettings = false;
    const params = this.defaultParams();
    // TODO params.name = this.enterCallForm.value.userName;
    params.password = sessionStorage.roomPassword;
    this.entryAllowed.emit(this.displaySettings);
    this.roomState.isLoading = false;
  }

  toggleWebCam(isCamera: boolean): void {
    this.roomState.isCamOnImSure = isCamera;
    StorageUtil.writeMediaPrmsToSessionStorage({ isCam: isCamera });
  }

  toggleMicro(isMicrophone: boolean): void {
    this.roomState.isMicroOnImSure = isMicrophone;
    StorageUtil.writeMediaPrmsToSessionStorage({ isMicro: isMicrophone });
  }

  private updateDeviceList(): Observable<ObservedValueOf<Promise<MediaDeviceInfo[]>>> {
   const deviceIds = from(this.mediaDevicesService.enumerateDevices(true));
   deviceIds.subscribe(
      (ids: MediaDeviceInfo[]) => {
        this.videoDeviceList = this.mediaDevicesService.getDeviceVideoInput(ids);
        this.audioDeviceList = this.mediaDevicesService.getDeviceAudioInput(ids);
        this.outputDevicesList = this.mediaDevicesService.getDeviceAudioOutput(ids);
      }
    );
   return deviceIds;
  }

  private async getAlert(): Promise<void> {
    if (this.isDebug) {
      const deviceList: MediaDeviceInfo[] = await this.mediaDevicesService.getDevices();
      const videoDevices = deviceList.filter((device) => device.kind === 'videoinput');
      const audioDevices = deviceList.filter((device) => device.kind === 'audioinput');
      const outputDevices = deviceList.filter((device) => device.kind === 'audiooutput');
      alert('videoDevices\n' + this.getInfo(videoDevices) + '\noutputDevices\n' + this.getInfo(outputDevices)
        + '\naudioDevices\n' + this.getInfo(audioDevices)  );
    }
  }

  private prepareMediaProperies(): void {
    if (!!LocalStorageUtil.getItem('vDeviceId')) {
      LocalStorageUtil.setItem('vDeviceId', null);
    }
    if (!!LocalStorageUtil.getItem('aDeviceId')) {
      LocalStorageUtil.setItem('aDeviceId', null);
    }
    if (!!LocalStorageUtil.getItem('outputDeviceId')) {
      LocalStorageUtil.setItem('outputDeviceId', null);
    }
  }

  returnToPermissionPage() {
    this.displayPermission.emit(true);
  }

}

<mat-dialog-content>
  <div class="popup-text">
    {{ 'popUp.notExist' | translate }}<a href="{{redirectLink}}">{{ 'popUp.dealer' | translate }}</a>
  </div>
  <mat-dialog-actions>
    <button [mat-dialog-close]="true"
            (click)="redirect()">
      {{ 'waitingRoom.ok' | translate }}
    </button>
  </mat-dialog-actions>
</mat-dialog-content>

import { Injectable } from "@angular/core";
import { SubRole } from "src/app/constants/subroles-constants";

declare const ViralLoops: any;

const STUDENT_CAMPAIGN_ID = "l7G2rQDbpaDkNLgQFJPOVC5HKF0";
const TEACHER_CAMPAIGN_ID = "w17nn3SVdhBy9bu8StDOlEj6MmU";

export interface VlUser {
	firstName: string;
	lastName: string;
	email: string;
}

@Injectable({
	providedIn: "root",
})
export class ReferralService {
	constructor() {}

	private campaignIds = {
		[SubRole.TEACHER_MATCHED]: TEACHER_CAMPAIGN_ID,
		[SubRole.STUDENT_MATCHED]: STUDENT_CAMPAIGN_ID,
	};

	public getCampaignId(subrole: SubRole) {
		return this.campaignIds[subrole];
	}

	async participateInViralLoops(vlUser: VlUser, subrole: SubRole) {
		try {
			const campaign = await ViralLoops.getCampaign(
				this.getCampaignId(subrole)
			);
			await campaign.identify(vlUser);
		} catch (error) {
			console.error("[Viral Loops] Participation error", error);
		}
	}
}

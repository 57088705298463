import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-lobby-info',
  templateUrl: './lobby-info.component.html',
  styleUrls: ['./lobby-info.component.scss']
})
export class LobbyInfoComponent implements OnInit {
  @Input() roomName: string = '';
  @Output() join = new EventEmitter<boolean>();
  @Input() haveStaticName: boolean = false;
  @Input() username: string = '';
  @Output() usernameChange = new EventEmitter<string>();
  @Input() canSubmit: boolean = true;
  // timer variables
  @Input() startTime: number;
  startTimeDisplayed: string;
  isLive: boolean = true;
  diffDays: number = 0;
  diffHrs: number = 0;
  diffMins: number = 0;
  diffSec: number = 0;
  constructor() { }

  ngOnInit() {
    this.isLive = !this.startTime || this.startTime < Date.now();
    if (!this.isLive) {
      const d = new Date(this.startTime);
      const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
      const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
      const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
      const hr = new Intl.DateTimeFormat('en', { hour: '2-digit', hour12: false }).format(d);
      const mn = new Intl.DateTimeFormat('en', { minute: '2-digit' }).format(d);
      this.startTimeDisplayed =  `${da}/${mo}/${ye} ${hr}:${mn}`;
      this.startTimer(this.startTime - Date.now());
    }
  }
  onSubmit() {
    this.usernameChange.emit(this.username);
    this.join.emit(true);
  }
  onEnterDown(event) {
    event.preventDefault();
  }
  private startTimer(dif: number) { // ToDo: Refactor this
    // milliseconds between now & Christmas
    this.diffDays = Math.floor(dif / 86400000); // days
    this.diffHrs = Math.floor((dif % 86400000) / 3600000); // hours
    this.diffMins = Math.round(((dif % 86400000) % 3600000) / 60000); // minutes
    this.diffSec = Math.round((((dif % 86400000) % 3600000) % 60000) / 1000);
    const next = dif - 1000;
    if (next >= 0) {
      setTimeout(() => {
        this.startTimer(next);
      }, 1000);
    } else {
      this.isLive = true;
    }
  }

}

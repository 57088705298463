@if (count > 0) {
	<table class="cloud-table" mat-table [dataSource]="filesData" matSort>
		<!-- Filename & Info Column -->
		<ng-container matColumnDef="name">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>
				{{ "material.table-title" | translate }}
			</th>
			<td class="mobile__file-info" mat-cell *matCellDef="let list">
				<a [href]="list?.url" target="_blank" rel="noreferrer" [download]="list?.name">
					<p class="file-name">{{ list?.name }}</p>
					<p class="file-info">{{ list?.extension }} | {{ list?.created | date: "dd.MM.yyyy - H:mm" }}</p>
				</a>
			</td>
		</ng-container>

		<!-- menu Column -->
		<ng-container matColumnDef="menu">
			<th mat-header-cell *matHeaderCellDef></th>
			<td class="mobile__dropdown-button" mat-cell *matCellDef="let item">
				@if (isTeacher) {
					<button class="!rounded-full transparent-blue-ui-border-button px-2.5 font-semibold" (click)="shareFile(item)">
						<span class="font-light text-[1rem] align-middle save-button material-symbols-outlined"> share </span>
						<span class="align-middle text-[0.8rem]">
							{{ "buttons.share" | translate }}
						</span>
					</button>
				}
			</td>
		</ng-container>

		<tr mat-row *matRowDef="let row; columns: displayedCloudColumns"></tr>
	</table>
	<mat-paginator
		#paginator
		[ngStyle]="{ display: count > 10 ? 'block' : 'none' }"
		[hidePageSize]="true"
		[pageSize]="10"
		[length]="count"
		(page)="pageChanged($event)"
		showFirstLastButtons
		aria-label="Select page of recordings elements"
	/>
} @else {
	<app-empty-list-container message="{{ 'material.empty-list-message' | translate }}" />
}

import { Injectable } from "@angular/core";
import { CookiesService } from "./cookies/cookies.service";
import { AuthenticationService } from "./authentication/authentication.service";
import { NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs/operators";
import { getSubDomain } from "src/app/helpers";
import { PlatformDetectorService } from "../services/platform-detector/platform-detector.service";

const GTM_SCRIPT = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-PJR5W5ZX')`;

declare var window;
declare const gtag;

@Injectable({
	providedIn: "root"
})
export class GA4Service {
	private _consent: boolean;

	public get hasConsent() {
		return this.cookieService.get("cookies_accepted");
	}

	constructor(
		private platformDetectorService: PlatformDetectorService,
		private auth: AuthenticationService,
		private cookieService: CookiesService,
		private router: Router
	) {}

	isBrowserSafari = this.platformDetectorService.isSafari();

	public insertAnalyticsScript() {
		if (!this.isBrowserSafari) {
			var gtagscript = document.createElement("script");
			gtagscript.type = "text/javascript";

			var inlineScript = document.createTextNode(GTM_SCRIPT);
			gtagscript.appendChild(inlineScript);
			document.getElementsByTagName("head")[0].appendChild(gtagscript);
		}
	}

	setUpAnalytics() {
		if (!this.isBrowserSafari) {
			this.router.events
				.pipe(filter((event) => event instanceof NavigationEnd))
				.subscribe((event: NavigationEnd) => {
					if (typeof gtag === "function") {
						gtag("config", "G-EL89NHFH4X", {
							page_path: event.urlAfterRedirects
						});
					}
				});
		}
	}

	open_piano() {
		// 	if (!this.isBrowserSafari) {
		// 		gtag("event", "app-piano-opened", {
		// 			event_category: "feature-usage",
		// 			event_label: "Virtual Piano feature opened",
		// 			value: "Piano opened"
		// 		});
		// 	}
	}

	monthlyAccount() {
		// 	if (!this.isBrowserSafari) {
		// 		gtag("event", "subscription_monthly", {
		// 			event_category: "Sirius-Product",
		// 			event_label: "Monthly Subscription",
		// 			value: "subscription successful"
		// 		});
		// 	}
	}

	yearlyAccount() {
		// 	if (!this.isBrowserSafari) {
		// 		gtag("event", "subscription_yearly", {
		// 			event_category: "Sirius-Product",
		// 			event_label: "Track Me Click",
		// 			value: "subscription successful"
		// 		});
		// 	}
	}

	roomCreated() {
		// 	if (!this.isBrowserSafari) {
		// 		gtag("event", "room_created", {
		// 			event_category: "Sirius-Rooms",
		// 			event_label: "Track Me Click",
		// 			value: "room created"
		// 		});
		// 	}
	}

	roomUsedMin5Min() {
		// 	if (!this.isBrowserSafari) {
		// 		gtag("event", "room_use_min_5min", {
		// 			event_category: "Sirius-Rooms",
		// 			event_label: "Track Me Click",
		// 			value: "room-used-5-minutes"
		// 		});
		// 	}
	}

	roomEntered() {
		// 	if (!this.isBrowserSafari) {
		// 		gtag("event", "room_entered", {
		// 			event_category: "Sirius-Rooms",
		// 			event_label: "Track Me Click",
		// 			value: "room-entered"
		// 		});
		// 	}
	}

	loggedIn() {
		// 	if (!this.isBrowserSafari) {
		// 		gtag("event", "logged_in", {
		// 			event_category: "Sirius-App",
		// 			event_label: "Track Me Click",
		// 			value: "logged-in"
		// 		});
		// 	}
	}

	signedUp() {
		// 	if (!this.isBrowserSafari) {
		// 		gtag("event", "signed_up", {
		// 			event_category: "Sirius-App",
		// 			event_label: "Track Me Click",
		// 			value: "profile-signed-up"
		// 		});
		// 	}
	}

	profileCreated() {
		// 	if (!this.isBrowserSafari) {
		// 		gtag("event", "profile_created", {
		// 			event_category: "Sirius-App",
		// 			event_label: "Track Me Click",
		// 			value: "profile-created"
		// 		});
		// 	}
	}

	open_settings() {
		// 	if (!this.isBrowserSafari) {
		// 		gtag("event", "app-settings-opened", {
		// 			event_category: "feature-usage",
		// 			event_label: "app-settings-opened",
		// 			value: "Settings opened"
		// 		});
		// 	}
	}

	cam_button_pressed() {
		// if (!this.isBrowserSafari) {
		// 	if (!getSubDomain()) {
		// 		gtag("event", "app-cam-button-pressed", {
		// 			event_category: "feature-usage",
		// 			event_label: "app-cam-button-pressed",
		// 			value: "true"
		// 		});
		// 	}
		// }
	}

	mic_button_pressed() {
		// if (!this.isBrowserSafari) {
		// 	if (!getSubDomain()) {
		// 		gtag("event", "app-mic-button-pressed", {
		// 			event_category: "feature-usage",
		// 			event_label: "app-mic-button-pressed",
		// 			value: "true"
		// 		});
		// 	}
		// }
	}

	play_sound() {
		// if (!this.isBrowserSafari) {
		// 	if (!getSubDomain()) {
		// 		gtag("event", "app-play-sound", {
		// 			event_category: "feature-usage",
		// 			event_label: "app-play-sound",
		// 			value: "true"
		// 		});
		// 	}
		// }
	}

	open_metronome() {
		// if (!this.isBrowserSafari) {
		// 	if (!getSubDomain()) {
		// 		gtag("event", "app-metronome", {
		// 			event_category: "feature-usage",
		// 			event_label: "app-metronome",
		// 			value: "true"
		// 		});
		// 	}
		// }
	}

	toogle_emoji() {
		// if (!this.isBrowserSafari) {
		// 	if (!getSubDomain()) {
		// 		gtag("event", "app-emoji-opened", {
		// 			event_category: "feature-usage",
		// 			event_label: "app-emoji-opened",
		// 			value: "true"
		// 		});
		// 	}
		// }
	}

	screenshare() {
		// if (!this.isBrowserSafari) {
		// 	if (!getSubDomain()) {
		// 		console.log("screenshare");
		// 		gtag("event", "app-screensharing", {
		// 			event_category: "feature-usage",
		// 			event_label: "app-screensharing",
		// 			value: "true"
		// 		});
		// 	}
		// }
	}

	youtube() {
		// if (!this.isBrowserSafari) {
		// 	if (!getSubDomain()) {
		// 		gtag("event", "app-youtube-sharing", {
		// 			event_category: "feature-usage",
		// 			event_label: "app-youtube-sharing",
		// 			value: "true"
		// 		});
		// 	}
		// }
	}

	changeView() {
		// if (!this.isBrowserSafari) {
		// 	if (!getSubDomain()) {
		// 		gtag("event", "app-change-view", {
		// 			event_category: "feature-usage",
		// 			event_label: "app-change-view",
		// 			value: "true"
		// 		});
		// 	}
		// }
	}

	chat() {
		// 	if (!this.isBrowserSafari) {
		// 		if (!getSubDomain()) {
		// 			gtag("event", "app-enter-chat", {
		// 				event_category: "feature-usage",
		// 				event_label: "app-enter-chat",
		// 				value: "true"
		// 			});
		// 		}
		// }
	}

	record() {
		// 	if (!this.isBrowserSafari) {
		// 		if (!getSubDomain()) {
		// 			gtag("event", "app-start-recording", {
		// 				event_category: "feature-usage",
		// 				event_label: "app-start-recording",
		// 				value: "true"
		// 			});
		// 		}
		// }
	}

	threedotmenu() {
		// if (!this.isBrowserSafari) {
		// 	if (!getSubDomain()) {
		// 		gtag("event", "app-open-threedotmenu", {
		// 			event_category: "feature-usage",
		// 			event_label: "app-open-threedotmenu",
		// 			value: "true"
		// 		});
		// 	}
		// }
	}

	threedotMaximize() {
		// if (!this.isBrowserSafari) {
		// 	if (!getSubDomain()) {
		// 		gtag("event", "app-threedotmenu-max", {
		// 			event_category: "feature-usage",
		// 			event_label: "app-threedotmenu-max",
		// 			value: "true"
		// 		});
		// 	}
		// }
	}

	threedotFullscreen() {
		// if (!this.isBrowserSafari) {
		// 	if (!getSubDomain()) {
		// 		gtag("event", "app-threedotmenu-fullscreen", {
		// 			event_category: "feature-usage",
		// 			event_label: "app-threedotmenu-fullscreen",
		// 			value: "true"
		// 		});
		// 	}
		// }
	}

	speedtest() {
		// if (!this.isBrowserSafari) {
		// 	if (!getSubDomain()) {
		// 		gtag("event", "app-start-speedtest", {
		// 			event_category: "feature-usage",
		// 			event_label: "app-start-speedtest",
		// 			value: "true"
		// 		});
		// 	}
		// }
	}

	copyLink() {
		// if (!this.isBrowserSafari) {
		// 	if (!getSubDomain()) {
		// 		gtag("event", "app-copy-roomlink", {
		// 			event_category: "feature-usage",
		// 			event_label: "app-copy-roomlink",
		// 			value: "true"
		// 		});
		// 	}
		// }
	}

	endSession() {
		// if (!this.isBrowserSafari) {
		// 	if (!getSubDomain()) {
		// 		gtag("event", "app-end-session", {
		// 			event_category: "feature-usage",
		// 			event_label: "app-end-session",
		// 			value: "true"
		// 		});
		// 	}
		// }
	}
}

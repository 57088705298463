import { Injectable } from "@angular/core";
import { displayGridView } from "../../helpers/video-loyauts";
import { PlatformDetectorService } from "../platform-detector/platform-detector.service";
import { PianoService } from "src/app/piano/service/piano.service";
import { skip, sampleTime, delay, map, startWith } from "rxjs/operators";
import { fromEvent } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class VideoLayoutService {
	// private timeout: any;
	private pianoHeight: number = 0;
	private padding: number;
	private headerHeight: number;
	private isMobile: boolean;

	calculateControlPanelHeightValue() {
		return (
			(this.platformDetectorService.isMobile() &&
			this.platformDetectorService.isOrientationLandscape()
				? 0
				: (this.isMobile ? 72 : 98) + this.pianoHeight) + this.padding
		);
	}

	constructor(
		private platformDetectorService: PlatformDetectorService,
		private pianoService: PianoService
	) {
		this.isMobile = this.platformDetectorService.isMobile();
		this.pianoService.isPiano.pipe(skip(1)).subscribe((value) => {
			this.pianoHeight = value ? 124 : 0;
			this.calculateLayout(true);
		});
		this.padding = this.isMobile ? 4 : 20;
		this.headerHeight = (this.isMobile ? 24 : 32) + this.padding;
	}

	public calculateLayout(event: Event | boolean) {
		displayGridView(
			event,
			"participants-grid-view",
			".participant-block",
			4 / 3,
			this.padding,
			this.calculateControlPanelHeightValue(),
			this.headerHeight
		);
	}
}

<!-- Validations to show or not the entire component -->
<div
	class="mat-container"
	[ngClass]="{ 'file-upload__dragging': isDragging }"
	(dragenter)="onDragEnter($event)"
	(dragover)="onDragOver($event)"
	(dragleave)="onDragLeave($event)"
	(drop)="onDrop($event)"
>
	@if (screenSize === "desktop" || screenSize === "tablet") {
		@if (isTeacher) {
			<input type="file" #fileInput style="display: none" (change)="onFileSelected($event)" multiple />
			<button class="default-ui-button input table-button_upload-material" (click)="fileInput.click()">
				<mat-icon fontIcon="add"></mat-icon>
				{{ "material.upload-button" | translate }}
			</button>
		}
		@if (count > 0) {
			<table class="desktop" mat-table [dataSource]="dataSource" matSort>
				<ng-container matColumnDef="name">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>
						{{ "material.table-title" | translate }}
					</th>
					<td mat-cell *matCellDef="let list">
						<span class="download-link" (click)="downloadFile(list?.url, list?.name)">
							<!-- <a [href]="list?.url" target="_blank" rel="noreferrer" [download]="list?.name"> -->
							{{ list?.name }}
							<!-- </a> -->
						</span>
					</td>
				</ng-container>

				<!-- Filename Column -->
				<ng-container matColumnDef="extension">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>
						{{ "material.table-type" | translate }}
					</th>
					<td mat-cell *matCellDef="let list">
						{{ convertToFriendlyDataType(list?.extension) }}
					</td>
				</ng-container>

				<!-- Weight Column -->
				<ng-container matColumnDef="created">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>
						{{ "material.table-date" | translate }}
					</th>
					<td mat-cell *matCellDef="let list">{{ list?.created | date: "dd.MM.yyyy - H:mm" }}</td>
				</ng-container>

				<!-- Students Column -->
				@if (isTeacher) {
					<ng-container matColumnDef="accessList">
						<th mat-header-cell *matHeaderCellDef mat-sort-header>
							{{ "material.table-student-access" | translate }}
						</th>
						<td mat-cell *matCellDef="let list">
							<ins (click)="fileSharing(list)" class="students_list">{{ setStudnetsLengthText(list) }}</ins>
						</td>
					</ng-container>
				}

				<!-- menu Column -->
				<ng-container matColumnDef="menu">
					<th mat-header-cell *matHeaderCellDef></th>
					<td mat-cell *matCellDef="let item">
						<button mat-button [matMenuTriggerFor]="contextMenu" [matMenuTriggerData]="{ item: item }">
							<mat-icon class="menu__expand-button">expand_more</mat-icon>
						</button>
					</td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="displayedDesktopColumns"></tr>
				<tr mat-row *matRowDef="let row; columns: displayedDesktopColumns"></tr>
			</table>
			<mat-menu #contextMenu="matMenu">
				<ng-template matMenuContent let-list="item">
					@if (isTeacher) {
						<button mat-menu-item (click)="fileSharing(list)">
							<mat-icon fontIcon="share"></mat-icon>
							{{ "material.menu.option1" | translate }}
						</button>
						<button mat-menu-item (click)="openRenameFileDialog(list)">
							<mat-icon fontIcon="edit"></mat-icon>
							{{ "material.menu.option2" | translate }}
						</button>
						<button mat-menu-item (click)="deleteFile(list)">
							<mat-icon fontIcon="delete"></mat-icon>
							{{ "material.menu.option3" | translate }}
						</button>
					}
					<button mat-menu-item (click)="downloadFile(list?.url, list?.name)">
						<mat-icon fontIcon="download"></mat-icon>
						{{ "material.menu.option4" | translate }}
					</button>
				</ng-template>
			</mat-menu>
			<mat-paginator
				#paginator
				[ngStyle]="{ display: count > 10 ? 'block' : 'none' }"
				[hidePageSize]="true"
				[pageSize]="10"
				[length]="count"
				(page)="pageChanged($event)"
				showFirstLastButtons
				aria-label="Select page of recordings elements"
			/>
		} @else {
			@if (isTeacher) {
				<app-files-dropzone />
			} @else {
				<app-empty-list-container message="{{ 'material.empty-list-message' | translate }}" />
			}
		}
	}
	<!-- <table class="tablet" mat-table></table> -->
	@else if (screenSize === "mobile") {
		<app-material-mobile-table
			[filesData]="dataSource"
			[displayMaterial]="true"
			[isTeacher]="isTeacher"
			[count]="count"
			(pageChangeEvent)="pageChanged($event)"
			(uploadFileEvent)="onFileSelected($event)"
			(deleteEvent)="deleteFile($event)"
			(renameEvent)="openRenameFileDialog($event)"
			(shareEvent)="fileSharing($event)"
		/>
	}
</div>

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthenticationService } from "../authentication/authentication.service";
import { Observable, Subject, of, throwError } from "rxjs";
import {
	ADD_NOTE,
	DELETE_NOTE,
	EDIT_NOTE,
	GET_NOTES
} from "../../constants/connection-constants";
import { catchError, map, tap } from "rxjs/operators";

export interface ItemNote {
	id?: string;
	modified?: string;
	teacherId: string;
	studentId: string;
	title: string;
	tasksJson: JSON;
	notesJson: JSON;
	tasksHtml: string;
	notesHtml: string;
}
export interface UpdateNote {
	title?: string;
	tasksJson?: JSON;
	notesJson?: JSON;
	tasksHtml?: string;
	notesHtml?: string;
}

@Injectable({
	providedIn: "root"
})
export class NotesService {
	private userId: string;
	private notesList: ItemNote[] = null;
	private displayNotes$: Subject<boolean> = new Subject<boolean>();

	constructor(
		private http: HttpClient,
		private authenticationService: AuthenticationService
	) {
		this.userId = this.authenticationService.profileInfo()?.userId;
	}

	get options() {
		return {
			headers: this.authenticationService.headers,
			withCredentials: true
		};
	}

	formatNote(noteData): ItemNote {
		return {
			id: noteData.id,
			modified: noteData.modified,
			teacherId: noteData.teacher_id,
			studentId: noteData.student_id,
			title: noteData.title,
			tasksJson: noteData.tasks_json,
			notesJson: noteData.notes_json,
			tasksHtml: noteData.tasks_html,
			notesHtml: noteData.notes_html
		};
	}

	//to do - add pagination queries

	getNotes(id?: string): Observable<any> {
		if (this.notesList) {
			return of(this.notesList);
		} else {
			const userId = id ? id : this.userId;
			const url = GET_NOTES.replace(":userId", userId);
			return this.http.get(url, this.options).pipe(
				catchError((err) => {
					console.log(err);
					return throwError(err);
				}),
				map((data: { page: number; pages: number; list: [] }) => {
					this.notesList = data.list.map(this.formatNote);
					return this.notesList;
				}),
				tap((data) => (this.notesList = data))
			);
		}
	}

	getNoteByUserId(userId: string): Observable<any> {
		return this.getNotes().pipe(
			map((notes) =>
				notes.find(
					(note) =>
						note.studentId === userId || note.teacherId === userId
				)
			)
		);
	}

	getNote(teacherId, studentId): Observable<any> {
		const url =
			GET_NOTES.replace(":userId", teacherId) + `?studentId=${studentId}`;
		return this.http.get(url, this.options).pipe(
			catchError((err) => {
				console.log(err);
				return throwError(err);
			}),
			map((data: { page: number; pages: number; list: any[] }) => {
				const note = data?.list[0];
				return !!note ? this.formatNote(note) : null;
			})
		);
	}

	editNote(noteId, note: UpdateNote): Observable<any> {
		const url = EDIT_NOTE.replace(":noteId", noteId);
		return this.http.put(url, note, this.options);
	}

	addNote(note: ItemNote): Observable<any> {
		return this.http.post(ADD_NOTE, note, this.options);
	}

	deleteNote(noteId: string): Observable<any> {
		const url = DELETE_NOTE.replace(":noteId", noteId);
		return this.http.delete(url, this.options);
	}

	getDisplayNotes$() {
		return this.displayNotes$;
	}
}

<div class="mat-container">
	<mat-button-toggle-group
		[hideSingleSelectionIndicator]="true"
		class="s-mat-button-toggle-group past-toggle mb-3"
		(change)="onPastLessonToggleChange($event)"
	>
		<mat-button-toggle class="s-mat-button-toggle" checked="true" value="upcoming">{{
			"lessons.upcoming" | translate
		}}</mat-button-toggle>
		<mat-button-toggle class="s-mat-button-toggle" value="past">{{ "lessons.past" | translate }}</mat-button-toggle>
	</mat-button-toggle-group>
	<ng-container *ngIf="lessonsList?.length && lessonsList.length > 0; else emptyList">
		<table class="desktop" mat-table [dataSource]="dataSource">
			<ng-container matColumnDef="Data">
				<td mat-header-cell *matHeaderCellDef></td>
				<td [class.in-past]="list?.inPast" mat-cell *matCellDef="let list">
					<div class="top-info-row">
						<div class="sub-role-name">
							{{ ["undefined ", " "].includes(list?.subRoleName) ? "&nbsp;&nbsp;" : list?.subRoleName }}
						</div>
						<div class="btn-container">
							<span *ngIf="list?.status && list?.status === 'canceled'">{{ "lessons.status-cancelled" | translate }}</span>
							<span class="cancel-request" *ngIf="list?.status && list?.status === 'cancel_request'">{{
								"lessons.status-cancel-request" | translate
							}}</span>
							<button
								*ngIf="isStudentInfoAvailable(list) && list.type == 'TRIAL' && list.status !== 'cancel_request'"
								(click)="openMoreLessonInfoModal(list)"
								class="more-info"
							>
								{{ "lessons.more-info" | translate }}
							</button>
						</div>
					</div>
					<div class="list-container">
						<div>
							<div class="date">
								{{ "lessons.date" | translate }}
							</div>
							<div>{{ list?.date }}</div>
						</div>
						<div>
							<div class="time">
								{{ "lessons.time" | translate }}
							</div>
							<div>{{ list?.time }}</div>
						</div>
						<div>
							<div class="duration">
								{{ "lessons.duration" | translate }}
							</div>
							<div>{{ list?.duration }}</div>
						</div>
						<div *ngIf="list?.status == 'pending' || list?.status == 'declined'">
							<div class="duration">Status</div>
							<div>
								@if (list?.status == "declined") {
									{{ "lessons.status-declined" | translate }}
								} @else if (list?.status == "pending") {
									{{ "lessons.status-pending" | translate }}
								}
							</div>
						</div>
						<div *ngIf="subrole === 3" class="session-block">
							<div class="session">
								{{ "lessons.type" | translate }}
							</div>
							<div [ngSwitch]="list?.type">
								<div *ngSwitchCase="'SINGLE'">
									{{ "lessons.regular-lesson" | translate }}
								</div>
								<div *ngSwitchCase="'TRIAL'">
									{{ "lessons.trial-lesson" | translate }}
								</div>
								<div *ngSwitchCase="'REPEAT'">
									{{ "lessons.regular-lesson" | translate }}
								</div>
								<div *ngSwitchDefault>{{ list?.type }}</div>
							</div>
						</div>
						<div *ngIf="list?.type == 'REPEAT'">
							<div class="repeat">
								{{ "lessons.repetition" | translate }}
							</div>
							<div class="flex">
								@if (list?.repeatSchema?.rhythm) {
									{{ "lessons." + list?.repeatSchema?.rhythm | translate }}
								} @else {
									{{ "lessons.stopped-in-future" | translate }}
								}
							</div>
						</div>

						<div
							*ngIf="
								!list?.inPast &&
								list?.status &&
								list?.status !== 'canceled' &&
								list?.status !== 'declined' &&
								list?.status !== 'cancel_request'
							"
							class="btn-wrapper"
						>
							<button
								*ngIf="
									subrole === 3 &&
									!list?.inPast &&
									list?.status &&
									list?.status !== 'canceled' &&
									list?.status !== 'cancel_request'
								"
								mat-icon-button
								[matMenuTriggerFor]="mainMenu"
							>
								<mat-icon>more_vert</mat-icon>
							</button>

							<mat-menu #mainMenu="matMenu">
								@if (list?.type !== "TRIAL") {
									<button mat-menu-item (click)="cancelBooking(list?.id, list?.type, list?.startTime)">
										{{ "lessons.cancel-lesson" | translate }}
									</button>
								} @else {
									<button mat-menu-item (click)="cancelBooking(list?.id, list?.type, list?.startTime)">
										{{ "lessons.cancel-this-trial-lesson" | translate }}
									</button>
								}
								<button
									*ngIf="list?.type == 'REPEAT'"
									mat-menu-item
									(click)="
										openEditRepeatBookingDialog(list?.teacher.id, list?.student.id, list?.student.name, list?.startTime)
									"
								>
									{{ "lessons.delete-future-recurrence" | translate }}
								</button>
								<button
									*ngIf="list?.type == 'REPEAT'"
									mat-menu-item
									(click)="openEditRepeatBookingDialog(list?.teacher.id, list?.student.id, list?.student.name, null)"
								>
									{{ "lessons.delete-all-recurrence" | translate }}
								</button>
							</mat-menu>

							<div *ngIf="list?.type !== 'TRIAL'">
								<button
									*ngIf="subrole === 3 && list.status !== 'pending'"
									class="default-ui-button input edit-session-button"
									(click)="rescheduleLesson(list)"
								>
									{{ "lessons.reschedule" | translate }}
								</button>
							</div>

							<button
								*ngIf="list?.status !== 'pending' && list?.status !== 'declined'"
								class="default-ui-button input start-session-button"
								(click)="startSession(list)"
							>
								{{ "lessons.start-btn" | translate }}
							</button>

							<p *ngIf="list?.status == 'pending' && subrole === 3">
								<button (click)="acceptRequest(list)" class="btn input edit-session-button default-ui-button">
									{{ "lessons.accept-request" | translate }}
								</button>
							</p>
							<p *ngIf="list?.status == 'pending' && subrole === 3">
								<button (click)="declineRequest(list)" class="btn input edit-session-button default-ui-button">
									{{ "lessons.decline-request" | translate }}
								</button>
							</p>
						</div>
					</div>

					<div
						*ngIf="
							!list?.inPast &&
							list?.status &&
							list?.status !== 'canceled' &&
							list?.status !== 'declined' &&
							list?.status !== 'cancel_request'
						"
						class="btn-wrapper-mobile"
					>
						<button
							*ngIf="
								subrole === 3 &&
								list?.type !== 'TRIAL' &&
								!list?.inPast &&
								list?.status &&
								list?.status !== 'canceled' &&
								list?.status !== 'cancel_request'
							"
							mat-icon-button
							[matMenuTriggerFor]="mainMenu"
						>
							<mat-icon>more_vert</mat-icon>
						</button>

						<button
							*ngIf="list?.status !== 'pending' && list?.status !== 'declined'"
							class="default-ui-button input start-session-button"
							(click)="startSession(list)"
						>
							{{ "lessons.start-btn" | translate }}
						</button>

						<p *ngIf="list?.status == 'pending' && subrole === 3">
							<button (click)="acceptRequest(list)" class="btn input edit-session-button default-ui-button">
								{{ "lessons.accept" | translate }}
							</button>
						</p>
						<p *ngIf="list?.status == 'pending' && subrole === 3">
							<button (click)="declineRequest(list)" class="btn input edit-session-button default-ui-button">
								{{ "lessons.decline" | translate }}
							</button>
						</p>

						<mat-menu #mainMenu="matMenu">
							@if (list?.type !== "TRIAL") {
								<button mat-menu-item (click)="cancelBooking(list?.id, list?.type, list?.startTime)">
									{{ "lessons.cancel-lesson" | translate }}
								</button>
							} @else {
								<button mat-menu-item (click)="cancelBooking(list?.id, list?.type, list?.startTime)">
									{{ "lessons.cancel-this-trial-lesson" | translate }}
								</button>
							}

							<button
								*ngIf="list?.type == 'REPEAT'"
								mat-menu-item
								(click)="
									openEditRepeatBookingDialog(list?.teacher.id, list?.student.id, list?.student.name, list?.startTime)
								"
							>
								{{ "lessons.delete-future-recurrence" | translate }}
							</button>
							<button
								*ngIf="list?.type == 'REPEAT'"
								mat-menu-item
								(click)="openEditRepeatBookingDialog(list?.teacher.id, list?.student.id, list?.student.name, null)"
							>
								{{ "lessons.delete-all-recurrence" | translate }}
							</button>
						</mat-menu>

						<div *ngIf="list?.type !== 'TRIAL' && list.status !== 'pending' && list?.status !== 'declined'">
							<button
								*ngIf="subrole === 3"
								class="default-ui-button input edit-session-button"
								(click)="rescheduleLesson(list)"
							>
								{{ "lessons.reschedule" | translate }}
							</button>
						</div>
					</div>
				</td>
			</ng-container>
			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
		</table>
		<mat-paginator
			[ngStyle]="{ display: size > 50 ? 'block' : 'none' }"
			#paginator
			[hidePageSize]="true"
			[pageSize]="50"
			[length]="size"
			(page)="pageChanged($event)"
			showFirstLastButtons
			aria-label="Select page of periodic elements"
		>
		</mat-paginator>
	</ng-container>
	<ng-template #emptyList>
		<div class="empty-list-container" [ngClass]="{ 'student-matched': subrole === 6 }">
			<span *ngIf="subrole === 3" class="empty-list-text">{{ "lessons.empty-lesson-list" | translate }}</span>
			<span *ngIf="subrole === 6" class="empty-list-text">{{ "lessons.empty-lesson-list-student" | translate }}</span>
		</div>
	</ng-template>
</div>

import {
	Component,
	OnInit,
	OnDestroy,
	ViewChild,
	ElementRef,
	Input,
	Output,
	EventEmitter,
	ChangeDetectorRef,
	AfterViewInit
} from "@angular/core";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { QuillService } from "./quill.service";

export interface Doc {
	json: {};
	html: string;
}

@Component({
	selector: "app-quill",
	templateUrl: "./quill.component.html",
	styleUrls: ["./quill.component.scss"]
})
export class QuillComponent implements OnDestroy, AfterViewInit, OnInit {
	private quill;
	@Input() isMiniEditor = false;
	@Input() content;
	@Input() isStudent = false;
	@Output() save = new EventEmitter();
	@Output() onChange = new EventEmitter();

	istoolbarHidden = true;
	toolbarId: string;
	private isMouseOver = false;

	constructor(
		public translateModule: TranslateModule,
		private quillService: QuillService,
		private changeDetector: ChangeDetectorRef,
		private translateService: TranslateService
	) {
		// this.toolbarId = this.quillService.toolbarId;
	}

	@ViewChild("editor") editorDom: ElementRef;
	ngOnInit(): void {
		this.toolbarId = this.quillService.toolbarId;
	}
	async ngAfterViewInit(): Promise<void> {
		const Quill = (await import("quill")).default;
		const toolbarElement = document.getElementById(this.toolbarId);
		const placeholder = this.isStudent
			? "quill.placeholder-student"
			: "quill.placeholder";
		const options = {
			placeholder: this.translateService.instant(placeholder),
			bounds: this.editorDom.nativeElement,
			theme: "snow",
			modules: {
				toolbar:
					this.isStudent || !toolbarElement
						? false
						: {
								container: `#${this.toolbarId}`,
								handlers: {
									image: this.imageHandler
								}
						  }
			}
		};

		this.quill = new Quill(this.editorDom.nativeElement, options);

		if (this.content) {
			this.quill.setContents(this.content);
		}
		if (!this.isStudent) {
			this.quill.on("text-change", () => this.onTextChange());
			this.quill.on("selection-change", (range) =>
				this.onSelectionChange(range)
			);
		}
		if (this.isStudent) {
			this.quill.disable();
		}
	}

	onSelectionChange(range) {
		if (range === null && !this.isMouseOver) {
			this.istoolbarHidden = true;
		} else if (this.isMouseOver) {
			this.istoolbarHidden = false;
		}
		this.changeDetector.detectChanges();
	}

	onTextChange() {
		this.onChange.emit({
			json: this.quill.getContents(),
			html: this.quill.root.innerHTML
		});
	}
	imageHandler(): void {
		// @ts-ignore
		const tooltip = this.quill.theme.tooltip;
		const originalSave = tooltip.save;
		const originalHide = tooltip.hide;

		tooltip.save = function () {
			const range = this.quill.getSelection(true);
			const value = this.textbox.value;
			if (value) {
				this.quill.insertEmbed(range.index, "image", value, "user");
			}
		};
		// Called on hide and save.
		tooltip.hide = function () {
			tooltip.save = originalSave;
			tooltip.hide = originalHide;
			tooltip.hide();
		};
		tooltip.edit("image");
		tooltip.textbox.placeholder = "Embed URL";
		const element = document.querySelector(".ql-snow .ql-tooltip");
		element.setAttribute("data-mode", "image");
	}

	onSave(): void {
		this.save.emit();
	}

	ngOnDestroy(): void {
		this.quill = null;
	}

	setIsMouseOver(value: boolean): void {
		this.isMouseOver = value;
	}
}

import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { LessonsCommunicationService } from "../../services/lessons-communication.service";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Router, ActivatedRoute } from "@angular/router";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { ProfileService } from "src/app/profile/profile.service";
import { PopupService } from "src/app/services/popup/popup.service";
import { TranslateModule } from "@ngx-translate/core";
import { MatTooltipModule } from "@angular/material/tooltip";
import { LessonUtils } from "src/app/utils/lesson-utils";

@Component({
	selector: "app-lessons-overview-menu",
	standalone: true,
	imports: [
		CommonModule,
		RouterModule,
		MatIconModule,
		MatButtonModule,
		TranslateModule,
		MatTooltipModule
	],
	templateUrl: "./lessons-overview-menu.component.html",
	styleUrl: "./lessons-overview-menu.component.scss"
})
export class LessonsOverviewMenuComponent implements OnInit {
	@Input() studentsList;
	@Input() userId;
	@Input() timeZone;
	@Input() count: number;
	@Input() page: number;
	@Input() pages: number;
	@Output() backButtonEvent = new EventEmitter<any>();
	@Output() forwardButtonEvent = new EventEmitter<any>();
	studentStatuses: { [key: string]: string } = {};
	isMobile: boolean = false;
	selectedStudentId: string | null;
	allowExternalStudents: boolean = false;
	profile;

	constructor(
		private lessonsComunication: LessonsCommunicationService,
		private breakpointObserver: BreakpointObserver,
		private router: Router,
		private route: ActivatedRoute,
		private profileSevice: ProfileService,
		private popupService: PopupService,
		private lessonUtils: LessonUtils
	) {}

	ngOnInit() {
		this.selectedStudentId = this.studentsList[0]?.id;
		this.breakpointObserver
			.observe([Breakpoints.Handset])
			.subscribe((result) => {
				this.isMobile = result.matches;
			});
		this.profileSevice.get().subscribe((profile) => {
			this.profile = profile;
			this.allowExternalStudents = profile.allowExternals;
		});
		this.calculateLessonPackageStatuses();
	}
	selectStudent(student) {
		this.selectedStudentId = student.id;
		this.lessonsComunication.updateStudentData(student);
	}
	goToSubMenu(student) {
		if (this.isMobile) {
			this.router.navigate(["submenu"], {
				relativeTo: this.route,
				queryParams: { name: student.name }
			});
		} else {
			return;
		}
	}

	handlePreviousPage() {
		this.backButtonEvent.emit();
	}
	handleNextPage() {
		this.forwardButtonEvent.emit();
	}

	public inviteExternalStudent() {
		this.popupService.openInviteStudenDialog();
	}

	calculateLessonPackageStatuses() {
		this.studentsList.forEach((student) => {
			if (student.activationStatus === "activated") {
				this.studentStatuses[student.id] =
					this.lessonUtils.setPackageLessonStatus(
						student.lessonPackage.status
					);
			} else {
				this.studentStatuses[student.id] =
					"lessons-overview.package-status.pending";
			}
		});
	}
}

import { EventEmitter, Injectable, Output } from "@angular/core";
import { UserVO } from "src/app/services/data/UserVO";

@Injectable({
  providedIn: "root",
})
export class UserActionsService {
  @Output() onMenuOpenedEvent = new EventEmitter<UserVO>();

  constructor() {}

  public onMenuOpened(user: UserVO): void {
    this.onMenuOpenedEvent.emit(user);
  }


}
